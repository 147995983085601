import React, { useEffect, useRef, useState } from "react";
import {
  Loader,
  Placeholder,
  Message,
  toaster,
  CheckboxGroup,
  Checkbox,
  Form,
  Schema,
} from "rsuite";
import {
  formatDateToYYYYMMDD,
  readableDate,
  readableDateTime,
} from "../../../Components/readableDate";
import { readableTime } from "../../../Components/readableTime";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ChampHealthApiClient } from "../../../config/apiClients";

const Field = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});

const { ArrayType } = Schema.Types;

const model = Schema.Model({
  appliedDate: ArrayType().isRequired("This field is required."),
});

function CandidateJobDetails() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [JobDetails, setJobDetails] = useState(false);

  const getSingleLiveJobDetails = async () => {
    try {
      const response = await ChampHealthApiClient.get(
        `/jobseekers/details/${id}`
      );
      if (response?.status === 200) {
        setJobDetails(response?.data?.data[0]);
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error?.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 5000 });
      console.log("Create_job_getSingleLiveJobDetails_Error...", error);
    }
  };

  useEffect(() => {
    getSingleLiveJobDetails();
  }, []);

  const renderButton = (props, ref) => {
    return (
      <i
        {...props}
        ref={ref}
        className="fa-solid fa-ellipsis-vertical  text-xl"
      ></i>
    );
  };

  const [noteForRecruiter, setNoteForRecruiter] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const applyToJob = async () => {
    try {
      const response = await ChampHealthApiClient.post(`/jobseekers/applied`, {
        job_id: id,
        notes: noteForRecruiter,
        applied_dates: formValue?.appliedDate,
      });

      if (response?.status === 200) {
        setIsDisabled(false);
        const message = (
          <Message showIcon type="success" header="Success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        navigate("/candidate/jobs?page=applied");
      } else {
        setIsDisabled(false);
        const message = (
          <Message showIcon type="error" closable>
            {response?.response.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      setIsDisabled(false);
      const message = (
        <Message showIcon type="error" closable>
          {error?.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 5000 });
    }
  };

  const handleClick = () => {
    if (formRef.current.check()) {
      setIsDisabled(true);
      applyToJob();
    }
    console.error("Form Error", formError);
  };






  function checkJobConflict(job_date, schedule, alreadyAppliedForDateRange) {


    // Parse the job_date and schedule times
    const jobStart = new Date(job_date);
    const jobEnd = new Date(job_date);

    // Assuming schedule is an array with two elements [startTime, endTime]
    const scheduleStartTime = new Date(schedule[0]);
    const scheduleEndTime = new Date(schedule[1]);

    // Adjust the job start and end times based on the schedule
    jobStart.setHours(scheduleStartTime.getHours(), scheduleStartTime.getMinutes(), scheduleStartTime.getSeconds());
    jobEnd.setHours(scheduleEndTime.getHours(), scheduleEndTime.getMinutes(), scheduleEndTime.getSeconds());


    // Loop through alreadyAppliedForDateRange to check for conflicts
    for (let range of alreadyAppliedForDateRange) {
      const rangeStart = new Date(range[0]);
      const rangeEnd = new Date(range[1]);

      // Subtract one minute from rangeStart
      rangeStart.setMinutes(rangeStart.getMinutes() - 1);

      if ((jobStart >= rangeStart && jobStart < rangeEnd) || // Job start falls within an existing job range
        (jobEnd > rangeStart && jobEnd <= rangeEnd) ||    // Job end falls within an existing job range
        (jobStart <= rangeStart && jobEnd >= rangeEnd)) { // Job envelops an existing job range
        return true; // Conflict found
      }
    }
    console.log("Conflict found");

    return false; // No conflict
  }

  function generateDateHTML(jobDate, startJobDate, scheduleJSON) {
    const endDate = new Date(jobDate);

    const schedule = scheduleJSON;

    const startTime = readableTime(schedule[0]);
    const endTime = readableTime(schedule[1]);


    if (startJobDate) {
      const startDate = new Date(startJobDate);
      const dateHTMLs = [];
      for (
        let currentDate = startDate;
        currentDate <= endDate;
        currentDate.setDate(currentDate.getDate() + 1)
      ) {
        const formattedDate = readableDate(currentDate);
        const html = (
          <Checkbox
            disabled={checkJobConflict(
              currentDate,
              schedule,
              JobDetails?.alreadyAppliedForDateRange,
            )}
            value={`${formatDateToYYYYMMDD(currentDate) +
              schedule[0]?.slice(10) +
              "," +
              formatDateToYYYYMMDD(currentDate) +
              schedule[1]?.slice(10)
              }`}
          >
            <p>
              <span>
                <span className="font-semibold">Date: </span>
                <span className="text-[#7D7D7D]">{formattedDate}</span>
              </span>
              <br />
              <span>
                <span className="font-semibold">Time: </span>
                <span className="text-[#7D7D7D]">
                  {startTime} - {endTime}
                </span>
              </span>
            </p>
          </Checkbox>
        );

        dateHTMLs.push(html);
      }

      return dateHTMLs;
    } else {
      const formattedDate = readableDate(endDate);
      const startTime = readableTime(schedule[0]);
      const endTime = readableTime(schedule[1]);


      const html = (
        <Checkbox
          disabled={checkJobConflict(
            endDate,
            schedule,
            JobDetails?.alreadyAppliedForDateRange,
          )}
          value={`${formatDateToYYYYMMDD(endDate) +
            schedule[0]?.slice(10) +
            "," +
            formatDateToYYYYMMDD(endDate) +
            schedule[1]?.slice(10)
            }`}
        >
          <p>
            <span>
              <span className="font-semibold">Date: </span>
              <span className="text-[#7D7D7D]">{formattedDate}</span>
            </span>
            <br />
            <span>
              <span className="font-semibold">Time: </span>
              <span className="text-[#7D7D7D]">
                {startTime} - {endTime}
              </span>
            </span>
          </p>
        </Checkbox>
      );

      return html;
    }
  }

  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({});

  return (
    <>
      <div className=" relative">
        <section className="py-6 bg-[#F1F4F8]">
          <div className="container divide-y divide-y-4 space-y-6">
            <div className="flex justify-between md:flex items-center lg:items-center md:space-x-2 md:justify-between w-full lg:py-6">
              {/* <!-- job List Search bar --> */}
              <div className="md:w-1/2 w-full lg:flex lg:items-center lg:justify-between lg:w-1/2 lg:space-x-4 xl:w-2/3 xl:justify-between my-2 ml-[4px]">
                <div
                  onClick={() => navigate("/candidate/jobs")}
                  className="hover:cursor-pointer inline-flex items-center gap-2 font-semibold text-black "
                >
                  {JobDetails ? (
                    <>
                      {" "}
                      <i className="fa-solid text-black fa-arrow-left-long text-2xl"></i>
                      <h1 className="text-2xl whitespace-nowrap">
                        {JobDetails.jp_position}
                      </h1>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <main className="container w-full h-full relative flex flex-col justify-center content-center">
          <div className="mt-8  bg-white py-4 rounded-lg">
            <div className="w-full">
              {JobDetails ? (
                <>
                  <Form
                    fluid
                    ref={formRef}
                    onCheck={setFormError}
                    onChange={setFormValue}
                    formValue={formValue}
                    className="relative"
                    model={model}
                  >
                    <div className="relative bg-white rounded  ">
                      {/* <!-- Modal body --> */}
                      <div className="">
                        <div className="job-seeker-details flex flex-col items-start pb-6">
                          <div className="flex flex-col">
                            <p className="ml-0 pl-0 text-3xl font-medium text-black">
                              {JobDetails.name}
                            </p>
                            <p className="text-[12px] text-champText400">
                              <span>{readableDate(JobDetails.createdAt)} </span>
                            </p>
                          </div>
                        </div>

                        <div className="py-6 flex flex-col gap-8 border-t-4 border-gray-200 rounded-b">
                          <div className="flex flex-col gap-4">
                            <p className="font-normal text-base">
                              <i className="fa-solid fa-location-dot pr-2"></i>
                              {JobDetails.address}
                            </p>
                          </div>

                          <div className="flex flex-col gap-4">
                            <p className="font-normal text-base flex flex-col">
                              <span className="text-2xl font-medium">
                                Employee Type:
                              </span>
                              <span className="text-base text-champText400">
                                {" "}
                                {JobDetails.job_type}
                              </span>
                            </p>
                            <p className="font-normal text-base flex flex-col">
                              <span className="text-2xl font-medium">
                                Shift:
                              </span>
                              <span className="text-base text-champText400">
                                {" "}
                                {JobDetails.job_shift}
                              </span>
                            </p>
                            <p className="font-normal text-base flex flex-col">
                              <span className="text-2xl font-medium">
                                Schedule:
                              </span>
                              <p className=" font-normal text-sm text-champText400 my-2">
                                <Field
                                  name="appliedDate"
                                  accepter={CheckboxGroup}
                                >
                                  {generateDateHTML(
                                    JobDetails?.job_date,
                                    JobDetails?.start_job_date,
                                    JobDetails?.schedule
                                  )}
                                </Field>
                                {/* <CheckboxGroup name="applied_dates">
                                  {generateDateHTML(
                                    JobDetails?.job_date,
                                    JobDetails?.start_job_date,
                                    JobDetails?.schedule
                                  )}
                                </CheckboxGroup> */}
                              </p>
                              {/* 

                            <p>


                              <span>
                                <span className="font-semibold">Date: </span>
                                {readableDate(JobDetails.job_date)}
                              </span>
                              <br />
                              <span>
                                <span className="font-semibold">Time: </span>

                                {readableTime(JobDetails.schedule[0]) +
                                  " - " +
                                  readableTime(JobDetails.schedule[1])}
                              </span>
                            </p> */}
                            </p>
                            <p className="font-normal text-base flex flex-col">
                              <span className="text-2xl font-medium">
                                Job description:
                              </span>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: JobDetails.job_description,
                                }}
                              />
                            </p>
                          </div>
                        </div>

                        <div className="py-6 flex flex-col gap-8 border-t-4 border-gray-200 rounded-b">
                          <div className="flex flex-col gap-4">
                            <div className="w-full pt-2">
                              <p className="text-black text-2xl font-semibold pb-4">
                                Note
                              </p>
                              <div className="flex justify-between items-center">
                                <ReactQuill
                                  className="border-0 bg-[#F8FBFF] my-2 w-full"
                                  theme="snow"
                                  value={noteForRecruiter}
                                  onChange={(event) => {
                                    setNoteForRecruiter(event);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full text-center py-4 ">
                          <button
                            className={`border-2 px-12 pt-[9px] py-[11px] rounded-[5px] text-lg font-normal  bg-blueBtnDark text-white border-blueBtnDark`}
                            onClick={handleClick}
                            type="submit"
                            disabled={isDisabled}
                          >
                            Confirmed
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              ) : (
                <>
                  <div className="relative">
                    <div
                      className="absolute left-0 right-0 m-auto my-auto top-12 "
                      style={{ textAlign: "center" }}
                    >
                      <Loader size="md" />
                    </div>
                    <Placeholder.Paragraph
                      style={{ marginTop: 30 }}
                      rows={5}
                      active
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default CandidateJobDetails;
