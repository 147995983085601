import React, { useEffect, useState } from "react";
import { DatePicker, Loader, Message, Placeholder, SelectPicker, useToaster } from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../../config/apiKeys";
import axios from "axios";
import { ChampHealthApiClient } from "../../../../config/apiClients";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function AdminClientDetails() {
  const toaster = useToaster();
  const navigate = useNavigate();
  const {clientId} = useParams();

  const [clientDetails, setClientDetails] = useState(false)



  const getClientDetails = async() => {
    try {
      const response =  await ChampHealthApiClient.get(`/admin/clients/details/${clientId}`)
      if(response.status === 200){
        setClientDetails(response?.data?.data[0])
      }
      else{
        const message = (
          <Message showIcon type="error"  closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        console.log("detail_client_error_page....", response);
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error"  closable>
          {error?.response?.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
      console.log("detail_client_error_page...", error);
      
    }

  }

  useEffect(()=>{
    getClientDetails();
  },[])



  return (
    <main>
      {/* <!------ Top section  ------> */}
      <section className="py-8 bg-[#F1F4F8]">
        <div className="container mx-auto md:flex md:justify-between md:gap-2 lg:flex lg:justify-between w-full">
          <div
            onClick={() => navigate("/admin/list-client")}
            className="hover:cursor-pointer inline-flex items-center gap-2 font-semibold text-black "
          >
            <i className="fa-solid fa-arrow-left-long text-2xl"></i>
            <h1 className="text-2xl whitespace-nowrap">Client detail</h1>
          </div>
        </div>
      </section>

      {/* <!------ Main Section  ------> */}
      <section className="py-8 bg-white">
        {clientDetails ? (
        <div className="container flex flex-col space-y-10">

          <div className="flex flex-col space-y-2">
            <div className="inline-flex items-center space-x-2 border-[2px] border-champBorder p-6 py-6 text-black">
              <div className="bg-champAvatarBg rounded-full text-white h-10 w-10 text-xl font-medium flex justify-center items-center  text-center uppercase">
                {" "}
                <p>{clientDetails.residence_name.charAt(0)}</p>
              </div>
              <p className="text-xl font-medium uppercase">{clientDetails.residence_name}</p>
            </div>
          </div>

          <div className="flex space-x-4 text-lg">
            <div className="flex space-x-2">
              <span className=" font-normal text-champText400">
                Customer name:
              </span>
              <span className="text-black font-medium">{clientDetails.name}</span>
            </div>
            <div className="flex space-x-2">
              <span className=" font-normal text-champText400">Position:</span>
              <span className="text-black font-medium">{clientDetails.relation}</span>
            </div>
          </div>


       

          <div className="flex flex-wrap lg:grid lg:grid-cols-2 md:border-[1px] md:border-champBorder text-black">
            <div className="flex flex-col lg:items-center h-full w-full border border-champBorder p-4">
              <div>
                <p className="text-start  text-champText400 font-normal text-base">
                  Main phone :{" "}
                </p>
                <p className="text-start text-black font-medium text-lg">
                  {clientDetails.main_phone}
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:items-center h-full w-full  border border-champBorder p-4">
              <div>
                <p className="text-start  text-champText400 font-normal text-base">
                Cell phone :{" "}
                </p>
                <p className="text-start text-black font-medium text-lg">
                {clientDetails?.cell_phone}
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:items-center h-full w-full  border  border-champBorder p-4">
              <div>
                <p className="text-start  text-champText400 font-normal text-base">
                Email :{" "}
                </p>
                <p className="text-start text-black font-medium text-lg">
                {clientDetails.email_address}
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:items-center h-full w-full   border border-champBorder p-4">
              <div>
                <p className="text-start  text-champText400 font-normal text-base">
                Account Payable Email :{" "}
                </p>
                <p className="text-start text-black font-medium text-lg">
                {clientDetails.payable_email}
                </p>
              </div>
            </div>
          </div>
         

          <div className="flex space-x-4 text-base">
            <div className="flex space-x-2">
              <span className=" font-normal text-champText400">
                Mode Of Communication:
              </span>
              <span className="text-black font-medium">
                {clientDetails.communication_mode.map((item, i)=>(
                  <>
                  {clientDetails.communication_mode.length-1 === i ? (<span>{item}</span>):(<><span>{item}</span> ,</>)}
                  </>
                  
                ))}
              </span>
            </div>
          </div>

          <div className="flex space-x-4 text-base">
            <div className="flex space-x-2">
              <span className=" font-normal text-champText400">
                <i className="fa-solid fa-location-dot pr-2"></i>
              </span>
              <span className="text-black font-medium">
                {`${clientDetails.street_address}    ${clientDetails.city}, ${clientDetails.province} , ${clientDetails.postal_code} `}
              </span>
            </div>
          </div>

          
          {clientDetails?.main_city ? 
          <div className="flex flex-col text-base">
            <h2>Main Address :</h2>
            <div className="flex space-x-2">
              <span className=" font-normal text-champText400">
                <i className="fa-solid fa-location-dot pr-2"></i>
              </span>
              <span className="text-black font-medium">
                {`${clientDetails?.main_street_address}    ${clientDetails?.main_city}, ${clientDetails?.main_province} , ${clientDetails?.main_postal_code} `}
              </span>
            </div>
          </div>:''}

          <hr className="my-4 border-t-2 border-gray-600 " />
          <div className="hidden">
         
      

          <div className="flex space-x-4 text-xl">
            <div className="flex space-x-2">
              <span className="text-black font-medium">Billing address</span>
            </div>
          </div>



          <div className="flex space-x-4 text-base">
            <div className="flex space-x-2">
              <span className=" font-normal text-champText400">Phone:</span>
              <span className="text-black font-medium">{clientDetails.billing_phone}</span>
            </div>
            <div className="flex space-x-2">
              <span className=" font-normal text-champText400">Email:</span>
              <span className="text-black font-medium">{clientDetails.billing_email_address}</span>
            </div>
          </div>

          <div className="flex space-x-4 text-base">
            <div className="flex space-x-2">
              <span className=" font-normal text-champText400">
                <i className="fa-solid fa-location-dot pr-2"></i>
              </span>
              <span className="text-black font-medium">
              {`${clientDetails.billing_street_address}, ${clientDetails.billing_postal_code}, ${clientDetails.billing_city}, ${clientDetails.billing_province} `}
              </span>
            </div>
          </div>

          </div>

        </div>):(
          <>
          <div className="relative container">
              <div
                className="absolute left-0 right-0 m-auto my-auto top-12 "
                style={{ textAlign: "center" }}
              >
                <Loader size="md" />
              </div>
              <Placeholder.Paragraph
                style={{ marginTop: 30 }}
                rows={8}
                active
              />
            </div>
          </>
        )}
      </section>
    </main>
  );
}

export default AdminClientDetails;
