function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const BEARERD_TOKEN = getCookie("token");
export const BEARER_TOKEN = getCookie("token");

const user = getCookie("user");
export const USER_DETAIL = user ? JSON.parse(user) : ''

export const CONFIG_HEADER = {
  headers: {
    'Authorization': `Bearer ${BEARERD_TOKEN}`
  }
};



export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  // d.setTime(d.getTime() + (60*1000));
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
