import axios from "axios";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {
  Form,
  InputPicker,
  DateRangePicker,
  Schema,
  SelectPicker,
  TagPicker,
  CheckPicker,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Message,
  useToaster,
  DatePicker,
  Toggle,
} from "rsuite";
import { useNavigate } from "react-router-dom";
import { ChampHealthApiClient } from "../../../config/apiClients";
import ScheduleDateRangePicker from "../../../Components/inputFields/ScheduleDateRangePicker";
import TextFieldBottomBorder from "../../../Components/TextFieldBottomBorder";
import { ObjectDate } from "../../../Components/objectDate";

const { beforeToday } = DateRangePicker;
const { StringType, ArrayType, NumberType, ObjectType, MixedType } = Schema.Types;

const model = Schema.Model({
  company_name: NumberType().isRequired("This field is required."),
  street_address: StringType()
    .pattern(
      /^[\/a-zA-Z0-9\s.!(),@#&*',\-\u0080-\u052F]*$/,
      "Please enter legal characters"
    )
    .isRequired("This field is required."),
  postion: NumberType().isRequired("This field is required."),
  date: ObjectType().isRequired('This field is required.'),
  // schedule: ArrayType().isRequired("This field is required."),
  schedule0: ObjectType().isRequired("This field is required."),
  schedule1: ObjectType().isRequired("This field is required."),
  job_shift: NumberType().isRequired("This field is required."),
  candidates_required: StringType()
    .pattern(/^[1-9][0-9]*$/, "Please enter only Positive Numbers")
    .isRequired("This field is required."),
  availability_other: MixedType().when(schema => {
    return schema.job_shift.value === 8
      ? StringType().isRequired('This field is required.')
      : StringType();
  })
});



const CreateJob = () => {
  const navigate = useNavigate()
  const [disableSubmit, setDisableSubmit] = useState(false)


  // multiple 
  const [checked, setChecked] = useState(false);

  const [companyList, setCompanyList] = useState(false);
  const [jobPostions, setJobPostions] = useState(false);
  const [jobTypes, setJobTypes] = useState(false);
  const [jobShift, setjobShift] = useState(false);

  const toaster = useToaster();

  //// detail form
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
  });


  const [companyListWithAddress, setCompanyListWithAddress] = useState(false);
  // fetch companylist
  const getCompanyList = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/common/company-name`);
      if (response?.status === 200) {
        setCompanyListWithAddress(response?.data?.data);
        setCompanyList(
          response?.data?.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      }


    } catch (error) {


      console.log("Create_Client_Job_response_Error...", error);
    }
  };

  // fetch company address by company id
  const getCompanyAddress = async (id) => {
    if (!id) {
      setFormValue({ ...formValue, street_address: "", email_address: "" });
      return;
    }
    const filteredData = companyListWithAddress.filter(item => item.id === id);
    setFormValue({ ...formValue, street_address: filteredData[0].address, email_address: filteredData[0].email_address });

  };

  // fetch AllJobPostion
  const getAllJobPostion = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/common/job-positions`);

      if (response?.status === 200) {
        setJobPostions(
          response?.data?.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.log("Create_job_getAllJobPostion_response_Error...", error);
    }
  };

  // fetch getAllJobTypes
  const getAllJobTypes = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/common/job-types`);
      console.log("Create_job_getAllJobTypes_response....", response);

      if (response?.status === 200) {
        setJobTypes(
          response?.data?.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.log("Create_job_getAllJobTypes_response_Error...", error);
    }
  };



  // fetch getAllJobTypes
  const getAllJobShift = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/common/job-shifts`);

      if (response?.status === 200) {
        setjobShift(
          response?.data?.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.log("Create_job_getAllJobShift_response_Error...", error);
    }
  };

  const createJobs = async () => {

    try {
      const response = await ChampHealthApiClient.post(`/admin/jobs/add`, {
        ...formValue,
        company_id: formValue.company_name,
        address: formValue.street_address,
        job_position: formValue.postion,
        job_date: checked ? ObjectDate(formValue.date[1]) : ObjectDate(formValue.date),
        start_job_date: checked ? ObjectDate(formValue.date[0]) : '',
        schedule: [ObjectDate(formValue.schedule0), ObjectDate(formValue.schedule1)],
        job_type: formValue.job_type,
        job_shift: formValue.job_shift,
        job_description: formValue.job_description,
        candidates_required: +formValue.candidates_required,
      });


      if (response.status === 200) {
        setFormValue({
          company_name: '',
          postion: '',
          street_address: '',
          date: '',
          job_shift: '',
          job_type: '',
          schedule: '',
          job_description: '',
          candidates_required: '',
          date: ''
        });
        setDisableSubmit(false)
        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      } else {
        const message = (
          <Message showIcon type="error"  closable>
            {response.response.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      setDisableSubmit(false)
      console.log("Create_job_createJobs_response_Error...", error);

      const message = (
        <Message showIcon type="error"  closable>
          {error.response.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
    }
  };

  const handleSubmit = async () => {
    if (formRef.current.check()) {
      setDisableSubmit(true)
      createJobs();
      return;
    }
  };

  useEffect(() => {
    getCompanyList();
    getAllJobPostion();
    getAllJobTypes();
    getAllJobShift();
  }, []);

  useEffect(() => {
    if (formValue) {
      getCompanyAddress(formValue.company_name);
    }
  }, [formValue?.company_name]);



  return (
    <main>
      <section className="py-8 bg-[#F1F4F8]">
        <div className="container mx-auto md:flex md:justify-between md:gap-2 lg:flex lg:justify-between w-full">
          {/* <!-- Client List Search bar --> */}
          <div
            onClick={() => navigate("/admin/list-jobs")}
            className="hover:cursor-pointer inline-flex items-center gap-2 font-semibold text-black "
          >
            <i className="fa-solid fa-arrow-left-long text-2xl"></i>
            <h1 className="text-[30px] text-black whitespace-nowrap">
              Create Job
            </h1>
          </div>
        </div>
      </section>

      {/* <!-- Create Client Form --> */}

      <section className="py-8 bg-white">
        <div className="container">
          {/* <!-- Createjob form selection --> */}
          <div className="form-container flex flex-col space-y-16 md:py-12">
            {/* <!-- form Form --> */}
            <Form
              fluid
              ref={formRef}
              onChange={setFormValue}
              onCheck={setFormError}
              formValue={formValue}
              model={model}
              className="relative flex flex-col space-y-24"
            >
              <div className="md:grid md:grid-cols-2 md:gap-x-16 gap-4 items-center flex flex-col">
                <TextFieldBottomBorder
                  name="company_name"
                  searchable={false}
                  data={companyList ? companyList : []}
                  accepter={SelectPicker}
                  label="Client Name : *"
                />
                <TextFieldBottomBorder
                  readOnly={true}
                  name="street_address"
                  label="Street Address *"
                />
                <TextFieldBottomBorder
                  name="postion"
                  searchable={false}
                  data={jobPostions ? jobPostions : []}
                  accepter={SelectPicker}
                  label="Position : *"
                />

                <TextFieldBottomBorder
                  name="date"
                  format="MM-dd-yyyy"
                  showOneCalendar
                  checked={checked}
                  onToggle={res => {
                    setChecked(res);
                    setFormValue(prevState => ({
                      ...prevState,
                      date: null
                    }))
                  }}
                  accepter={checked ? DateRangePicker : DatePicker}
                  disabledDate={beforeToday()}
                  ranges={[]}
                  label="Shift Date : *"
                  placeholder={checked ? "mm-dd-yyyy - mm-dd-yyyy" : "mm-dd-yyyy"}
                  oneTap={!checked}
                />

                <ScheduleDateRangePicker setChanges={setFormValue} value={formValue} label="Schedule : *" name="schedule" />

                <TextFieldBottomBorder
                  name="job_type"
                  searchable={false}
                  data={jobTypes ? jobTypes : []}
                  accepter={SelectPicker}
                  label="Job Type :"
                />
                <div className="flex space-x-2 justify-between items-end w-full">

                  <TextFieldBottomBorder
                    name="job_shift"
                    searchable={false}
                    data={jobShift ? jobShift : []}
                    accepter={SelectPicker}
                    label="Shift :  *"
                  />

                  {formValue?.job_shift === 8 ? <TextFieldBottomBorder
                    name="availability_other"
                    label="Other : *"
                    placeholder="enter your custom value..."
                  /> : ''}

                </div>
                <TextFieldBottomBorder
                  name="candidates_required"
                  label="Staff Required : *"
                />
                <div className="md:col-span-2 w-full">
                  <label className="text-sm text-[#A1A1A1] font-semibold duration-300">Job Description / Comments : </label>
                  <ReactQuill className="border-0 bg-[#fafafaf0] my-2" theme="snow" value={formValue?.job_description} onChange={(event) => {
                    setFormValue({
                      ...formValue,
                      job_description: event
                    })
                  }} />

                </div>
              </div>

              <button
                type="submit"
                onClick={handleSubmit}
                className="text-white bg-blue-900  font-medium rounded text-sm mx-auto font-semibold text-2xl px-12 py-3 mt-8 text-center"
                disabled={disableSubmit}
              >
                Create Job
              </button>
            </Form>
          </div>

        </div>
      </section>

    </main>
  );
};

export default CreateJob;
