import React, { useEffect, useState } from "react";

import { Dropdown, Loader, Message, Placeholder, toaster, useToaster } from "rsuite";
import { ChampHealthApiClient } from "../../../config/apiClients";
import { API_BASE_For_File_Upload } from "../../../config/apiKeys";
import { useNavigate } from "react-router-dom";

const renderButton = (props, ref) => {
  return (
    <i
      {...props}
      ref={ref}
      className="fa-solid fa-ellipsis-vertical  text-xl"
    ></i>
  );
};

function UpdateJobSeekerDocuments({jobSeekerId}) {

  const [documents, setDocuments] = useState(false);

  const getProfileDocuments = async () => {
    try {
      const response = await ChampHealthApiClient.get("/jobseekers/list-documents/"+jobSeekerId);
      if (response?.status === 200) {
        setDocuments(response?.data?.data[0]);
      } else {
        const message = (
          <Message showIcon type="error"  closable>
            {response?.data?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error"  closable>
          {error.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
    }
  };



  const navigate = useNavigate();
  const toaster = useToaster();

  const [isResumeUploading, setIsResumeUploading] = useState(false);
  const [isVaccineUploading, setIsVaccineUploading] = useState(false);
  const [isDiplomaUploading, setIsDiplomaUploading] = useState(false);
  const [isOtherUploading, setIsOtherUploading] = useState(false);

  const [isResumeUploaded, setIsResumeUploaded] = useState(false);
  const [isVaccineUploaded, setIsVaccineUploaded] = useState(false);
  const [isDiplomaUploaded, setIsDiplomaUploaded] = useState(false);
  const [isOtherUploaded, setIsOtherUploaded] = useState(false);


  const [documentsUpload, setDocumentsUpload] = useState({
    resume: false,
    vaccine: false,
    diploma: false,
    other: false,
  })

  const UploadFile = async (fileInput, id) => {
    let formdata = new FormData();
    formdata.append("docs", fileInput.files[0]);
    formdata.append("doc_type", id);
    formdata.append("jobseeker_id", jobSeekerId);



    let requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${API_BASE_For_File_Upload}/jobseekers/upload-docs/`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response?.status) {
          const message = (
            <Message showIcon type="success" header="success" closable>
              {response?.message}
            </Message>
          );
          toaster.push(message, { placement: "topEnd", duration: 3000 });
        }
        getProfileDocuments()
        setIsResumeUploading(false)
          setIsVaccineUploading(false)
          setIsDiplomaUploading(false)
        setIsOtherUploading(false)

        //  id ===1 ?  setIsResumeUploaded(true):'';
        //  id ===2 ?  setIsVaccineUploaded(true):'';
        //  id ===3 ?  setIsDiplomaUploaded(true):'';
        //  id ===4 ?  setIsOtherUploaded(true):'';

      })
      .catch((error) => {
        const message = (
          <Message showIcon type="error"  closable>
            {error?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        setIsResumeUploading(false);
        setIsVaccineUploading(false);
        setIsDiplomaUploading(false);

      });
  };



  useEffect(() => {
    getProfileDocuments()
  }, [])



  const deleteDocument = async(doc_type)=>{

    try {
      const response = await ChampHealthApiClient.patch('/jobseekers/delete-documents/'+jobSeekerId, {doc_name:doc_type});

      if (response.status === 200) {
        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        getProfileDocuments()
      } else {
        const message = (
          <Message showIcon type="error"  closable>
            {response?.data?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error"  closable>
          {error?.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
    }
  }




  return (
    <>
      {documents ? (<section className="w-full flex flex-col gap-6">

        <div className="flex items-start gap-6 w-full">
          <div className="w-[230px] whitespace-nowrap">
            <h3 className="text-xl font-medium text-[#7D7D7D]">Resume: </h3>
          </div>
          <div className="w-full">
            {documents?.resume ?
              <div className="flex justify-between items-center">
                <div className="inline-flex gap-8 border-[1px] border-[#94A1CE]  px-4 py-2 text-xl font-medium text-black">
                  <a href={documents?.resume} download="Resume" className=" text-[#0066FF] inline-flex gap-2 text-center items-center">
                    <svg
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5257 1.33105V5.63397H16.8287M1.33643 1.33105H13.8166L16.8287 4.3431V20.2639H1.33815L1.33643 1.33105Z"
                        stroke="#0066FF"
                        stroke-width="1.5"
                      />
                      <path
                        d="M17.1791 4.80176L13.7922 1.14844L12.7297 1.68114V5.56597H16.4485L17.1791 4.80176Z"
                        fill="#0066FF"
                      />
                      <circle
                        cx="9.02808"
                        cy="12.4111"
                        r="4.03418"
                        fill="#0066FF"
                      />
                      <path d="M7.22998 12.4102H11.1812" stroke="white" />
                    </svg>
                    Resume
                  </a>
                  <Dropdown renderToggle={renderButton} placement="bottomEnd">
                    <h3 onClick={()=>deleteDocument('resume')} className="hover:cursor-pointer text-base  py-1 px-4 font-medium hover:bg-grayBg">
                      Delete
                    </h3>
                  </Dropdown>
                </div>
                <label
                htmlFor="resume"
                
              >
                <input
                  className="hidden"
                  type="file"
                  name="resume"
                  id="resume"
                  onChange={(e) => {
                    setDocumentsUpload((prevState) => ({ ...prevState, resume: e.target }))
                    UploadFile(e.target, 1)
                    setIsResumeUploading(true);
                  }}
                />
                {isResumeUploading ? (
                  <>
                    <div className="flex justify-between items-center mx-2 border-blueBtnDark w-full">
                      <Loader speed="fast" />
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                   
                    <a className="hover:cursor-pointer text-[#0066FF] text-lg font-semibold decoration-none"
                > <i className="fa-solid fa-file-arrow-up mr-2"></i>{" "}
                  Upload new
                </a>
                  </>
                )}
              </label>
              </div>
              :
              <div className="hover:cursor-pointer w-full text-center m-auto border py-2 px-2 pr-6 rounded-[5px] text-lg font-normal text-blueBtnDark border-blueBtnDark">
              <label
                htmlFor="resume"
                
              >
                <input
                  className="hidden"
                  type="file"
                  name="resume"
                  id="resume"
                  onChange={(e) => {
                    setDocumentsUpload((prevState) => ({ ...prevState, resume: e.target }))
                    UploadFile(e.target, 1)
                    setIsResumeUploading(true);
                  }}
                />
                {isResumeUploading ? (
                  <>
                    <div className="flex justify-between items-center mx-2 border-blueBtnDark w-full">
                      <p className="overflow-auto ">
                        {documentsUpload?.resume?.files[0]?.name}</p>
                    </div>
                  </>
                ) : (
                  <>
                  <div className="w-full hover:cursor-pointer ">
                    {" "}
                    <i className="fa-solid fa-file-arrow-up mr-2"></i>{" "}
                    Upload Resume
                    </div>
                  </>
                )}
              </label>
              </div>

            }

            <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
          </div>
        </div>

        <div className="flex items-start gap-6 w-full">
          <div className="w-[230px] whitespace-nowrap">
            <h3 className="text-xl font-medium text-[#7D7D7D]">Vaccine <br /> Certification: </h3>
          </div>
          <div className="w-full">
            {documents?.vaccine ?
              <div className="flex justify-between items-center">
                <div className="inline-flex gap-8 border-[1px] border-[#94A1CE]  px-4 py-2 text-xl font-medium text-black">
                  <a href={documents?.vaccine} download className=" text-[#0066FF] inline-flex gap-2 text-center items-center">
                    <svg
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5257 1.33105V5.63397H16.8287M1.33643 1.33105H13.8166L16.8287 4.3431V20.2639H1.33815L1.33643 1.33105Z"
                        stroke="#0066FF"
                        stroke-width="1.5"
                      />
                      <path
                        d="M17.1791 4.80176L13.7922 1.14844L12.7297 1.68114V5.56597H16.4485L17.1791 4.80176Z"
                        fill="#0066FF"
                      />
                      <circle
                        cx="9.02808"
                        cy="12.4111"
                        r="4.03418"
                        fill="#0066FF"
                      />
                      <path d="M7.22998 12.4102H11.1812" stroke="white" />
                    </svg>
                    Vaccine
                  </a>
                  <Dropdown renderToggle={renderButton} placement="bottomEnd">
                    <h3 onClick={()=>deleteDocument('vaccine')} className="hover:cursor-pointer text-base  py-1 px-4 font-medium hover:bg-grayBg">
                      Delete
                    </h3>
                  </Dropdown>
                </div>
               
                <label
                htmlFor="Vaccine"
                
              >
                <input
                  className="hidden"
                  type="file"
                  name="Vaccine"
                  id="Vaccine"
                  onChange={(e) => {
                    setDocumentsUpload((prevState) => ({ ...prevState, vaccine: e.target }))
                    UploadFile(e.target, 2)
                    setIsVaccineUploading(true);
                  }}
                />
                {isVaccineUploading ? (
                  <>
                    <div className="flex justify-between items-center mx-2 border-blueBtnDark w-full">
                      <Loader speed="fast" />
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                   
                    <a className="hover:cursor-pointer text-[#0066FF] text-lg font-semibold decoration-none"
                > <i className="fa-solid fa-file-arrow-up mr-2"></i>{" "}
                  Upload new
                </a>
                  </>
                )}
              </label>


              </div>
              :
              <div className="hover:cursor-pointer w-full text-center m-auto border py-2 px-2 pr-6 rounded-[5px] text-lg font-normal text-blueBtnDark border-blueBtnDark">
              <label
                htmlFor="Vaccine"
                
              >
                <input
                  className="hidden"
                  type="file"
                  name="Vaccine"
                  id="Vaccine"
                  onChange={(e) => {
                    setDocumentsUpload((prevState) => ({ ...prevState, vaccine: e.target }))
                    UploadFile(e.target, 2)
                    setIsVaccineUploading(true);
                  }}
                />
                {isVaccineUploading ? (
                  <>
                    <div className="flex justify-between items-center mx-2 border-blueBtnDark w-full">
                      <p className="overflow-auto ">
                        {documentsUpload?.vaccine?.files[0]?.name}</p>
                    </div>
                  </>
                ) : (
                  <>
                   <div className="w-full hover:cursor-pointer ">
                    {" "}
                    <i className="fa-solid fa-file-arrow-up mr-2"></i>{" "}
                    Upload Vaccine Certification
                    </div>
                  </>
                )}
              </label>
              </div>

            }

            <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
          </div>
        </div>



        <div className="flex items-start gap-6 w-full">
          <div className="w-[230px] whitespace-nowrap">
            <h3 className="text-xl font-medium text-[#7D7D7D]">Diploma: </h3>
          </div>
          <div className="w-full">
            {documents?.diploma ?
              <div className="flex justify-between items-center">
                <div className="inline-flex gap-8 border-[1px] border-[#94A1CE]  px-4 py-2 text-xl font-medium text-black">
                  <a href={documents?.diploma} download className=" text-[#0066FF] inline-flex gap-2 text-center items-center">
                    <svg
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5257 1.33105V5.63397H16.8287M1.33643 1.33105H13.8166L16.8287 4.3431V20.2639H1.33815L1.33643 1.33105Z"
                        stroke="#0066FF"
                        stroke-width="1.5"
                      />
                      <path
                        d="M17.1791 4.80176L13.7922 1.14844L12.7297 1.68114V5.56597H16.4485L17.1791 4.80176Z"
                        fill="#0066FF"
                      />
                      <circle
                        cx="9.02808"
                        cy="12.4111"
                        r="4.03418"
                        fill="#0066FF"
                      />
                      <path d="M7.22998 12.4102H11.1812" stroke="white" />
                    </svg>
                    Diploma
                  </a>
                  <Dropdown renderToggle={renderButton} placement="bottomEnd">
                    <h3 onClick={()=>deleteDocument('diploma')} className="hover:cursor-pointer text-base  py-1 px-4 font-medium hover:bg-grayBg">
                      Delete
                    </h3>
                  </Dropdown>
                </div>
                    
                <label
                htmlFor="diploma"
                
              >
                <input
                  className="hidden"
                  type="file"
                  name="diploma"
                  id="diploma"
                  onChange={(e) => {
                    setDocumentsUpload((prevState) => ({ ...prevState, diploma: e.target }))
                    UploadFile(e.target, 3)
                    setIsDiplomaUploading(true);
                  }}
                />
                {isDiplomaUploading ? (
                  <>
                    <div className="flex justify-between items-center mx-2 border-blueBtnDark w-full">
                      <Loader speed="fast" />
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                   
                    <a className="hover:cursor-pointer text-[#0066FF] text-lg font-semibold decoration-none"
                > <i className="fa-solid fa-file-arrow-up mr-2"></i>{" "}
                  Upload new
                </a>
                  </>
                )}
              </label>
              </div>
              :
              <div className="hover:cursor-pointer w-full text-center m-auto border py-2 px-2 pr-6 rounded-[5px] text-lg font-normal text-blueBtnDark border-blueBtnDark">
              <label
                htmlFor="diploma"
                
              >
                <input
                  className="hidden"
                  type="file"
                  name="diploma"
                  id="diploma"
                  onChange={(e) => {
                    setDocumentsUpload((prevState) => ({ ...prevState, diploma: e.target }))
                    UploadFile(e.target, 3)
                    setIsDiplomaUploading(true);
                  }}
                />
                {isDiplomaUploading ? (
                  <>
                    <div className="flex justify-between items-center mx-2 border-blueBtnDark w-full">
                      <p className="overflow-auto ">
                        {documentsUpload?.diploma?.files[0]?.name}</p>
                    </div>
                  </>
                ) : (
                  <>
                   <div className="w-full hover:cursor-pointer ">
                    {" "}
                    <i className="fa-solid fa-file-arrow-up mr-2"></i>{" "}
                    Upload Diploma
                    </div>
                  </>
                )}
              </label>
              </div>

            }

            <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
          </div>
        </div>



        <div className="flex items-start gap-6 w-full">
          <div className="w-[230px] whitespace-nowrap">
            <h3 className="text-xl font-medium text-[#7D7D7D]">
              Other documents:{" "}
            </h3>
          </div>
          <div className="w-full">
            {documents?.other_doc ?
               <div className="flex justify-between items-center">
               <div className="inline-flex gap-8 border-[1px] border-[#94A1CE]  px-4 py-2 text-xl font-medium text-black">
                 <a href={documents?.other_doc} download className=" text-[#0066FF] inline-flex gap-2 text-center items-center">
                   <svg
                     width="18"
                     height="22"
                     viewBox="0 0 18 22"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <path
                       d="M12.5257 1.33105V5.63397H16.8287M1.33643 1.33105H13.8166L16.8287 4.3431V20.2639H1.33815L1.33643 1.33105Z"
                       stroke="#0066FF"
                       stroke-width="1.5"
                     />
                     <path
                       d="M17.1791 4.80176L13.7922 1.14844L12.7297 1.68114V5.56597H16.4485L17.1791 4.80176Z"
                       fill="#0066FF"
                     />
                     <circle
                       cx="9.02808"
                       cy="12.4111"
                       r="4.03418"
                       fill="#0066FF"
                     />
                     <path d="M7.22998 12.4102H11.1812" stroke="white" />
                   </svg>
                   Other Document
                 </a>
                 <Dropdown renderToggle={renderButton} placement="bottomEnd">
                   <h3 onClick={()=>deleteDocument('other_doc')} className="hover:cursor-pointer text-base  py-1 px-4 font-medium hover:bg-grayBg">
                     Delete
                   </h3>
                 </Dropdown>
               </div>
              

               <label
                htmlFor="otehrFile"
                
              >
                <input
                  className="hidden"
                  type="file"
                  name="otehrFile"
                  id="otehrFile"
                  onChange={(e) => {
                    setDocumentsUpload((prevState) => ({ ...prevState, other: e.target }))
                    UploadFile(e.target, 4)
                    setIsOtherUploading(true);
                  }}
                />
                {isOtherUploading ? (
                  <>
                    <div className="flex justify-between items-center mx-2 border-blueBtnDark w-full">
                      <Loader speed="fast" />
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                   
                    <a className="hover:cursor-pointer text-[#0066FF] text-lg font-semibold decoration-none"
                > <i className="fa-solid fa-file-arrow-up mr-2"></i>{" "}
                  Upload new
                </a>
                  </>
                )}
              </label>


             </div>
              :
              <div className="hover:cursor-pointer w-full text-center m-auto border py-2 px-2 pr-6 rounded-[5px] text-lg font-normal text-blueBtnDark border-blueBtnDark">
              <label
                htmlFor="otehrFile"
                
              >
                <input
                  className="hidden"
                  type="file"
                  name="otehrFile"
                  id="otehrFile"
                  onChange={(e) => {
                    setDocumentsUpload((prevState) => ({ ...prevState, other: e.target }))
                    UploadFile(e.target, 4)
                    setIsOtherUploading(true);
                  }}
                />
                {isOtherUploading ? (
                  <>
                    <div className="flex justify-between items-center mx-2 border-blueBtnDark w-full">
                      <p className="overflow-auto ">
                        {documentsUpload?.other?.files[0]?.name}</p>
                    </div>
                  </>
                ) : (
                  <>
                   <div className="w-full hover:cursor-pointer ">
                    {" "}
                    <i className="fa-solid fa-file-arrow-up mr-2"></i>{" "}
                    Upload Other Document
                    </div>
                  </>
                )}
              </label>
              </div>
            }

            <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
          </div>
        </div>


      </section>) : (
        <>
          <div className="relative">
            <div
              className="absolute left-0 right-0 m-auto my-auto top-12 "
              style={{ textAlign: "center" }}
            >
              <Loader size="md" speed="fast" />
            </div>
            <Placeholder.Paragraph style={{ marginTop: 30 }} rows={5} active />
          </div>
        </>
      )
      }
    </>
  );
}

export default UpdateJobSeekerDocuments;
