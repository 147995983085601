import { useEffect, useState } from "react";
import CustomInputWithButton from "../../Components/CustomInputWithButton";
import { DatePicker, Form, Message, SelectPicker, toaster } from "rsuite";
import TextField from "../../Components/TextField";
import { ChampHealthApiClient } from "../../config/apiClients";
import CustomPagination from "../../Components/CustomPagination";

const StaffList = () => {

   const data = [1,2,3,4,5];

   const createStaffList = (data) => {

   return (<div className="inline-flex justify-between gap-2 items-center text-lg font-[500] space-y-6">
    <p className="w-1/4 md:w-1/3">
      <span className="rounded-full text-[15px] border-2 py-1 text-center px-2 font-[400] mr-2">A</span> 
      Angie Thomas {data}
    </p>
    <p className="w-1/3">RegistZ er Practical Nurse; RPN (866) 430 9436 Amked Technologies</p>
    <a href="/admin/time-sheets" className="text-md border-solid border-2 rounded-full px-6 py-1 mx-auto">view</a>
  </div>)
   }




   const [clientTypes, setClientTypes] = useState([])
   const fetchClientTypes = async () => {
     try {
       const response = await ChampHealthApiClient.get('common/client-types');
       if (response.status === 200) {
         setClientTypes(response?.data?.data?.map((item) => ({ label: item.name, value: item.id })));
       } else {
 
         const message = (
           <Message showIcon type="error"  closable>
             {response?.data?.message}
           </Message>
         );
         toaster.push(message, { placement: "topEnd", duration: 3000 });
       }
 
 
     } catch (error) {
       const message = (
         <Message showIcon type="error"  closable>
           {error?.message}
         </Message>
       );
       toaster.push(message, { placement: "topEnd", duration: 3000 });
     }
   }

   useEffect(()=>{
    fetchClientTypes();
   },[])

  return (
    <>
      <main className="main-content">
        {/* <!-- Filter and Searched Options --> */}
        <section className="py-10 bg-[#F1F4F8]">
          <div className="container md:flex md:justify-between md:gap-2 lg:flex  lg:justify-between w-full md:items-center">
            {/* <!-- Client List Search bar --> */}
            <div className="w-full md:w-1/3 lg:flex lg:items-center lg:justify-between lg:w-1/3 lg:space-x-4  xl:justify-between my-2 ml-[4px]">
              <h1 className="font-[500] lg:text-[30px] text-lg whitespace-nowrap">
                list of staff
              </h1>
              <div className="flex justify-center items-center h-[45px] w-full xl:w-2/3">
              <CustomInputWithButton
                    name="search"
                    placeholder="Search...."
                    className="border-2 border-blueBtnDark py-2 w-full  px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded "
                  />
              </div>
            </div>

            {/* <!-- Filters --> */}
            <Form className="md:w-2/3 my-2 lg:flex lg:items-center lg:gap-2 lg:w-2/3 lg:justify-end">
                <h3 className="text-xl font-medium">Filter</h3>
                <div className="grid lg:grid-cols-4 grid-cols-2  gap-4 items-center w-full">


                  <TextField
                    name="Status"
                    searchable={false}
                    data={[{ label: 'Active', value: 'Active' }, { label: 'Deactive', value: 'Deactive' }]}
                    accepter={SelectPicker}
                    onChange={() => { }}
                    placeholder="Status"
                    className="border-2 border-blueBtnDark py-2 w-full  px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
                  />


                  <TextField
                    name="client"
                    searchable={false}
                    data={clientTypes}
                    accepter={SelectPicker}
                    onChange={() => { }}
                    placeholder="Client type"
                    className="border-2 border-blueBtnDark py-2 w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
                  />

                  <TextField
                    name="date"
                    searchable={false}
                    accepter={DatePicker}
                    format="MM-dd-yyyy"
                    oneTap
                    onChange={() => { }}
                    placeholder="Date"
                    className="border-2 border-blueBtnDark py-2 w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
                  />
                  <button className=" border border-champBorder bg-blueBtn text-white rounded h-[52px]">
                    Apply
                  </button>
                </div>

              </Form>
          </div>
        </section>

        {/* staff list and pagination section  */}
        <section className="pt-6">
          <div className="container flex flex-col">

            {/* <!-- Cards Started--> */}
            <div className="grid gap-2 my-4 grid-cols-1 divide-y-[1px] divide-[#C8D3E1] space-y-6">

              <div className="inline-flex justify-between gap-2 items-center text-lg font-[600] text-[#5A6CAA] ">
                <p className="w-1/4 md:w-1/3">Name</p>
                <p className="w-1/3">Details</p>
                <p className="mx-auto">Timesheet</p>
              </div>



            {data && data.map((member)=>(createStaffList(member)))}




            </div>
            {/* <!-- Cards ended / --> */}

            {/* <CustomPagination count={jobCount} clickFuntion={getAllclosedJobs} /> */}

          </div>
        </section>
      </main>
    </>
  );
};

export default StaffList;
