import { useEffect, useState } from "react";
import ClosedJobs from "./ClosedJob";
import LiveJob from "./LiveJob";
import PendingJob from "./Pendingjob";
import { useSearchParams } from "react-router-dom";
import CanceledJob from "./CanceledJob";

const ClientJobList = () => {
  const [selectedFilterBtn, setSelectedFilterBtn] = useState("Live Jobs");

  const data = [1, 2, 3, 4, 5];


  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    if (searchParams.get('page') === 'live') {
      setSelectedFilterBtn("Live Jobs");
    } else if (searchParams.get('page') === 'close') {
      setSelectedFilterBtn("Closed Jobs");
    }
    setSearchParams('')
  }, [])



  return (
    <>
      <main className="main-content ">
        {/* <!------ Top section  ------> */}
        <section className="pt-10 bg-[#F1F4F8]">
          <div className="container divide-y divide-y-4 space-y-6">
            {/* <!------ selection buttons ----> */}
            <div className="grid grid-cols-2 md:flex md:justify-center gap-4 lg:flex w-full md:items-center font-semibold text-sm md:text-md">
              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Closed Jobs"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Closed Jobs");
                }}
              >
                Closed jobs
              </button>

              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Live Jobs"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Live Jobs");
                }}
              >
                Live Jobs
              </button>

              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Pending Jobs"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white  border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Pending Jobs");
                }}
              >
                Pending Jobs
              </button>
              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Canceled Jobs"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white  border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Canceled Jobs");
                }}
              >
                Canceled Jobs
              </button>


            </div>

            {/* <!------ Search and filter options ----> */}
            <div className="hidden md:hidden md:flex items-start lg:items-center md:space-x-2 md:justify-between w-full lg:py-6">
              {/* <!-- job List Search bar --> */}
              <div className="md:w-1/2 w-full lg:flex lg:items-center lg:justify-between lg:w-1/2 lg:space-x-4 xl:w-2/3 xl:justify-between my-2 ml-[4px]">
                <h1 className="font-semibold text-2xl whitespace-nowrap my-2 md:my-0">
                  {selectedFilterBtn}
                </h1>

                <div className="flex justify-center items-center h-11 w-full xl:w-2/3 ">
                  <i className="fa-solid fa-magnifying-glass -mr-6 z-10 text-searchIcon"></i>
                  <input
                    className="shadow appearance-none border rounded w-full h-full px-8 leading-tight focus:outline text-champPlaceholder border-champBorderPagination text-sm"
                    id="client-name"
                    type="text"
                    placeholder="Search organisation or location... "
                  />
                </div>
              </div>

              {/* <!-- Filters --> */}
              <div className="md:w-1/2 my-2 lg:flex lg:items-center lg:gap-2 lg:w-2/3 lg:justify-end">
                <h3 className="text-xl font-medium">Filter</h3>
                <div className="flex flex-col gap-2 lg:flex-row lg:h-11 text-lg">
                  <select
                    id="status"
                    className="py-2 lg:py-0 px-2 border-champBorder border text-champSelectInput font-normal rounded"
                  >
                    <option selected>Status</option>
                    <option value="Active">Active</option>
                    <option value="Deactive">Deactive</option>
                  </select>
                  <select
                    id="client"
                    className="py-2 lg:py-0 px-2 border-champBorder border text-champSelectInput font-normal text-sm rounded"
                  >
                    <option selected>client type</option>
                    <option value="company-1">company 1</option>
                    <option value="company-2">company 2</option>
                    <option value="company-3">company 3</option>
                    <option value="company-4">company 4</option>
                  </select>
                  <input
                    type="date"
                    className="py-2 lg:py-0 px-2 border-champBorder border text-champSelectInput font-normal rounded"
                    placeholder="Date"
                  />
                  <select
                    id="status"
                    className="py-2 lg:py-0 px-2 border-champBorder border text-champSelectInput font-normal rounded"
                  >
                    <option selected>Status</option>
                    <option value="Active">Active</option>
                    <option value="Deactive">Deactive</option>
                  </select>
                  <button className="py-2 lg:py-0 px-8 border border-champBorder bg-blueBtn text-white rounded">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!------ Main Section  ------> */}
        <section className="pt-0">
          {selectedFilterBtn === "Closed Jobs" ? (
            <ClosedJobs data={data} />
          ) : (
            <>
              {selectedFilterBtn === "Live Jobs" ? (
                <LiveJob data={data} />
              ) : (
                <>
                  {selectedFilterBtn === "Pending Jobs" ? (
                    <PendingJob data={data} />
                  ) : (
                    <>
                      {selectedFilterBtn === "Canceled Jobs" ? (
                        <CanceledJob data={data} />
                      ) : (
                        <>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </section>
      </main>
    </>
  );
};

export default ClientJobList;
