import axios from "axios";
import React, { useEffect, useState } from "react";
import { Dropdown, Loader, Modal, Placeholder, Message, useToaster } from "rsuite";
import { API_BASE_URL } from "../../../config/apiKeys";
import { readableDate } from "../../../Components/readableDate";
import { readableTime } from "../../../Components/readableTime";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CONFIG_HEADER } from "../../../config/headerToken";




function CandidateJobApplyPage() {
  const navigate = useNavigate();
  let { id } = useParams();
  const toaster = useToaster()

  const [JobDetails, setJobDetails] = useState(false);

  const getSingleLiveJobDetails = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/jobSeeker/job-full-details-fetch/${id}`, CONFIG_HEADER
      );

      if (response?.status === 200) {
        setJobDetails(response?.data?.data);
      }
    } catch (error) {
      console.log("Create_job_getSingleLiveJobDetails_Error...", error);
    }
  };



  useEffect(() => {
    getSingleLiveJobDetails();
  }, []);

  const renderButton = (props, ref) => {
    return (
      <i
        {...props}
        ref={ref}
        className="fa-solid fa-ellipsis-vertical  text-xl"
      ></i>
    );
  };


  const [noteForRecruiter, setNoteForRecruiter] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  const applyToJob = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/jobSeeker/job-apply/${id}`, {
        job_id: id,
        resume_id: 1,
        vaccine_id: 2,
        notes: noteForRecruiter
      }, CONFIG_HEADER);


      if (response?.status === 200) {
        setIsDisabled(false);
        const message = (
          <Message showIcon type="success" header="Success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      setIsDisabled(false);
      const message = (
        <Message showIcon type="error"  closable>
          {error?.response?.data}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 5000 });
      console.log("applyToJob_Error...", error);
    }
  }


  const handleClick = () => {
    setIsDisabled(true);
    applyToJob()

  }


  return (
    <>
      <div className=" relative">
        <section className="py-6 bg-[#F1F4F8]">
          <div className="container divide-y divide-y-4 space-y-6">
            <div className="flex justify-between md:flex items-center lg:items-center md:space-x-2 md:justify-between w-full lg:py-6">
              {/* <!-- job List Search bar --> */}
              <div className="md:w-1/2 w-full lg:flex lg:items-center lg:justify-between lg:w-1/2 lg:space-x-4 xl:w-2/3 xl:justify-between my-2 ml-[4px]">
                <div
                  onClick={() => navigate(`/candidate/jobs/${id}`)}
                  className="hover:cursor-pointer inline-flex items-center gap-2 font-semibold text-black "
                >
                  <i className="fa-solid text-black fa-arrow-left-long text-2xl"></i>
                  <h1 className="text-2xl whitespace-nowrap">BACK</h1>
                </div>
              </div>

              <div className="md:w-1/2 w-full lg:flex lg:items-center lg:justify-end lg:w-1/2 lg:space-x-4 xl:w-2/3 xl:justify-end my-2 ml-[4px]">
                <div
                  onClick={() => navigate(`/candidate/jobs`)}
                  className="hover:cursor-pointer inline-flex items-center gap-2 font-semibold text-black "
                >
                  <h1 className="text-xl whitespace-nowrap">Cancel</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <main className="container w-full h-full relative flex flex-col justify-center content-center">
          <div className="mt-8  bg-white py-4 rounded-lg">
            <div className="w-full">
              {JobDetails ? (
                <>
                  <div className="relative bg-white rounded  ">
                    {/* <!-- Modal body --> */}
                    <div className="">
                      <div className="job-seeker-details flex flex-col items-start pb-6">
                        <div className="flex flex-col">
                          <p className="ml-0 pl-0 text-3xl font-medium text-black">
                            {JobDetails.company_name}
                          </p>
                          <p className="ml-0 pl-0 text-sm font-medium text-black">
                            {JobDetails.job_position_name}
                          </p>
                          <p className="font-normal text-sm">
                            <i className="fa-solid fa-location-dot pr-2"></i>
                            {JobDetails.address}
                          </p>
                        </div>
                      </div>

                      <div className="py-6 flex flex-col gap-8 border-t-4 border-gray-200 rounded-b">
                        <div className="flex flex-col gap-4">
                          <div className="w-full">
                            <p className="text-lg text-black">Resume</p>
                            <div className="flex justify-between items-center">
                              <div className="lg:w-1/4 inline-flex gap-8 border-double border-[4px] border-[#0066FF]  px-4 py-2 text-xl font-medium text-black">
                                <button className=" text-[#0066FF] inline-flex gap-2 text-center items-center">
                                  <svg
                                    width="18"
                                    height="22"
                                    viewBox="0 0 18 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.5257 1.33105V5.63397H16.8287M1.33643 1.33105H13.8166L16.8287 4.3431V20.2639H1.33815L1.33643 1.33105Z"
                                      stroke="#0066FF"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      d="M17.1791 4.80176L13.7922 1.14844L12.7297 1.68114V5.56597H16.4485L17.1791 4.80176Z"
                                      fill="#0066FF"
                                    />
                                    <circle
                                      cx="9.02808"
                                      cy="12.4111"
                                      r="4.03418"
                                      fill="#0066FF"
                                    />
                                    <path
                                      d="M7.22998 12.4102H11.1812"
                                      stroke="white"
                                    />
                                  </svg>
                                  Existing Resume
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="w-full">
                            <div className="lg:w-1/4 inline-flex items-center gap-2">
                              <hr className="w-1/2" />
                              <span className="text-black">OR</span>
                              <hr className="w-1/2" />
                            </div>
                          </div>

                          <div className="w-full">
                            <div className="flex justify-between items-center">
                              <div className="lg:w-1/4 inline-flex gap-8 border-[1px] border-[#0066FF]  px-4 py-1  font-medium text-black">
                                <button className=" text-[#0066FF] inline-flex gap-2 text-center items-center text-lg">
                                  <p className="inline-flex">
                                    <svg
                                      width="18"
                                      height="22"
                                      viewBox="0 0 18 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12.0433 1.61035V5.91327H16.3462M8.60098 17.9614V10.2162M5.15864 12.7979L8.60098 9.35561L12.0433 12.7979M0.854004 1.61035H13.3342L16.3462 4.6224V20.5432H0.855725L0.854004 1.61035Z"
                                        stroke="#0066FF"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  </p>
                                  <p className="flex flex-col text-start">
                                    <span className="text-base -mb-2">
                                      Upload resume
                                    </span>
                                    <span className="text-[10px] text-black text-center">
                                      Use a pdf, docx, doc, rtf and txt
                                    </span>
                                  </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="py-6 flex flex-col gap-8 border-t-4 border-gray-200 rounded-b">
                        <div className="flex flex-col gap-4">
                          <div className="w-full">
                            <p className="text-black text-base">Vaccine certifications or other documents</p>
                            <div className="flex justify-between items-center">
                              <div className="mt-2 lg:w-1/4 inline-flex gap-8 border-[1px] border-[#0066FF]  px-4 py-1  font-medium text-black">
                                <button className=" text-[#0066FF] inline-flex gap-2 text-center items-center text-lg">
                                  <p className="inline-flex">
                                    <svg
                                      width="18"
                                      height="22"
                                      viewBox="0 0 18 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12.0433 1.61035V5.91327H16.3462M8.60098 17.9614V10.2162M5.15864 12.7979L8.60098 9.35561L12.0433 12.7979M0.854004 1.61035H13.3342L16.3462 4.6224V20.5432H0.855725L0.854004 1.61035Z"
                                        stroke="#0066FF"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  </p>
                                  <p className="flex flex-col text-start">
                                    <span className="text-base -mb-2">
                                      Upload other documets
                                    </span>
                                    <span className="text-[10px] text-black text-center">
                                      Use a pdf, docx, doc,
                                    </span>
                                  </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="py-6 flex flex-col gap-8 border-t-4 border-gray-200 rounded-b">
                        <div className="flex flex-col gap-4">
                          <div className="w-full">
                            <p className="text-black text-base font-medium">Note</p>
                            <div className="flex justify-between items-center">

                              <ReactQuill className="border-0 bg-[#F8FBFF] my-2 w-full" theme="snow" value={noteForRecruiter} onChange={(event) => { setNoteForRecruiter(event) }} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full text-center py-4 ">

                        <button
                          className={`border-2 px-12 pt-[9px] py-[11px] rounded-[5px] text-lg font-normal  bg-blueBtnDark text-white border-blueBtnDark`}
                          onClick={handleClick}
                          disabled={isDisabled}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="relative">
                    <div
                      className="absolute left-0 right-0 m-auto my-auto top-12 "
                      style={{ textAlign: "center" }}
                    >
                      <Loader size="md" />
                    </div>
                    <Placeholder.Paragraph
                      style={{ marginTop: 30 }}
                      rows={5}
                      active
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default CandidateJobApplyPage;
