export const readableDate = (selectedDate) => {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;

  // if (datePattern.test(selectedDate)) {
  //   return convertDate(selectedDate?.slice(0, 10));
  // }

  // let d;
  // if (selectedDate) {
  //   d = new Date(selectedDate);
  // } else {
  //   return;
  // }

  // const targetTimeZone = "Asia/Kolkata";
  // const targetTimeZone = "America/Toronto";
  const inputDate = new Date(selectedDate); // Replace with your input date

  const options = {
    // timeZone: targetTimeZone,
    year: "numeric",
    month: "long",
    day: "2-digit",
  };

  const dateInTargetTimeZone = inputDate.toLocaleString("en-US", options);

  return dateInTargetTimeZone;
};

export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function convertDate(dateString) {
  // Split the date string into its components.
  const [year, month, day] = dateString?.split("-");

  // Convert the month to a string.
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthName = monthNames[month - 1];

  // Return the converted date.
  return `${monthName} ${day}, ${year}`;
}





export const readableDateTime = (selectedDateTime) => {

  // const targetTimeZone = "America/Toronto";
  const inputDateTime = new Date(selectedDateTime);

  const options = {
    // timeZone: targetTimeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const dateTimeInTargetTimeZone = inputDateTime.toLocaleString("en-US", options);

  return dateTimeInTargetTimeZone;
};


