import React, { forwardRef, useRef, useState } from "react";
import { Form, Schema, SelectPicker } from "rsuite";
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import { API_BASE_URL } from "../../../config/apiKeys";
import CandidatePasswordResetSuccessMessage from "./PasswordResetSuccessMessage";
import { ChampHealthApiClient } from "../../../config/apiClients";

const { StringType } = Schema.Types;

const passwordResetModal = Schema.Model({
  password: StringType().pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,20}$/, "please enter 8 characters , smallcase, uppercase & symbols(!@#$%^&*)").isRequired("This field is required."),
  verifyPassword: StringType()
  .addRule((value, data) => {

    if (value !== data.password) {
      return false;
    }

    return true;
  }, 'passwords do not match').isRequired('This field is required.'),
});

const TextField = forwardRef((props, ref) => {
  const { placeholder, name, accepter, ...rest } = props;
  return (
    <Form.Group
      className="admin_login_input_group"
      controlId={`${name}-4`}
      ref={ref}
    >
      <Form.Control
        className="admin_login_input_field w-full px-4 py-2 text-lg font-medium text-champPlaceholder border-[1px] border-black transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        name={name}
        accepter={accepter}
        {...rest}
        placeholder={placeholder}
      />
    </Form.Group>
  );
});

function CandidatePasswordResetForm() {

  const navigate = useNavigate();

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [resetSuccess, setResetSuccess]=useState(false);

  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    password: "",
    verifyPassword: "",
  });

  const {token} = useParams();

  const passwordReset = async () => {
    try {
      const response = await ChampHealthApiClient.post(`/jobseekers/reset-password/${token}`, {
        password: formValue.verifyPassword,
        c_password: formValue.password,
      });
      if(response.status){
        setResetSuccess(true)
        setDisableSubmit(false)
      }

    } catch (error) {
      setDisableSubmit(false)
      console.log("reset_form_page", error);
    }
  };

  const handlePasswordReset = () => {
    if (formRef.current.check()) {
      setDisableSubmit(true)
      passwordReset();
      return;
    }
    console.log("form error...login page", formError);
  };

  return (
    <>
    {resetSuccess ? (<CandidatePasswordResetSuccessMessage />):(
    <div className=" h-screen relative pb-12 overflow-y-hidden">
      <img className="-z-10 absolute w-full bottom-0" src="/assests/images/candidate-bg.png" />
      <header className="container py-4">
        <img src="/logo.png" alt="health champ logo" />
      </header>
    <main className="h-full container">
      <section className="relative h-full w-full">
        <div className="container h-full w-full flex justify-center items-center">
          <div className="flex justify-center items-center  -mt-16 flex-wrap h-full g-6 text-gray-800 lg:w-1/3">
            <div className="w-full">
              <div className="flex flex-col justify-center items-center space-y-6 mb-6">
                <h1 className="font-semibold text-3xl">Set new password</h1>
                <h3 className="font-medium leading-6 text-base text-center">
                  You have password be different to <br />
                  previously used passwords.
                </h3>
                <Form
                  fluid
                  ref={formRef}
                  onChange={setFormValue}
                  onCheck={setFormError}
                  formValue={formValue}
                  model={passwordResetModal}
                  className="w-full flex flex-col gap-4  my-4"
                >
                  <TextField
                    name="password"
                    type="password"
                    placeholder="Password"
                    autoComplete="off"
                  />
                  <TextField
                    name="verifyPassword"
                    type="password"
                    placeholder="Confirm Password"
                  />
                </Form>
                <button
                  className="inline-block px-7 py-3 bg-blueBgDark text-white border-champBorder font-semibold text-lg rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out w-full mt-4"
                  onClick={handlePasswordReset}
                  disabled={disableSubmit ? true:false}
                >
                  {" "}
                  Reset Password
                </button>
              </div>

              <div className="flex justify-center items-center my-6">
                <a
                  href="/"
                  className="text-champText400 text-center text-base "
                >
                  <i className="fa-solid fa-arrow-left-long mr-1"></i>{" "}
                  <span className="text-lg ">Back to log in</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    </div>)}

    
    </>
  );
}

export default CandidatePasswordResetForm;
