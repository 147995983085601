import React, { forwardRef, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  DateRangePicker,
  Modal,
  Button,
  ButtonToolbar,
  Placeholder,
  DatePicker,
  Radio,
  Whisper,
  Popover,
  Table,
} from "rsuite";

const { Column, HeaderCell, Cell } = Table;

const TimeSheetsDay = () => {
  let item = 3;

  const [isDropdown, setIsDropdown] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const data = [1];

  const handleActionClick = (id) => {
    isDropdown === id ? setIsDropdown(false) : setIsDropdown(id);
  };

  return (
    <>

     <div className="relative  sm:rounded-lg mb-8 overflow-y-auto">
      <table className="relative w-full text-left  pb-8 border-collapse border text-center overflow-y-auto">

<thead>
        <tr className="text-black text-xl border-b bg-[#F1F4F8] w-full">
          <th className="sticky top-0 px-4 py-2 border border-champBorderPagination ">
            <div className="inline-flex items-center space-x-1 text-xl font-normal"></div>
          </th>
          <th className="w-full sticky top-0 px-4 py-2 border border-champBorderPagination ">
            <div className="inline-flex items-center space-x-1 text-xl font-normal">
              <span>9 Monday</span>
            </div>
          </th>
          <th className="w-full sticky top-0 px-4 py-2 border border-champBorderPagination ">
            <div className="inline-flex items-center space-x-1 text-xl font-normal">
              <span>9 Monday</span>
            </div>
          </th>
          <th className="w-full sticky top-0 px-4 py-2 border border-champBorderPagination ">
            <div className="inline-flex items-center space-x-1 text-xl font-normal">
              <span>9 Monday</span>
            </div>
          </th>
          <th className="w-full sticky top-0 px-4 py-2 border border-champBorderPagination ">
            <div className="inline-flex items-center space-x-1 text-xl font-normal">
              <span>9 Monday</span>
            </div>
          </th>
          <th className="w-full sticky top-0 px-4 py-2 border border-champBorderPagination ">
            <div className="inline-flex items-center space-x-1 text-xl font-normal">
              <span>9 Monday</span>
            </div>
          </th>
          <th className="w-full sticky top-0 px-4 py-2 border border-champBorderPagination ">
            <div className="inline-flex items-center space-x-1 text-xl font-normal">
              <span>9 Monday</span>
            </div>
          </th>
          <th className="w-full sticky top-0 px-4 py-2 border border-champBorderPagination ">
            <div className="inline-flex items-center space-x-1 text-xl font-normal">
              <span>9 Monday</span>
            </div>
          </th>
        </tr>
        <tr className="bg-white border text-black text-[20px]">
          <th className="h-full px-4 py-4  border border-champBorderPagination">
            <div className="inline-flex items-center space-x-1 w-40 text-center justify-center">
              <p className="bg-[#D6DFFB] rounded-full w-8 h-8 text-center inline-flex items-center justify-center">
                <i className="text-xl text-[#5A6CAA] fa-regular fa-clock"></i>
              </p>
              <p className="flex flex-col text-sm text-left">
                <span className="font-medium text-lg">Open shifts</span>
                <span className="font-normal text-[#737373]">0 shifts</span>
              </p>
            </div>
          </th>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="border border-champBorderPagination bg-[#E7F4FF] border-t-4 border-t-[#306898] relative">
              <div className="pl-2 flex flex-col text-[#306898] text-sm font-normal w-full h-full text-left">
                <p>Personal Support Worker</p>
                <p>9 am - 6 pm</p>
              </div>
          </td><td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
        </tr>
</thead>

        {/* //// time and shift */}
<tbody>
       <tr className="bg-white border text-black text-[20px]">
          <td className="w-4  px-4 py-2 border border-champBorderPagination">
            <div className="inline-flex items-center space-x-1 text-sm font-normal">
              <span>12 AM</span>
            </div>
          </td>
          <td rowSpan={4} className="border bg-[#E9F6FF] border-t-4 border-[#79CAFF] relative">
              <p className="bg-[#79CAFF] absolute -top-2.5 left-0 right-0 m-auto rounded-full h-4 w-4 text-center flex justify-center items-center hover:cursor-pointer">
                <i className="fa-solid fa-star text-white text-[9px]"></i>
              </p>

              <div className="pl-2 flex flex-col text-[#009AFF] text-sm font-normal w-full h-full text-left">
                <p>RegistZ er Practical Nurse; RPN</p>
                <p>9 am - 6 pm</p>
              </div>
          </td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
        </tr>

        <tr className="bg-white border text-black text-[20px]">
          <td className="w-4  px-4 py-2 border border-champBorderPagination">
            <div className="inline-flex items-center space-x-1 text-sm font-normal">
              <span>11 AM</span>
            </div>
          </td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
        </tr>
        <tr className="bg-white border text-black text-[20px]">
          <td className="w-4  px-4 py-2 border border-champBorderPagination">
            <div className="inline-flex items-center space-x-1 text-sm font-normal">
              <span>11 AM</span>
            </div>
          </td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
        </tr>
        <tr className="bg-white border text-black text-[20px]">
          <td className="w-4  px-4 py-2 border border-champBorderPagination">
            <div className="inline-flex items-center space-x-1 text-sm font-normal">
              <span>11 AM</span>
            </div>
          </td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
        </tr>
        <tr className="bg-white border text-black text-[20px]">
          <td className="w-4  px-4 py-2 border border-champBorderPagination">
            <div className="inline-flex items-center space-x-1 text-sm font-normal">
              <span>11 AM</span>
            </div>
          </td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
        </tr>
        <tr className="bg-white border text-black text-[20px]">
          <td className="w-4  px-4 py-2 border border-champBorderPagination">
            <div className="inline-flex items-center space-x-1 text-sm font-normal">
              <span>11 AM</span>
            </div>
          </td>
          
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
          <td className="px-6 py-4  border border-champBorderPagination"></td>
        </tr>
        </tbody>
      </table>
    </div>  
    
    </>
  );
};

export default TimeSheetsDay;
