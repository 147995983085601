import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Form, Loader, Message, Schema, Nav, useToaster } from "rsuite";
import { useNavigate } from "react-router-dom";
import { ChampHealthApiClient } from "../config/apiClients";
import { USER_DETAIL, setCookie } from "../config/headerToken";
import TextField from "../Components/TextField";

const Navbar = ({ active, onSelect, ...props }) => {
  return (
    <Nav
      {...props}
      activeKey={active}
      onSelect={onSelect}
      style={{ marginBottom: 50 }}
    >
      <Nav.Item className="text-lg font-normal hover:!text-champGreen" eventKey="employer">
        I'm a Client
      </Nav.Item>
      <Nav.Item className="text-lg font-normal hover:!text-champGreen" eventKey="jobSeeker">
        I'm a Job Seeker
      </Nav.Item>
    </Nav>
  );
};

const { StringType, NumberType } = Schema.Types;

const signInModal = Schema.Model({
  password: StringType().isRequired("This field is required."),
  email: StringType().isRequired("This field is required."),
});



const Login = () => {

  const toaster = useToaster();
  const navigate = useNavigate();
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });

  // const userDetails = useSelector((state) => state.userDetails.user);




  const [disableSubmit, setDisableSubmit] = useState(false)

  const signInJobSeeker = async () => {
    try {
      const res = await ChampHealthApiClient.post(`/jobseekers/login`,
        {
          email: formValue.email,
          password: formValue.password,
        }
      );


      if (res.status === 200) {
        setDisableSubmit(false)
        setCookie("user", JSON.stringify(res.data), 3)
        setCookie("token", res.data.token, 3)
        window.location.pathname = '/candidate/jobs';
      } else {
        setDisableSubmit(false)
        const message = (
          <Message showIcon type="error" closable>
            {res.data.message}
          </Message>
        );
        toaster.push(message, { placement: 'topEnd', duration: 3000 })
      }


    } catch (error) {
      setDisableSubmit(false)
      const message = (
        <Message showIcon type="error" closable>
          {error?.response?.data?.message || error.message}
        </Message>
      );
      toaster.push(message, { placement: 'topEnd', duration: 3000 })
      console.log("login_page error.....", error);
    }
  };

  const signInEmployer = async () => {
    try {
      const res = await ChampHealthApiClient.post(`/clients/login`,
        {
          email: formValue.email,
          password: formValue.password,
        }
      );

      if (res.status === 200) {
        setDisableSubmit(false)
        setCookie("user", JSON.stringify(res.data), 3)
        setCookie("token", res.data.token, 3)
        window.location.pathname = '/client/jobs';


      } else {
        setDisableSubmit(false)
        const message = (
          <Message showIcon type="error"  closable>
            {res.data.message}
          </Message>
        );
        toaster.push(message, { placement: 'topEnd', duration: 3000 })
      }


    } catch (error) {
      setDisableSubmit(false)
      const message = (
        <Message showIcon type="error"  closable>
          {error?.response?.data?.message || error.message}
        </Message>
      );
      toaster.push(message, { placement: 'topEnd', duration: 3000 })
      console.log("login_page error.....", error);
    }
  };


  const handleSignIn = () => {
    if (formRef.current.check()) {
      setDisableSubmit(true)
      if (active === "jobSeeker") {
        signInJobSeeker();
      } else {
        signInEmployer();
      }

      return;
    }
  };


  const [active, setActive] = useState("jobSeeker");


  useEffect(() => {
    if (USER_DETAIL?.role === 'jobseeker') {
      window.location.pathname = '/candidate/jobs';
    }
    if (USER_DETAIL?.role === 'client') {
      window.location.pathname = '/client/jobs';
    }
    if (USER_DETAIL?.role === 'admin') {
      window.location.pathname = '/admin/list-client';
    }
  }, [])

  return (
    <>
      <div className="h-screen w-screen relative">
        <img
          className="-z-10 absolute w-full bottom-0"
          src="/assests/images/candidate-bg.png"
        />
        <div className="container relative">
          <header className="py-4">
            <img src="/logo.png" alt="health champ logo" />
          </header>
          <main className="w-full h-full relative flex justify-center content-center">
            <div className="mt-8 w-[560px] bg-white shadow px-16 py-4 rounded-lg">
              <h2 className="text-black font-semibold	text-center text-2xl p-2 py-6">
                Log In
              </h2>
              <Navbar
                appearance="subtle"
                active={active}
                onSelect={setActive}
                className="employer__jobSeeker__login font-semibold flex items-center text-center justify-around border-b-0"
              />

              <div className="w-full">
                <Form
                  fluid
                  ref={formRef}
                  onChange={setFormValue}
                  onCheck={setFormError}
                  formValue={formValue}
                  model={signInModal}
                  className="w-full "
                >
                  <div className="mb-6">
                    <TextField name="email" placeholder="Email *" autoFocus={true} />
                  </div>
                  <div className="mb-6">
                    <TextField
                      name="password"
                      placeholder="Password *"
                      type="password"
                    />
                  </div>

                  <button
                    type="submit"
                    className="inline-block px-7 py-3 bg-blueBgDark text-white border-champBorder font-semibold text-2xl rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out w-full"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    onClick={(e) => {
                      handleSignIn(e);
                    }}
                    disabled={disableSubmit ? true : false}
                  > Log In
                  </button>
                </Form>


                {active == "jobSeeker" ?
                  <div className="flex flex-col space-y-4 justify-center items-center my-6">
                    <a
                      href="/candidate/forgot-password"
                      className="text-champText400 hover:text-champGreen text-center text-lg hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out decoration-none underline-offset-4 "
                    >
                      Forgot password?
                    </a>
                    <div className="flex gap-1 text-base">

                      <p className="text-black">New to Champhealth?</p> <a href="/candidate/register" className="text-[#869538]"> Create an account </a>
                    </div>
                  </div> :
                  <div className="flex flex-col space-y-4 justify-center items-center my-6">
                    <a
                      href="/client/forgot-password"
                      className="text-champText400 hover:text-champGreen text-center text-lg hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out decoration-none underline-offset-4 "
                    >
                      Forgot password?
                    </a>
                    <div className="flex gap-1 text-base">

                      <p className="text-black">New to Champhealth?</p> <a href="/client/register" className=" text-[#869538]"> Create an account </a>
                    </div>
                  </div>}

              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Login;
