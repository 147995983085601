
import { DateRangePicker, Modal, ButtonToolbar, Placeholder, DatePicker, Radio, Input, Popover, Whisper, Button } from 'rsuite';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import TimeSheetsDay from './Day';
import TimeSheetsWeek from './Week';
import TimeSheetsBiWeek from './BiWeek';
import TimeSheetsMonth from './Month';


const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } =
  DateRangePicker;

  
const TimeSheetsCandidate = () => {
      const navigate = useNavigate();

    const [isDropdown, setIsDropdown] = useState(false);
    const [isSchedulePage, setIsSchedulePage] = useState(true);


   const data = [1]

   const [selectedFilterBtn, setSelectedFilterBtn] = useState('Day');


   const [selectedDate, setSelectedDate] = useState(false);
   const [selectDateDropdown, setSelectDateDropdown] = useState(false);
   const [displayDate, setDisplayDate] = useState(false);


   const readableDate = () => {
    let d;
    if(selectedDate){d = new Date(selectedDate);}else{d = new Date()}
     
    const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November","December"];
  

    let day = d.getDate();
    if (day <= 9) {day = `0${day}`;}
    let month = d.getMonth();
    let year = d.getFullYear();

    let date = {
      day,
      month:monthNames[month],
      year
    }

    setDisplayDate(date)
   }


   useEffect(()=>{
    readableDate();
   },[selectedDate])


  return (
    <main>
      {/* <!-- Filter and title Options --> */}
      <section className="py-10 bg-[#F1F4F8]">
        <div className="container flex flex-col md:flex-row md:justify-between md:gap-2  lg:justify-between w-full md:items-center space-y-6 md:space-y-0">
          {/* <!-- Shift detaials--> */}
          <div className="w-full flex lg:items-center justify-between lg:space-x-4 xl:justify-between my-2 ml-[4px]">
            <div className="inline-flex items-center gap-2 font-medium hover:cursor-pointer text-black ">
              <h1 className="text-2xl lg:text-3xl text-black  whitespace-nowrap">
                Shift details
              </h1>
            </div>
          </div>

          {/* <!-- active page --> */}
          <div className="w-full order-last md:order-none inline-flex items-center font-medium hover:cursor-pointer text-black text-base space-x-6 md:space-x-4">
            <div>
              <span
                onClick={() => setIsSchedulePage(!isSchedulePage)}
                className={
                  isSchedulePage
                    ? " border-b-4 border-black pb-[38px] md:pb-[52px] font-medium"
                    : " text-[#7D7D7D]"
                }
              >
                Schedule
              </span>
            </div>

            <div>
              <span
                onClick={() => setIsSchedulePage(!isSchedulePage)}
                className={
                  isSchedulePage
                    ? " text-[#7D7D7D]"
                    : " border-b-4 border-black pb-[38px] md:pb-[52px] font-medium"
                }
              >
                Requests
              </span>
            </div>
          </div>

          {/* <!-- Filters --> */}
          <div className="w-full lg:flex md:items-center lg:gap-2 md:justify-end">
            <div className=" bg-white rounded-full">
              <div className="inline-flex justify-between text-[1rem] whitespace-nowrap">
                <button
                  className={`px-4 lg:px-6 py-2 rounded-full ${
                    selectedFilterBtn === "Day"
                      ? " bg-blueBtn text-white"
                      : " bg-white"
                  }`}
                  onClick={() => setSelectedFilterBtn("Day")}
                >
                  Day
                </button>
                <button
                  className={` px-4 lg:px-6 py-2 rounded-full ${
                    selectedFilterBtn === "Weekly"
                      ? " bg-blueBtn text-white"
                      : "  bg-white"
                  }`}
                  onClick={() => setSelectedFilterBtn("Weekly")}
                >
                  Weekly
                </button>
                <button
                  className={` px-4 lg:px-6 py-2 rounded-full  ${
                    selectedFilterBtn === "BiWeekly"
                      ? " bg-blueBtn text-white"
                      : "  bg-white"
                  }`}
                  onClick={() => setSelectedFilterBtn("BiWeekly")}
                >
                  Bi-Weekly
                </button>
                <button
                  className={`px-5 lg:px-6 py-2 rounded-full  ${
                    selectedFilterBtn === "Calendar"
                      ? " bg-blueBtn text-white"
                      : "  bg-white"
                  }`}
                  onClick={() => setSelectedFilterBtn("Calendar")}
                >
                  Calendar
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <>
        {/* <!---- Calander ---- >  <!---- requests ---- > */}
        <section>
          <div className="container">
            <div className="flex flex-col my-8 space-y-4">
              <div className="flex justify-between">
                <div className="inline-flex space-x-6 items-center text-black mb-8">
                  <div className="inline-flex space-x-1">
                    <button className="">
                      <i className="fa-solid fa-chevron-left  p-2 text-xl"></i>
                    </button>
                    <button className="">
                      <i className="fa-solid fa-chevron-right p-2 text-xl"></i>
                    </button>
                  </div>
                    <div className="relative" >
                      <div className="inline-flex space-x-2 items-center text-black bg-white hover:cursor-pointer" onClick={()=>setSelectDateDropdown(!selectDateDropdown)}>
                      <p className="text-[#545454] text-2xl ">{`${displayDate.month} ${displayDate.day}, ${displayDate.year}`}</p>{" "}
                      <i className="fa-solid fa-chevron-down text-xl"></i>
                      </div>

                      <div
                      className={
                        "dropdown-menu min-w-max absolute text-base z-20 shadow-slate-300 top-0 right-0" +
                        (selectDateDropdown ? " bg-gray" : " hidden")
                      }
                    >
                      <DatePicker
                      format="MM-dd-yyyy"
                        className='relative candidate_timesheet_datepicker-dropdown  invisible z-20'
                        onOk={(date)=>{
                          setSelectedDate(date)
                          setSelectDateDropdown(!selectDateDropdown)
                        }}
                          open={(selectDateDropdown ? true:false)}
                        />
                    </div>

                      
                    </div>
                </div>

                <div>
                  <div
                    className="relative inline-flex items-center"
                    onClick={() => {
                      setIsDropdown(!isDropdown);
                    }}
                  >
                    <button className="bg-black text-white border-[1px] p-2">
                      <i className="fa-solid fa-clock"></i> Clock In
                    </button>
                    <button className="bg-black text-white p-2 border-[1px] border-l-0">
                      {isDropdown ? (
                        <i className="fa-solid fa-chevron-up"></i>
                      ) : (
                        <i className="fa-solid fa-chevron-down"></i>
                      )}
                    </button>
                    <div
                      className={
                        "dropdown-menu min-w-max absolute bg-white text-base z-50  rounded-sm shadow-md shadow-slate-300 top-10 right-0 border flex flex-col text-[#545454]" +
                        (isDropdown ? " bg-gray" : " hidden")
                      }
                    >
                      <a className="hover:cursor-pointer py-2 px-6 font-medium hover:bg-grayBg">
                        <i className="fa-regular fa-clock pr-2"></i>
                        Stop clock
                      </a>
                      <a className="hover:cursor-pointer py-2 px-6 font-medium hover:bg-grayBg">
                        <i className="fa-solid fa-mug-saucer pr-2"></i>
                        Start a break
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {isSchedulePage ? (
                <>
                  {selectedFilterBtn === "Calendar" ? (
                    <TimeSheetsMonth />
                  ) : (
                    <>
                      {selectedFilterBtn === "Weekly" ? (
                        <TimeSheetsWeek />
                      ) : (
                        <>
                          {selectedFilterBtn === "BiWeekly" ? (
                            <TimeSheetsBiWeek />
                          ) : (
                            <TimeSheetsDay />
                          )}{" "}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="tab-pane fade">
                    <div className="flex divide-x divide-gray-200 text-black border-t-4">
                      {/* left side */}
                      <div className="flex flex-col gap-2 w-1/3 divide-y-2 space-y-4">
                        {/* in Progress requests */}
                        <div className="flex flex-col gap-2 mt-4 text-black">
                          <h4 className="mb-4 text-black font-medium">
                            Status pending
                          </h4>
                          <div className="inline-flex gap-2 py-2 bg-[#EEF5FF] mx-2 rounded text-black">
                            <p className="rounded-full mt-1">
                              <span className="rounded-full px-2 m-2 border border-blueRibbion-200 bg-white text-xl">
                                P
                              </span>
                            </p>
                            <p className="flex flex-col gap-4">
                              <span className="font-medium">
                                Patrick Swanson requested a shift, Personal
                                Support Worker Oct 9, 8 AM - 5PM
                              </span>
                              <span className="font-normal">4:06 PM</span>
                            </p>
                          </div>
                        </div>

                        {/* Completed request */}
                        <div>
                          <h4 className="mb-4 text-gray-900 font-medium">
                            completed
                          </h4>
                          <div className="inline-flex gap-2 py-2 mx-2 rounded">
                            <p className="rounded-full mt-1">
                              <span className="rounded-full px-2 m-2 border border-blueRibbion-200 bg-white text-xl">
                                P
                              </span>
                            </p>
                            <p className="flex flex-col gap-4">
                              <span className="font-medium">
                                Patrick Swanson requested a shift, Personal
                                Support Worker Oct 9, 8 AM - 5PM
                              </span>
                              <span className="font-normal">4:06 PM</span>
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* right side */}
                      <div className="w-full">
                        <div className="flex flex-col gap-3 mt-4">
                          <h4 className="px-8 font-semibold">
                            Request details
                          </h4>
                          <hr className="bg-gray-600" />
                        </div>

                        <div>
                          <div className="px-4 lg:px-12 py-6">
                            <div className="inline-flex w-full p-4 ">
                              <p className="rounded-full w-8 h-8 border border-[#BCC5E0] text-center">
                                <span className="rounded-full m-2 bg-white text-xl">
                                  P
                                </span>
                              </p>
                              <div className="flex flex-col bg-white mx-4 border border-[#BCC5E0] shadow w-full">
                                <div className="flex flex-col space-y-4 p-4 mx-2">
                                  <p className="inline-flex space-x-4">
                                    <span className="font-medium">
                                      Patrick Swanson
                                    </span>
                                    <span className="font-normal">
                                      {" "}
                                      4:06 PM
                                    </span>
                                  </p>

                                  <div className="text-black w-full bg-[#F9F8FF] px-4 py-2 border-l-4 border-[#9C8EE0] font-normal text-base">
                                    <p className="font-medium">Oct, 9 2022</p>
                                    <div>
                                      <p>Personal Support Worker</p>
                                      <p>8 AM - 5PM</p>
                                    </div>
                                  </div>

                                  <div>
                                    <button className="shadow text-white border-blueBtnDark px-4 py-1 bg-blueBtnDark text-base font-normal">
                                      {" "}
                                      Cancel requests
                                    </button>
                                  </div>
                                </div>

                                <div className="flex flex-col bg-white border-t border-[#BCC5E0] shadow w-full p-4">
                                  <div className="flex space-x-4">
                                    <div className="border border-blueBtnDark rounded-full  h-8 w-8 text-center inline-flex items-center justify-center">
                                      <i className="fa-solid fa-user text-blueBtnDark "></i>{" "}
                                    </div>

                                    <div className="flex flex-col space-y-2">
                                      <div className="inline-flex space-x-4 items-center">
                                        <h4 className="font-medium text-base text-black">
                                          Manager response
                                        </h4>
                                        <span className="text-[#757575] font-normal text-[15px]">
                                          4:06 PM
                                        </span>
                                      </div>
                                      <p className="text-[#757575] text-[15px]">
                                        <i className="fa-regular fa-clock text-[15px]"></i>{" "}
                                        <span>Pending final approval</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </>
    </main>
  );
}

export default TimeSheetsCandidate