import React from 'react'
import { useNavigate } from 'react-router-dom'

const CreateJobThankYouPage = () => {
    const navigate = useNavigate()
    return (
        <>

            <main>
                <section className="py-8 bg-[#F1F4F8]">
                    <div className="container mx-auto md:flex md:justify-between md:gap-2 lg:flex lg:justify-between w-full">
                        {/* <!-- Client List Search bar --> */}
                        <div onClick={() => navigate(-1)} className="hover:cursor-pointer inline-flex items-center gap-2 font-semibold text-black "
                        >
                            <i className="fa-solid fa-arrow-left-long text-2xl"></i>
                            <h1 className="text-[30px] text-black whitespace-nowrap">
                                Back
                            </h1>
                        </div>
                    </div>
                </section>

                {/* <!-- Create Client Form --> */}

                <section className="py-8 bg-white">
                    <div className="container">
                        <div className="h-[300px] w-full flex justify-center items-center ">
                            <div className='flex flex-col items-center text-base text-center'>
                                <h2 className="text-center text-3xl font-semibold py-2">Thank You</h2>
                                <p>
                                    Your Job has been successfully created. <br /> Our team will activate it pretty soon.
                                </p>
                                <p>
                                    Thanks for your patience.
                                </p>

                                <div className='flex gap-2 pt-6 text-white'>

                                    <button onClick={()=>navigate('/client/jobs')} className='hover:cursor-pointer border border-gray-700 rounded bg-gray-700 p-2 px-3'>Go Home</button>
                                    <button 
                                    onClick={()=> navigate('/client/create-job')} className='hover:cursor-pointer border border-champBorder rounded bg-blue-800 p-2 px-3'>Create New Job</button>
                                </div>

                            </div>


                        </div>

                    </div>
                </section>

            </main></>




    )
}

export default CreateJobThankYouPage