import { forwardRef } from "react";
import { DatePicker, Form, InputGroup, Schema } from "rsuite";



const getHours = (date) => {
    if (date) {
        const d = new Date(date);
        return d.getHours();
    } else {
        return false
    }
}
const getMinutes = (date) => {
    if (date) {
        const d = new Date(date);
        return d.getMinutes();
    } else {
        return false
    }
}

const ScheduleDateRangePicker = forwardRef((props, ref) => {
    const { placeholder, name, label, accepter, setChanges, value, ...rest } = props;

    return (
        <>


            <Form.Group
                className="admin_create_client relative w-full"
                controlId={`${name}-4`}
                ref={ref}
            >
                <Form.ControlLabel className="text-sm text-[#A1A1A1] font-semibold duration-300">
                    {label}
                </Form.ControlLabel>

                <InputGroup className={`pt-0 pb-2 px-0 w-full text-lg font-semibold text-[#545454] bg-transparent border-0 border-b-2 border-champBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer rounded-none !outline-none	`}>
                    <Form.Control
                        name={`${name}0`}
                        accepter={DatePicker}
                        placeholder={'hh:mm'}
                        format="HH:mm"
                        ranges={[]}
                        className="w-full"
                    />
                    <InputGroup.Addon>-</InputGroup.Addon>
                    <Form.Control
                        name={`${name}1`}
                        accepter={DatePicker}
                        {...rest}
                        format="HH:mm"
                        placeholder={'hh:mm'}
                        ranges={[]}
                        className="w-full"
                    />



                </InputGroup>
            </Form.Group>
        </>
    );
});


export default ScheduleDateRangePicker;