import React, { useState } from "react";
import { Dropdown, Loader, Message, Modal, Placeholder, toaster } from "rsuite";
import { ChampHealthApiClient } from "../../config/apiClients";
import { useNavigate } from "react-router-dom";
import { readableDate } from "../readableDate";
import { readableTime } from "../readableTime";

const PendingJobCard = ({ job, onChange, isAdmin = false }) => {
  const baseUrl = isAdmin ? "/admin" : "/client";
  const navigate = useNavigate();

  const [modalJobDetails, setModalJobDetails] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = (id) => {
    setOpenModal(true);
    getSingleLiveJobDetails(id);
  };
  const handleClose = () => {
    setModalJobDetails(false);
    setOpenModal(false);
  };

  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const handleDeactivateOpen = () => setDeactivateOpen(true);
  const handleDeactivateClose = () => {
    setDeactivateMessage("");
    setDeactivateOpen(false);
  };

  const [deactivateMessage, setDeactivateMessage] = useState("");
  const [deactivateJobId, setDeactivateJobId] = useState(false);

  const renderButton = (props, ref) => {
    return (
      <i
        {...props}
        ref={ref}
        className="fa-solid fa-ellipsis-vertical  text-xl"
      ></i>
    );
  };

  const renderButtonIcon = (props, ref) => {
    return (
      <i
        {...props}
        ref={ref}
        className="fa-solid fa-ellipsis-vertical text-xl"
      ></i>
    );
  };

  const getSingleLiveJobDetails = async (id) => {
    try {
      const response = await ChampHealthApiClient.get(
        `/clients/jobs/details/${id}`
      );
      if (response?.status === 200) {
        setModalJobDetails(response?.data?.data[0]);
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error?.response?.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
      console.log("Create_job_getSingleLiveJobDetails_Error...", error);
    }
  };

  const archiveJob = async (id) => {
    try {
      const response = await ChampHealthApiClient.patch(
        `/admin/jobs/archive/${id}`,
        {
          deleted: 1,
        }
      );
      if (response.status === 200) {
        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        onChange();
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        console.log("archiveLiveJobs_page....", response);
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error?.response?.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
      console.log("archiveLiveJobs_page...", error);
    }
  };

  const deactiveJob = async (id) => {
    try {
      const response = await ChampHealthApiClient.patch(`/admin/jobs/${id}`, {
        status: 4,
        reason: deactivateMessage,
      });
      if (response.status === 200) {
        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        onChange();
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        console.log("deactivateLiveJobs_page....", response);
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error?.response?.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
      console.log("deactivateLiveJobs_page...", error);
    }
  };

  const cancelJob = async (id) => {
    try {
      const response = await ChampHealthApiClient.get(
        `/common/cancel-jobs/${id}`
      );
      if (response.status === 200) {
        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        onChange();
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        console.log("cancelJob_page....", response);
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error?.response?.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
      console.log("cancelJob_page...", error);
    }
  };

  return (
    <>
      <div
        key={job.id}
        className="card rounded border border-champBorder hover:shadow-md text-black"
      >
        <div className="text-black relative h-full flex justify-between card rounded bg-white p-4 gap-4">
          <div className="flex flex-col h-full justify-between gap-2">
            <div
              className="flex flex-col hover:cursor-pointer justify-between gap-2"
              onClick={() => handleOpen(job)}
            >
              <div className="inline-flex justify-between items-start">
                <p className="font-semibold text-xl">
                  {job.jp_position}
                  <span className="bg-gray-300 px-1 rounded">
                    #{job?.id}{" "}
                  </span>{" "}
                </p>
              </div>
              <p className="font-medium text-base">{job.name} </p>
              <p className="font-medium text-base ">
                <i className="fa-solid fa-location-dot pr-2"></i>
                {job.address}
              </p>
              <div className="space-x-3">
                <button className="w-max px-2 h-30 rounded-full bg-champOnBtn text-champOnText text-center font-medium text-base">
                  {job.job_shift}
                </button>
                {job?.job_type ? (
                  <button className="w-max px-2 h-30 rounded-full bg-champPartTimeBtn text-center text-champPartTimeText text-base ">
                    {job.job_type}
                  </button>
                ) : (
                  ""
                )}
              </div>
              <p className="font-semibold text-sm text-black my-2">
                <p>
                  <span>
                    <span className="font-semibold">Date: </span>
                    {job?.start_job_date && (
                      <>
                        <span className="text-[#7D7D7D]">
                          {readableDate(job?.start_job_date)}
                        </span>{" "}
                        {" - "}
                      </>
                    )}

                    <span className="text-[#7D7D7D]">
                      {readableDate(job?.job_date)}
                    </span>
                  </span>
                  <br />
                  <span>
                    <span className="font-semibold">Time: </span>
                    <span className="text-[#7D7D7D]">
                      {readableTime(JSON.parse(job?.schedule)[0]) +
                        " - " +
                        readableTime(JSON.parse(job?.schedule)[1])}
                    </span>
                  </span>
                </p>
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-medium inline-flex text-medium text-[#7D7D7D] ">
                <i className=" pr-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 12.57C14.3261 12.57 15.5979 13.0968 16.5355 14.0345C17.4732 14.9722 18 16.2439 18 17.57V18.57C18 19.1004 17.7893 19.6091 17.4142 19.9842C17.0391 20.3593 16.5304 20.57 16 20.57H2C1.46957 20.57 0.960859 20.3593 0.585786 19.9842C0.210714 19.6091 0 19.1004 0 18.57V17.57C0 16.2439 0.526784 14.9722 1.46447 14.0345C2.40215 13.0968 3.67392 12.57 5 12.57H13ZM17 6.57001C17.2652 6.57001 17.5196 6.67536 17.7071 6.8629C17.8946 7.05044 18 7.30479 18 7.57001V8.57001H19C19.2652 8.57001 19.5196 8.67536 19.7071 8.8629C19.8946 9.05044 20 9.30479 20 9.57001C20 9.83522 19.8946 10.0896 19.7071 10.2771C19.5196 10.4647 19.2652 10.57 19 10.57H18V11.57C18 11.8352 17.8946 12.0896 17.7071 12.2771C17.5196 12.4647 17.2652 12.57 17 12.57C16.7348 12.57 16.4804 12.4647 16.2929 12.2771C16.1054 12.0896 16 11.8352 16 11.57V10.57H15C14.7348 10.57 14.4804 10.4647 14.2929 10.2771C14.1054 10.0896 14 9.83522 14 9.57001C14 9.30479 14.1054 9.05044 14.2929 8.8629C14.4804 8.67536 14.7348 8.57001 15 8.57001H16V7.57001C16 7.30479 16.1054 7.05044 16.2929 6.8629C16.4804 6.67536 16.7348 6.57001 17 6.57001ZM9 0.570007C10.3261 0.570007 11.5979 1.09679 12.5355 2.03447C13.4732 2.97216 14 4.24392 14 5.57001C14 6.89609 13.4732 8.16786 12.5355 9.10554C11.5979 10.0432 10.3261 10.57 9 10.57C7.67392 10.57 6.40215 10.0432 5.46447 9.10554C4.52678 8.16786 4 6.89609 4 5.57001C4 4.24392 4.52678 2.97216 5.46447 2.03447C6.40215 1.09679 7.67392 0.570007 9 0.570007Z"
                      fill="black"
                    />
                  </svg>
                </i>
                Hiring{" "}
                <span className="text-black px-1 font-semibold text-base">
                  {job?.candidates_required}
                </span>{" "}
                candidates for this role
              </p>
              {job?.applied >= 0 ? (
                <p
                  onClick={() =>
                    navigate(baseUrl + "/job-applications/" + job?.id)
                  }
                  className="hover:cursor-pointer font-medium inline-flex text-medium text-[#7D7D7D] "
                >
                  <i className="pr-2">
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 10.53L19.56 7.75001L19.9 4.07001L16.29 3.25001L14.4 0.0700073L11 1.53001L7.6 0.0700073L5.71 3.25001L2.1 4.06001L2.44 7.74001L0 10.53L2.44 13.31L2.1 17L5.71 17.82L7.6 21L11 19.53L14.4 20.99L16.29 17.81L19.9 16.99L19.56 13.31L22 10.53ZM9 15.53L5 11.53L6.41 10.12L9 12.7L15.59 6.11001L17 7.53001L9 15.53Z"
                        fill="black"
                      />
                    </svg>
                  </i>
                  Applied candidates{" "}
                  <span className="text-black px-1 font-semibold text-base">
                    {job?.applied}
                  </span>
                </p>
              ) : (
                ""
              )}

              {job?.shortlist >= 0 ? (
                <p
                  onClick={() =>
                    navigate(baseUrl + "/job-applications/" + job?.id)
                  }
                  className="hover:cursor-pointer font-medium inline-flex text-medium text-[#7D7D7D] "
                >
                  <i className="pr-2">
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 10.53L19.56 7.75001L19.9 4.07001L16.29 3.25001L14.4 0.0700073L11 1.53001L7.6 0.0700073L5.71 3.25001L2.1 4.06001L2.44 7.74001L0 10.53L2.44 13.31L2.1 17L5.71 17.82L7.6 21L11 19.53L14.4 20.99L16.29 17.81L19.9 16.99L19.56 13.31L22 10.53ZM9 15.53L5 11.53L6.41 10.12L9 12.7L15.59 6.11001L17 7.53001L9 15.53Z"
                        fill="black"
                      />
                    </svg>
                  </i>
                  Shortlisted candidates{" "}
                  <span className="text-black px-1 font-semibold text-base">
                    {job?.shortlist}
                  </span>
                </p>
              ) : (
                ""
              )}
              {job?.final_selected >= 0 ? (
                <p
                  onClick={() =>
                    navigate(baseUrl + "/job-applications/" + job?.id)
                  }
                  className="hover:cursor-pointer font-medium inline-flex text-medium text-[#7D7D7D] "
                >
                  <i className="pr-2">
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 10.53L19.56 7.75001L19.9 4.07001L16.29 3.25001L14.4 0.0700073L11 1.53001L7.6 0.0700073L5.71 3.25001L2.1 4.06001L2.44 7.74001L0 10.53L2.44 13.31L2.1 17L5.71 17.82L7.6 21L11 19.53L14.4 20.99L16.29 17.81L19.9 16.99L19.56 13.31L22 10.53ZM9 15.53L5 11.53L6.41 10.12L9 12.7L15.59 6.11001L17 7.53001L9 15.53Z"
                        fill="#D6B239"
                      />
                    </svg>
                  </i>
                  Selected candidates{" "}
                  <span className="text-black px-1 font-semibold text-base">
                    {job?.final_selected}
                  </span>
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="font-normal text-sm text-champText400">
              <span>Created At: </span>
              <p>
                <span>
                  <span className="font-semibold">Date: </span>
                  <span className="text-[#7D7D7D]">
                    {readableDate(job?.createdAt)}
                  </span>
                </span>
                <br />
                <span>
                  <span className="font-semibold">Time: </span>
                  <span className="text-[#7D7D7D]">
                    {readableTime(job?.createdAt)}
                  </span>
                </span>
              </p>
            </div>
          </div>

          <div className="relative flex flex-col justify-between items-end">
            <Dropdown
              renderToggle={renderButtonIcon}
              trigger="click"
              placement="bottomEnd"
            >
              <Dropdown.Item
                onClick={() => {
                  archiveJob(job.id);
                }}
                className="hover:cursor-pointer text-base py-1 px-8 font-medium hover:bg-grayBg"
              >
                Archive
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  navigate(baseUrl + "/update-job/" + job.id);
                }}
                className="whitespace-nowrap	hover:cursor-pointer text-base  py-1 px-8 font-medium hover:bg-grayBg"
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  cancelJob(job.id);
                }}
                className="whitespace-nowrap	hover:cursor-pointer text-base  py-1 px-8 font-medium hover:bg-grayBg"
              >
                Cancel
              </Dropdown.Item>
              {/* <Dropdown.Item
                onClick={() => {
                  closeJob(job.id);
                }}
                className="whitespace-nowrap	hover:cursor-pointer text-base  py-1 px-8 font-medium hover:bg-grayBg"
              >
                Close
              </Dropdown.Item> */}
            </Dropdown>

            {job?.selected ? (
              <button
                onClick={() =>
                  navigate(baseUrl + "/job-applications/" + job?.id)
                }
                className="hover:cursor-default  rounded bg-green-500 text-center text-white text-base p-1 px-2 sel"
              >
                Selected : {job?.selected}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <Modal open={openModal} onClose={handleClose}>
        {modalJobDetails ? (
          <>
            <div className="relative bg-white rounded shadow ">
              {/* <!-- Modal header --> */}
              <div className="inline-flex w-full p-4 px-6 justify-end border-b rounded-t bg-blueBgDark">
                <h3 className="w-full text-xl font-semibold text-white uppercase">
                  {modalJobDetails?.jp_position}
                </h3>
                <button
                  onClick={() => {
                    handleClose();
                  }}
                  type="button"
                  className="w-8 text-gray-400 bg-transparent hover:bg-blue-500 hover:text-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              {/* <!-- Modal body --> */}
              <div className="">
                <div className="job-seeker-details flex flex-col items-start space-y-6 p-6 ">
                  <div className="flex flex-col">
                    <p className="ml-0 pl-0 text-3xl font-medium text-black">
                      {modalJobDetails?.name}
                    </p>
                    <p className="text-[12px] text-champText400">
                      <span>{readableDate(modalJobDetails?.createdAt)}</span>
                    </p>
                  </div>
                </div>

                <div className="p-6 flex flex-col gap-8 border-t-4 border-gray-200 rounded-b">
                  <div className="flex flex-col gap-4">
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-location-dot pr-2"></i>
                      {modalJobDetails?.address}
                    </p>
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-envelope pr-2"></i>
                      {modalJobDetails?.email_address}
                    </p>
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-phone pr-2"></i>
                      {modalJobDetails?.main_phone}
                    </p>
                  </div>

                  <div className="flex flex-col gap-4">
                    {modalJobDetails?.job_type ? (
                      <p className="font-normal text-base flex flex-col">
                        <span className="text-2xl font-medium">Job Type:</span>
                        <span className="text-base text-black">
                          {" "}
                          {modalJobDetails?.job_type}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}

                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">Shift:</span>
                      <span className="text-base text-champText400">
                        {" "}
                        {modalJobDetails?.job_shift}
                      </span>
                    </p>
                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">Schedule:</span>
                      <p>
                        <span>
                          <span className="font-semibold">Date: </span>
                          {modalJobDetails?.start_job_date && (
                            <>
                              <span className="text-[#7D7D7D]">
                                {readableDate(modalJobDetails?.start_job_date)}
                              </span>{" "}
                              {" - "}
                            </>
                          )}

                          <span className="text-[#7D7D7D]">
                            {readableDate(modalJobDetails?.job_date)}
                          </span>
                        </span>
                        <br />
                        <span>
                          <span className="font-semibold">Time: </span>
                          <span className="text-[#7D7D7D]">
                            {readableTime(modalJobDetails?.schedule[0]) +
                              " - " +
                              readableTime(modalJobDetails?.schedule[1])}
                          </span>
                        </span>
                      </p>
                    </p>
                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">
                        Job description:
                      </span>
                      {modalJobDetails?.job_description ? <div
                                                dangerouslySetInnerHTML={{
                                                    __html: modalJobDetails?.job_description,
                                                }}
                                            />:<span className="text-gray-300">Not Mentioned</span>}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="relative">
              <div
                className="absolute left-0 right-0 m-auto my-auto top-12 "
                style={{ textAlign: "center" }}
              >
                <Loader size="md" />
              </div>
              <Placeholder.Paragraph
                style={{ marginTop: 30 }}
                rows={5}
                active
              />
            </div>
          </>
        )}
      </Modal>

      {/* job deactivate modal */}
      <Modal open={deactivateOpen} onClose={handleDeactivateClose}>
        {/* <!-- Modal content --> */}
        <div className="relative w-full bg-white rounded ">
          {/* <!-- Modal header --> */}
          <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Reason for deactivating the Job
            </h3>
            <button
              onClick={handleDeactivateClose}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* <!-- Modal body --> */}
          <div className="pt-6">
            <div className="form-group">
              <textarea
                type="text"
                rows="6"
                cols="50"
                className="form-control w-full h-full rounded px-3 py-1.5 text-base font-normal outline-none border-none"
                name="title"
                id="title"
                onChange={(event) => {
                  setDeactivateMessage(event.target.value);
                }}
                placeholder="You can type your reason here...."
              ></textarea>
            </div>

            {/* <!-- Modal footer --> */}
            <div className="flex justify-center items-center py-2 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="submit"
                className="text-white bg-black font-500 text-md py-2 px-8 rounded"
                onClick={() => {
                  deactiveJob(deactivateJobId);
                  handleDeactivateClose();
                }}
              >
                De-activate
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PendingJobCard;
