import React, { useState } from 'react'
import { Navigate } from 'react-router-dom';
import { DateRangePicker, Modal, Button, ButtonToolbar, Placeholder, DatePicker, Radio } from 'rsuite';

const TimeSheetsWeek = () => {
  

  return (
    <div className="flex flex-col my-8 space-y-4">
    <div>
      <div className="relative overflow-x-auto sm:rounded-lg mb-8">
        <table className="w-full text-left overflow-visible h-full pb-8 border-collapse border text-center">
          <thead className="font-normal uppercase text-black text-[18px] border-b bg-[#F1F4F8]">
            <tr>
              <th className="px-6 py-3 border border-champBorderPagination" >
                <div className="inline-flex items-center space-x-1">
                  <span className="text-4xl	">1</span>
                  <p className="flex flex-col">
                    <span className="text-sm">MON</span>
                    <span className="text-xs text-timeSheetTextGray">Dec</span>
                  </p>
                </div>
              </th>
              <th className="px-6 py-3 border border-champBorderPagination" >
                <div className="inline-flex items-center space-x-1">
                  <span className="text-4xl	">1</span>
                  <p className="flex flex-col">
                    <span className="text-sm">MON</span>
                    <span className="text-xs text-timeSheetTextGray">Dec</span>
                  </p>
                </div>
              </th>
              <th className="px-6 py-3 border border-champBorderPagination" >
                <div className="inline-flex items-center space-x-1">
                  <span className="text-4xl	">1</span>
                  <p className="flex flex-col">
                    <span className="text-sm">MON</span>
                    <span className="text-xs text-timeSheetTextGray">Dec</span>
                  </p>
                </div>
              </th>
              <th className="px-6 py-3 border border-champBorderPagination" >
                <div className="inline-flex items-center space-x-1">
                  <span className="text-4xl	">1</span>
                  <p className="flex flex-col">
                    <span className="text-sm">MON</span>
                    <span className="text-xs text-timeSheetTextGray">Dec</span>
                  </p>
                </div>
              </th>
              <th className="px-6 py-3 border border-champBorderPagination" >
                <div className="inline-flex items-center space-x-1">
                  <span className="text-4xl	">1</span>
                  <p className="flex flex-col">
                    <span className="text-sm">MON</span>
                    <span className="text-xs text-timeSheetTextGray">Dec</span>
                  </p>
                </div>
              </th>
              <th className="px-6 py-3 border border-champBorderPagination" >
                <div className="inline-flex items-center space-x-1">
                  <span className="text-4xl	">1</span>
                  <p className="flex flex-col">
                    <span className="text-sm">MON</span>
                    <span className="text-xs text-timeSheetTextGray">Dec</span>
                  </p>
                </div>
              </th>
              <th className="px-6 py-3 border border-champBorderPagination" >
                <div className="inline-flex items-center space-x-1">
                  <span className="text-4xl	">1</span>
                  <p className="flex flex-col">
                    <span className="text-sm">MON</span>
                    <span className="text-xs text-timeSheetTextGray">Dec</span>
                  </p>
                </div>
              </th>
              
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border text-black text-[20px]">
              <td className="px-6 py-4  border border-champBorderPagination">
                7:22:12
              </td>
              <td className="px-6 py-4  border border-champBorderPagination">7:22:12</td>
              <td className="px-6 py-4  border border-champBorderPagination">7:22:12</td>
              <td className="px-6 py-4  border border-champBorderPagination">7:22:12</td>
              <td className="px-6 py-4  border border-champBorderPagination">7:22:12</td>
              <td className="px-6 py-4  border border-champBorderPagination">7:22:12</td>
              <td className="px-6 py-4  border border-champBorderPagination">7:22:12</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <p className="text-[20px] text-right text-black text-[#00C34E]">Total: 43:49:26</p>
    </div>
  </div>
  );
}

export default TimeSheetsWeek