import React from "react";

function CandidatePasswordResetSuccessMessage() {


  return (
    <div className=" h-screen relative pb-12 overflow-y-hidden">
      <img className="-z-10 absolute w-full bottom-0" src="/assests/images/candidate-bg.png" />
      <header className="container py-4">
        <img src="/logo.png" alt="health champ logo" />
      </header>
    <main className="h-full container">
      <section className="relative h-full w-full">
        <div className="h-full w-full flex justify-center items-center">
          <div className="flex justify-center items-center flex-wrap h-full -mt-16 gap-6 text-gray-800 lg:w-1/3">
            <div className="w-full">
              <div className="flex flex-col justify-center items-center space-y-6 mb-6">
                <h1 className="font-semibold text-3xl">Password reset</h1>
                <h3 className="font-normal leading-7 text-lg text-center">
                  Your password has been successfully reset. <br /> Click below
                  to log in.
                </h3>
              </div>

              <a href="/">
                <button
                  className="inline-block px-7 py-3 bg-blueBgDark text-white border-champBorder font-semibold text-lg rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out w-full mt-4"
                 
                >
                  {" "}
                  Continue
                </button>
              </a>

            </div>
          </div>
        </div>
      </section>
    </main>
    </div>
  );
}

export default CandidatePasswordResetSuccessMessage;
