import React, { forwardRef, useRef, useState } from "react";
import { candaCities, states } from '../../../../data/CandaData';
import {
  Form,
  Schema,
  SelectPicker,
  TagPicker,
  RadioGroup,
  Radio,
  useToaster,
  Message,
  AutoComplete,
  MaskedInput
} from "rsuite";
import { API_BASE_URL } from "../../../../config/apiKeys";
import axios from "axios";
import LocationIcon from '@rsuite/icons/Location';
import { usePlacesAutocomplete } from "../../../../Components/googleMap/useGoogleMap";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ChampHealthApiClient } from "../../../../config/apiClients";
import { USER_DETAIL, setCookie } from "../../../../config/headerToken";


const { StringType, ArrayType } = Schema.Types;

const model = Schema.Model({
  private_residence_name: StringType()
    .pattern(/^[a-zA-Z0-9\s.]*$/, "Please enter legal characters")
    .isRequired("This field is required."),
  relation: StringType()
    .pattern(/^[a-zA-Z0-9\s.]*$/, "Please enter legal characters"),
  street_address: StringType()
    .pattern(
      /^[\/a-zA-Z0-9\s.!@#&*',\-\u0080-\u052F]*$/,
      "Please enter legal characters"
    )
    .isRequired("This field is required."),
  province: StringType().isRequired("This field is required."),
  city: StringType().isRequired("This field is required."),
  postalCode: StringType().addRule((value) => {
    if (value.includes('_')) {
      return false;
    }

    return true;
  }, "Please Enter Complete Postal Code")
    .maxLength(7, "Cannot be greater than 6 characters")
    .minLength(7, "Can't be less than 6 characters")
    .isRequired("This field is required."),
  cell_phone: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Phone Number"),
  main_phone: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Phone Number")
    .isRequired("This field is required."),
  person_name: StringType()
    .pattern(/^[a-zA-Z0-9\s.]*$/, "Please enter only characters")
    .isRequired("This field is required."),
  payable_email: StringType().isEmail("Please enter a valid email address."),
  mode_of_communication: ArrayType().isRequired("This field is required."),
  email_address: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
});

const billingAddressModel = Schema.Model({
  billing_phone: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Phone Number")
    .isRequired("This field is required."),
  billing_email_address: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
  billing_street_address: StringType()
    .pattern(
      /^[\/a-zA-Z0-9\s.!@#&*',\-\u0080-\u052F]*$/,
      "Please enter legal characters"
    )
    .isRequired("This field is required."),
  billing_province: StringType().isRequired("This field is required."),
  billing_city: StringType().isRequired("This field is required."),
  billing_postalCode: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Postal Code")
    .maxLength(7, "Cannot be greater than 6 characters")
    .minLength(7, "Can't be less than 6 characters")
    .isRequired("This field is required."),
});

const TextField = forwardRef((props, ref) => {
  const { placeholder, name, label, accepter, ...rest } = props;
  return (
    <Form.Group
      className="admin_create_client relative mb-4"
      controlId={`${name}-4`}
      ref={ref}
    >
      <Form.ControlLabel className="text-sm text-[#A1A1A1] font-semibold duration-300">
        {label}
      </Form.ControlLabel>
      <Form.Control
        className={`pt-0 pb-2 px-0 w-full text-lg font-semibold text-[#545454] bg-transparent border-0 border-b-2 border-champBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
        name={name}
        accepter={accepter}
        placeholder={placeholder}
        {...rest}
      />
    </Form.Group>
  );
});

const PrivateHome = ({ clientDetails }) => {




  const navigate = useNavigate();
  const { clientId } = useParams();
  const toaster = useToaster();
  const data = [
    { label: "Email", value: "Email" },
    { label: "Cell Phone", value: "Cell Phone" },
    { label: "Text messages", value: "Text messages" },
    { label: "Main Phone", value: "Main Phone" },
  ];

  const [disableSubmit, setDisableSubmit] = useState(true)

  const stateData = states.map((oneState) => ({ label: oneState.name, value: oneState.name }));
  const citiesData = (state_code) => (candaCities(state_code).map((city) => ({ label: city.name, value: city.name })))

  // auto suggestion api
  const [suggestions, fetchSuggestions] = usePlacesAutocomplete()

  //// detail form
  const formRef = useRef();
  const [formError, setFormError] = useState({});

  const [formValue, setFormValue] = useState({
    type: clientDetails.type,
    private_residence_name: clientDetails.residence_name,
    relation: clientDetails.relation,
    street_address: clientDetails.street_address,
    province: clientDetails.province,
    city: clientDetails.city,
    main_phone: clientDetails.main_phone,
    postalCode: clientDetails.postal_code,
    person_name: clientDetails.name,
    payable_email: clientDetails.payable_email,
    email_address: clientDetails.email_address,
    cell_phone: clientDetails.cell_phone,
    mode_of_communication: clientDetails.communication_mode,
    billing_address_same_as: true,
    status: clientDetails.status,
    reason: clientDetails.reason,
  });

  //// billing form
  const billingFormRef = useRef();
  const [billingFormError, setBillingFormError] = useState({});
  const [billingFormValue, setBillingFormValue] = useState({
    billing_email_address: clientDetails.billing_email_address,
    billing_phone: clientDetails.billing_phone,
    billing_street_address: clientDetails.billing_street_address,
    billing_province: clientDetails.billing_province,
    billing_postalCode: clientDetails.billing_postal_code,
    billing_city: clientDetails.billing_city
  });




  const getUserDetail = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/clients/client-name`);

      if (response?.status === 200) {
        let data = response?.data;
        setCookie("token", USER_DETAIL?.token, 3);
        setCookie("user", JSON.stringify({
          ...USER_DETAIL,
          ...data
        }), 3)
        // window.location = '/client/profile'

      } else {
        const message = (
          <Message showIcon type="error"  closable>
            {response?.response?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      console.log("Create_job_getSingleLiveJobDetails_Error...", error);
    }

  }


  const updateClient = async () => {
    try {
      const response = await ChampHealthApiClient.put(`/admin/clients/${clientId}`, {
        client_type: 2,
        residence_name: formValue.private_residence_name,
        relation: formValue.relation,
        street_address: formValue.street_address,
        province: formValue.province,
        city: formValue.city,
        postal_code: (formValue.postalCode).toUpperCase(),
        main_phone: formValue.main_phone,
        name: formValue.person_name,
        payable_email: formValue.payable_email,
        cell_phone: formValue.cell_phone,
        communication_mode: formValue.mode_of_communication,
        email_address: formValue.email_address,
        billing_phone: billingFormValue.billing_phone,
        billing_email_address: billingFormValue.billing_email_address,
        billing_street_address: billingFormValue.billing_street_address,
        billing_province: billingFormValue.billing_province,
        billing_city: billingFormValue.billing_city,
        billing_postal_code: (billingFormValue.billing_postalCode).toUpperCase(),
        status: formValue.status,
        reason: formValue.reason,
      });


      if (response?.status === 200) {
        getUserDetail();
        setFormValue({
          private_residence_name: "",
          relation: "",
          street_address: "",
          province: "",
          city: "",
          main_phone: "",
          person_name: "",
          postalCode: "",
          position: "",
          payable_email: '',
          email_address: '',
          cell_phone: '',
          mode_of_communication: [],
          billing_address_same_as: true
        })
        setBillingFormValue({
          billing_email_address: "",
          billing_phone: "",
          billing_street_address: "",
          billing_province: "",
          billing_postalCode: "",
          billing_city: "",

        })
        setBillingFormError({})
        setFormError({})
        setSubmit(false)
        setDisableSubmit(true)



        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: 'topEnd', duration: 3000 })
      }

    } catch (error) {
      setSubmit(false)
      setDisableSubmit(true)
      const message = (
        <Message showIcon type="error"  closable>
          {error.response.data.message}
        </Message>
      );
      toaster.push(message, { placement: 'topEnd', duration: 3000 })
    }

  }


  const [submit, setSubmit] = useState(false);

  const handleSubmit = () => {
    setSubmit(false)
    if (!formRef.current.check()) {
      return;
    }
    if (formValue.billing_address_same_as) {
      setBillingFormValue({
        billing_phone: formValue.main_phone,
        billing_email_address: formValue.email_address,
        billing_street_address: formValue.street_address,
        billing_province: formValue.province,
        billing_city: formValue.city,
        billing_postalCode: formValue.postalCode,
      });
    } else {
      if (!billingFormRef.current.check()) {
        return;
      }
    }
    setDisableSubmit(false)
    setSubmit(true)

  };


  if (submit) {
    updateClient();
  }



  return (
    <div className="form-container flex flex-col space-y-4 md:py-4">
      <div className="form-heading_section  mb-2">
        <h1 className="text-[25px] font-[500] mb-4">Private home</h1>
        <hr className="border rounded bg-champDivideLine" />
      </div>

      <Form
        fluid
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        className="relative flex flex-col space-y-24"
      >
        <div className="grid md:grid-cols-2 md:gap-x-16 gap-y-4">
          <TextField
            name="private_residence_name"
            label="Private Residence Name : *"
          />
          <TextField
            name="relation"
            label="Relation :"
          />
          {/* <TextField name="street_address" label="Street Address : *" /> */}
          <TextField
            name="street_address"
            label="Street Address : *"
            accepter={AutoComplete}
            onChange={(e) => fetchSuggestions(e)}
            data={suggestions.length ? (suggestions.map((item) => ({ label: `${item.terms[0].value}, ${item.terms[1].value}`, value: `${item.terms[0].value}, ${item.terms[1].value}` }))) : data}
            renderMenuItem={item => {
              return (
                <div>
                  <LocationIcon /> {item}
                </div>
              );
            }}
          />

          <div className="relative z-0 w-full flex justify-between space-x-8">
            <div className="w-full">
              <TextField
                name="province"
                searchable={false}
                data={stateData}
                accepter={SelectPicker}
                label="Province : *"
              />
            </div>
            <div className="w-full">
              <TextField
                name="city"
                searchable
                disabled={formValue.province ? false : true}
                data={formValue.province ? citiesData(formValue.province) : data}
                accepter={SelectPicker}
                label="City : *"
              />
            </div>
          </div>

          <TextField name="postalCode" label="Postal Code : *"
            style={{ 'text-transform': 'uppercase' }}
            accepter={MaskedInput}
            mask={[/[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/]} />
          <TextField name="main_phone" label="Main Phone : *" accepter={MaskedInput}

            mask={['+', '1', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
          <TextField name="person_name" label="Your Name : *" />
          {/* <TextField
            name="payable_email"
            label="Account Payable Email Address :"
          /> */}
          <TextField name="email_address" label="Email Address : *" />
          <TextField name="cell_phone" label="Cell Phone :" accepter={MaskedInput}

            mask={['+', '1', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
          <TextField
            name="mode_of_communication"
            searchable={false}
            data={data}
            accepter={TagPicker}
            label="Mode Of Communication ( Preferred ) : *"
          />

          <div className="hidden relative z-0 w-full group whitespace-nowrap">
            <TextField
              name="billing_address_same_as"
              label="Billing address *"
              accepter={RadioGroup}
              inline
              className={`pt-0 pb-2 px-0 w-full text-lg font-semibold text-[#545454] bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
            >
              <Radio value={true}>Same as Above</Radio>
              <Radio value={false} className="add_new_address_btn"><div className="hover:cursor-pointer border border-black text-black font-normal px-4 py-1 rounded inline-flex items-center space-x-2"><i className="fa-solid fa-plus"></i>
                <span className="text-base">Add new</span>
              </div></Radio>

            </TextField>
          </div>
        </div>


      </Form>


      <div className={` ${formValue.billing_address_same_as ? " hidden" : <>{formValue.billing_address_same_as ? " " : " hidden"}</>}`}>
        <div><h2 className="font-medium text-2xl mb-8">Billing Address</h2> </div>
        <Form
          fluid
          ref={billingFormRef}
          onChange={setBillingFormValue}
          onCheck={setBillingFormError}
          formValue={billingFormValue}
          model={billingAddressModel}
          className="relative flex flex-col space-y-24"
        >
          <div className="grid md:grid-cols-2 md:gap-x-16 gap-y-4">
            <TextField name="billing_phone" label="Phone : *" accepter={MaskedInput}

              mask={['+', '1', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
            <TextField name="billing_email_address" label="Email Address : *" />
            <TextField name="billing_street_address" label="Street Address : *" />
            <TextField name="billing_province" searchable={false} data={stateData} accepter={SelectPicker} label="Province : *" />
            <TextField name="billing_city" searchable disabled={billingFormValue.billing_province ? false : true} data={billingFormValue.billing_province ? citiesData(billingFormValue.billing_province) : data} accepter={SelectPicker} label="City : *" />
            <TextField name="billing_postalCode" label="Postal Code : *" 
            
              style={{ 'text-transform': 'uppercase' }}
              accepter={MaskedInput}
              mask={[/[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/]} />
          </div>
        </Form>
      </div>

      <button
        type="submit"
        className="text-white bg-blueBtnDark font-medium rounded mx-auto text-xl px-12 py-3 mt-8 text-center"
        onClick={handleSubmit}
        disabled={disableSubmit ? false : true}
      >
        {" "}
        Update{" "}
      </button>
    </div>
  );
};

export default PrivateHome;
