import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Form,
    Message,
    Schema,
    SelectPicker,
    MaskedInput,
    toaster,
    Loader,
    Placeholder,
    TagPicker,
    CheckboxGroup,
    Checkbox,
} from "rsuite";
import { ChampHealthApiClient } from '../../../config/apiClients';
import TextFieldBottomBorder from '../../../Components/TextFieldBottomBorder';
import TextField from '../../../Components/TextField';
import { candaCities, states } from '../../../data/CandaData';
import { StringToArray } from '../../../Components/stringToArray';
import { USER_DETAIL, setCookie } from '../../../config/headerToken';
import { daysArray } from '../../../Components/JobSeekerSchemaModal';





const { StringType, NumberType, MixedType } = Schema.Types;

const model = Schema.Model({
    firstName: StringType().isRequired("This field is required."),
    lastName: StringType().isRequired("This field is required."),
    email: StringType()
        .isEmail("Please enter a valid email address.")
        .isRequired("This field is required."),
    phone: StringType().addRule((value) => {
        if (value.includes('_')) { return false; } return true;
    }, "Please Enter Complete Phone Number")
        .isRequired("This field is required."),
    address: StringType().isRequired("This field is required."),
    availability: NumberType().isRequired("This field is required."),
    jobtype: NumberType().isRequired("This field is required."),
    language: StringType().isRequired("This field is required."),
    province: StringType().isRequired("This field is required."),
    city: StringType().isRequired("This field is required."),
    postalCode: StringType().addRule((value) => {
        if (value.includes('_')) {
            return false;
        }

        return true;
    }, "Please Enter Complete Postal Code")
        .maxLength(7, "Cannot be greater than 6 characters")
        .minLength(7, "Can't be less than 6 characters")
        .isRequired("This field is required."),
    availability_other: MixedType().when(schema => {
        return schema.availability.value === 8
            ? StringType().isRequired('This field is required.')
            : StringType();
    })
});






const UpdateDetails = () => {
    const { jobSeekerId } = useParams();
    const navigate = useNavigate()

    const [jobSeekerDetails, setJobSeekerDetails] = useState(false);





    const stateData = states.map((oneState) => ({
        label: oneState.name,
        value: oneState.name,
    }));
    const citiesData = (state_code) =>
        candaCities(state_code).map((city) => ({
            label: city.name,
            value: city.name,
        }));



    //// detail form
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        phone: "",
        availability: "",
        employmenttype: "",
        jobtype: "",
        language: "",
        availability_other: ""
    });


    const getUserDetail = async () => {
        try {
            const response = await ChampHealthApiClient.get(`jobseekers/jobseeker-name/`);

            if (response?.status === 200) {
                window.location = '/candidate/profile'

                let data = response?.data;
                console.log(data);
                setCookie("token", USER_DETAIL?.token, 3);
                setCookie("user", JSON.stringify({
                    ...USER_DETAIL,
                    ...data
                }), 3)
            } else {
                const message = (
                    <Message showIcon type="error" closable>
                        {response?.response?.message}
                    </Message>
                );
                toaster.push(message, { placement: "topEnd", duration: 3000 });
            }
        } catch (error) {
            console.log("Create_job_getSingleLiveJobDetails_Error...", error);
        }

    }





    const updateJobSeeker = async () => {
        try {

            const response = await ChampHealthApiClient.put('/admin/jobseekers/update/' + jobSeekerId, {
                firstname: formValue.firstName,
                lastname: formValue.lastName,
                address: formValue.address,
                province: formValue.province,
                city: formValue.city,
                postal_code: formValue.postalCode,
                email_id: formValue.email,
                phone: formValue.phone,
                job_position: formValue.job_position.toString(),
                applied_jobs: formValue.applied_jobs,
                availability: formValue.availability,
                transportation_mode: formValue.transportation_mode,
                reason: formValue.reason,
                job_type: formValue.jobtype,
                language: formValue.language,
                mon: formValue.mon.toString(),
                tue: formValue.tue.toString(),
                wed: formValue.wed.toString(),
                thu: formValue.thu.toString(),
                fri: formValue.fri.toString(),
                sat: formValue.sat.toString(),
                sun: formValue.sun.toString(),
            });

            if (response.status === 200) {
                const message = (
                    <Message showIcon type="success" header="Success" closable>
                        {response?.data?.message}
                    </Message>
                );
                toaster.push(message, { placement: "topEnd", duration: 3000 });
                getUserDetail()

            } else {
                const message = (
                    <Message showIcon type="error" closable>
                        {response?.data?.message}
                    </Message>
                );
                toaster.push(message, { placement: "topEnd", duration: 3000 });
            }

        } catch (error) {
            const message = (
                <Message showIcon type="error" closable>
                    {error?.message}
                </Message>
            );
            toaster.push(message, { placement: "topEnd", duration: 3000 });
        }
    }




    const handleSubmit = () => {
        if (formRef.current.check()) {
            updateJobSeeker();
            return;
        }
        console.error("Form Error", formError);
    };




    /// get data from api to show in form
    const [availability, setAvailability] = useState(false);
    const [jobtype, setJobtype] = useState(false);

    // availability time
    const getAvailability = async () => {
        try {
            const response = await ChampHealthApiClient.get(`/common/job-shifts`);

            if (response?.status === 200) {
                setAvailability(
                    response?.data?.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }))
                );
            }
        } catch (error) {
            console.log(
                "candidate_register_getAvailability_response_Error...",
                error
            );
        }
    };

    // employmenttype
    const getJobtype = async () => {
        try {
            const response = await ChampHealthApiClient.get(`/common/job-types`);

            if (response?.status === 200) {
                setJobtype(
                    response?.data?.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }))
                );
            }
        } catch (error) {
            console.log(
                "candidate_register_getEmploymenttype_response_Error...",
                error
            );
        }
    };


    /// get function for jobs
    const getSingleJobSeekerDetails = async () => {
        try {
            const response = await ChampHealthApiClient.get(`/common/jobseeker-details/${jobSeekerId}`);

            if (response?.status === 200) {
                let data = response?.data?.data[0];
                setFormValue({
                    ...data,
                    firstName: data?.firstname,
                    lastName: data?.lastname,
                    email: data?.email_id,
                    address: data?.address,
                    province: data?.province,
                    city: data?.city,
                    postalCode: data?.postal_code,
                    phone: data?.phone,
                    availability: data?.availability_id,
                    job_position: StringToArray(data?.job_position_id),
                    jobtype: data?.job_type_id,
                    language: data?.language,
                    mon: StringToArray(data?.mon),
                    tue: StringToArray(data?.tue),
                    wed: StringToArray(data?.wed),
                    thu: StringToArray(data?.thu),
                    fri: StringToArray(data?.fri),
                    sat: StringToArray(data?.sat),
                    sun: StringToArray(data?.sun),

                })
                setJobSeekerDetails(data)
            } else {
                const message = (
                    <Message showIcon type="error" closable>
                        {response?.response?.message}
                    </Message>
                );
                toaster.push(message, { placement: "topEnd", duration: 3000 });
            }
        } catch (error) {
            console.log("Create_job_getSingleLiveJobDetails_Error...", error);
        }
    };


    const [jobPostions, setJobPostions] = useState(false);
    // fetch AllJobPostion
    const getAllJobPostion = async () => {
        try {
            const response = await ChampHealthApiClient.get(`/common/job-positions`);
            if (response?.status === 200) {
                setJobPostions(
                    response?.data?.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }))
                );
            }
        } catch (error) {
            console.log("candidate_register_getAllJobPostion_response_Error...", error);
        }
    };


    useEffect(() => {
        getAvailability();
        getJobtype();
        getAllJobPostion();
        getSingleJobSeekerDetails();
    }, []);



    return (
        <main>
            <section className="py-8 bg-[#F1F4F8]">
                <div className="container mx-auto md:flex md:justify-between md:gap-2 lg:flex lg:justify-between w-full ">
                    {/* <!-- Client List Search bar --> */}
                    <div
                        className="inline-flex items-center gap-2 font-[600] hover:cursor-pointer text-black"
                        onClick={() => navigate(-1)}
                    >
                        <i className="fa-solid fa-arrow-left-long text-2xl"></i>
                        <h1 className="text-[30px] whitespace-nowrap">
                            {'Back'}
                        </h1>
                    </div>
                </div>
            </section>

            {jobSeekerDetails ? (<div className="container relative pb-12">
                <Form
                    fluid
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={(e) => {
                        const availabilityFields = {
                            mon: "This field is required",
                            tue: "This field is required",
                            wed: "This field is required",
                            thu: "This field is required",
                            fri: "This field is required",
                            sat: "This field is required",
                            sun: "This field is required",
                        };

                        // Determine if all availability fields exist in the object
                        const allAvailabilitiesExist = Object.keys(
                            availabilityFields
                        ).every(
                            (field) => field in e // Replace "yourObject" with the actual object
                        );

                        // Remove error messages from availability fields if all exist
                        if (!allAvailabilitiesExist) {
                            for (const field in availabilityFields) {
                                delete e[field];
                            }
                        }
                        setFormError(e);
                    }}
                    formValue={formValue}
                    model={model}
                    className="relative w-full py-8 space-y-4"
                >
                    <div className="flex justify-center space-x-4">
                        <div className='w-1/2'>

                            <TextField
                                name="firstName"
                                label="First name : *"
                                placeholder="Patrick "
                            />

                        </div>
                        <div className='w-1/2'>
                            <TextField
                                name="lastName"
                                label="Last name : *"
                                placeholder="Swanson "
                            />
                        </div>

                    </div>

                    <TextField
                        name="address"
                        label="Home address : *"
                        placeholder="609 Beach Blvd, jacksonville, Florida"
                    />

                    <div className="relative z-0 w-full flex justify-between space-x-2 mb-4">
                        <div className="w-1/2">
                            <TextField
                                name="province"
                                searchable={false}
                                data={stateData}
                                accepter={SelectPicker}
                                label="Province : *"
                            />
                        </div>
                        <div className="w-1/2">
                            <TextField
                                name="city"
                                searchable
                                disabled={formValue.province ? false : true}
                                data={formValue.province ? citiesData(formValue.province) : []}
                                accepter={SelectPicker}
                                label="City : *"
                            />
                        </div>
                    </div>
                    <div className="flex space-x-2 justify-between">

                        <TextField name="postalCode" label="Postal Code : *" style={{ 'text-transform': 'uppercase' }}
                            accepter={MaskedInput}
                            mask={[/[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/]} />

                        <TextField
                            name="email"
                            type="email"
                            label="Email address : *"
                            placeholder="patrickswanson@gmail.com "
                        />
                    </div>


                    <div className="flex space-x-2 justify-between">
                        <TextField
                            name="phone"
                            label="Phone number : *"
                            placeholder="+1(676)576-5794"
                            accepter={MaskedInput}
                            mask={['+', '1', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        />


                        <TextField
                            name="job_position"
                            label="Job Postions : *"
                            placeholder="Job Postions"
                            accepter={TagPicker}
                            data={jobPostions ? jobPostions : []}
                            searchable={false}
                        />
                    </div>
                    <div className="flex space-x-2 justify-between">

                        <TextField
                            name="jobtype"
                            label="Job type :"
                            placeholder=" Part time"
                            accepter={SelectPicker}
                            data={jobtype ? jobtype : []}
                            searchable={false}
                        />

                        <TextField
                            name="language"
                            label="Language : *"
                            placeholder=" Spoken language"
                        />
                    </div>
                    <div className="flex flex-col space-y-2 justify-between">

                        {daysArray.map((item, index) => {
                            return (
                                <div key={index}>
                                    <Form.Group controlId={"checkbox-" + item.value}>
                                        <Form.ControlLabel className="text-base text-black font-medium">
                                            {" "}
                                            {item.label}:{" "}
                                        </Form.ControlLabel>
                                        <Form.Control
                                            className=" p-0 m-0  register_input_field -pl-2"
                                            name={item.label.slice(0, 3).toLocaleLowerCase()}
                                            accepter={CheckboxGroup}
                                            inline
                                        >
                                            <Checkbox value={1}>Morning</Checkbox>
                                            <Checkbox value={2}>Evening</Checkbox>
                                            <Checkbox value={3}>Night</Checkbox>
                                            <Checkbox value={4}>Any Time</Checkbox>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            );
                        })}

                        {/* <TextField
                            name="availability"
                            label="Availability : *"
                            placeholder=" Morning"
                            accepter={SelectPicker}
                            data={availability ? availability : []}
                            searchable={false}
                        />

                        {formValue?.availability === 8 ? <TextField
                            name="availability_other"
                            label="Other : *"
                            placeholder="enter other Availability"
                        /> : ''} */}

                    </div>



                </Form>


                <div className='flex justify-between'>
                    <button
                        type="submit"
                        className="text-white text-center bg-blueBtnDark font-medium rounded mx-auto text-xl px-12 py-3 mt-8 text-center"
                        onClick={handleSubmit}
                    >
                        {" "}
                        Update{" "}
                    </button>

                </div>
            </div>) : (
                <>
                    <div className="container relative">
                        <div
                            className="absolute left-0 right-0 m-auto my-auto top-12 "
                            style={{ textAlign: "center" }}
                        >
                            <Loader size="md" />
                        </div>
                        <Placeholder.Paragraph
                            style={{ marginTop: 30 }}
                            rows={5}
                            active
                        />
                    </div>
                </>
            )}

        </main>

    )
}

export default UpdateDetails