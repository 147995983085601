import React, { useEffect, useState } from "react";
import { Loader, Message, Nav, Placeholder, toaster } from "rsuite";
import { ChampHealthApiClient } from "../../../config/apiClients";
import CandidateAccountDetails from "./AccountDetails";
import CandidateDocuments from "./Documents";
import CandidateJobPreferences from "./JobPreferences";
import { useNavigate } from "react-router-dom";

const Navbar = ({ active, onSelect, ...props }) => {
  return (
    <Nav
      {...props}
      activeKey={active}
      onSelect={onSelect}
      style={{ marginBottom: 50 }}
    >
      <Nav.Item
        className="text-lg font-medium hover:!text-champGreen"
        eventKey="AccountDetails"
      >
        Account detail
      </Nav.Item>
      <Nav.Item
        className="text-lg font-medium hover:!text-champGreen"
        eventKey="CvDocuments"
      >
       CV's other documents
      </Nav.Item>
      <Nav.Item
        className="text-lg font-medium hover:!text-champGreen"
        eventKey="JobPreferences"
      >
      Job preferences
      </Nav.Item>
    </Nav>
  );
};

function ClientProfile() {
  const navigate = useNavigate()
  const [active, setActive] = useState("AccountDetails");



  const [profileDetails, setProfileDetails] = useState(false);

  const getProfileDetails = async () => {
    try {
      const response = await ChampHealthApiClient.get("/clients/profile");

      if (response?.status === 200) {
        setProfileDetails(response?.data?.data[0]);
      } else {
        const message = (
          <Message showIcon type="error"  closable>
            {response?.data?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error"  closable>
          {error.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
    }
  };

  useEffect(() => {
    getProfileDetails();
  }, []);


  return (
    <>
      <div className=" relative">
        <section className="py-6 bg-[#F1F4F8]">
          <div className="container divide-y divide-y-4 space-y-6">
            <div className="flex justify-between md:flex items-center lg:items-center md:space-x-2 md:justify-between w-full lg:py-6">
              {/* <!-- job List Search bar --> */}
              <div className="md:w-1/2 w-full lg:flex lg:items-center lg:justify-between lg:w-1/2 lg:space-x-4 xl:w-2/3 xl:justify-between my-2 ml-[4px]">
                <h1 className="font-semibold text-black min-w-[150px] text-3xl whitespace-nowrap my-2 md:my-0">Profile</h1>
              </div>
              <div className="md:w-1/2 w-full lg:flex lg:items-center lg:justify-end lg:w-1/2 lg:space-x-4 xl:w-2/3 xl:justify-end my-2 ml-[4px]">
                <button onClick={()=>navigate('/client/profile/update/'+profileDetails.id)} className="text-end border border-black rounded px-8 py-1 text-xl text-black font-medium bg-white">Edit</button>
              </div>
            </div>
          </div>
        </section>


        <main className="container w-full h-full relative flex flex-col justify-center content-center">
          <div className="-mt-[14px]">
            <div className="inline-flex items-center space-x-3 text-black">
              <div className="bg-champAvatarBg rounded-full text-white h-20 w-20 text-2xl font-medium flex justify-center items-center  text-center uppercase">
                {" "}
                <p>{profileDetails && profileDetails?.name.charAt(0)}</p>
              </div>
              <p className="text-3xl font-medium uppercase">{profileDetails ? profileDetails?.residence_name : <Loader size="sm" speed="fast" content="Loading"/>}</p>
            </div>
          </div>

          <div className="mt-8  bg-white py-4 rounded-lg">
            {/* <Navbar
              appearance="subtle"
              active={active}
              onSelect={setActive}
              className="profilePage__ActiveIcon font-semibold flex items-center gap-4 text-center border-b-0"
            /> */}

            <div className="w-full">
              {active === "AccountDetails" ? <CandidateAccountDetails clientDetails={profileDetails} />:<>
              {active === "CvDocuments" ? <CandidateDocuments />:<>
              {active === "JobPreferences" ? <CandidateJobPreferences />:<></>}
              </>}
              </>}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default ClientProfile;
