import React from "react";

import { Dropdown } from "rsuite";

function CandidateDocuments() {
  const renderButton = (props, ref) => {
    return (
      <i
        {...props}
        ref={ref}
        className="fa-solid fa-ellipsis-vertical  text-xl"
      ></i>
    );
  };

  return (
    <>
      <section className="w-full flex flex-col gap-6">
        <div className="flex items-start gap-6 w-full">
          <div className="w-[230px] whitespace-nowrap">
            <h3 className="text-xl font-medium text-[#7D7D7D]">Resume: </h3>
          </div>
          <div className="w-full">
            <div className="flex justify-between items-center">
              <div className="inline-flex gap-8 border-[1px] border-[#94A1CE]  px-4 py-2 text-xl font-medium text-black">
                <button className=" text-[#0066FF] inline-flex gap-2 text-center items-center">
                  <svg
                    width="18"
                    height="22"
                    viewBox="0 0 18 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5257 1.33105V5.63397H16.8287M1.33643 1.33105H13.8166L16.8287 4.3431V20.2639H1.33815L1.33643 1.33105Z"
                      stroke="#0066FF"
                      stroke-width="1.5"
                    />
                    <path
                      d="M17.1791 4.80176L13.7922 1.14844L12.7297 1.68114V5.56597H16.4485L17.1791 4.80176Z"
                      fill="#0066FF"
                    />
                    <circle
                      cx="9.02808"
                      cy="12.4111"
                      r="4.03418"
                      fill="#0066FF"
                    />
                    <path d="M7.22998 12.4102H11.1812" stroke="white" />
                  </svg>
                  Resume
                </button>
                <Dropdown renderToggle={renderButton} placement="bottomEnd">
                  <h3 className="hover:cursor-pointer text-base  py-1 px-4 font-medium hover:bg-grayBg">
                    Delete
                  </h3>
                </Dropdown>
              </div>
              <a
                href="#"
                className="text-[#0066FF] text-lg font-semibold decoration-solid	!underline"
              >
                Edit
              </a>
            </div>
            <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
          </div>
        </div>
        <div className="flex items-start gap-6 w-full">
          <div className="w-[230px] whitespace-nowrap">
            <h3 className="text-xl font-medium text-[#7D7D7D]">
              Other documents:{" "}
            </h3>
          </div>
          <div className="w-full">
            <div className="flex justify-between items-center">
              <div className="inline-flex items-center flex-wrap gap-16">
                <div className="inline-flex items-center gap-8 border-[1px] border-[#94A1CE]  px-4 py-2 text-xl font-medium text-black">
                  <button className="  inline-flex gap-2 items-center">
                    <svg
                      width="21"
                      height="29"
                      viewBox="0 0 21 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.0095 0.357422H2.68451C1.99992 0.357422 1.34337 0.65209 0.859298 1.1766C0.375222 1.70112 0.103271 2.41251 0.103271 3.15428V25.5292C0.103271 26.271 0.375222 26.9824 0.859298 27.5069C1.34337 28.0314 1.99992 28.326 2.68451 28.326H18.1719C18.8565 28.326 19.5131 28.0314 19.9971 27.5069C20.4812 26.9824 20.7532 26.271 20.7532 25.5292V8.74801L13.0095 0.357422ZM7.19909 20.2012C6.80029 20.6067 6.21177 20.7885 5.52645 20.7885C5.39357 20.7901 5.26075 20.7817 5.12894 20.7633V22.7575H3.97513V17.2533C4.49583 17.1691 5.02197 17.1308 5.54839 17.1386C6.26727 17.1386 6.77835 17.2868 7.12295 17.5847C7.45077 17.8672 7.67275 18.3301 7.67275 18.8755C7.67146 19.4236 7.50368 19.8865 7.19909 20.2012ZM12.1125 22.096C11.5704 22.5841 10.7457 22.8162 9.73774 22.8162C9.13373 22.8162 8.70654 22.7743 8.41615 22.7323V17.2547C8.93704 17.1723 9.46302 17.1335 9.98941 17.1386C10.9664 17.1386 11.6014 17.3288 12.097 17.7343C12.6326 18.165 12.9682 18.8517 12.9682 19.8376C12.9682 20.9046 12.6081 21.6415 12.1125 22.096ZM16.8813 18.2154H14.9041V19.4894H16.7523V20.5158H14.9041V22.7589H13.7348V17.1806H16.8813V18.2154ZM13.0095 10.1464H11.7188V3.15428L18.1719 10.1464H13.0095Z"
                        fill="black"
                      />
                    </svg>
                    <p className="flex flex-col text-start">
                      <span className="text-base -mb-2">Vaccination proof</span>
                      <span className="text-[12px]">Pdf</span>
                    </p>
                  </button>
                  <Dropdown renderToggle={renderButton} placement="bottomEnd">
                    <h3 className="hover:cursor-pointer text-base  py-1 px-4 font-medium hover:bg-grayBg">
                      Delete
                    </h3>
                  </Dropdown>
                </div>

                <div className="inline-flex items-center gap-8 border-[1px] border-[#94A1CE]  px-4 py-2 text-xl font-medium text-black">
                  <button className="  inline-flex gap-2 items-center">
                    <svg
                      width="21"
                      height="29"
                      viewBox="0 0 21 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.0095 0.357422H2.68451C1.99992 0.357422 1.34337 0.65209 0.859298 1.1766C0.375222 1.70112 0.103271 2.41251 0.103271 3.15428V25.5292C0.103271 26.271 0.375222 26.9824 0.859298 27.5069C1.34337 28.0314 1.99992 28.326 2.68451 28.326H18.1719C18.8565 28.326 19.5131 28.0314 19.9971 27.5069C20.4812 26.9824 20.7532 26.271 20.7532 25.5292V8.74801L13.0095 0.357422ZM7.19909 20.2012C6.80029 20.6067 6.21177 20.7885 5.52645 20.7885C5.39357 20.7901 5.26075 20.7817 5.12894 20.7633V22.7575H3.97513V17.2533C4.49583 17.1691 5.02197 17.1308 5.54839 17.1386C6.26727 17.1386 6.77835 17.2868 7.12295 17.5847C7.45077 17.8672 7.67275 18.3301 7.67275 18.8755C7.67146 19.4236 7.50368 19.8865 7.19909 20.2012ZM12.1125 22.096C11.5704 22.5841 10.7457 22.8162 9.73774 22.8162C9.13373 22.8162 8.70654 22.7743 8.41615 22.7323V17.2547C8.93704 17.1723 9.46302 17.1335 9.98941 17.1386C10.9664 17.1386 11.6014 17.3288 12.097 17.7343C12.6326 18.165 12.9682 18.8517 12.9682 19.8376C12.9682 20.9046 12.6081 21.6415 12.1125 22.096ZM16.8813 18.2154H14.9041V19.4894H16.7523V20.5158H14.9041V22.7589H13.7348V17.1806H16.8813V18.2154ZM13.0095 10.1464H11.7188V3.15428L18.1719 10.1464H13.0095Z"
                        fill="black"
                      />
                    </svg>
                    <p className="flex flex-col text-start">
                      <span className="text-base -mb-2">Vaccination proof</span>
                      <span className="text-[12px]">Pdf</span>
                    </p>
                  </button>
                  <Dropdown renderToggle={renderButton} placement="bottomEnd">
                    <h3 className="hover:cursor-pointer text-base  py-1 px-4 font-medium hover:bg-grayBg">
                      Delete
                    </h3>
                  </Dropdown>
                </div>
              </div>
              <a
                href="#"
                className="text-[#0066FF] text-lg font-semibold decoration-solid	!underline"
              >
                Edit
              </a>
            </div>
            <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
          </div>
        </div>
      </section>
    </>
  );
}

export default CandidateDocuments;
