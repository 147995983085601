import React, { useEffect, useState } from 'react'
import { ChampHealthApiClient } from '../../../../config/apiClients';
import { Loader, Message, Placeholder, TagPicker, useToaster } from 'rsuite';
import { Button, DatePicker, DateRangePicker, Form, Input, SelectPicker } from "rsuite";
import CustomInputWithButton from "../../../../Components/CustomInputWithButton";
import TextField from "../../../../Components/TextField";
import CustomPagination from '../../../../Components/CustomPagination';
import CloseJobCard from '../../../../Components/Cards/CloseJobCard';
import { FormatedDate } from '../../../../Components/dateTime/FormatedDateTime';
import CanceledJobCard from '../../../../Components/Cards/CanceledJobCard';


function CanceledJob({ data }) {
    const toaster = useToaster()


    const [jobList, setJobList] = useState(false);
    const [originalJobList, setOriginalJobList] = useState(false);
    const [jobCount, setJobCount] = useState(false);

    // fetch getAllJobTypes
    const getAllclosedJobs = async (start = 0, end = 6) => {
        try {
            const response = await ChampHealthApiClient.get(`/admin/jobs/cancel-jobs/${start}/${end}?name=${filter?.searchInput || ''}&client_type=${filter?.clientType || ''}&status=${filter?.status || ''}&startDate=${filter?.dateRange ? FormatedDate(filter?.dateRange[0]) : ''}&endDate=${filter?.dateRange ? FormatedDate(filter?.dateRange[1]) : ''}&positions=${filter?.jobPostions?.toString() || ''}`);

            if (response?.status === 200) {
                setJobList(response?.data?.data);
                setOriginalJobList(response?.data?.data);
                setJobCount(response?.data?.count);
            }

        } catch (error) {
            setJobList(false);
            setJobCount("0");
            const message = (
                <Message showIcon type="error" header="SomeThing Went Wrong" closable>
                    {error?.response?.data.message}
                </Message>
            );
            toaster.push(message, { placement: "topEnd", duration: 3000 });
        }
    };





    const [jobPostions, setJobPostions] = useState(false);
    // fetch AllJobPostion
    const getAllJobPostion = async () => {
        try {
            const response = await ChampHealthApiClient.get(`/common/job-positions`);
            if (response?.status === 200) {
                setJobPostions(
                    response?.data?.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }))
                );
            }
        } catch (error) {
            console.log("candidate_register_getAllJobPostion_response_Error...", error);
        }
    };

    const [clientTypes, setClientTypes] = useState([])
    const fetchClientTypes = async () => {
        try {
            const response = await ChampHealthApiClient.get('common/client-types');
            if (response.status === 200) {
                setClientTypes(response?.data?.data?.map((item) => ({ label: item.name, value: item.id })));
            } else {

                const message = (
                    <Message showIcon type="error" closable>
                        {response?.data?.message}
                    </Message>
                );
                toaster.push(message, { placement: "topEnd", duration: 3000 });
            }


        } catch (error) {
            const message = (
                <Message showIcon type="error" closable>
                    {error?.message}
                </Message>
            );
            toaster.push(message, { placement: "topEnd", duration: 3000 });
        }
    }






    useEffect(() => {
        getAllclosedJobs();
        getAllJobPostion();
        fetchClientTypes()
    }, [])


    const [filter, setFilter] = useState({
        clientType: '',
        searchInput: '',
        status: '',
        dateRange: '',
        jobPostions: '',
    })

    const handleFilter = () => {
        let filteredJobs;

        if (filter?.clientType) {
            filteredJobs = originalJobList.filter((item) => item.type_id == filter?.clientType)

        }

        if (filter?.status) {
            if (filteredJobs) {
                filteredJobs = filteredJobs.filter((item) => item.status == filter?.status)
            } else {
                filteredJobs = originalJobList.filter((item) => item.status == filter?.status)
            }
        }

        if (filter?.dateRange && filter?.dateRange[0]) {
            if (filteredJobs) {
                filteredJobs = filteredJobs.filter((item) => (Date.parse(item?.createdAt) >= Date.parse(filter?.dateRange[0]) && Date.parse(item?.createdAt) <= Date.parse(filter?.dateRange[1])))
            } else {
                filteredJobs = originalJobList.filter((item) => (Date.parse(item?.createdAt) >= Date.parse(filter?.dateRange[0]) && Date.parse(item?.createdAt) <= Date.parse(filter?.dateRange[1])))

            }
        }

        if (filter?.jobPostions) {

            if (filteredJobs) {
                filteredJobs = filteredJobs.filter((item) => {
                    for (let index = 0; index < filter?.jobPostions.length; index++) {
                        return item.status = filter?.jobPostions[index];
                    }
                })
            } else {
                filteredJobs = originalJobList.filter((item) => {
                    for (let index = 0; index < filter?.jobPostions.length; index++) {
                        return item.status = filter?.jobPostions[index];
                    }
                })
            }


        }


        if (filter?.searchInput) {
            const escapedText = filter?.searchInput.replace(/[.*+,?^${}()|[\]\\]/g, " ").split(/\s+/);
            const regexPattern = new RegExp(escapedText.join('|'), 'gi');


            if (filteredJobs) {
                filteredJobs = filteredJobs.filter((item => {
                    for (let key in item) {
                        if (regexPattern.test(item[key])) {
                            return true;
                        }
                    }
                    return false;
                }))
            } else {
                filteredJobs = originalJobList.filter((item => {
                    for (let key in item) {
                        if (regexPattern.test(item[key])) {
                            return true;
                        }
                    }
                    return false;
                }))

            }
        }




        if (filteredJobs) {
            setJobList(filteredJobs);
        } else {
            setJobList(originalJobList)
        }

    }

    useEffect(() => {
        getAllclosedJobs()
    }, [filter])



    return (

        <>

            <div className="  bg-[#F1F4F8]">
                {/* <!------ Search and filter options ----> */}
                <div className="container md:flex items-start lg:items-center md:space-x-2 md:justify-between w-full lg:py-6 pt-6">
                    {/* <!-- job List Search bar --> */}
                    <div className="md:w-1/3  lg:flex lg:items-center lg:justify-between lg:w-1/2 lg:space-x-4 xl:w-1/3 xl:justify-between my-2 ml-[4px]">
                        <h1 className="font-semibold text-2xl whitespace-nowrap my-2 md:my-0">
                           
                        </h1>

                        <div className="flex justify-center items-center h-11 w-full xl:w-2/3 ">


                            <CustomInputWithButton
                                onChange={(i) => setFilter((prevState) => ({ ...prevState, searchInput: i }))}
                                name="search"
                                placeholder="Search Canceled Jobs ...."
                                className="border-2 border-blueBtnDark py-2 w-full  px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded "
                            />
                        </div>
                    </div>
                    {/* <!-- Filters --> */}
                    <Form className="md:w-2/3 my-2 lg:flex lg:items-center lg:gap-2 lg:w-2/3 lg:justify-end">
                        <h3 className="text-xl font-medium">Filter</h3>
                        <div className="grid lg:grid-cols-4 grid-cols-2  gap-4 items-center w-full">


                            <TextField
                                name="Status"
                                searchable={false}
                                data={[{ label: 'Active', value: '5' }, { label: 'Deactive', value: '4' }]}
                                accepter={SelectPicker}
                                onChange={(i) => setFilter((prevState) => ({ ...prevState, status: i }))}
                                placeholder="Status"
                                className="border-2 border-blueBtnDark py-2 w-full  px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
                            />


                            <TextField
                                name="client"
                                searchable={false}
                                data={clientTypes}
                                accepter={SelectPicker}
                                onChange={(i) => setFilter((prevState) => ({ ...prevState, clientType: i }))}
                                placeholder="Client type"
                                className="border-2 border-blueBtnDark py-2 w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
                            />

                            <TextField
                                name="date"
                                searchable={false}
                                accepter={DateRangePicker}
                                format="MM-dd-yyyy"
                                showOneCalendar
                                ranges={[]}
                                onChange={(i) => setFilter((prevState) => ({ ...prevState, dateRange: i }))}
                                placeholder="Date"
                                className="border-2 border-blueBtnDark py-2 w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
                            />
                            <TextField
                                name="postion"
                                searchable={false}
                                data={jobPostions ? jobPostions : []}
                                accepter={TagPicker}
                                onChange={(i) => setFilter((prevState) => ({ ...prevState, jobPostions: i }))}
                                placeholder="Position"
                                className="border-2 border-blueBtnDark py-2 w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
                            />
                            <button className="hidden border border-champBorder bg-blueBtn text-white rounded h-[52px]">
                                Apply
                            </button>
                        </div>

                    </Form>

                </div>
            </div>



            <div className="container flex flex-col my-12 gap-4 pt-6">
                {jobList ? (
                    <>
                        {!jobCount ? (
                            <>
                                <div className="container flex flex-col">
                                    {/* <!-- Cards Started--> */}
                                    <div className="my-8 ">
                                        <div className="h-full w-full flex justify-center items-center ">
                                            <div className="no-client-list flex flex-col items-center space-y-4 ">

                                                <div>
                                                    <h2 className="text-center text-3xl font-semibold">
                                                        No Closed Job to Show
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {" "}
                                <div className="inline-flex justify-start space-x-4 items-center w-full">
                                    <p className="w-40 text-champText500 font-medium text-base inline-flex items-center">
                                        <span>Results showing {jobCount && jobCount}</span>
                                    </p>
                                </div>
                                {/* <!-- Cards Started--> */}
                                <div className="grid grid-cols-fluid gap-6">
                                    {/* <!-- single cards --> */}
                                    {jobList && jobList.map((item, index) => <>
                                        <CanceledJobCard key={index} job={item} onChange={getAllclosedJobs} />
                                    </>)}
                                    {/* {jobList && jobList.map((item) => <>{createCard(item)}</>)} */}
                                </div>

                                <CustomPagination count={jobCount} clickFuntion={getAllclosedJobs} />

                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className="relative">
                            <div
                                className="absolute left-0 right-0 m-auto my-auto top-12 "
                                style={{ textAlign: "center" }}
                            >
                                <Loader size="md" />
                            </div>
                            <Placeholder.Paragraph
                                style={{ marginTop: 30 }}
                                rows={5}
                                active
                            />
                        </div>
                    </>
                )}

            </div>

        </>
    )
}

export default CanceledJob