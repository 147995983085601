export const daysOfWeek = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};

export function getTimeOfDaysAsString(input) {
  const timeOfDay = {
    1: "Morning",
    2: "Evening",
    3: "Night",
    4: "Anytime",
  };
  let numbers = input;

  if (typeof numbers === "string") {
    numbers = input.split(",").map(Number);
  }

  let result = "";

  if (numbers) {
    for (const number of numbers) {
      const time = timeOfDay[number];
      if (time) {
        if (result !== "") {
          result += ", ";
        }
        result += time;
      } else {
        result += "Not Available";
      }
    }
  } else {
    result = 'Not Available'
  }

  return result;
}



export const ExtractDaysOfWeek = (existingObject) => ({
  [daysOfWeek["mon"]]: existingObject.mon,
  [daysOfWeek["tue"]]: existingObject.tue,
  [daysOfWeek["wed"]]: existingObject.wed,
  [daysOfWeek["thu"]]: existingObject.thu,
  [daysOfWeek["fri"]]: existingObject.fri,
  [daysOfWeek["sat"]]: existingObject.sat,
  [daysOfWeek["sun"]]: existingObject.sun,
});
