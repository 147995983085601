import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config/apiKeys";
import {useNavigate } from "react-router-dom";
import { ChampHealthApiClient } from "../../../config/apiClients";

function AdminPasswordResetEmailMessage({username, email}) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({username,email})

  const resendLink = async () => {
    try {
      const response  = await ChampHealthApiClient.post(
        `/admin/forget-password`,
        {email: userInfo.username}
      );


    } catch (error) {
      console.log("forgot_password___error", error);
    }
  };



  return (
    <div className=" h-screen relative pb-12 overflow-y-hidden">
      <img
        className="-z-10 absolute w-full bottom-0"
        src="/assests/images/candidate-bg.png" alt="screen bg"
      />
      <header className="container py-4">
        <img  onClick={() => navigate('/')} className="hover:cursor-pointer" src="/logo.png" alt="health champ logo" />
      </header>
      <main className="h-full container">
        <section className="relative h-full w-full">
          <div className=" h-full w-full flex justify-center items-center">
            <div className="flex justify-center items-center -mt-16 h-full gap-6 text-gray-800 lg:w-1/3">
              <div className="w-full">
                <div className="flex flex-col justify-center items-center space-y-6 ">
                  <h1 className="font-semibold text-3xl">Check your email</h1>
                  <h3 className="font-normal leading-7 text-lg text-center">
                    We have sent a password reset link to {userInfo.email}
                  </h3>
                </div>

                <div className="font-medium flex flex-col text-center text-lg justify-center items-center my-4">
                  <span>Didn't receive the the email? </span>
                  <a
                    onClick={resendLink}
                    className="hover:cursor-pointer text-champText400 ml-1 text-blue-700"
                  >
                    Click to resend{" "}
                  </a>
                </div>

                <div className="flex justify-center items-center my-6">
                  <a
                    href="/admin"
                    className="text-champText400 text-center text-base "
                  >
                    <i className="fa-solid fa-arrow-left-long mr-1"></i>{" "}
                    <span className="text-lg ">Back to log in</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default AdminPasswordResetEmailMessage;
