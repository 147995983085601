import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, Dropdown, Popover, Whisper } from "rsuite";
import { USER_DETAIL, setCookie } from "../../config/headerToken";

const NavigationBar = () => {
  const [isDropdown, setIsDropdown] = useState(false);
  const navigate = useNavigate();


  // show hidden dropdown onclick function
  const handleDropDown = (e) => {
    switch (e) {
      case "user-avtar":
        if (isDropdown === e) {
          setIsDropdown(false);
        } else {
          setIsDropdown(e);
        }
        break;
      case "client-menu-list":
        if (isDropdown === e) {
          setIsDropdown(false);
        } else {
          setIsDropdown(e);
        }
        break;
      case "staff-menu-list":
        if (isDropdown === e) {
          setIsDropdown(false);
        } else {
          setIsDropdown(e);
        }
        break;
      case "jobs-menu-list":
        if (isDropdown === e) {
          setIsDropdown(false);
        } else {
          setIsDropdown(e);
        }
        break;

      default:
        break;
    }
  };




  const renderMenuClient = (
    <Popover full>
      <Dropdown.Menu>
        <ul className="dropdown-menu min-w-max  bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none ">
          <li>
            <a
              className="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-gray-700 hover:bg-gray-100 "
              href="/admin/list-client"
            >
              List of Clients
            </a>
          </li>
          <li>
            <a
              className="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-gray-700 hover:bg-gray-100 "
              href="/admin/list-invoices"
            >
              List Invoices
            </a>
          </li>
        </ul>
      </Dropdown.Menu>
    </Popover>
  );
  const renderMenuStaff = (
    <Popover full>
      <Dropdown.Menu>
        <ul className="dropdown-menu min-w-max bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none">
          <li>
            <a
              className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
              // onClick={()=>navigate('/list-staff')}
              href="/admin/list-staff"
            >
              List of staff
            </a>
          </li>
          <li>
            <a
              className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
              // onClick={()=>navigate('/scheduling')}
              href="/admin/scheduling"
            >
              Scheduling
            </a>
          </li>
          <li>
            <a
              className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
              href="#"
            >
              Payroll
            </a>
          </li>
        </ul>
      </Dropdown.Menu>
    </Popover>
  );

  const renderMenuJobs = (
    <Popover full>
      <Dropdown.Menu style={{ background: "#fff" }}>
        <ul className="dropdown-menu min-w-max  bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none">
          <li>
            <a
              className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
              href="/admin/list-jobs"
            >
              List of Jobs
            </a>
          </li>
          <li>
            <a
              className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
              href="/admin/create-job"
            >
              Create Job
            </a>
          </li>
          <li>
            <a
              className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
              // onClick={()=>navigate('/job-seeker')}
              href="/admin/job-seeker"
            >
              Job Seekers
            </a>
          </li>
        </ul>
      </Dropdown.Menu>
    </Popover>
  );

  const renderMenuProfile = (
    <Popover full>
      <Dropdown.Menu
        // onSelect={handleSelect}
        style={{ background: "#fff" }}
      >
        <ul className="dropdown-menu min-w-max bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg mt-1 m-0">
          <li className="hover:cursor-pointer">
            <a
              className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
              href="/admin/profile"
            >
              Profile
            </a>
          </li>
          <li className="hover:cursor-pointer">
            <a
              className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
              href="/admin/archive"
            >
              Archive
            </a>
          </li>
          <li className="hover:cursor-pointer">
            <a
              className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                  
                "
              onClick={() => {
                setCookie("token", "", 0);
                setCookie("user", "", 0);
              }}
              href="/admin"
            >
              Log Out
            </a>
          </li>
        </ul>
      </Dropdown.Menu>
    </Popover>
  );



  const renderMenuToggleButton = (props, ref) => {
    return (
      <button {...props} ref={ref}>
        {false ? <svg
          className=" w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg> :
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>}
      </button>
    );
  };

  const [open, setOpen] = useState(false)

  return (
    <>
      <header className="bg-white drop-shadow sticky z-[999] top-0">
        <nav className="border-gray-200 h-[77px] flex items-center ">
          <div className="flex flex-wrap justify-between items-center space-x-8 mx-auto container">
            <a href="/admin/" className="flex items-center">
              <img src="/logo.png" alt="champhealth logo" />
            </a>
            <div className="flex items-center lg:order-2">
              {/* <!-- Right elements --> */}
              <div className="flex items-center">
                {/* <!-- notification icon --> */}
                {/* <a
                  className="text-gray-500 hover:text-gray-700 focus:text-gray-700 mr-8 dropdown-toggle hidden-arrow flex items-center "
                  href="#"
                  role="button"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="bell"
                    className="w-4"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
                    ></path>
                  </svg>
                  <span className="text-white bg-red-700 absolute rounded-full text-xs -mt-2.5 ml-2 py-0 px-1.5">
                    1
                  </span>
                </a> */}
                {/* <!-- user avtar --> */}

                <div className="user-avtar relative">
                  <Whisper
                    placement="bottomEnd"
                    trigger="hover"
                    enterable
                    speaker={renderMenuProfile}
                  >
                    <p
                      href="#"
                      className="flex items-center space-x-1 hover:cursor-pointer"
                    >
                      {/* <svg
                        width="22"
                        height="22"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="17" cy="17" r="17" fill="#D9D9D9" />
                      </svg> */}
                      <span className=" whitespace-nowrap text-lg">{USER_DETAIL?.name.charAt(0).toUpperCase() + USER_DETAIL?.name.slice(1) || 'Guest'}</span>
                      <i className="fa-solid fa-angle-down self-items-end"></i>
                    </p>
                  </Whisper>
                </div>
              </div>
              {/* <!-- Right elements --> */}

              {/* <!-- open menu btn -->  */}
              {/* <button
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"

                onClick={() => setOpen(!open)}>
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button> */}

              {/* <Drawer open={open} onClose={() => setOpen(false)}>
                <Drawer.Body>
                <div className="w-max flex flex-col px-4">
                  <Dropdown title="Client" placement="bottomEnd" className="px-2 w-max">
                    <li>
                      <a
                        className="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-gray-700 hover:bg-gray-100 "
                        href="/admin/list-client"
                      >
                        List of Clients
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-gray-700 hover:bg-gray-100 "
                        href="/admin/list-invoices"
                      >
                        List Invoices
                      </a>
                    </li>
                  </Dropdown>

                  <Dropdown title="Staff" placement="bottomEnd" className="px-2 w-max">
                    <li>
                      <a
                        className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
                        // onClick={()=>navigate('/list-staff')}
                        href="/admin/list-staff"
                      >
                        List of staff
                      </a>
                    </li>
                    <li>
                      <a
                        className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
                        // onClick={()=>navigate('/scheduling')}
                        href="/admin/scheduling"
                      >
                        Scheduling
                      </a>
                    </li>
                    <li>
                      <a
                        className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
                        href="#"
                      >
                        Payroll
                      </a>
                    </li>
                  </Dropdown>

                  <Dropdown title="Jobs" placement="bottomEnd" className="px-2  w-max">
                    <li>
                      <a
                        className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
                        href="/admin/list-jobs"
                      >
                        List of Jobs
                      </a>
                    </li>
                    <li>
                      <a
                        className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
                        href="/admin/create-job"
                      >
                        Create Job
                      </a>
                    </li>
                    <li>
                      <a
                        className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-gray-100
                "
                        // onClick={()=>navigate('/job-seeker')}
                        href="/admin/job-seeker"
                      >
                        Job Seekers
                      </a>
                    </li>
                  </Dropdown>
                </div>
                </Drawer.Body>
              </Drawer> */}




            </div>

            {/* <!-- menu list -->  */}
            <div className={`hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1`}
            >
              <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li className="relative">
                  <Whisper
                    placement="bottomStart"
                    trigger="hover"
                    enterable
                    speaker={renderMenuClient}
                  >
                    <a
                      className="text-center rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 inline-flex items-center font-semibold hover:cursor-pointer"
                      onClick={() => {
                        handleDropDown("client-menu-list");
                      }}
                    >
                      <span className="text-xl">Client</span>
                      <i className="fa-solid fa-angle-down px-1"></i>
                    </a>
                  </Whisper>
                </li>
                <li className="relative">
                  <Whisper
                    placement="bottomStart"
                    trigger="hover"
                    enterable
                    speaker={renderMenuStaff}
                  >
                    <a
                      href="#"
                      className="text-center rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 inline-flex items-center font-semibold"
                    >
                      <span className="text-xl">Staff</span>
                      <i className="fa-solid fa-angle-down px-1"></i>
                    </a>
                  </Whisper>
                </li>
                <li className="relative">
                  <Whisper
                    placement="bottomStart"
                    trigger="hover"
                    enterable
                    speaker={renderMenuJobs}
                  >
                    <a
                      href="#"
                      className="text-center rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 inline-flex items-center font-semibold"
                    >
                      <span className="text-xl">Jobs</span>
                      <i className="fa-solid fa-angle-down px-1"></i>
                    </a>
                  </Whisper>
                </li>
              </ul>
            </div>
            {/* <!-- /menu list -->  */}
          </div>
        </nav>
        <div className="lg:hidden">
          <ul className="flex justify-center gap-8 pb-2 w-full mt-1 font-medium lg:space-x-8 lg:mt-0">
            <li className="relative">
              <Whisper
                placement="bottomStart"
                trigger="hover"
                enterable
                speaker={renderMenuClient}
              >
                <a
                  className="text-center rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 inline-flex items-center font-semibold hover:cursor-pointer"
                  onClick={() => {
                    handleDropDown("client-menu-list");
                  }}
                >
                  <span className="text-xl">Client</span>
                  <i className="fa-solid fa-angle-down px-1"></i>
                </a>
              </Whisper>
            </li>
            <li className="relative">
              <Whisper
                placement="bottomStart"
                trigger="hover"
                enterable
                speaker={renderMenuStaff}
              >
                <a
                  href="#"
                  className="text-center rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 inline-flex items-center font-semibold"
                >
                  <span className="text-xl">Staff</span>
                  <i className="fa-solid fa-angle-down px-1"></i>
                </a>
              </Whisper>
            </li>
            <li className="relative">
              <Whisper
                placement="bottomStart"
                trigger="hover"
                enterable
                speaker={renderMenuJobs}
              >
                <a
                  href="#"
                  className="text-center rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 inline-flex items-center font-semibold"
                >
                  <span className="text-xl">Jobs</span>
                  <i className="fa-solid fa-angle-down px-1"></i>
                </a>
              </Whisper>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default NavigationBar;
