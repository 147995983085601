import React, { forwardRef, useRef, useState, useEffect } from "react";
import { candaCities, states } from "../../../data/CandaData";
import {
  Form,
  Schema,
  SelectPicker,
  TagPicker,
  RadioGroup,
  Radio,
  useToaster,
  Message,
  AutoComplete,
  Checkbox,
  MaskedInput,
} from "rsuite";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LocationIcon from "@rsuite/icons/Location";
import { API_BASE_URL } from "../../../config/apiKeys";
import { usePlacesAutocomplete } from "../../../Components/googleMap/useGoogleMap";
import { ChampHealthApiClient } from "../../../config/apiClients";
import TextField from "../../../Components/TextField";

const { StringType, ArrayType } = Schema.Types;

const model = Schema.Model({
  residence_name: StringType()
    .pattern(/^[A-Za-z][a-zA-Z0-9\s]*$/, "Please enter legal characters")
    .isRequired("This field is required."),
  relation: StringType()
    .pattern(/^[a-zA-Z0-9\s]*$/, "Please enter legal characters")
    .isRequired("This field is required."),
  street_address: StringType()
    .pattern(
      /^[\/a-zA-Z0-9\s.!@#&*',\-\u0080-\u052F]*$/,
      "Please enter legal characters"
    )
    .isRequired("This field is required."),
  province: StringType().isRequired("This field is required."),
  city: StringType().isRequired("This field is required."),
  postalCode: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Postal Code")
    .maxLength(7, "Cannot be greater than 6 characters")
    .minLength(7, "Can't be less than 6 characters")
    .isRequired("This field is required."),
  main_phone: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Phone Number")
    .isRequired("This field is required."),
  person_name: StringType()
    .pattern(/^[a-zA-Z\s]*$/, "Please enter only characters")
    .isRequired("This field is required."),
    email: StringType().isEmail("Please enter a valid email address.").isRequired("This field is required."),
  payable_email: StringType().isEmail("Please enter a valid email address."),
  cell_phone: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Phone Number"),
  mode_of_communication: ArrayType().isRequired("This field is required."),
  main_street_address: StringType()
    .pattern(/^[\/a-zA-Z0-9\s.!@#&*',\-\u0080-\u052F]*$/, "Please enter legal characters")
    .isRequired("This field is required."),
  main_province: StringType().isRequired("This field is required."),
  main_city: StringType().isRequired("This field is required."),
  main_postal_code: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Postal Code")
    .maxLength(7, "Cannot be greater than 6 characters")
    .minLength(7, "Can't be less than 6 characters")
    .isRequired("This field is required."),

});

const signupModal = Schema.Model({
  password: StringType()
    .pattern(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,20}$/,
      "please enter 8 characters , smallcase, uppercase & symbols(!@#$%^&*)"
    )
    .isRequired("This field is required."),
  verifyPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }

      return true;
    }, "The two passwords do not match")
    .isRequired("This field is required."),
});



const ResidentialForm = ({ clientType }) => {
  const toaster = useToaster();
  const navigate = useNavigate();

  const stateData = states.map((oneState) => ({
    label: oneState.name,
    value: oneState.name,
  }));

  const citiesData = (state_code) =>
    candaCities(state_code).map((city) => ({
      label: city.name,
      value: city.name,
    }));

  //// detail form
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    email: "",
    cell_phone: "",
    billing_address_same_as: false,
  });

  const [disableSubmit, setDisableSubmit] = useState(true);

  const createNewClient = async () => {

    try {
      const response = await ChampHealthApiClient.post(`/clients/register`, {
        ...formValue,
        client_type: clientType,
        residence_name: formValue.residence_name,
        relation: formValue.relation,
        street_address: formValue.street_address,
        province: formValue.province,
        city: formValue.city,
        postal_code: (formValue.postalCode).toUpperCase(),
        main_phone: formValue.main_phone,
        name: formValue.person_name,
        payable_email: formValue.payable_email,
        email_address: formValue.email,
        cell_phone: formValue.cell_phone,
        communication_mode: formValue.mode_of_communication,
        password: signUpFormValue.password,
        billing_address_same_as: false
      });
      console.log(response);
      if (response?.status === 200) {
        setFormValue({
          residence_name: "",
          relation: "",
          street_address: "",
          province: "",
          city: "",
          main_phone: "",
          person_name: "",
          postalCode: "",
          position: "",
          payable_email: "",
          email: "",
          cell_phone: "",
          mode_of_communication: [],
          billing_address_same_as: false,
        });
        setFormError({});
        setDisableSubmit(true);

        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        navigate('/register/success/' + formValue.person_name)
      }
    } catch (error) {
      setDisableSubmit(true);
      const message = (
        <Message showIcon type="error"  closable>
          {error.response.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
    }
  };



  /// signup form
  const [isSignUpForm, setIsSignUpForm] = useState(false);
  const signUpFormRef = useRef();
  const [signUpFormError, setSignUpFormError] = useState({});
  const [signUpFormValue, setSignUpFormValue] = useState({
    email: "",
    password: "",
    verifyPassword: "",
  });



  const handleSubmit = () => {
    if (formRef.current.check()) {
      setSignUpFormValue({
        ...signUpFormValue,
        email: formValue.email,
      });
      setIsSignUpForm(true);
      return;
    }
  };


  const handleSubmitSignUp = () => {
    if (signUpFormRef.current.check()) {
      createNewClient();
      return;
    }
  };

  // address suggestion
  const [suggestions, fetchSuggestions] = usePlacesAutocomplete();

  return (
    <>
      {isSignUpForm ? (
        <>
          <div className="container relative pb-12">
            <header className="py-4">
              <img onClick={() => navigate('/')} className="hover:cursor-pointer" src="/logo.png" alt="health champ logo" />
            </header>
            <main className="w-full h-full relative flex justify-center content-center">
              <div className="flex flex-col space-y-8 lg:w-1/2 text-black m-auto mt-16 justify-center content-center items-center">
                <h3 className="font-semibold text-3xl text-center">
                  Create My Account
                </h3>
                <p className="text-sm text-black">
                  Sign up to start your journey
                </p>

                <div className="flex flex-col  space-y-8 justify-center w-full items-center">
                  <div className="w-full">
                    <Form
                      fluid
                      ref={signUpFormRef}
                      onChange={setSignUpFormValue}
                      onCheck={setSignUpFormError}
                      formValue={signUpFormValue}
                      model={signupModal}
                      className="w-full flex flex-col gap-4"
                    >
                      <TextField
                        name="password"
                        type="password"
                        
                        autoComplete="off"
                      />
                      <TextField
                        name="verifyPassword"
                        type="password"
                        
                      />
                    </Form>
                  </div>
                  <button
                    className="w-8/12 text-center m-auto border-2 py-2 rounded-[5px] text-lg font-normal bg-blueBtnDark text-white border-blueBtnDark"
                    onClick={handleSubmitSignUp}
                  >
                    {" "}
                    Sign Up
                  </button>
                </div>
              </div>
            </main>

            <button
              onClick={() => {
                setIsSignUpForm(false);
              }}
              className="text-white border-blueBtnDark px-3 py-1 bg-blueBtnDark text-base font-normal rounded-[5px] mt-20 lg:mt-36  hover:text-white"
            >
              <i className="fa-solid fa-arrow-left pr-2"></i> Back
            </button>
          </div>
        </>
      ) : (
        <div className="container relative pb-12">
          <header className="py-4">
            <img src="/logo.png" alt="health champ logo" />
          </header>
          <main className="w-full h-full relative flex justify-center content-center">
            <div className="flex flex-col space-y-8  lg:w-1/2 text-black m-auto mt-12 justify-center content-center items-center">
              <h3 className="font-semibold text-3xl text-center">
                Fill the detail
              </h3>

              <div className="flex flex-col justify-center w-full items-center">
                <div className="w-full">
                  <Form
                    fluid
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={model}
                    className="relative flex flex-col "
                  >
                    <div className="flex flex-col gap-4">
                      <TextField
                        name="person_name"
                        label="Your Name : *"
                        
                      />

                      <TextField
                        name="residence_name"
                        label={" Retirement Residence: *"}
                        
                      />
                      <TextField
                        name="relation"
                        label={"Position : *"}
                        
                      />
                      {/* <TextField name="street_address" label="Street Address : *" /> */}
                      <TextField
                        name="street_address"
                        label="Street Address : *"
                        
                      />

                      <div className="relative mb-[4px]  z-0 w-full flex justify-between space-x-4">
                        <div className="w-full">
                          <TextField
                            name="province"
                            searchable={false}
                            data={stateData}
                            accepter={SelectPicker}
                            label="Province : *"
                            
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            name="city"
                            searchable
                            disabled={formValue.province ? false : true}
                            data={
                              formValue.province
                                ? citiesData(formValue.province)
                                : []
                            }
                            accepter={SelectPicker}
                            label="City : *"
                            
                          />
                        </div>
                      </div>

                      <div className="relative mb-[4px] z-0 w-full flex justify-between space-x-4">
                        <div className="w-full">
                          <TextField
                            name="postalCode"
                            label="Postal Code : *"
                            
                            style={{ 'text-transform': 'uppercase' }}
                            accepter={MaskedInput}
                            mask={[/[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/]}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            name="main_phone"
                            label="Main Phone : *"
                            

                            accepter={MaskedInput}
                            mask={['+', '1', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                          />
                        </div>
                      </div>



                      <div className="relative mb-[12px] z-0 w-full flex justify-between space-x-4">
                        <div className="w-full">
                          <TextField
                            name="email"
                            label="Email Address : *"
                            
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            name="cell_phone"
                            label="Cell Phone :"
                            
                            accepter={MaskedInput}
                            mask={['+', '1', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                          />
                        </div>
                      </div>



                      <TextField
                        name="mode_of_communication"
                        searchable={false}
                        data={[
                          { label: "Email", value: "Email" },
                          { label: "Cell Phone", value: "Cell Phone" },
                          { label: "Text messages", value: "Text messages" },
                          { label: "Main Phone", value: "Main Phone" },
                        ]}
                        accepter={TagPicker}
                        label="Mode Of Communication ( Preferred ) : *"
                        
                      />



                      <div>
                        <h2 className="font-medium text-2xl mb-2">Main Address</h2>{" "}
                      </div>
                      <div
                        className="relative flex flex-col space-y-24"
                      >
                        <div className="grid md:grid-cols-2 md:gap-x-16 gap-y-4">
                          <TextField name="payable_email" label="Payable Account Email Address : " />
                          <TextField
                            name="main_street_address"
                            label="Street Address : *"
                          />
                          <TextField
                            name="main_province"
                            searchable={false}
                            data={stateData}
                            accepter={SelectPicker}
                            label="Province : *"
                          />
                          <TextField
                            name="main_city"
                            searchable
                            disabled={formValue.main_province ? false : true}
                            data={
                              formValue.main_province
                                ? citiesData(formValue.main_province)
                                : []
                            }
                            accepter={SelectPicker}
                            label="City : *"
                          />
                          <TextField name="main_postal_code" label="Postal Code : *" 
                            accepter={MaskedInput}
                            mask={[/[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/]} />
                        </div>
                      </div>




                    </div>




                  </Form>

                </div>

                <button
                  className="mt-8 w-8/12 text-center m-auto border-2 py-2 rounded-[5px] text-lg font-normal bg-blueBtnDark text-white border-blueBtnDark"
                  onClick={handleSubmit}
                >
                  Next
                </button>
              </div>
            </div>
          </main>

          <a href="/client/register">
            <button className="text-white border-blueBtnDark px-3 py-1 bg-blueBtnDark text-base font-normal rounded-[5px] mt-20  hover:text-white">
              <i className="fa-solid fa-arrow-left pr-2"></i> Back
            </button>
          </a>
        </div>
      )}
    </>
  );
};

export default ResidentialForm;
