import React, { useState } from 'react'
import { Button, Modal, Placeholder } from 'rsuite'

function ConfirmPromptCard({ onConfirm, handleClose, open, message, }) {

    const handleClick = () => {
        onConfirm();
        handleClose()
    }



    return (
        <>
            <Modal className='p-2' size={'xs'} open={open} onClose={handleClose}>
                <Modal.Body className='p-2 flex gap-4 flex-col justify-between'>
                    <div className='flex justify-between border-b-2 pb-2' >
                        <h1 className='text-2xl font-bold text-black'>Confirm</h1>
                        <i className='fa fa-close text-lg bg-blue-500 h-6 w-6 tex-center flex items-center justify-center text-white rounded-full' />
                    </div>
                    <div>
                        <p className='text-base text-black px-2'>
                            {message || 'Are you Sure about the action you performing....'}
                        </p>
                    </div>

                    <div className='flex justify-end gap-2 border-t-2 pt-2'>
                        <button className='font-semibold text-black bg-gray-200 hover:bg-gray-300 rounded px-2 py-1 text-md' onClick={handleClose}>Cancel</button>
                        <button className='text-white font-bold bg-green-600 hover:bg-green-500  rounded px-2 py-1 text-md' onClick={handleClick}>Confirm</button>
                    </div>

                </Modal.Body>
            </Modal>



        </>
    )
}

export default ConfirmPromptCard