import { useEffect, useState } from "react";
import ClosedJob from "./ClosedJob";
import JobRequest from "./JobRequest";
import LiveJob from "./LiveJob";
import { useSearchParams } from "react-router-dom";
import TextField from "../../../../Components/TextField";
import { Button, DatePicker, DateRangePicker, Form, Input, Message, SelectPicker, toaster } from "rsuite";
import { ChampHealthApiClient } from "../../../../config/apiClients";
import CustomInputWithButton from "../../../../Components/CustomInputWithButton";
import CanceledJob from "./CanceledJob";


const JobList = () => {
  const [selectedFilterBtn, setSelectedFilterBtn] = useState('Job Request');
  const [searchParams, setSearchParams] = useSearchParams();



  useEffect(() => {
    if (searchParams.get('page') === 'live') {
      setSelectedFilterBtn("Live Jobs");
    } else if (searchParams.get('page') === 'close') {
      setSelectedFilterBtn("Closed Jobs");
    } else if (searchParams.get('page') === 'cancel') {
      setSelectedFilterBtn("Canceled Jobs");
    } 

    setSearchParams('')
  }, [])

  const data = [1, 2, 3, 4, 5];




  const [jobPostions, setJobPostions] = useState(false);
  // fetch AllJobPostion
  const getAllJobPostion = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/common/job-positions`);
      if (response?.status === 200) {
        setJobPostions(
          response?.data?.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.log("candidate_register_getAllJobPostion_response_Error...", error);
    }
  };

  const [clientTypes, setClientTypes] = useState([])
  const fetchClientTypes = async () => {
    try {
      const response = await ChampHealthApiClient.get('common/client-types');
      if (response.status === 200) {
        setClientTypes(response?.data?.data?.map((item) => ({ label: item.name, value: item.id })));
      } else {

        const message = (
          <Message showIcon type="error"  closable>
            {response?.data?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }


    } catch (error) {
      const message = (
        <Message showIcon type="error"  closable>
          {error?.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
    }
  }

  useEffect(() => {
    getAllJobPostion();
    fetchClientTypes()
  }, []);



  return (
    <>
      <main className="main-content ">
        {/* <!------ Top section  ------> */}
        <section className="pt-10 bg-[#F1F4F8]">
          <div className="container divide-y divide-y-4">
            {/* <!------ selection buttons ----> */}
            <div className="grid grid-cols-2 md:flex md:justify-center gap-4 lg:flex w-full md:items-center font-semibold text-sm md:text-md">
              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Job Request"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Job Request");
                }}
              >
                Job Request
              </button>

              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Live Jobs"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Live Jobs");
                }}
              >
                Live Jobs
              </button>

              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Closed Jobs"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white  border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Closed Jobs");
                }}
              >
                Closed Jobs
              </button>
              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Canceled Jobs"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white  border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Canceled Jobs");
                }}
              >
                Canceled Jobs
              </button>


            </div>
          </div>
        </section>

        {/* <!------ Main Section  ------> */}
        <section className="pt-0">



          {selectedFilterBtn === "Job Request" ? <JobRequest /> : <>
            {selectedFilterBtn === "Live Jobs" ? <LiveJob /> : <>
              {selectedFilterBtn === "Closed Jobs" ? <ClosedJob /> : <>
              {selectedFilterBtn === "Canceled Jobs" ? <CanceledJob /> : <>
              </>}
              </>}
            </>}
          </>}
        </section>
      </main>


    </>
  );
};

export default JobList;
