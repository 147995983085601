import React, { useEffect, useState } from "react";
import CustomPagination from "../../../Components/CustomPagination";
import {
  Dropdown,
  Loader,
  Message,
  Modal,
  Placeholder,
  toaster,
  useToaster,
} from "rsuite";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import JobApplicantCard from "../../../Components/Cards/JobApplicantCard";
import { ChampHealthApiClient } from "../../../config/apiClients";

const ShortListed = () => {

  const navigate = useNavigate();
  const location = useLocation()
  const toaster = useToaster();
  const { jobId } = useParams()

  //get jobSeeker and list them
  const [jobSeekerList, setJobSeekerList] = useState([]);
  const [jobSeekerCount, setJobSeekerCount] = useState(false);

  const getJobSeekerList = async (start = 0, end = 6) => {
    try {
      const response = await ChampHealthApiClient.get(
        `/clients/shortlist-jobseekers/${jobId}/${start}/${end}`
      );
      if (response?.status === 200) {
        setJobSeekerList(response?.data?.data);
        setJobSeekerCount(response?.data?.count);
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error.response.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
      setJobSeekerCount(0);
    }
  };


  useEffect(() => {
    getJobSeekerList();
  }, []);

  return (
    <main className="main-content">

      <section className="py-10 bg-[#F1F4F8]">
        <div className="w-full container divide-y divide-y-4 space-y-6">
          <div className="w-full md:flex items-start lg:items-center md:space-x-2 md:justify-between w-full lg:py-6">
            {/* <!-- job List Search bar --> */}
            <div
              onClick={() => navigate(location.pathname.includes('admin') ? '/admin/list-jobs?page=' + location?.state?.page || '' : '/client/jobs?page=' + location?.state?.page || '')}
              className="hover:cursor-pointer md:w-1/2 flex-col w-full flex ml-[4px]"
            >
              <div className="hover:cursor-pointer inline-flex items-center gap-2 font-semibold text-black ">
                <i className="fa-solid fa-arrow-left-long text-2xl"></i>
                <h1 className="text-2xl whitespace-nowrap">
                  {" "}
                  Job Applications
                </h1>
              </div>
              <h1 className="ml-7 text-xl whitespace-nowrap capitalize ">
                {jobSeekerCount ? jobSeekerList[0]?.job_title : ""}
              </h1>
            </div>

            {/* <!-- Filters --> */}
            {/* <div className="md:w-1/2  lg:flex lg:items-center lg:gap-2 my-2 lg:w-2/3 lg:justify-end">
              <div className="flex justify-center items-center h-11 w-full xl:w-2/3 ">
                <i className="fa-solid fa-magnifying-glass -mr-6 z-10 text-searchIcon"></i>
                <input
                  className="shadow appearance-none border rounded w-full h-full px-8 leading-tight focus:outline text-champPlaceholder border-champBorderPagination text-sm"
                  id="client-name"
                  type="text"
                  placeholder="Search organisation or location... "
                />
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="pt-6">
        <div className="container flex flex-col my-12 gap-4">
          {/* <!-- No. of search result and Create client Button --> */}
          <div className="flex justify-between items-center  w-full">
            <p className="inline-flex ">
              Results showing {jobSeekerCount && jobSeekerCount}
            </p>
          </div>

          {/* <!-- Cards Started--> */}
          <div className="grid grid-cols-jobSeekerList gap-6">
            {/* <!-- single cards --> */}
            {jobSeekerList &&
              jobSeekerList.map((item) => <><JobApplicantCard jobTitle={jobSeekerCount ? jobSeekerList[0]?.job_title : ""} isAdmin={false} jobSeeker={item} key={item.id} /></>)}
            {/* {jobSeekerList &&
              jobSeekerList.map((item) => <>{createCard(item)}</>)} */}
          </div>
          {/* <!-- Cards ended / --> */}

          {/* <!-- Pagination Started / --> */}

          <CustomPagination />

          {/* <!-- Pagination ended / --> */}
        </div>
      </section>


    </main>
  );
};

export default ShortListed;
