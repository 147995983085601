import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Message,
  Schema,
  SelectPicker,
  useToaster,
  MaskedInput,
  TagPicker,
  CheckPicker,
  CheckboxGroup,
  Checkbox,
} from "rsuite";
import { useNavigate } from "react-router-dom";
import { usePlacesAutocomplete } from "../../../Components/googleMap/useGoogleMap";
import { ChampHealthApiClient } from "../../../config/apiClients";
import { candaCities, states } from "../../../data/CandaData";
import TextField from "../../../Components/TextField";
import { daysArray, jobSeekerProfileModal } from "../../../Components/JobSeekerSchemaModal";

const { StringType, NumberType, MixedType, ArrayType } = Schema.Types;

const model = jobSeekerProfileModal;


const signupModal = Schema.Model({
  password: StringType()
    .pattern(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,20}$/,
      "please enter 8 characters , smallcase, uppercase & symbols(!@#$%^&*)"
    )
    .isRequired("This field is required."),
  verifyPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }

      return true;
    }, "The two passwords do not match")
    .isRequired("This field is required."),
});

const CandidateDetailForm = ({ position }) => {
  const toaster = useToaster();
  const navigate = useNavigate();

  const stateData = states.map((oneState) => ({
    label: oneState.name,
    value: oneState.name,
  }));
  const citiesData = (state_code) =>
    candaCities(state_code).map((city) => ({
      label: city.name,
      value: city.name,
    }));

  //// detail form
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
    availability: "",
    employmenttype: "",
    jobtype: "",
    language: "",
    position,
    mon:[],
    tue:[],
    wed:[],
    thu:[],
    fri:[],
    sat:[],
    sun:[],
  });

  const handleSubmit = () => {
    
    if (formRef.current.check()) {
      setSignUpFormValue({
        ...signUpFormValue,
        email: formValue.email,
      });
      setIsSignUpForm(true);
      return;
    }
    console.error("Form Error", formError);

  };

  /// signup form
  const [isSignUpForm, setIsSignUpForm] = useState(false);
  const signUpFormRef = useRef();
  const [signUpFormError, setSignUpFormError] = useState({});
  const [signUpFormValue, setSignUpFormValue] = useState({
    email: "",
    password: "",
    verifyPassword: "",
  });

  const handleSubmitSignUp = () => {
    if (signUpFormRef.current.check()) {
      registerJobSeeker();
      return;
    }
  };

  const registerJobSeeker = async () => {
    try {
      const response = await ChampHealthApiClient.post(`/jobseekers/register`, {
        firstname: formValue.firstName,
        lastname: formValue.lastName,
        address: formValue.address,
        province: formValue.province,
        postal_code: formValue.postalCode,
        city: formValue.city,
        email_id: formValue.email,
        password: signUpFormValue.password,
        phone: formValue.phone,
        job_position: formValue.position.toString(),
        availability: 1,
        job_type: formValue.jobtype,
        language: formValue.language,
        mon:formValue.mon.toString(),
        tue:formValue.tue.toString(),
        wed:formValue.wed.toString(),
        thu:formValue.thu.toString(),
        fri:formValue.fri.toString(),
        sat:formValue.sat.toString(),
        sun:formValue.sun.toString(),
        reason: "",
      });

      if (response?.status === 200) {
        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        navigate(
          "/candidate/register/resume-upload/" +
          response?.data?.id +
          "/" +
          (formValue.firstName + " " + formValue.lastName)
        );
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {`${error?.response?.data.message
              ? error?.response?.data.message
              : error?.message
            }`}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });

      console.log("registerJobSeeker_response_Error...", error);
    }
  };

  /// get data from api to show in form
  const [availability, setAvailability] = useState(false);
  const [jobtype, setJobtype] = useState(false);

  // availability time
  // const getAvailability = async () => {
  //   try {
  //     const response = await ChampHealthApiClient.get(`/common/job-shifts`);

  //     if (response?.status === 200) {
  //       setAvailability(
  //         response?.data?.data.map((item) => ({
  //           label: item.name,
  //           value: item.id,
  //         }))
  //       );
  //     }
  //   } catch (error) {
  //     console.log(
  //       "candidate_register_getAvailability_response_Error...",
  //       error
  //     );
  //   }
  // };

  // employmenttype
  const getJobtype = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/common/job-types`);

      if (response?.status === 200) {
        setJobtype(
          response?.data?.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.log(
        "candidate_register_getEmploymenttype_response_Error...",
        error
      );
    }
  };

  useEffect(() => {
    // getAvailability();
    getJobtype();
  }, []);



  return (
    <>
      {isSignUpForm ? (
        <>
          <div className="container relative pb-12">
            <header className="py-4">
              <img
                onClick={() => navigate("/")}
                className="hover:cursor-pointer"
                src="/logo.png"
                alt="health champ logo"
              />
            </header>
            <main className="w-full h-full relative flex justify-center content-center">
              <div className="flex flex-col space-y-8 lg:w-1/2 text-black m-auto mt-16 justify-center content-center items-center">
                <h3 className="font-semibold text-3xl text-center">
                  Create My Account
                </h3>
                <p className="text-sm text-black">
                  Sign up to start your journey
                </p>

                <div className="flex flex-col  space-y-8 justify-center w-full items-center">
                  <div className="w-full">
                    <Form
                      fluid
                      ref={signUpFormRef}
                      onChange={setSignUpFormValue}
                      onCheck={setSignUpFormError}
                      formValue={signUpFormValue}
                      model={signupModal}
                      className="w-full flex flex-col gap-4"
                    >
                      <TextField
                        name="password"
                        type="password"
                        autoComplete="off"
                      />
                      <TextField
                        name="verifyPassword"
                        type="password"
                      />
                    </Form>
                  </div>
                  <button
                    className="w-8/12 text-center m-auto border-2 py-2 rounded-[5px] text-lg font-normal bg-blueBtnDark text-white border-blueBtnDark"
                    onClick={handleSubmitSignUp}
                  >
                    {" "}
                    Sign Up
                  </button>
                </div>
              </div>
            </main>

            <button
              onClick={() => {
                setIsSignUpForm(false);
              }}
              className="text-white border-blueBtnDark px-3 py-1 bg-blueBtnDark text-base font-normal rounded-[5px] mt-20 lg:mt-36  hover:text-white"
            >
              <i className="fa-solid fa-arrow-left pr-2"></i> Back
            </button>
          </div>
        </>
      ) : (
        <div className="container relative pb-12">
          <header className="py-4">
            <img src="/logo.png" alt="health champ logo" />
          </header>
          <main className="w-full h-full relative flex justify-center content-center">
            <div className="flex flex-col space-y-8  lg:w-1/2 text-black m-auto mt-12 justify-center content-center items-center">
              <h3 className="font-semibold text-3xl text-center">
                Fill the detail
              </h3>

              <div className="flex flex-col justify-center w-full items-center">
                <div className="w-full">
                  <Form
                    fluid
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={model}
                    className="w-full flex flex-col gap-4"
                  >
                    <div className="flex space-x-2  justify-between">
                      <TextField
                        name="firstName"
                        label="First name : *"
                      />

                      <TextField
                        name="lastName"
                        label="Last name : *"
                      />
                    </div>

                    <TextField
                      name="address"
                      label="Home address : *"
                    />

                    <div className="relative z-0 w-full flex justify-between space-x-2">
                      <div className="w-1/2">
                        <TextField
                          name="province"
                          searchable={false}
                          data={stateData}
                          accepter={SelectPicker}
                          label="Province : *"
                        />
                      </div>
                      <div className="w-1/2">
                        <TextField
                          name="city"
                          searchable
                          disabled={formValue.province ? false : true}
                          data={
                            formValue.province
                              ? citiesData(formValue.province)
                              : []
                          }
                          accepter={SelectPicker}
                          label="City : *"
                        />
                      </div>
                    </div>
                    <TextField
                      name="postalCode"
                      label="Postal Code : *"
                      style={{ "text-transform": "uppercase" }}
                      accepter={MaskedInput}
                      mask={[
                        /[a-zA-Z]/,
                        /[a-zA-Z0-9]/,
                        /[a-zA-Z0-9]/,
                        " ",
                        /[a-zA-Z0-9]/,
                        /[a-zA-Z0-9]/,
                        /[a-zA-Z0-9]/,
                      ]}
                    />

                    <div className="flex space-x-2 justify-between">
                      <TextField
                        name="email"
                        type="email"
                        label="Email address : *"
                      />
                      <TextField
                        name="phone"
                        label="Phone number : *"
                        accepter={MaskedInput}
                        mask={[
                          "+",
                          "1",
                          "(",
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          ")",
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </div>
                    <div className="flex space-x-2 justify-between">
                      <TextField
                        name="jobtype"
                        label="Job type : *"

                        accepter={SelectPicker}
                        data={jobtype ? jobtype : []}
                        searchable={false}
                      />

                      <TextField
                        name="language"
                        label="Language : *"
                      />
                    </div>
                    <p className="text-lg font-medium -mb-4">Availability: *</p>

                    {daysArray.map((item, index) => {
                      return (
                        <div key={index}>
                          <Form.Group controlId={"checkbox-" + item.value}>
                            <Form.ControlLabel className="text-base text-black font-medium">
                              {" "}
                              {item.label}:{" "}
                            </Form.ControlLabel>
                            <Form.Control
                              className=" p-0 m-0  register_input_field -pl-2"
                              name={item.label.slice(0, 3).toLocaleLowerCase()}
                              accepter={CheckboxGroup}
                              inline
                            >
                              <Checkbox value={1}>Morning</Checkbox>
                              <Checkbox value={2}>Evening</Checkbox>
                              <Checkbox value={3}>Night</Checkbox>
                              <Checkbox value={4}>Any Time</Checkbox>
                            </Form.Control>
                          </Form.Group>
                        </div>
                      );
                    })}

                    {/* <div className="flex space-x-2 justify-between">
                      <TextField
                        name="day"
                        label="Day *"
                        placeholder="Monday"
                        accepter={SelectPicker}
                        data={daysArray}
                        searchable={false}
                      />

                      <TextField
                        name="availability"
                        label="Availability : *"
                        placeholder=" Morning"
                        accepter={SelectPicker}
                        data={availability ? availability : []}
                        searchable={false}
                      />

                      {formValue?.availability === 8 ? (
                        <TextField
                          name="availability_other"
                          label="Other : *"
                          placeholder="enter other Availability"
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    {daysArray.map((item, index) => {
                      if (!index) {
                        return;
                      }
                      return (
                        <div
                          key={index}
                          className="flex space-x-2 mt-1 justify-between"
                        >
                          <TextField
                            name={"day" + index}
                            placeholder="Monday"
                            accepter={SelectPicker}
                            data={daysArray}
                            searchable={false}
                          />

                          <TextField
                            name={"availability" + index}
                            placeholder=" Morning"
                            accepter={SelectPicker}
                            data={availability ? availability : []}
                            searchable={false}
                          />

                          {formValue?.availability === 8 ? (
                            <TextField
                              name="availability_other"
                              label="Other : *"
                              placeholder="enter other Availability"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })} */}

                    <div className="my-4"></div>
                  </Form>
                </div>

                <button
                  className="mt-4 w-8/12 text-center m-auto border-2 py-2 rounded-[5px] text-lg font-normal bg-blueBtnDark text-white border-blueBtnDark"
                  onClick={handleSubmit}
                >
                  Next
                </button>
              </div>
            </div>
          </main>

          <a href="/candidate/register">
            <button className="text-white border-blueBtnDark px-3 py-1 bg-blueBtnDark text-base font-normal rounded-[5px] mt-20 hover:text-white">
              <i className="fa-solid fa-arrow-left pr-2"></i> Back
            </button>
          </a>
        </div>
      )}
    </>
  );
};

export default CandidateDetailForm;
