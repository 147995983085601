import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ChampHealthApiClient } from '../../../config/apiClients';
import { Loader, Message, Placeholder, useToaster } from 'rsuite';


const AcceptRejectJob = () => {

    const toaster = useToaster();
    const navigate = useNavigate();
    const { acceptReject, jobId, jobSeekerId } = useParams();

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')


    const AcceptJob = async () => {
        try {
            const response = await ChampHealthApiClient.get(`/common/accept-jobs/${jobId}/${jobSeekerId}`);
            if (response.status === 200) {
                setIsLoading(false)
                setMessage(response?.data.message)
                // const message = (
                //     <Message showIcon type="success" header="success" closable>
                //         {response?.data.message}
                //     </Message>
                // );
                // toaster.push(message, { placement: "topEnd", duration: 3000 });
            } else {
                setIsLoading(false)
                setMessage(response?.data.message)
                // const message = (
                //     <Message showIcon type="error" closable>
                //         {response?.data.message}
                //     </Message>
                // );
                // toaster.push(message, { placement: "topEnd", duration: 3000 });
                // console.log("activateLiveJobs_page....", response);
            }
        } catch (error) {
            setIsLoading(false)
            setMessage(error?.response?.data.message)
            // const message = (
            //     <Message showIcon type="error" closable>
            //         {error?.response?.data.message}

            //     </Message>
            // );
            // toaster.push(message, { placement: "topEnd", duration: 3000 });

        }
    }



    const RejectJob = async () => {
        try {
            const response = await ChampHealthApiClient.get(`/common/reject-jobs/${jobId}/${jobSeekerId}`);
            if (response.status === 200) {
                setIsLoading(false)
                setMessage(response?.data?.message)
                // const message = (
                //     <Message showIcon type="success" header="success" closable>
                //         {response?.data.message}
                //     </Message>
                // );
                // toaster.push(message, { placement: "topEnd", duration: 3000 });
            } else {
                setIsLoading(false)
                setMessage(response?.data?.message)
                // const message = (
                //     <Message showIcon type="error" closable>
                //         {response?.data.message}
                //     </Message>
                // );
                // toaster.push(message, { placement: "topEnd", duration: 3000 });

            }
        } catch (error) {
            setIsLoading(false)
            setMessage(error?.response?.data?.message)
            // const message = (
            //     <Message showIcon type="error" closable>
            //         {error?.response?.data.message}
            //     </Message>
            // );
            // toaster.push(message, { placement: "topEnd", duration: 3000 });

        }
    }



    useEffect(() => {
        if (acceptReject == 'accept') { AcceptJob() }
        if (acceptReject == 'reject') { RejectJob() }
    }, [])

    return (
        <>


            <div className="h-screen w-screen relative">
                <img
                    className="-z-10 absolute w-full bottom-0"
                    src="/assests/images/candidate-bg.png"
                />
                <div className="container relative">
                    <header className="py-4">
                        <img src="/logo.png" alt="health champ logo" />
                    </header>

                    <main className="w-full h-full relative flex justify-center content-center ">
                        <div className="mt-40 w-[560px] bg-white shadow px-16 py-20 rounded-lg">

                            <div className="w-full">
                                <div className='flex flex-col items-center text-base text-center'>

                                    {isLoading ? <>

                                        <div
                                            className="flex justify-center content-center items-center m-auto h-full w-full"
                                            style={{ textAlign: "center" }}
                                        >
                                            <div className='w-full'>
                                                <div className='absolute mx-auto w-full my-auto left-0'>
                                                    <Loader className='z-50' size="md" speed='fast' content="please wait..." />
                                                </div>

                                                <Placeholder.Paragraph
                                                    className='z-20'
                                                    style={{ marginTop: -90 }}
                                                    rows={6}
                                                    active
                                                />


                                            </div>

                                        </div>

                                    </> : <>

                                        <div className="text-center text-xl  py-2" dangerouslySetInnerHTML={{ __html: message }} />


                                        <div className='flex gap-2 pt-6 text-white'>

                                            <button onClick={() => navigate('/')} className='hover:cursor-pointer border border-gray-700 rounded bg-gray-700 p-2 px-3'>Go Home</button>

                                        </div>
                                    </>}
                                </div>

                            </div>
                        </div>
                    </main>
                </div>
            </div>


        </>
    )
}

export default AcceptRejectJob