import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

const SchedulingForm = () => {
  const navigate = useNavigate();
  
  const tabChange = (e) => {
    // hidde tab content
    const tabsContent = document.querySelectorAll('[role="tabpanel"]');
    for (let index = 0; index < tabsContent.length; index++) {
      const element = tabsContent[index];
      element.classList.add("hidden");
    }

    // remove border from existing tabs
    const tabsPanel = document.querySelectorAll('[role="tab"]');
    for (let index = 0; index < tabsPanel.length; index++) {
      const element = tabsPanel[index];
      element.classList.remove("border-b-2");
    }

    const tabId = e.target.id.slice(0, -4);

    // toggle hidden class for selected the tabs content
    document.getElementById(tabId).classList.toggle("hidden");

    // toggle border-2 class for selected the tabs content
    document.getElementById(`${tabId}-tab`).classList.toggle("border-b-2");
  };

  // editor


  return (
    <>
      <main>
        <section className="py-8 bg-[#F1F4F8]">
          <div className="container mx-auto md:flex md:justify-between md:gap-2 lg:flex lg:justify-between w-full">
            {/* <!-- Client List Search bar --> */}
            <button className="inline-flex items-center gap-2 font-[600]" onClick={() => navigate("/admin/scheduling")}>
              <i className="fa-solid fa-arrow-left-long mt-1 text-2xl"></i>
              <h1 className="text-[30px] text-lg whitespace-nowrap">
                New Scheduling
              </h1>
            </button>
          </div>
        </section>

        {/* <!-- Create Client Form --> */}

        <section className="py-8 bg-white">
          <div className="container">
            {/* <!-- tabs --> */}

            <div className="tabs">
              {/* <!------  Tab list ----->  */}
              <ul
                className="nav nav-tabs flex flex-wrap list-none border-b-0 pl-0 mb-4"
                id="tabs-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <a
                    href="#tabs-scheduling"
                    className="nav-link block text-xs leading-tight uppercase font-bold border-black border-0 border-b-2 px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent active"
                    id="tabs-scheduling-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-scheduling"
                    role="tab"
                    aria-controls="tabs-scheduling"
                    aria-selected="true"
                    onClick={tabChange}
                  >
                    Scheduling
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    href="#tabs-notes"
                    className="nav-link block font-bold border-black text-xs leading-tight uppercase px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
                    id="tabs-notes-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-notes"
                    role="tab"
                    aria-controls="tabs-notes"
                    aria-selected="true"
                    onClick={tabChange}
                  >
                    Notes
                  </a>
                </li>

                <li className="nav-item" role="presentation">
                  <a
                    href="#tabs-request"
                    className="nav-link block font-bold border-black text-xs leading-tight uppercase px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
                    id="tabs-request-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-request"
                    role="tab"
                    aria-controls="tabs-request"
                    aria-selected="true"
                    onClick={tabChange}
                  >
                    Requests
                  </a>
                </li>
              </ul>

              {/* <!------  horizontal line ----->  */}
              <hr className="rounded" />

              {/* <!------  Tab content ----->  */}

              <div className="tab-content" id="tabs-tabContent">
                {/* <!--------------------------- Tab Content Schedule --------------------------> */}
                <div
                  className="tab-pane fade mt-6"
                  id="tabs-scheduling"
                  role="tabpanel"
                  aria-labelledby="tabs-scheduling-tab"
                >
                  <div className="flex flex-col space-y-2 text-sm">
                    <div className="flex">
                      <div className="mb-3 xl:w-96">
                        <select className="form-select w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border  border border-gray-200 rounded">
                          <option defaultValue>staff name: Joseph</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <h3 className="text-gray-600">
                    <span className="font-medium text-black">
                      Client Name:{" "}
                    </span>
                    Georgiana Kautzer
                  </h3>

                  <div className="flex flex-col gap-4 my-8">
                    {/* Date Selector  */}
                    <div className="flex items-center flex-wrap gap-1">
                      <p className="w-40 font-bold text-xl">Date: </p>

                      <div className="flex items-center gap-2">
                        <input
                          type="date"
                          name="job_schedule"
                          id="job_schedule"
                          className="block rounded pt-3 pb-2 px-4 w-full text-sm font-[600] text-[#545454] bg-transparent  border border-champInputBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                        />
                        <i className="fa-solid fa-arrow-right px-4"></i>
                        <input
                          type="date"
                          name="job_schedule"
                          id="job_schedule"
                          className="block rounded pt-3 pb-2 px-4 w-full text-sm font-[600] text-[#545454] bg-transparent  border border-champInputBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                        />
                      </div>
                    </div>

                    {/* Time Selector  */}
                    <div className="flex items-center flex-wrap gap-1">
                      <p className="w-40 font-bold text-xl">Time: </p>

                      <div className="flex items-center gap-2">
                        <input
                          type="time"
                          name="job_schedule"
                          id="job_schedule"
                          className="block rounded pt-3 pb-2 px-4 w-full text-sm font-[600] text-[#545454] bg-transparent  border border-champInputBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                        />
                        <i className="fa-solid fa-arrow-right px-4"></i>
                        <input
                          type="time"
                          name="job_schedule"
                          id="job_schedule"
                          className="block pt-3 pb-2 px-4 w-full text-sm font-[600] text-[#545454] bg-transparent  border border-champInputBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                        />
                      </div>
                    </div>

                    {/* repeat  */}
                    <div className="flex items-center flex-wrap gap-1">
                      <p className="w-40 font-bold text-xl">Repeat: </p>

                      <div className="flex items-center gap-2">
                        <select
                          type="text"
                          name="schedule_repeat"
                          id="schedule_repeat"
                          className="block pt-3 rounded pb-2 px-0 w-full text-sm font-[600] text-[#545454] bg-transparent px-4 border border-champInputBorder focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        >
                          <option defaultValue>Job Type</option>
                          <option value="US">United States</option>
                          <option value="CA">Canada</option>
                          <option value="FR">France</option>
                          <option value="DE">Germany</option>
                        </select>
                      </div>
                    </div>

                    {/* hourly rate  */}
                    <div className="flex items-center flex-wrap gap-1">
                      <p className="w-40 font-bold text-xl">Hourly Rate: </p>

                      <div className="flex items-center gap-2 relative">
                        <input
                          type="number"
                          name="job_schedule"
                          id="job_schedule"
                          className=" pt-3 rounded pb-2 px-4 pl-8 w-full text-xl font-[600] bg-transparent  border border-champInputBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                        />
                        <span className="absolute left-2 font-semibold text-2xl pl-1">
                          $
                        </span>
                      </div>
                    </div>

                    {/* Location */}
                    <div className="flex items-center flex-wrap gap-1">
                      <p className="w-40 font-bold text-xl">Location : </p>

                      <div className="flex items-center gap-2 relative">
                        <input
                          type="text"
                          name="job_location"
                          id="job_location"
                          className="w-full rounded pt-3 pb-2 px-4 w-full text-xl font-[600] bg-transparent  border border-champInputBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder="Add Location"
                          required
                        />
                      </div>
                    </div>

                    <div className="flex items-center flex-wrap gap-1">
                      <p className="w-40 font-bold text-xl">Time off : </p>

                      <div className="flex items-center lg:flex-nowrap flex-wrap  gap-2">
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                          />
                          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>

                        <input
                          type="date"
                          name="job_schedule"
                          id="job_schedule"
                          className="block rounded pt-3 pb-2 px-4 w-full text-sm font-[600] text-[#545454] bg-transparent  border border-champInputBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          
                        />
                        <i className="fa-solid fa-arrow-right px-4"></i>
                        <input
                          type="date"
                          name="job_schedule"
                          id="job_schedule"
                          className="block rounded pt-3 pb-2 px-4 w-full text-sm font-[600] text-[#545454] bg-transparent  border border-champInputBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mr-6"
                          placeholder=" "
                          disabled
                        />

                        <input
                          type="time"
                          name="job_schedule"
                          id="job_schedule"
                          className="block rounded pt-3 pb-2 px-4 w-full text-sm font-[600] text-[#545454] bg-transparent  border border-champInputBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                        />
                        <i className="fa-solid fa-arrow-right px-4"></i>
                        <input
                          type="time"
                          name="job_schedule"
                          id="job_schedule"
                          className="block rounded pt-3 pb-2 px-4 w-full text-sm font-[600] text-[#545454] bg-transparent  border border-champInputBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                        />
                        <select
                          type="text"
                          name="schedule_repeat"
                          id="schedule_repeat"
                          className="block pt-3 rounded pb-2 px-0 w-full text-sm font-[600] text-[#545454] bg-transparent px-4 border border-champInputBorder focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          disabled
                        >
                          <option defaultValue>reason</option>
                          <option value="US">United States</option>
                          <option value="CA">Canada</option>
                          <option value="FR">France</option>
                          <option value="DE">Germany</option>
                        </select>
                      </div>
                    </div>

                    {/* Details */}
                    <div className="flex items-center flex-wrap gap-1">
                      <p className="w-40 font-bold text-xl">Details : </p>
                      <div className="flex items-center gap-2 relative">
                        <textarea
                          className="w-full border-solid border"
                          id="detail_schedule"
                          name="detail_schedule"
                          rows="4"
                          cols="80"
                        ></textarea>
                      </div>
                    </div>

                    {/* Buttons  */}
                    <div className="my-6  text-center	 space-x-4">
                      <button
                        type="submit"
                        className="text-white bg-blue-900  font-medium rounded text-sm mx-auto font-semibold text-2xl px-12 py-2 mt-8 text-center mx-4"
                      >
                        Save
                      </button>
                      <button
                        type="submit"
                        className="border font-medium rounded text-sm mx-auto font-semibold text-2xl px-12 py-2 mt-8 text-center"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>

                {/* <!--------------------------- Tab notes Schedule --------------------------> */}

                <div
                  className="tab-pane fade hidden mt-6"
                  id="tabs-notes"
                  role="tabpanel"
                  aria-labelledby="tabs-notes-tab"
                >
                  <div>
                    {/* <!--------------------------- take notes input   -------------------------->*/}
                    <textarea
                      className="w-full rounded border-0 border-b-2 p-4 bg-[#F5F9FD] "
                      id="detail_schedule"
                      name="detail_schedule"
                      rows="4"
                      cols="80"
                      placeholder="Start typing your note..."
                    ></textarea>

                    {/* <!--------------------------- chat response here   -------------------------->*/}

                    <div className="my-6 bg-[#FAFAFC] divide-y divide-y-2 divide-gray-300 text-gray-500">
                      <div className="p-4 flex flex-col gap-4">
                        <div className="flex justify-between items-center text-black">
                          <h3 className="font-bold">Patrick Swanson</h3>
                          <span className="font-medium">1 hour ago</span>
                        </div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book
                        </p>
                        <span className="text-blue-500 font-normal text-lg">
                          Reply
                        </span>
                      </div>
                      <div className="p-4 flex flex-col gap-4">
                        <div className="flex justify-between items-center text-black">
                          <h3 className="font-bold">You</h3>
                          <span className="font-medium">Oct 9, 4AM</span>
                        </div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book
                        </p>
                        <span className="text-blue-500 font-normal text-lg">
                          Reply
                        </span>
                      </div>
                      <div className="p-4 flex flex-col gap-4">
                        <div className="flex justify-between items-center text-black">
                          <h3 className="font-bold">Patrick Swanson</h3>
                          <span className="font-medium">Oct 8, 4AM</span>
                        </div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book
                        </p>
                        <span className="text-blue-500 font-normal text-lg">
                          Reply
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--------------------------- Tab request Schedule --------------------------> */}

                <div
                  className="tab-pane fade hidden"
                  id="tabs-request"
                  role="tabpanel"
                  aria-labelledby="tabs-request-tab"
                >
                  <div className="flex divide-x-2 divide-gray-400">
                    {/* left side */}
                    <div className="flex flex-col gap-2 w-1/3 divide-y-2 space-y-4">
                      {/* in Progress requests */}
                      <div className="flex flex-col gap-2 mt-4">
                        <h4 className="mb-4 text-gray-900 font-medium">
                          In Progress
                        </h4>
                        <div className="inline-flex gap-2 py-2 bg-gray-100 mx-2 rounded">
                          <p className="rounded-full mt-1">
                            <span className="rounded-full px-2 m-2 border border-blueRibbion-200 bg-white text-xl">
                              P
                            </span>
                          </p>
                          <p className="flex flex-col gap-4">
                            <span className="font-medium">
                              Patrick Swanson requested a shift, Personal
                              Support Worker Oct 9, 8 AM - 5PM
                            </span>
                            <span className="font-normal">4:06 PM</span>
                          </p>
                        </div>
                      </div>

                      {/* Completed request */}
                      <div>
                        <h4 className="mb-4 text-gray-900 font-medium">
                          completed
                        </h4>
                        <div className="inline-flex gap-2 py-2 mx-2 rounded">
                          <p className="rounded-full mt-1">
                            <span className="rounded-full px-2 m-2 border border-blueRibbion-200 bg-white text-xl">
                              P
                            </span>
                          </p>
                          <p className="flex flex-col gap-4">
                            <span className="font-medium">
                              Patrick Swanson requested a shift, Personal
                              Support Worker Oct 9, 8 AM - 5PM
                            </span>
                            <span className="font-normal">4:06 PM</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* right side */}
                    <div className="w-full">
                      <div className="flex flex-col gap-3 mt-4">
                        <h4 className="px-8 font-semibold">Request details</h4>
                        <hr className="bg-gray-600" />
                      </div>

                      <div>
                        <div className="px-4 lg:px-12 py-6">
                          <div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow">
                            <div className="flex flex-col gap-2 py-2  mx-2 rounded">
                              <div className="inline-flex">
                                <p className="rounded-full mt-1">
                                  <span className="rounded-full px-2 m-2 border border-blueRibbion-200 bg-white text-xl">
                                    P
                                  </span>
                                </p>
                                <p className="flex flex-col gap-4">
                                  <span className="font-medium">
                                    Patrick Swanson requested a shift, Personal
                                    Support Worker Oct 9, 8 AM - 5PM
                                  </span>
                                  <span className="font-normal">
                                    Request: Today 4:06 PM
                                  </span>
                                </p>
                              </div>

                              <div>
                                <input
                                  type="text"
                                  placeholder="Add a note ( optional )"
                                  className="border w-full rounded border-[#F9FBFF] px-2 py-1 bg-[#D0DCFF]"
                                />
                              </div>

                              <div className="inline-flex gap-2">
                                <button className="border px-3 py-1 rounded border-black">
                                  Deny
                                </button>
                                <button className=" px-6 py-1 rounded  bg-blueRibbon-900 text-white">
                                  Approve
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default SchedulingForm;
