import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';


import "./css/rsuite.css"
import "./css/output.css"
import "./css/input.css"
import { enUS } from 'rsuite/esm/locales';
import { CustomProvider } from 'rsuite';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <CustomProvider


            locale={enUS}>

            <App />
      </CustomProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
