
import { DateRangePicker, Modal, Button, ButtonToolbar, Placeholder, DatePicker, Radio, Input } from 'rsuite';

import {startOfDay, endOfDay, addDays, subDays}  from 'date-fns';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import TimeSheetsDay from './Day';
import TimeSheetsWeek from './Week';
import TimeSheetsBiWeek from './BiWeek';
import TimeSheetsMonth from './Month';

import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } =
  DateRangePicker;
const TimeSheets = () => {
      const navigate = useNavigate();

    const [dateRange, setDateRange] = useState(null);
    const [isDropdown, setIsDropdown] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

   const data = [1]

   const [selectedFilterBtn, setSelectedFilterBtn] = useState('Day');


const datePickerStyle = { width: 260, display: "block", marginBottom: 10 };


const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 2 week',
    value: [subDays(new Date(), 14), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left'
  },
  {
    label: 'Last week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
      ];
    },
    appearance: 'default'
  },
  {
    label: 'Next week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
      ];
    },
    appearance: 'default'
  }
];

const BiWeekRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 2 week',
    value: [subDays(new Date(), 14), new Date()],
    placement: 'left'
  },

];

  return (
    <main>
      {/* <!-- Filter and Searched Options --> */}
      <section className="py-10 bg-[#F1F4F8]">
        <div className="container md:flex md:justify-between md:gap-2 lg:flex  lg:justify-between w-full md:items-center">
          {/* <!-- Client List Search bar --> */}
          <div className="w-full md:w-1/2 lg:flex lg:items-center lg:justify-between lg:w-1/2 lg:space-x-4 xl:w-2/3 xl:justify-between my-2 ml-[4px]">
            <div
              className="inline-flex items-center gap-2 font-[500] hover:cursor-pointer text-black "
              onClick={() => navigate("/admin/list-staff")}
            >
              <i className="fa-solid fa-arrow-left-long text-xl"></i>
              <h1 className="text-2xl text-black  whitespace-nowrap">
                View & edit timesheets
              </h1>
            </div>
            <div className="flex justify-center items-center h-[45px] w-full xl:w-2/3">

            {/* <Input size="lg" style={datePickerStyle}  type="week" /> */}
            <>{
            selectedFilterBtn === "Calendar" ? <DateRangePicker format="MM-dd-yyyy" oneTap showOneCalendar hoverRange="month" ranges={[]}  style={datePickerStyle} />: <>{
                selectedFilterBtn === "Weekly" ? <DateRangePicker format="MM-dd-yyyy" oneTap showOneCalendar hoverRange="week" ranges={[]} style={datePickerStyle}  />: <>{
                    selectedFilterBtn === "BiWeekly" ?  <DateRangePicker
                    format="MM-dd-yyyy"
                    ranges={BiWeekRanges}
                    showOneCalendar
                    disabledDate={allowedMaxDays(14)}
                    style={datePickerStyle}
                  />: <DateRangePicker
                  ranges={predefinedRanges}
                  format="MM-dd-yyyy"
                  showOneCalendar
                  style={datePickerStyle}
                />
                } </>
            }</>
          }</>
              
            </div>
          </div>

          {/* <!-- Filters --> */}
          <div className="w-full md:w-1/2 lg:flex md:items-center lg:gap-2 lg:w-2/3 md:justify-end">
            <div className=" bg-white rounded-full">
              <div className="inline-flex flex-wrap justify-around text-[1rem]">
                <button
                  className={`px-6 py-2 rounded-full ${selectedFilterBtn === "Day" ? " bg-blueBtn text-white":" bg-white"}`}

                  onClick={()=>setSelectedFilterBtn("Day")}
                >
                  Day
                </button>
                <button className={` px-6 py-2 rounded-full ${selectedFilterBtn === "Weekly" ? " bg-blueBtn text-white":"  bg-white"}`}
                onClick={()=>setSelectedFilterBtn("Weekly")}
                >Weekly</button>
                <button className={` px-6 py-2 rounded-full  ${selectedFilterBtn === "BiWeekly" ? " bg-blueBtn text-white":"  bg-white"}`}
                onClick={()=>setSelectedFilterBtn("BiWeekly")}
                >Bi-Weekly</button>
                <button
                  className={`px-6 py-2 rounded-full  ${selectedFilterBtn === "Calendar" ? " bg-blueBtn text-white":"  bg-white"}`}
                  onClick={()=>setSelectedFilterBtn("Calendar")}
                >
                  Calendar
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!---- Calander ---- > */}
      <section>
        <div className="container">
        <div className="flex flex-col my-8 space-y-4">
          <div>
            <h3 className="text-black font-semibold text-[20px]"> Angie Thomas </h3>
          </div>
          <div>
            {selectedFilterBtn === "Day" ? (''):<div className="inline-flex space-x-2">
              <button className="shadow"><i className="fa-solid fa-chevron-left border border-[#D3D8EA] p-2"></i></button>
              <button className="shadow"><i className="fa-solid fa-chevron-right border border-[#D3D8EA] p-2"></i></button>
            </div>}
            
          </div>


          {
            selectedFilterBtn === "Calendar" ? <TimeSheetsMonth />: <>{
                selectedFilterBtn === "Weekly" ? <TimeSheetsWeek />: <>{
                    selectedFilterBtn === "BiWeekly" ? <TimeSheetsBiWeek />: <TimeSheetsDay />
                } </>
            }</>
          }


          </div>
        </div>
      </section>


      <Modal open={open} onClose={handleClose}>
        <Modal.Header className="border-b-2 border-[#CBDAEE]">
          <Modal.Title>
            <h3 className="w-full text-lg font-semibold text-center">
              Modify time
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="space-y-6">
          <div className="flex justify-between items-center">
            <DatePicker format="MM-dd-yyyy" />
            <div className="inline-flex items-center  justify-end space-x-8">
              <p>From</p>{" "}
              <DatePicker format="hh:mm:ss aa" showMeridian ranges={[]} />
              <p>To</p>
              <DatePicker format="hh:mm:ss aa" showMeridian ranges={[]} />
            </div>
          </div>
          <div className="inline-flex items-center space-x-4">
            <Radio value="billable">Billable</Radio>
            <a className="text-lg text-[#0066FF] inline-flex items-center space-x-2">
              <svg width="18" height="20" viewBox="0 0 18 20" fill="none">
                <path
                  d="M3.414 13.9708L13.556 3.82879L12.142 2.41479L2 12.5568V13.9708H3.414ZM4.243 15.9708H0V11.7278L11.435 0.292787C11.6225 0.105316 11.8768 0 12.142 0C12.4072 0 12.6615 0.105316 12.849 0.292787L15.678 3.12179C15.8655 3.30931 15.9708 3.56362 15.9708 3.82879C15.9708 4.09395 15.8655 4.34826 15.678 4.53579L4.243 15.9708ZM0 18.5H18V19.9708H0V18.5Z"
                  fill="#0066FF"
                />
              </svg>
              <span>Add a reason</span>
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-center w-1/2 mx-auto space-x-4 h-11 my-6 font-medium">
            <button
              onClick={handleClose}
              className="w-1/2 text-black rounded border border-black text-center text-base"
            >
              Cancel
            </button>
            <button className="w-1/2 rounded bg-blueBtnDark text-center text-white text-base">
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      
    </main>
  );
}

export default TimeSheets