import { useState } from "react";
import ArchiveJobs from "./jobs";
import ArchiveClientList from "./clients";
import StaffArchiveList from "./staff";

const JobList = () => {
  const [selectedFilterBtn, setSelectedFilterBtn] = useState('Clients');


  const data = [1,2,3,4,5];



  return (
    <>
      <main className="main-content ">
        {/* <!------ Top section  ------> */}
        <section className="py-10 bg-[#F1F4F8]">
          <div className="container divide-y divide-y-4 space-y-6">
            {/* <!------ selection buttons ----> */}
            <div className="grid grid-cols-2 md:flex md:justify-center gap-4 lg:flex w-full md:items-center font-semibold text-sm md:text-md">
              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Clients"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Clients");
                }}
              >
                Clients
              </button>

              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Jobs"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Jobs");
                }}
              >
                Jobs
              </button>

              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Staff"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white  border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Staff");
                }}
              >
                Staff
              </button>
            </div>

            {/* <!------ Search and filter options ----> */}
            <div className="md:flex items-start lg:items-center md:space-x-2 md:justify-between w-full lg:py-6">
              {/* <!-- job List Search bar --> */}
              <div className="md:w-1/2 w-full lg:flex lg:items-center lg:justify-between lg:w-1/2 lg:space-x-4 xl:w-2/3 xl:justify-between my-2 ml-[4px]">
                <h1 className="font-semibold text-black text-4xl whitespace-nowrap my-2 md:my-0">
                Archive {selectedFilterBtn} List
                </h1>

                
              </div>

              {/* <!-- Filters --> */}
              <div className="md:w-1/2 my-2 lg:flex lg:items-center lg:gap-2 lg:w-2/3 lg:justify-end">
                <h3 className="text-xl font-medium">Filter</h3>
                <div className="flex justify-center items-center h-11 w-full xl:w-2/3 ">
                  <i className="fa-solid fa-magnifying-glass -mr-6 z-10 text-searchIcon"></i>
                  <input
                    className="shadow appearance-none border rounded w-full h-full px-8 leading-tight focus:outline text-champPlaceholder border-champBorderPagination text-sm"
                    id="client-name"
                    type="text"
                    placeholder="Search organisation or location... "
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!------ Main Section  ------> */}
        <section className="pt-6">
               {selectedFilterBtn === "Clients" ? <ArchiveClientList />: <>
               {selectedFilterBtn === "Jobs" ? <ArchiveJobs  />: <>
               {selectedFilterBtn === "Staff" ? <StaffArchiveList />: <>
               </> }
               </> }
               </> }
        </section>
      </main>

      
    </>
  );
};

export default JobList;
