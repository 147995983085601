import axios from "axios";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, SelectPicker, Message, useToaster, Schema } from "rsuite";
import { API_BASE_URL } from "../../../config/apiKeys";

const { StringType, ArrayType, NumberType } = Schema.Types;

const model = Schema.Model({
  company_name: NumberType().isRequired("This field is required."),
  street_address: StringType()
    .pattern(
      /^[\/a-zA-Z0-9\s.!(),@#&*',\-\u0080-\u052F]*$/,
      "Please enter legal characters"
    )
    .isRequired("This field is required."),
  postion: NumberType().isRequired("This field is required."),
  schedule: ArrayType().isRequired("This field is required."),
  job_type: NumberType().isRequired("This field is required."),
  payment_schedule: NumberType().isRequired("This field is required."),
  job_shift: NumberType().isRequired("This field is required."),
  candidates_required: StringType()
    .pattern(/^[1-9][0-9]*$/, "Please enter only Positive Numbers")
    .isRequired("This field is required."),
});

const TextField = forwardRef((props, ref) => {
  const { placeholder, name, label, accepter, ...rest } = props;
  return (
    <Form.Group
      className="candidate_form relative mb-4"
      controlId={`${name}-4`}
      ref={ref}
    >
      <Form.ControlLabel className="text-sm text-[#A1A1A1] font-semibold duration-300">
        {label}
      </Form.ControlLabel>
      <Form.Control
        className={`pt-0 pb-2 px-0 w-full text-lg font-semibold text-[#545454] bg-transparent border-0 border-b-2 border-champBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
        name={name}
        accepter={accepter}
        placeholder={placeholder}
        {...rest}
      />
    </Form.Group>
  );
});

function CandidateJobPreferences() {
  const navigate = useNavigate();
  const [formType, setFormType] = useState(false);
  const [callForm, setCallForm] = useState(false);
  const [jobPostions, setJobPostions] = useState(false);

  //// detail form
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({});

  // fetch AllJobPostion
  const getAllJobPostion = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/admin/all-job-position-id-fetch`
      );
      console.log("candidate_register_getAllJobPostion_response....", response);

      if (response?.status === 200) {
        setJobPostions(
          response?.data?.data.map((item) => ({
            label: item.position_name,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.log(
        "candidate_register_getAllJobPostion_response_Error...",
        error
      );
    }
  };

  const handleClick = () => {
    setCallForm(true);
  };

  useEffect(() => {
    getAllJobPostion();
  }, []);
  return (
    <>
      <section className="w-full flex flex-col gap-2">
        <p>Jobs</p>

        <Form
          fluid
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}
          model={model}
          className="relative w-9/12 flex gap-4 items-baseline	"
        >
          <div className="w-8/12">
          <TextField
            name="postion"
            searchable={false}
            data={jobPostions ? jobPostions : []}
            accepter={SelectPicker}
            placeholder="Position"
            onChange={(event) => {
              setFormType(event);
            }}
            className="w-full border-2 border-blueBtnDark w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
          />
          </div>
          <div className="w-4/12 flex  items-end">
            <button
              disabled={formType ? false : true}
              className={`border-2 pt-[9px] w-full py-[11px] rounded-[5px] text-lg font-normal  ${
                formType
                  ? " bg-blueBtnDark text-white border-blueBtnDark "
                  : " border-champInputBorder"
              }`}
              onClick={handleClick}
            >
              Next
            </button>
          </div>
        </Form>
      </section>
    </>
  );
}

export default CandidateJobPreferences;
