import React, { useEffect, useState } from "react";
import Pagination from "../../Components/Pagination";
import {
  DatePicker,
  DateRangePicker,
  Dropdown,
  Form,
  Loader,
  Message,
  Modal,
  Nav,
  Placeholder,
  SelectPicker,
  TagPicker,
  toaster,
  useToaster,
} from "rsuite";
import axios from "axios";
import { API_BASE_URL } from "../../../config/apiKeys";
import { readableTime } from "../../../Components/readableTime";
import { readableDate } from "../../../Components/readableDate";
import { ChampHealthApiClient } from "../../../config/apiClients";
import { useNavigate } from "react-router-dom";
import TextField from "../../../Components/TextField";
import CustomInputWithButton from "../../../Components/CustomInputWithButton";
import CustomPagination from "../../../Components/CustomPagination";
import { ExtractDaysOfWeek, getTimeOfDaysAsString } from "../../../Components/AvailbilityValues";

const Navbar = ({ active, onSelect, ...props }) => {
  return (
    <Nav
      {...props}
      activeKey={active}
      onSelect={onSelect}
      style={{ marginBottom: 50 }}
    >
      <Nav.Item
        className="text-lg font-medium hover:!text-champGreen"
        eventKey="AccountDetails"
      >
        Account detail
      </Nav.Item>
      <Nav.Item
        className="text-lg font-medium hover:!text-champGreen"
        eventKey="CvDocuments"
      >
        CV's other documents
      </Nav.Item>
    </Nav>
  );
};

const JobSeeker = () => {
  // Call Modal as per click by user
  const toaster = useToaster();
  const navigate = useNavigate();

  const renderButton = (props, ref) => {
    return (
      <i
        {...props}
        ref={ref}
        className="fa-solid fa-ellipsis-vertical text-xl"
      ></i>
    );
  };
  const renderHoldIcon = (props, ref) => {
    return (
      <div className="border border-[#4A5800] rounded-full w-8 h-8 flex items-center justify-center">
        <i
          {...props}
          ref={ref}
          className="fa-solid fa-hand text-[#4A5800] text-2xl"
        ></i>
      </div>
    );
  };

  // card creator function

  const createCard = (jobSeeker) => {
    return (
      <div
        key={jobSeeker.id + "-job"}
        className="card rounded text-black bg-white border-champBorder border hover:shadow-lg shadow-clientCard p-4 relative"
      >
        <div className="flex flex-col justify-between gap-1 relative">
          <div className="inline-flex justify-between items-start">
            <p
              onClick={() => handleOpen(jobSeeker)}
              className="font-[600] text-[25px] hover:cursor-pointer w-full"
            >
              {jobSeeker.name}
            </p>
            <div className="inline-flex items-center gap-4 text-black">
              {jobSeeker.status === "Hold" ? (
                <Dropdown
                  trigger={"hover"}
                  renderToggle={renderHoldIcon}
                  placement="bottomEnd"
                >
                  <h3 className="text-sm py-1 px-4 whitespace-nowrap font-medium">
                    Acount on Hold
                  </h3>
                </Dropdown>
              ) : (
                ""
              )}

              <Dropdown renderToggle={renderButton} placement="bottomEnd">
                <Dropdown.Item
                  onClick={() => archiveJobSeeker(jobSeeker.id, 1)}
                  className="hover:cursor-pointer text-base  py-1 px-6 font-medium hover:bg-grayBg text-black"
                >
                  Archive
                </Dropdown.Item>
                {jobSeeker.status === "Hold" ? (
                  ""
                ) : (
                  <Dropdown.Item
                    onClick={() => {
                      setJobSeekerId({
                        id: jobSeeker.id,
                        type: 3,
                      });
                      handleDeactivateOpen();
                    }}
                    className="hover:cursor-pointer text-base py-1 px-6 font-medium hover:bg-grayBg text-black"
                  >
                    Hold
                  </Dropdown.Item>
                )}
                {jobSeeker.status === "Active" ? (
                  <Dropdown.Item
                    onClick={() => {
                      setJobSeekerId({
                        id: jobSeeker.id,
                        type: 4,
                      });
                      handleDeactivateOpen();
                    }}
                    className="hover:cursor-pointer text-base py-1 px-6 font-medium hover:bg-grayBg text-black"
                  >
                    Deactivate
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    onClick={() => {
                      activeDeactiveHoldJobSeeker(jobSeeker.id, 5);
                    }}
                    className="hover:cursor-pointer text-base py-1 px-6 font-medium hover:bg-grayBg text-black"
                  >
                    Activate
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() =>
                    navigate("/admin/job-seeker/edit/" + jobSeeker.id)
                  }
                  className="hover:cursor-pointer text-base  py-1 px-6 font-medium hover:bg-grayBg text-black"
                >
                  Edit
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>

          <div className="w-full flex justify-between">
            <div
              onClick={() => handleOpen(jobSeeker)}
              className="w-full flex flex-col justify-between gap-2 hover:cursor-pointer"
            >
              {/* <p className="font-medium text-base job_dropdown">{jobSeeker.job_position}</p> */}

              <p className="font-medium text-base job_dropdown">
                <span className="bg-[#f5f5f5] pl-[10px] pr-[10px] rounded-[100px]">
                  {jobSeeker?.job_position}
                </span>

                <button className="hidden jobs_dropdown">
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.25781 15.3059L16.8665 8.30591L8.25781 1.30591"
                      stroke="#555555"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 15.3059L9.6087 8.30591L1 1.30591"
                      stroke="#555555"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <ul>
                    <li>
                      <a href="#">One</a>
                    </li>
                    <li>
                      <a href="#">Two</a>
                    </li>
                    <li>
                      <a href="#">Three</a>
                    </li>
                  </ul>
                </button>
              </p>

              <div className=" inline-flex items-center text-center gap-2 text-sm font-medium">
                <span className="p-2 flex items-center h-30 rounded-full bg-champOnBtn text-champOnText text-center">
                  {jobSeeker.job_type}
                </span>
                <span className="p-2 flex items-center h-30 rounded-full bg-champPartTimeBtn text-center text-champPartTimeText">
                  {jobSeeker.availability}
                </span>
              </div>
              <p className="font-normal text-base">
                <i className="fa-solid fa-location-dot pr-2"></i>
                {jobSeeker.address}
              </p>
              <p className="font-normal text-base text-text-[#FF0F00]">
                {jobSeeker?.reason}
              </p>
              <p className="self-start mx-0 font-normal text-sm text-champText400">
                Registered on: {readableDate(jobSeeker?.createdAt)}
              </p>
            </div>

            <div className="flex items-center justify-items-center">
              {jobSeeker.status === "Inactive" ? (
                <button
                  className="rounded bg-red-800 text-center text-white text-base font-normal w-32 h-11"
                  onClick={() => {
                    activeDeactiveHoldJobSeeker(jobSeeker.id, 5);
                  }}
                >
                  Activate
                </button>
              ) : (
                ""
              )}
              {jobSeeker.status === "Active" ? (
                <button
                  className="rounded bg-blueBtnDark text-center text-white text-base font-normal w-32 h-11"
                  onClick={() => {
                    setJobSeekerId({
                      id: jobSeeker.id,
                      type: 4,
                    });
                    handleDeactivateOpen();
                  }}
                >
                  Deactivate
                </button>
              ) : (
                ""
              )}
              {jobSeeker.status === "Disapprove" ? (
                <button
                  className="rounded bg-green-700 text-center text-white text-base font-normal w-32 h-11"
                  onClick={() => {
                    activeDeactiveHoldJobSeeker(jobSeeker.id, 5);
                  }}
                >
                  Approve
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  //get jobSeeker and list them
  const [jobSeekerList, setJobSeekerList] = useState([]);
  const [originalJobSeekerList, setOriginalJobSeekerList] = useState(false);
  const [jobSeekerCount, setJobSeekerCount] = useState(false);

  const getJobSeekerList = async (start = 0, end = 6) => {
    try {
      const response = await ChampHealthApiClient.get(
        `/admin/jobseekers/${start}/${end}`
      );

      if (response?.status === 200) {
        setJobSeekerList(response?.data?.data);
        setOriginalJobSeekerList(response?.data?.data);
        setJobSeekerCount(response?.data?.count);
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error.response.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });

      setJobSeekerCount("0");
      console.log("list_client_error_page...", error);
    }
  };

  const [jobSeekerModal, setJobSeekerModal] = useState(false);
  const [jobSeekerModalDetails, setJobSeekerModalDetails] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = (job) => {
    setJobSeekerModal(job);
    setOpenModal(true);
    getSingleJobSeekerDetails(job.id);
  };
  const handleClose = () => {
    setJobSeekerModal(false);
    setOpenModal(false);
    setJobSeekerModalDetails(false);
  };

  //  deactivate/hold  job modal

  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const handleDeactivateOpen = () => setDeactivateOpen(true);
  const handleDeactivateClose = () => {
    setDeactivateMessage("");
    setDeactivateOpen(false);
  };

  const [deactivateMessage, setDeactivateMessage] = useState("");
  const [JobSeekerId, setJobSeekerId] = useState(false);

  const activeDeactiveHoldJobSeeker = async (id, statusName) => {
    try {
      const response = await ChampHealthApiClient.patch(
        `/admin/jobseekers/${id}`,
        { status: statusName }
      );

      if (response.status === 200) {
        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        getJobSeekerList();
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error?.response?.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
      console.log("activeDeactiveHoldJobSeeker_error...", error);
    }
  };

  // function to handle archive
  const archiveJobSeeker = async (id, status) => {
    try {
      const response = await ChampHealthApiClient.patch(
        `/admin/jobseekers/archive/${id}`,
        {
          deleted: 1,
        }
      );

      if (response.status === 200) {
        getJobSeekerList();
        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error?.response?.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
      console.log("archive error job...", error);
    }
  };

  /// get function for jobs
  const getSingleJobSeekerDetails = async (id) => {
    try {
      const response = await ChampHealthApiClient.get(
        `/common/jobseeker-details/${id}`
      );

      if (response?.status === 200) {
        setJobSeekerModalDetails(response?.data?.data[0]);
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.response?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      console.log("Create_job_getSingleLiveJobDetails_Error...", error);
    }
  };

  const [jobPostions, setJobPostions] = useState(false);
  // fetch AllJobPostion
  const getAllJobPostion = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/common/job-positions`);
      if (response?.status === 200) {
        setJobPostions(
          response?.data?.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.log(
        "candidate_register_getAllJobPostion_response_Error...",
        error
      );
    }
  };

  const [clientTypes, setClientTypes] = useState([]);
  const fetchClientTypes = async () => {
    try {
      const response = await ChampHealthApiClient.get("common/client-types");
      if (response.status === 200) {
        setClientTypes(
          response?.data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.data?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error?.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
    }
  };

  useEffect(() => {
    getAllJobPostion();
    getJobSeekerList();
    fetchClientTypes();
  }, []);

  const [filter, setFilter] = useState({
    searchInput: false,
    status: false,
    dateRange: false,
    jobPostions: false,
  });

  const handleFilter = () => {
    let filteredJobs;

    if (filter?.status) {
      if (filteredJobs) {
        filteredJobs = filteredJobs.filter(
          (item) => item.status == filter?.status
        );
      } else {
        filteredJobs = originalJobSeekerList.filter(
          (item) => item.status == filter?.status
        );
      }
    }

    if (filter?.dateRange && filter?.dateRange[0]) {
      if (filteredJobs) {
        filteredJobs = filteredJobs.filter(
          (item) =>
            Date.parse(item?.createdAt) >= Date.parse(filter?.dateRange[0]) &&
            Date.parse(item?.createdAt) <= Date.parse(filter?.dateRange[1])
        );
      } else {
        filteredJobs = originalJobSeekerList.filter(
          (item) =>
            Date.parse(item?.createdAt) >= Date.parse(filter?.dateRange[0]) &&
            Date.parse(item?.createdAt) <= Date.parse(filter?.dateRange[1])
        );
      }
    }

    if (filter?.jobPostions?.length) {
      if (filteredJobs) {
        filteredJobs = filteredJobs.filter((item) => {
          for (let index = 0; index < filter?.jobPostions.length; index++) {
            return (item.job_position_id = filter?.jobPostions[index]);
          }
        });
      } else {
        filteredJobs = originalJobSeekerList.filter((item) => {
          for (let index = 0; index < filter?.jobPostions.length; index++) {
            return (item.job_position_id = filter?.jobPostions[index]);
          }
        });
      }
    }

    if (filter?.searchInput) {
      const escapedText = filter?.searchInput
        .replace(/[.*+,?^${}()|[\]\\]/g, " ")
        .split(/\s+/);
      const regexPattern = new RegExp(escapedText.join("|"), "gi");

      if (filteredJobs) {
        filteredJobs = filteredJobs.filter((item) => {
          for (let key in item) {
            if (regexPattern.test(item[key])) {
              return true;
            }
          }
          return false;
        });
      } else {
        filteredJobs = originalJobSeekerList.filter((item) => {
          for (let key in item) {
            if (regexPattern.test(item[key])) {
              return true;
            }
          }
          return false;
        });
      }
    }

    if (filteredJobs) {
      setJobSeekerList(filteredJobs);
    } else {
      setJobSeekerList(originalJobSeekerList);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [filter]);

  return (
    <main className="main-content">
      {/* top section  */}
      <section className="py-10 bg-[#F1F4F8]">
        <div className="w-full container divide-y divide-y-4 space-y-6">
          {/* <!------ Search and filter options ----> */}
          <div className="w-full md:flex items-start lg:items-center md:space-x-2 md:justify-between w-full lg:py-6">
            {/* <!-- job List Search bar --> */}
            <div className="md:w-1/3 w-full lg:flex lg:items-center lg:justify-between lg:w-1/3 lg:space-x-4 xl:w-1/3 xl:justify-between my-2 ml-[4px]">
              <h1 className="font-medium text-black lg:text-[30px] text-2xl whitespace-nowrap my-2 md:my-0">
                Job Seeker
              </h1>

              <div className="flex justify-center items-center h-11 w-full xl:w-2/3 ">
                <CustomInputWithButton
                  onChange={(i) =>
                    setFilter((prevState) => ({ ...prevState, searchInput: i }))
                  }
                  name="search"
                  placeholder="Search...."
                  className="border-2 border-blueBtnDark py-2 w-full  px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded "
                />
              </div>
            </div>

            {/* <!-- Filters --> */}
            <Form className="md:w-2/3 my-2 lg:flex lg:items-center lg:gap-2 lg:w-2/3 lg:justify-end">
              <h3 className="text-xl font-medium">Filter</h3>
              <div className="grid lg:grid-cols-3 grid-cols-3  gap-4 items-center w-full">
                <TextField
                  name="Status"
                  searchable={false}
                  data={[
                    { label: "Active", value: "Active" },
                    { label: "Deactive", value: "Inactive" },
                    { label: "On Hold", value: "Hold" },
                  ]}
                  accepter={SelectPicker}
                  onChange={(i) =>
                    setFilter((prevState) => ({ ...prevState, status: i }))
                  }
                  placeholder="Status"
                  className="border-2 border-blueBtnDark py-2 w-full  px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
                />

                <TextField
                  name="date"
                  searchable={false}
                  accepter={DateRangePicker}
                  format="MM-dd-yyyy"
                  showOneCalendar
                  ranges={[]}
                  onChange={(i) =>
                    setFilter((prevState) => ({ ...prevState, dateRange: i }))
                  }
                  placeholder="Date"
                  className="border-2 border-blueBtnDark py-2 w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
                />
                <TextField
                  name="postion"
                  searchable={false}
                  data={jobPostions ? jobPostions : []}
                  accepter={TagPicker}
                  onChange={(i) =>
                    setFilter((prevState) => ({ ...prevState, jobPostions: i }))
                  }
                  placeholder="Position"
                  className="border-2 border-blueBtnDark py-2 w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
                />
                <button className="hidden border border-champBorder bg-blueBtn text-white rounded h-[52px]">
                  Apply
                </button>
              </div>
            </Form>
          </div>
        </div>
      </section>

      <section className="pt-6">
        <div className="container flex flex-col my-12 gap-4">
          {/* <!-- No. of search result and Create client Button --> */}
          <div className="flex justify-between items-center  w-full">
            <p className="inline-flex ">
              Results showing {jobSeekerCount && jobSeekerCount}
            </p>
          </div>

          {/* <!-- Cards Started--> */}
          {jobSeekerCount ? (
            <>
              {" "}
              <div className="grid grid-cols-jobSeekerList gap-6">
                {/* <!-- single cards --> */}
                {jobSeekerList?.length !== 0 &&
                  jobSeekerList.map((item) => <>{createCard(item)}</>)}
              </div>
              <CustomPagination
                count={jobSeekerCount}
                clickFuntion={getJobSeekerList}
              />
            </>
          ) : (
            <>
              <div className="relative">
                <div
                  className="absolute left-0 right-0 m-auto my-auto top-12 "
                  style={{ textAlign: "center" }}
                >
                  <Loader size="md" />
                </div>
                <Placeholder.Paragraph
                  style={{ marginTop: 30 }}
                  rows={5}
                  active
                />
              </div>
            </>
          )}
          {/* <!-- Cards ended / --> */}
        </div>
      </section>

      {/* job details modal */}

      <Modal open={openModal} onClose={handleClose}>
        {jobSeekerModalDetails ? (
          <>
            <div className="relative bg-white rounded shadow ">
              {/* <!-- Modal header --> */}
              <div className="inline-flex w-full p-4 px-6 justify-end border-b rounded-t bg-blueBgDark">
                <h3 className="w-full text-xl font-semibold text-white uppercase">
                  JobSeeker Detail
                </h3>
                <button
                  onClick={() => {
                    handleClose();
                  }}
                  type="button"
                  className="w-8 text-gray-400 bg-transparent hover:bg-blue-500 hover:text-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              {/* <!-- Modal body --> */}
              <div className="">
                <div className="job-seeker-details flex flex-col items-start space-y-4 p-6 ">
                  <div className="flex flex-col">
                    <p className="font-normal text-base text-[#FF0F00]">
                      {jobSeekerModalDetails?.reason}{" "}
                    </p>
                    <div className="inline-flex items-center space-x-2 py-4 text-black">
                      <div className="bg-champAvatarBg rounded-full text-white h-10 w-10 text-xl font-medium flex justify-center items-center  text-center uppercase">
                        <p>{jobSeekerModalDetails?.name.charAt(0)}</p>
                      </div>
                      <p className="text-xl font-medium uppercase">
                        {jobSeekerModalDetails?.name}
                      </p>
                    </div>

                    {/* <p className="ml-0 pl-0 text-3xl font-medium text-black">
                      {jobSeekerModalDetails?.company_name}
                    </p> */}
                    <p className="text-[13px] text-champText400">
                      <span>
                        {readableDate(jobSeekerModalDetails?.createdAt)}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="px-6 pb-6 flex flex-col gap-8 rounded-b">
                  <div className="flex flex-col gap-4">
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-location-dot pr-2"></i>
                      {`${jobSeekerModalDetails?.address}, ${jobSeekerModalDetails?.city
                        }, ${jobSeekerModalDetails?.province
                        }, ${jobSeekerModalDetails?.postal_code.toUpperCase()}`}
                    </p>
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-envelope pr-2"></i>
                      {jobSeekerModalDetails?.email_id}
                    </p>
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-phone pr-2"></i>
                      {jobSeekerModalDetails?.phone}
                    </p>
                  </div>

                  <div className="flex flex-col gap-4">
                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">Positions:</span>
                      <span className="text-base">
                        {" "}
                        {jobSeekerModalDetails?.job_position}
                      </span>
                    </p>

                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">
                        Availability:
                      </span>
                      <span className="text-base text-champText400">
                        {" "}
                        <div className="text-base font-medium text-black ml-2">
                          {Object.entries(
                            ExtractDaysOfWeek(jobSeekerModalDetails)
                          ).map(([key, value]) => (
                            <p>
                              <span>{key}</span> :{" "}
                              <span className="text-base font-normal">
                                {getTimeOfDaysAsString(value)}
                              </span>
                            </p>
                          ))}
                        </div>
                      </span>
                    </p>

                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">Job Type:</span>
                      <span className="text-base text-champText400">
                        {" "}
                        {jobSeekerModalDetails?.job_type}
                      </span>
                    </p>
                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">Language:</span>
                      <span className="text-base text-champText400">
                        {" "}
                        {jobSeekerModalDetails?.language}
                      </span>
                    </p>

                    <div className="inline-flex justify-between gap-4 border-t-4 pt-4">
                      <div className="inline-flex gap-4">
                        {jobSeekerModalDetails?.resume ? (
                          <a
                            href={jobSeekerModalDetails?.resume}
                            className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                          >
                            <span>
                              <img src="/assests/images/jobseeker-resume-icon.png" />
                            </span>
                            <span>Resume</span>
                          </a>
                        ) : (
                          ""
                        )}
                        {jobSeekerModalDetails?.vaccine ? (
                          <a
                            href={jobSeekerModalDetails?.vaccine}
                            className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                          >
                            <span>
                              <svg
                                width="18"
                                height="22"
                                viewBox="0 0 18 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.651 9.44817V18.8704C16.651 19.4863 16.4057 20.077 15.9689 20.5125C15.5321 20.948 14.9398 21.1926 14.3221 21.1926H3.4538C2.83613 21.1926 2.24376 20.948 1.807 20.5125C1.37025 20.077 1.12488 19.4863 1.12488 18.8704V3.38845C1.12488 2.77254 1.37025 2.18186 1.807 1.74634C2.24376 1.31083 2.83613 1.06616 3.4538 1.06616H8.24507C8.65671 1.06622 9.05147 1.22928 9.34257 1.51949L16.1964 8.35379C16.4874 8.64406 16.651 9.0377 16.651 9.44817Z"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.82848 1.64111V7.39154C8.82848 7.79823 8.99561 8.18827 9.2931 8.47585C9.5906 8.76342 9.99408 8.92498 10.4148 8.92498H16.3635M4.86267 12.7586H12.7943M4.86267 16.5922H12.7943"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                            <span>Vaccine</span>
                          </a>
                        ) : (
                          ""
                        )}
                        {jobSeekerModalDetails?.diploma ? (
                          <a
                            href={jobSeekerModalDetails?.diploma}
                            className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                          >
                            <span>
                              <img src="/assests/images/jobseeker-resume-icon.png" />
                            </span>
                            <span>Diploma</span>
                          </a>
                        ) : (
                          ""
                        )}
                        {jobSeekerModalDetails?.other_doc ? (
                          <a
                            href={jobSeekerModalDetails?.other_doc}
                            className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                          >
                            <span>
                              <svg
                                width="18"
                                height="22"
                                viewBox="0 0 18 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.651 9.44817V18.8704C16.651 19.4863 16.4057 20.077 15.9689 20.5125C15.5321 20.948 14.9398 21.1926 14.3221 21.1926H3.4538C2.83613 21.1926 2.24376 20.948 1.807 20.5125C1.37025 20.077 1.12488 19.4863 1.12488 18.8704V3.38845C1.12488 2.77254 1.37025 2.18186 1.807 1.74634C2.24376 1.31083 2.83613 1.06616 3.4538 1.06616H8.24507C8.65671 1.06622 9.05147 1.22928 9.34257 1.51949L16.1964 8.35379C16.4874 8.64406 16.651 9.0377 16.651 9.44817Z"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.82848 1.64111V7.39154C8.82848 7.79823 8.99561 8.18827 9.2931 8.47585C9.5906 8.76342 9.99408 8.92498 10.4148 8.92498H16.3635M4.86267 12.7586H12.7943M4.86267 16.5922H12.7943"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                            <span>Other Document</span>
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="relative">
              <div
                className="absolute left-0 right-0 m-auto my-auto top-12 "
                style={{ textAlign: "center" }}
              >
                <Loader size="md" />
              </div>
              <Placeholder.Paragraph
                style={{ marginTop: 30 }}
                rows={5}
                active
              />
            </div>
          </>
        )}
      </Modal>

      {/* job deactivate modal */}
      <Modal open={deactivateOpen} onClose={handleDeactivateClose}>
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded ">
          {/* <!-- Modal header --> */}
          <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {JobSeekerId.type === 3
                ? "Reason for put Account on hold"
                : "Reason for deactivating Account"}
            </h3>
            <button
              onClick={handleDeactivateClose}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* <!-- Modal body --> */}
          <div className="pt-6">
            <div className="form-group">
              <textarea
                type="text"
                rows="6"
                cols="50"
                className="form-control w-full h-full rounded px-3 py-1.5 text-base font-normal outline-none border-none"
                name="title"
                id="title"
                onChange={(event) => {
                  setDeactivateMessage(event.target.value);
                }}
                placeholder="You can type your reason here...."
              ></textarea>
            </div>

            {/* <!-- Modal footer --> */}
            <div className="flex justify-center items-center py-2 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="submit"
                className="text-white bg-black font-500 text-md py-2 px-8 rounded"
                onClick={() => {
                  activeDeactiveHoldJobSeeker(JobSeekerId.id, JobSeekerId.type);
                  handleDeactivateClose();
                }}
              >
                {JobSeekerId.type === 3 ? "Hold" : "De-activate"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </main>
  );
};

export default JobSeeker;
