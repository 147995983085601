import React, { forwardRef, useRef, useState } from "react";
import { Form, Message, Schema, useToaster } from "rsuite";

import axios from "axios";
import { API_BASE_URL } from "../../../config/apiKeys";
import CandidatePasswordResetEmailMessage from "./PasswordResetEmailMessage"
import { ChampHealthApiClient } from "../../../config/apiClients";


const { StringType } = Schema.Types;

const forgotPasswordModal = Schema.Model({
  username: StringType().isRequired("This field is required."),
});

const TextField = forwardRef((props, ref) => {
  const { placeholder, name, accepter, ...rest } = props;
  return (
    <Form.Group
      className="admin_login_input_group"
      controlId={`${name}-4`}
      ref={ref}
    >
      <Form.Control
        className="admin_login_input_field w-full px-4 py-2 text-xl font-medium text-champPlaceholder font-medium border-[1px] border-black transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        name={name}
        accepter={accepter}
        {...rest}
        placeholder={placeholder}
      />
    </Form.Group>
  );
});

function CandidateForgotPassword() {
  const toaster = useToaster();
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    username: "",
  });

  const [mailSuccess, setMailSuccess] = useState(false)

  const [disableSubmit, setDisableSubmit] = useState(false)

  const forgotPassword = async () => {
    try {
      const  response  = await ChampHealthApiClient.post(
        `/jobseekers/forget-password`,
        {email:formValue.username}
      );

      if(response.status === 200){
        setDisableSubmit(false)
        setMailSuccess({
          email:response.data.data.email,
          username:formValue.username
        });
        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 4000 });

      }else{
        const message = (
          <Message showIcon type="error"  closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 4000 });
      }

      console.log("forgot_password__page ", response);
    } catch (error) {
      const message = (
        <Message showIcon type="error"  closable>
          {error.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 4000 });

      setDisableSubmit(false)
      console.log("forgot_password___error", error);
    }
  };

  const handleForgot = () => {
    if (formRef.current.check()) {
      setDisableSubmit(true)
      forgotPassword();
      return;
    }
    console.log("form error...forgot password page", formError);
  };

  return (
    <>
    {mailSuccess ? (<CandidatePasswordResetEmailMessage email={mailSuccess.email} username={mailSuccess.username} />):(
    <div className=" h-screen relative pb-12 overflow-y-hidden">
      <img
        className="-z-10 absolute w-full bottom-0"
        alt="background page"
        src="/assests/images/candidate-bg.png"
      />
      <header className="container py-4">
        <img src="/logo.png" alt="health champ logo" />
      </header>
      <main className="container h-full pb-12 w-full">
        <section className="relative h-full w-full">
          <div className=" h-full w-full flex justify-center items-center">
            <div className="w-full flex justify-center  -mt-16 items-center flex-wrap h-full gap-6 text-gray-800 lg:w-1/3">
              <div className="w-full">
                <div className="flex flex-col justify-center items-center gap-2 mb-6">
                  <h1 className="font-semibold text-2xl">Forgot Password</h1>
                  <h3 className="font-medium text-lg text-center">
                    No worries. we'll send you reset instructions.
                  </h3>
                </div>
                <Form
                  fluid
                  ref={formRef}
                  onChange={setFormValue}
                  onCheck={setFormError}
                  formValue={formValue}
                  model={forgotPasswordModal}
                  className="w-full "
                >
                  <div className="mb-6">
                    <TextField name="username" placeholder="Email" />
                  </div>
                </Form>
                <button
                  type="submit"
                  className="inline-block px-7 py-3 bg-blueBgDark text-white border-champBorder font-semibold text-xl rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out w-full"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  onClick={handleForgot}
                  disabled={disableSubmit ? true:false}
                >
                  Reset Password
                </button>

                <div className="flex justify-center items-center my-6">
                  <a
                    href="/"
                    className="text-champText400 text-center text-base hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out  "
                  >
                    <i className="fa-solid fa-arrow-left-long mr-1"></i>{" "}
                    <span className="text-xl ">Back to log in</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>)}

    
    </>
  );
}

export default CandidateForgotPassword;
