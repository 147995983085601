import React from 'react'

function NotFound() {
  return (
    <>
    <div className='container flex flex-col h-screen justify-center items-center'>

    <div>Not Found</div>
    <a href='/' className='text-blue-500'>Home Page</a>
    </div>
    
    </>
  )
}

export default NotFound