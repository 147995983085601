import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Form, Loader, Message, Schema, SelectPicker, useToaster } from "rsuite";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../../config/apiKeys";
import { ChampHealthApiClient } from "../../../config/apiClients";
import { USER_DETAIL, setCookie } from "../../../config/headerToken";
import TextField from "../../../Components/TextField";

const { StringType, NumberType } = Schema.Types;

const signInModal = Schema.Model({
  password: StringType().isRequired("This field is required."),
  email: StringType().isRequired("This field is required."),
});


// const TextField = forwardRef((props, ref) => {
//   const { placeholder, name, accepter, ...rest } = props;
//   return (
//     <Form.Group
//       className="admin_login_input_group"
//       controlId={`${name}-4`}
//       ref={ref}
//     >
//       <Form.Control
//         className="admin_login_input_field w-full px-4 py-2 text-xl font-medium text-champPlaceholder font-medium border-[1px] border-black transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
//         name={name}
//         accepter={accepter}
//         {...rest}
//         placeholder={placeholder}
//       />
//     </Form.Group>
//   );
// });


function Login() {
  const toaster = useToaster();
  const navigate = useNavigate();
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });

  // const userDetails = useSelector((state) => state.userDetails.user);



  const [disableSubmit, setDisableSubmit] = useState(false)

  const signIn = async () => {
    console.log(formValue);
    try {
      const res = await ChampHealthApiClient.post(`/admin/login`,
        {
          email: formValue.email,
          password: formValue.password,
        }
      );
      if (res.status === 200) {
        setDisableSubmit(false)
        setCookie("token", res.data.token, 3)
        setCookie("user", JSON.stringify(res.data), 3)
        window.location.pathname = '/admin/list-client';
      } else {
        setDisableSubmit(false)
        const message = (
          <Message showIcon type="error"  closable>
            {res?.data.message}
          </Message>
        );
        toaster.push(message, { placement: 'topEnd', duration: 3000 })
      }


    } catch (error) {
      setDisableSubmit(false)
      const message = (
        <Message showIcon type="error"  closable>
          {error?.response?.data?.message || error.message}
        </Message>
      );
      toaster.push(message, { placement: 'topEnd', duration: 3000 })
      console.log("login_page error.....", error);
    }
  };

  const handleSignIn = () => {
    if (formRef.current.check()) {
      setDisableSubmit(true)
      signIn();
      return;
    }
    console.log("form error...login page", formError);
  };

  useEffect(() => {
    if (USER_DETAIL?.role === 'jobseeker') {
      window.location.pathname = '/candidate/jobs';
    }
    if (USER_DETAIL?.role === 'client') {
      window.location.pathname = '/client/jobs';
    }
    if (USER_DETAIL?.role === 'admin') {
      window.location.pathname = '/admin/list-client';
    }
  }, [])

  return (
    <>

      <div className=" h-screen relative pb-12 overflow-y-hidden">
        <img className="-z-10 absolute h-full w-full bottom-0" src="/assests/images/candidate-bg.png" />
        <header className="container py-4">
          <img onClick={() => navigate('/')} className="hover:cursor-pointer" src="/logo.png" alt="health champ logo" />
        </header>
        <main className="h-full container">
          <section className="relative h-full w-full">
            <div className=" h-full w-full flex justify-center items-center">
              <div className="flex justify-center items-center flex-wrap  -mt-16 h-full g-6 text-gray-800 lg:w-1/3">
                <div className="w-full">
                  <div className="flex flex-col justify-center items-center gap-4 mb-6">
                    <h1 className="font-semibold text-3xl">Log In</h1>
                    <h3 className="font-medium text-lg">
                      Login to start your session
                    </h3>
                  </div>
                  <Form
                    fluid
                    ref={formRef}
                    onChange={setFormValue}
                    // onSubmit={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={signInModal}
                    className="w-full "

                  >
                    <div className="mb-6">
                      <TextField autoComplete="off" name="email" placeholder="Email *" autoFocus={true} />
                    </div>
                    <div className="mb-6">
                      <TextField
                        name="password"
                        placeholder="Password *"
                        type="password"
                        autoComplete="off"
                      />
                    </div>

                    <button
                      type="submit"
                      className="inline-block px-7 py-3 bg-blueBgDark text-white border-champBorder font-semibold text-2xl rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out w-full"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                      onClick={(e) => {
                        handleSignIn(e);
                      }}
                      disabled={disableSubmit ? true : false}
                    > Log In
                    </button>
                  </Form>

                  <div className="flex justify-center items-center my-6">
                    <a
                      href="/admin/forgot-password"
                      className="text-champText400 text-center text-lg hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out   !decoration-2 !underline underline-offset-4 "
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Login;
