import React, { forwardRef, useState } from 'react'
import { Navigate } from 'react-router-dom';
import { DateRangePicker, Modal, Button, ButtonToolbar, Placeholder, DatePicker, Radio, Whisper, Popover } from 'rsuite';


const TimeSheetsDay = () => {

    let item = 3;

    const [isDropdown, setIsDropdown] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

   const data = [1]


    const handleActionClick = (id) => {
    isDropdown === id ? setIsDropdown(false) : setIsDropdown(id)
    }



  return (
    <div className="flex flex-col my-8 space-y-4">
      <div>
        <h3 className="text-black font-semibold text-[20px]">
          Sat, Dec {item}, 2023
        </h3>
      </div>
      <div>
        <hr className="py-1.5 border-0 bg-[#F1F4F8]" />
      </div>
      <div>
        <div className="relative overflow-x-auto sm:rounded-lg">
          <table className="w-full text-left overflow-visible h-full mb-8 table-fixed	">
            <thead className="uppercase  text-blueDarkText text-[18px] border-b">
              <tr>
                <th className="px-6 py-3">Project</th>
                <th className="px-6 py-3">In</th>
                <th className="px-6 py-3">Break</th>
                <th className="px-6 py-3">Out</th>
                <th className="px-6 py-3">Duration</th>
                <th className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b text-black text-base">
                <th scope="row" className="font-medium  px-6 py-4">
                  RegistZ er Practical Nurse
                </th>
                <td className="px-6 py-4">{item}:47 am</td>
                <td className="px-6 py-4">2:00 pm- 3:0 pm</td>
                <td className="px-6 py-4">7:00 pm</td>
                <td className="px-6 py-4">{item}:17:52</td>
                <td className="px-6 py-4 relative">
                  <button
                    className="font-medium border border-[#D7D7D7] px-4 py-2 text-lg inline-flex items-center"
                    onClick={() => handleActionClick(item)}
                  >
                    <span>Action</span>
                    <i
                      className={`fa-solid pl-1 mt-1 text-sm transition ease-in	 duration-200	 ${
                        isDropdown === item
                          ? " fa-angle-up bg-gray"
                          : " fa-angle-down"
                      }`}
                    ></i>
                  </button>
                  <div
                    id={item}
                    className={
                      "dropdown-menu min-w-max absolute bg-white text-base z-50  rounded-sm shadow-md shadow-slate-300 top-15 left-6 border flex flex-col " +
                      (isDropdown === item ? " bg-gray" : " hidden")
                    }
                  >
                    <a
                      className="hover:cursor-pointer text-[18px] text-blueDarkText  py-2 px-6 font-medium hover:bg-grayBg"
                      onClick={handleOpen}
                    >
                      Modify
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Modal open={open} onClose={handleClose}>
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded">
          {/* <!-- Modal header --> */}
          <div className="flex items-start justify-between p-4 border-b border-[#CBDAEE] rounded-t w-full">
            <h3 className="text-xl font-semibold text-center text-black w-full">
              Modify time
            </h3>
            <button
              onClick={handleClose}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* <!-- Modal body --> */}
          <div className="pt-6">
            <div className="flex justify-between items-center py-4 px-8">
              <div className="border-[1px] border-[#C8D3E1] rounded-[2px]">
                <DatePicker format="MM-dd-yyyy"oneTap style={{ width: 128 }} />
              </div>
              <div className="inline-flex items-center  justify-end space-x-8">
                <p>From</p>{" "}
                <div className="border-[1px] border-[#C8D3E1] rounded-[2px]">
                  <DatePicker
                    format="hh:mm:ss aa"
                    style={{ width: 128 }}
                    showMeridian
                    ranges={[]}
                  />
                </div>
                <p>To</p>
                <div className="border-[1px] border-[#C8D3E1] rounded-[2px]">
                  <DatePicker
                    format="hh:mm:ss aa"
                    style={{ width: 128 }}
                    showMeridian
                    ranges={[]}
                  />
                </div>
              </div>
            </div>
            <div className="inline-flex items-center space-x-4 py-4 px-8">
              <Radio value="billable">Billable</Radio>
              <a className="text-lg text-[#0066FF] inline-flex items-center space-x-2">
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none">
                  <path
                    d="M3.414 13.9708L13.556 3.82879L12.142 2.41479L2 12.5568V13.9708H3.414ZM4.243 15.9708H0V11.7278L11.435 0.292787C11.6225 0.105316 11.8768 0 12.142 0C12.4072 0 12.6615 0.105316 12.849 0.292787L15.678 3.12179C15.8655 3.30931 15.9708 3.56362 15.9708 3.82879C15.9708 4.09395 15.8655 4.34826 15.678 4.53579L4.243 15.9708ZM0 18.5H18V19.9708H0V18.5Z"
                    fill="#0066FF"
                  />
                </svg>
                <span>Add a reason</span>
              </a>
            </div>
          </div>
          {/* <!-- Modal Footer --> */}
          <div className="pt-4 pb-4">
            <div className="flex justify-center w-1/2 mx-auto space-x-4 h-11 my-6 font-medium">
              <button
                onClick={handleClose}
                className="w-1/2 text-black rounded border border-black text-center text-base"
              >
                Cancel
              </button>
              <button className="w-1/2 rounded bg-blueBtnDark text-center text-white text-base">
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TimeSheetsDay