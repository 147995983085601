import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PrivateHome from "./PrivateHome";
import RetirementResidence from "./RetirementResidence";
import axios from "axios";
import { API_BASE_URL } from "../../../../config/apiKeys";
import { Loader, Message, Placeholder, useToaster } from "rsuite";
import { ChampHealthApiClient } from "../../../../config/apiClients";
import { USER_DETAIL, setCookie } from "../../../../config/headerToken";

const ClientUpdateClient = () => {
  const navigate = useNavigate();
  const toaster = useToaster();
  const [formType, setFormType] = useState(false);

  const { clientId } = useParams();

  const [clientDetails, setClientDetails] = useState(false);



  const getUserDetail = async () => {
    try {
        const response = await ChampHealthApiClient.get(`/clients/client-name`);

        if (response?.status === 200) {
            window.location ='/client/profile'
            let data = response?.data;
            console.log(data);
            setCookie("token", USER_DETAIL?.token, 3);
            setCookie("user", JSON.stringify({
                ...USER_DETAIL,
                ...data
            }), 3)
        } else {
            const message = (
                <Message showIcon type="error"  closable>
                    {response?.response?.message}
                </Message>
            );
            toaster.push(message, { placement: "topEnd", duration: 3000 });
        }
    } catch (error) {
        console.log("Create_job_getSingleLiveJobDetails_Error...", error);
    }

}


  const getClientDetails = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/admin/clients/details/${clientId}`)


      if (response.status === 200) {
        setClientDetails(response?.data?.data[0]);
        setFormType(response?.data?.data[0].type);
      } else {
        const message = (
          <Message showIcon type="error"  closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error"  closable>
          {error?.response?.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
    }
  };


  useEffect(() => {
    getClientDetails();
  }, []);

  return (
    <main>
      <section className="py-8 bg-[#F1F4F8]">
        <div className="container mx-auto md:flex md:justify-between md:gap-2 lg:flex lg:justify-between w-full ">
          {/* <!-- Client List Search bar --> */}
          <div
            className="inline-flex items-center gap-2 font-[600] hover:cursor-pointer text-black"
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left-long text-2xl"></i>
            <h1 className="text-[30px] whitespace-nowrap">Edit Profile</h1>
          </div>
        </div>
      </section>

      {/* <!-- Create Client Form --> */}
      {formType ? (<section className="py-8 bg-white">
        <div className="container">
          {/* <!-- type of form selection --> */}
          <div className="flex flex-col space-y-2 text-sm">
            <p>Client Type:</p>
            <div>
              <div className="flex">
                <div className="mb-3 xl:w-96">
                  <select
                    className="form-select w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border  border border-champBorder rounded"
                    onChange={(event) => setFormType(event.target.value)}
                    value={formType}
                  >
                    <option>Select Client Type</option>
                    <option value={1}>
                      Retirement Residence
                    </option>
                    <option value={2}>
                      Private Home
                    </option>
                  </select>
                </div>
              </div>
            </div>

          </div>

          {/* <!-- type of form component --> */}

          {formType == 1 ? (
            <RetirementResidence clientDetails={clientDetails} />
          ) : (
            <>
              {formType == 2 ? (
                <PrivateHome clientDetails={clientDetails} />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </section>) : (
        <>
          <div className="container relative">
            <div
              className="absolute left-0 right-0 m-auto my-auto top-12 "
              style={{ textAlign: "center" }}
            >
              <Loader size="lg" center content="loading" />
            </div>
            <Placeholder.Paragraph
              style={{ marginTop: 30 }}
              rows={5}
              active
            />
          </div>

        </>
      )}
    </main>
  );
};

export default ClientUpdateClient;
