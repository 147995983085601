import React, { useEffect, useRef, useState } from "react";
import { Form, Loader, Message, Placeholder, Schema, toaster } from "rsuite";
import { ChampHealthApiClient } from "../../../config/apiClients";
import TextField from "../../../Components/TextField";
import { ExtractDaysOfWeek, getTimeOfDaysAsString } from "../../../Components/AvailbilityValues";

function CandidateAccountDetails({ profileDetails }) {
  const { StringType } = Schema.Types;
  const passwordModal = Schema.Model({
    password: StringType()
      .pattern(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,20}$/,
        "please enter 8 characters , smallcase, uppercase & symbols(!@#$%^&*)"
      )
      .isRequired("This field is required."),
    // oldpassword: StringType().isRequired("This field is required."),
    verifyPassword: StringType()
      .addRule((value, data) => {
        if (value !== data.password) {
          return false;
        }

        return true;
      }, "The two passwords do not match")
      .isRequired("This field is required."),
  });
  const [isChangePassword, setIsChangePassword] = useState(false);
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    password: "",
    verifyPassword: "",
  });

  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);

  const changePassword = async () => {
    try {
      const response = await ChampHealthApiClient.post(
        `/jobseekers/change-password/`,
        {
          password: formValue.verifyPassword,
          c_password: formValue.password,
        }
      );

      if (response.status === 200) {
        setIsChangePassword(!isChangePassword);
        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 4000 });
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 4000 });
      }
      setIsPasswordUpdating(!isPasswordUpdating);
    } catch (error) {
      setIsPasswordUpdating(!isPasswordUpdating);
      const message = (
        <Message showIcon type="error" closable>
          {`${error?.response?.data.message
              ? error?.response?.data.message
              : error.message
            }`}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 4000 });
    }
  };

  const handleSubmit = () => {
    if (formRef.current.check()) {
      setIsPasswordUpdating(true);
      changePassword();
      return;
    }
  };

  return (
    <>
      {profileDetails ? (
        <>
          <section className="w-full flex flex-col gap-6">
            <div className="flex items-start gap-6 w-full">
              <div className="w-[230px] whitespace-nowrap">
                <h3 className="text-xl font-medium text-[#7D7D7D]">Name: </h3>
              </div>
              <div className="w-full">
                <h3 className="text-lg font-medium text-black">
                  {profileDetails?.firstname + " " + profileDetails?.lastname}
                </h3>
                <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
              </div>
            </div>
            <div className="flex items-start gap-6 w-full">
              <div className="w-[230px] whitespace-nowrap">
                <h3 className="text-xl font-medium text-[#7D7D7D]">
                  Phone number:{" "}
                </h3>
              </div>
              <div className="w-full">
                <h3 className="text-lg font-medium text-black">
                  <a href={"tel:" + profileDetails?.phone}>
                    {profileDetails?.phone}
                  </a>
                </h3>
                <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
              </div>
            </div>
            <div className="flex items-start gap-6 w-full">
              <div className="w-[230px]  whitespace-nowrap">
                <h3 className="text-xl font-medium text-[#7D7D7D]">Email : </h3>
              </div>
              <div className="w-full">
                <h3 className="text-lg font-medium text-black">
                  {profileDetails?.email_id}
                </h3>
                <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
              </div>
            </div>
            <div className="flex items-start gap-6 w-full">
              <div className="w-[230px]  whitespace-nowrap">
                <h3 className="text-xl font-medium text-[#7D7D7D]">
                  Address:{" "}
                </h3>
              </div>
              <div className="w-full">
                <h3 className="text-lg font-medium text-black">
                  {profileDetails?.address}, {profileDetails?.city},{" "}
                  {profileDetails?.province}, {profileDetails?.postal_code}
                </h3>
                <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
              </div>
            </div>
            <div className="flex items-start gap-6 w-full">
              <div className="w-[230px]  whitespace-nowrap">
                <h3 className="text-xl font-medium text-[#7D7D7D]">
                  Job Type:{" "}
                </h3>
              </div>
              <div className="w-full">
                <h3 className="text-lg font-medium text-black">
                  {profileDetails?.job_type_name}
                </h3>
                <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
              </div>
            </div>
            <div className="flex items-start gap-6 w-full">
              <div className="w-[230px]  whitespace-nowrap">
                <h3 className="text-xl font-medium text-[#7D7D7D]">
                  Job Position:{" "}
                </h3>
              </div>
              <div className="w-full">
                <h3 className="text-lg font-medium text-black">
                  {profileDetails?.job_position_name}
                </h3>
                <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
              </div>
            </div>
            <div className="flex items-start gap-6 w-full">
              <div className="w-[230px]  whitespace-nowrap">
                <h3 className="text-xl font-medium text-[#7D7D7D]">
                  Job Shift:{" "}
                </h3>
              </div>
              <div className="w-full">
                <div className="text-lg font-medium text-black">
                  {Object.entries(ExtractDaysOfWeek(profileDetails)).map(
                    ([key, value]) => (
                      <p>
                        <span>{key}</span> : <span className="text-base font-normal">{getTimeOfDaysAsString(value)}</span>
                      </p>
                    )
                  )}
                </div>
                <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
              </div>
            </div>
            <div id="changePassword" className="flex items-start gap-6 w-full">
              <div className="w-[230px] whitespace-nowrap">
                <h3 className="text-xl font-medium text-[#7D7D7D]">
                  Change password:{" "}
                </h3>
              </div>
              <div className="w-full">
                {isChangePassword ? (
                  <>
                    {isPasswordUpdating ? (
                      <>
                        <div className="flex justify-between items-center mx-2 border-blueBtnDark w-full">
                          <Loader speed="fast" content="Processing...." />
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col  space-y-8 justify-center w-full items-center">
                        <div className="w-full">
                          <Form
                            fluid
                            ref={formRef}
                            onChange={setFormValue}
                            onCheck={setFormError}
                            formValue={formValue}
                            model={passwordModal}
                            className="w-full flex flex-col gap-4"
                          >
                            {/* <TextField
                          name="oldpassword"
                          type="password"
                          placeholder="Old Password"
                          autoComplete="off"
                        /> */}
                            <TextField
                              name="password"
                              type="password"
                              placeholder="New Password"
                              autoComplete="off"
                            />
                            <TextField
                              name="verifyPassword"
                              type="password"
                              placeholder="Confirm New Password"
                            />
                          </Form>
                        </div>
                        <div className="flex gap-4">
                          <button
                            className="px-4 text-center m-auto border-2 py-2 rounded-[5px] text-lg font-semibold  text-black border-blueBtnDark"
                            onClick={() =>
                              setIsChangePassword(!isChangePassword)
                            }
                          >
                            {" "}
                            Cancel
                          </button>
                          <button
                            className="px-4 text-center m-auto border-2 py-2 rounded-[5px] text-lg font-normal bg-blueBtnDark text-white border-blueBtnDark"
                            onClick={handleSubmit}
                          >
                            {" "}
                            Update
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="flex justify-between flex-wrap">
                      <h3 className="text-xl font-medium ">*********</h3>
                      <a
                        onClick={() => setIsChangePassword(!isChangePassword)}
                        href="#changePassword"
                        className="text-[#0066FF] text-lg font-semibold decoration-solid	!underline"
                      >
                        Edit
                      </a>
                    </div>
                  </>
                )}
                <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <div className="relative">
            <div
              className="absolute left-0 right-0 m-auto my-auto top-12 "
              style={{ textAlign: "center" }}
            >
              <Loader size="md" />
            </div>
            <Placeholder.Paragraph style={{ marginTop: 30 }} rows={5} active />
          </div>
        </>
      )}
    </>
  );
}

export default CandidateAccountDetails;
