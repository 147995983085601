import axios from 'axios';
import { BEARER_TOKEN } from './headerToken';

export const ChampHealthApiClient = axios.create({
	// baseURL: 'http://192.168.0.21:3002/api',
	baseURL: 'https://champdev.webwatt.com/api',
	timeout: 100000,
	headers: {
		Accept: 'application/json',
        Authorization: `Bearer ${BEARER_TOKEN}`
    },
	},
);