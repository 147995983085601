import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Loader,
  Modal,
  Placeholder,
  Message,
  toaster,
  SelectPicker,
  DateRangePicker,
  Form,
} from "rsuite";

import { readableDate } from "../../../Components/readableDate";
import { useNavigate } from "react-router-dom";
import { ChampHealthApiClient } from "../../../config/apiClients";
import { readableTime } from "../../../Components/readableTime";

import TextField from "../../../Components/TextField";
import CustomInputWithButton from "../../../Components/CustomInputWithButton";
import CustomPagination from "../../../Components/CustomPagination";
import JobDetailModal from "../../../Components/DetailModal/JobDetailModal";
import { FormatedDate } from "../../../Components/dateTime/FormatedDateTime";
import { ObjectDate } from "../../../Components/objectDate";

function LiveJob() {
  const navigate = useNavigate();

  const [jobList, setJobList] = useState(false);
  const [jobCount, setJobCount] = useState(false);

  const getAllLiveJobs = async (start = 0, end = 6) => {
    try {
      const response = await ChampHealthApiClient.get(
        `/jobseekers/applied-jobs-list/${start}/${end}?name=${filter?.searchInput || ""
        }&client_type=${filter?.clientType || ""}&startDate=${filter?.dateRange ? FormatedDate(filter?.dateRange[0]) : ""
        }&endDate=${filter?.dateRange ? FormatedDate(filter?.dateRange[1]) : ""
        }&job_shift=${filter.jobShift || ""}`
      );

      if (response?.status === 200) {
        setJobCount(response?.data?.count);
        setJobList(response?.data?.data);
        setOriginalJobList(response?.data?.data);
      }
    } catch (error) {
      setJobCount(0);
      setJobList(false);
      const message = (
        <Message showIcon type="error" closable>
          {error?.response?.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
      console.log("JobSeeker_getAllLiveJobs_response_Error...", error);
    }
  };

  const renderButtonIcon = (props, ref) => {
    return (
      <>
        <i
          {...props}
          ref={ref}
          className="fa-light fa-star text-xl hover:text-fa-solid text-champBorderDark fa-regular fa-star"
        ></i>
      </>
    );
  };

  // card creator function
  const createCard = (job) => {
    return (
      <div className="hover:shadow-lg shadow-slate-900	 card rounded bg-white border-champBorder border shadow-clientCard p-4 relative">
        <div className="hidden inline-flex items-center gap-4 absolute right-4">
          <Dropdown
            renderToggle={renderButtonIcon}
            onClick={() => {
              saveUnsaveJob(job.id, 1);
            }}
            placement="bottomEnd"
            trigger="hover"
          >
            <h5 className="bg-white p-2 w-24 text-center hover:cursor-pointer text-sm font-semibold hover:bg-grayBg">
              Save Job
            </h5>
          </Dropdown>
        </div>
        <div
          className="hover:cursor-pointer h-full  flex flex-col justify-between gap-2.5"
          onClick={() => handleOpen(job.id)}
        >
          <div className="inline-flex justify-between items-start">
            <p className="font-semibold text-2xl">{job.jp_position} <span className="bg-gray-300 px-1 ml-1 rounded">#{job?.id} </span></p>
            {job?.status === 10 ? <p className="font-semibold text-sm text-red-500 bg-orange-100 px-1 rounded">Canceled</p> : ''}

          </div>

          <p className="font-medium text-base">{job.name} </p>
          <p className="font-medium text-base">
            <i className="fa-solid fa-location-dot pr-2"></i>
            {job.address}
          </p>
          <div className=" inline-flex items-center text-center gap-2 text-sm font-medium">
            <span className="p-2 flex items-center h-30 rounded-full bg-champOnBtn text-champOnText text-center">
              {job.job_shift}
            </span>
          </div>

          {/* <p className="font-normal text-sm text-champText400">
            <p>
              <span>
                <span className="font-semibold">Date:  </span>
                <span className="text-[#7D7D7D]">
                  {readableDate(job?.job_date)}</span>
              </span>
              <br />
              <span>
                <span className="font-semibold">Time:  </span>
                <span className="text-[#7D7D7D]">
                  {readableTime(JSON.parse(job?.schedule)[0]) +
                    " - " +
                    readableTime(JSON.parse(job?.schedule)[1])}</span>
              </span>
            </p>
          </p> */}

          <div className="font-normal text-sm text-champText400">
          <p
              // onClick={(e) => {
              //   e.stopPropagation();
              //   handlescheduleTimeModalOpen();
              //   setscheduleTimeModalContent(job);
              // }}
              className="flex items-center gap-4 font-normal text-sm text-champText400 my-2"
            >
              <p>
                <span>
                  <span className="font-semibold">Date: </span>
                  {job?.start_job_date && (
                    <>
                      <span className="text-[#7D7D7D]">
                        {readableDate(job?.start_job_date)}
                      </span>{" "}
                      {" - "}
                    </>
                  )}

                  <span className="text-[#7D7D7D]">
                    {readableDate(job?.job_date)}
                  </span>
                </span>
                <br />
                <span>
                  <span className="font-semibold">Time: </span>
                  <span className="text-[#7D7D7D]">
                    {readableTime(JSON.parse(job?.schedule)[0]) +
                      " - " +
                      readableTime(JSON.parse(job?.schedule)[1])}
                  </span>
                </span>
              </p>
              {/* {generateDateHTML(
                job?.job_date,
                job?.start_job_date,
                job?.schedule
              )} */}
            </p>


            {/* <p
              onClick={(e) => {
                e.stopPropagation();
                handlescheduleTimeModalOpen();
                setscheduleTimeModalContent(job);
              }}
              className="flex items-center gap-4 font-normal text-sm text-champText400 my-2"
            >
              {generateDateHTML(
                job?.job_date,
                job?.start_job_date,
                job?.schedule
              )}
            </p> */}
          </div>

          <div className="hidden font-normal text-sm text-champText400">
            <span>Created At: </span>
            <p>
              <span>
                <span className="font-semibold">Date: </span>
                <span className="text-[#7D7D7D]">
                  {readableDate(job?.createdAt)}
                </span>
              </span>
              <br />
              <span>
                <span className="font-semibold">Time: </span>
                <span className="text-[#7D7D7D]">
                  {readableTime(job?.createdAt)}
                </span>
              </span>
            </p>
          </div>

          <p className="font-normal text-sm text-champText400">
            <span>Created At : </span>
            <p>
              <span>
                <span className="font-semibold">Date: </span>
                <span className="text-[#7D7D7D]">
                  {readableDate(job?.createdAt)}
                </span>
              </span>
              <br />
              <span>
                <span className="font-semibold">Time: </span>
                <span className="text-[#7D7D7D]">
                  {readableTime(job?.createdAt)}
                </span>
              </span>
            </p>
          </p>
        </div>
      </div>
    );
  };

  const saveUnsaveJob = async (id, status) => {
    try {
      const response = await ChampHealthApiClient.post(
        `/jobseekers/saved-jobs/`,
        {
          job_id: id,
          status: status,
        }
      );
      if (response?.status === 200) {
        const message = (
          <Message showIcon type="success" header="Success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        getAllLiveJobs();
      } else {
        const message = (
          <Message showIcon type="success" header="Success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error?.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 5000 });
      console.log("Create_job_getSingleLiveJobDetails_Error...", error);
    }
  };

  const [modalJobDetails, setModalJobDetails] = useState(false);

  const [open, setOpen] = useState(false);
  const [jobId, setJobId] = useState(false);
  const handleOpen = (id) => {
    setJobId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setJobId(false);
    setOpen(false);
  };

  const [originalJobList, setOriginalJobList] = useState(false);

  const getSingleLiveJobDetails = async (id) => {
    try {
      const response = await ChampHealthApiClient.get(
        `/jobseekers/details/${id}`
      );
      console.log(response);
      if (response?.status === 200) {
        setModalJobDetails(response?.data?.data[0]);
      }
    } catch (error) {
      console.log("Create_job_getSingleLiveJobDetails_Error...", error);
    }
  };

  const [clientTypes, setClientTypes] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [jobtype, setJobtype] = useState([]);

  const fetchClientTypes = async () => {
    try {
      const response = await ChampHealthApiClient.get("common/client-types");
      if (response.status === 200) {
        setClientTypes(
          response?.data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.data?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error?.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });
    }
  };

  // availability time
  const getAvailability = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/common/job-shifts`);

      if (response?.status === 200) {
        setAvailability(
          response?.data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.log(
        "candidate_register_getAvailability_response_Error...",
        error
      );
    }
  };

  // employmenttype
  const getJobtype = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/common/job-types`);

      if (response?.status === 200) {
        setJobtype(
          response?.data?.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.log(
        "candidate_register_getEmploymenttype_response_Error...",
        error
      );
    }
  };

  useEffect(() => {
    fetchClientTypes();
    getAvailability();
    getJobtype();
  }, []);

  const [filter, setFilter] = useState({
    clientType: "",
    dateRange: "",
    searchInput: "",
    jobShift: "",
  });

  useEffect(() => {
    getAllLiveJobs();
  }, [filter]);

  const [scheduleTimeModal, setscheduleTimeModal] = useState(false);
  const [scheduleTimeModalContent, setscheduleTimeModalContent] = useState("");
  const handlescheduleTimeModalOpen = () => setscheduleTimeModal(true);
  const handlescheduleTimeModalClose = () => {
    setscheduleTimeModal(false);
    setscheduleTimeModalContent("");
  };

  function generateDateHTML(
    jobDate,
    startJobDate,
    scheduleJSON,
    maxDatesToShow = 2
  ) {
    // Define or import ObjectDate and readableDate functions
    // Define or import readableTime function
    const endDate = ObjectDate(jobDate);
    const schedule = JSON.parse(scheduleJSON); // Parse the JSON schedule here

    const datesToShow = [];

    if (startJobDate) {
      for (
        let currentDate = new Date(startJobDate);
        currentDate <= endDate;
        currentDate.setDate(currentDate.getDate() + 1)
      ) {
        datesToShow.push(new Date(currentDate)); // Push a new Date object
      }
    } else {
      datesToShow.push(new Date(endDate)); // Push a new Date object
    }

    const dateHTMLs = [];
    const numDatesToShow =
      maxDatesToShow === 0
        ? datesToShow.length
        : Math.min(datesToShow.length, maxDatesToShow);

    for (let i = 0; i < numDatesToShow; i++) {
      const currentDate = datesToShow[i];
      const formattedDate = readableDate(currentDate);
      const startTime = readableTime(schedule[0]);
      const endTime = readableTime(schedule[1]);

      // Use backticks for JSX-like syntax
      const html = (
        <div key={formattedDate}>
          <span>
            <span className="font-semibold">Date: </span>
            <span className="text-[#7D7D7D]">{formattedDate}</span>
          </span>
          <br />
          <span>
            <span className="font-semibold">Time: </span>
            <span className="text-[#7D7D7D]">
              {startTime} - {endTime}
            </span>
          </span>
        </div>
      );

      dateHTMLs.push(html);
    }

    if (datesToShow.length > maxDatesToShow && maxDatesToShow !== 0) {
      // Add a "Read more" link with ellipsis
      dateHTMLs.push(
        <p key="readmore">
          <span className="text-[#7D7D7D]">
            <i className="fa-solid fa-angles-right text-xl"></i>
          </span>
        </p>
      );
    }

    return dateHTMLs;
  }

  function generateDateHTMLModal(jobDate, startJobDate, scheduleJSON) {
    const endDate = new Date(jobDate);
    const schedule = JSON.parse(scheduleJSON); // Parse the JSON schedule here
    if (startJobDate) {
      const startDate = startJobDate;
      const dateHTMLs = [];

      for (
        let currentDate = new Date(startDate);
        currentDate <= endDate;
        currentDate.setDate(currentDate.getDate() + 1)
      ) {
        const formattedDate = readableDate(currentDate);
        const startTime = readableTime(schedule[0]);
        const endTime = readableTime(schedule[1]);

        const html = (
          <p key={formattedDate}>
            <span>
              <span className="font-semibold">Date: </span>
              <span className="text-[#7D7D7D]">{formattedDate}</span>
            </span>
            <br />
            <span>
              <span className="font-semibold">Time: </span>
              <span className="text-[#7D7D7D]">
                {startTime} - {endTime}
              </span>
            </span>
          </p>
        );

        dateHTMLs.push(html);
      }
      return dateHTMLs;
    } else {
      const formattedDate = readableDate(endDate);
      const startTime = readableTime(schedule[0]);
      const endTime = readableTime(schedule[1]);

      const html = (
        <p>
          <span>
            <span className="font-semibold">Date: </span>
            <span className="text-[#7D7D7D]">{formattedDate}</span>
          </span>
          <br />
          <span>
            <span className="font-semibold">Time: </span>
            <span className="text-[#7D7D7D]">
              {startTime} - {endTime}
            </span>
          </span>
        </p>
      );

      return html;
    }
  }

  return (
    <>
      <div className="  bg-[#F1F4F8]">
        {/* <!------ Search and filter options ----> */}
        <div className="container md:flex md:gap-6  items-start lg:items-center md:space-x-2 justify-between w-full py-3">
          {/* <!-- job List Search bar --> */}
          <div className="lg:w-1/2 lg:flex lg:items-center lg:justify-between lg:space-x-4  xl:justify-between my-2 ml-[4px]">
            <h1 className="font-semibold text-2xl text-black whitespace-nowrap my-2 md:my-0">
              Applied Jobs
            </h1>

            <div className="flex justify-center items-center h-11 w-full xl:w-2/3 ">
              <CustomInputWithButton
                onChange={(i) =>
                  setFilter((prevState) => ({ ...prevState, searchInput: i }))
                }
                name="search"
                placeholder="Search...."
                className="border-2 border-blueBtnDark py-2 w-full  px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded "
              />
            </div>
          </div>
          {/* <!-- Filters --> */}
          <Form className="lg:w-1/2 my-2 lg:flex lg:items-center lg:gap-2 lg:justify-end">
            <h3 className="text-xl font-medium text-black">Filter</h3>
            <div className="grid lg:grid-cols-3 grid-cols-3  gap-4 items-center w-full">
              <TextField
                name="date"
                searchable={false}
                accepter={DateRangePicker}
                format="MM-dd-yyyy"
                onChange={(i) =>
                  setFilter((prevState) => ({ ...prevState, dateRange: i }))
                }
                showOneCalendar
                ranges={[]}
                placeholder="Date"
                className="border-2 border-blueBtnDark py-2 w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
              />

              <TextField
                name="client"
                searchable={false}
                data={clientTypes}
                accepter={SelectPicker}
                onChange={(i) =>
                  setFilter((prevState) => ({ ...prevState, clientType: i }))
                }
                placeholder="Client type"
                className="border-2 border-blueBtnDark py-2 w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
              />
              <TextField
                name="jobShift"
                searchable={false}
                data={availability}
                accepter={SelectPicker}
                onChange={(i) =>
                  setFilter((prevState) => ({ ...prevState, jobShift: i }))
                }
                placeholder="Job Shift"
                className="border-2 border-blueBtnDark py-2 w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
              />

              <button className="hidden border border-champBorder bg-blueBtn text-white rounded h-[52px]">
                Apply
              </button>
            </div>
          </Form>
        </div>
      </div>

      <div className="container flex flex-col my-12 gap-4">
        {/* <!-- No. of search result and Create client Button --> */}
        {jobList ? (
          <>
            {!jobCount ? (
              <>
                <div className="h-[300px] w-full flex justify-center items-center ">
                  <h2 className="text-center text-3xl font-semibold">
                    No Applied Job
                  </h2>
                </div>
              </>
            ) : (
              <>
                <div className="inline-flex justify-start space-x-4 items-center w-full">
                  <p className="w-40 text-champText500 font-medium text-base inline-flex items-center">
                    <span>Results showing {jobCount && jobCount}</span>
                  </p>
                </div>
                {/* <!-- Cards Started--> */}
                <div className="grid grid-cols-fluid gap-6">
                  {/* <!-- single cards --> */}
                  {jobCount === "0" ? (
                    "NO Job to List"
                  ) : (
                    <>
                      {jobList &&
                        jobList.map((item) => <>{createCard(item)}</>)}
                    </>
                  )}
                </div>
                <CustomPagination
                  count={jobCount}
                  clickFuntion={getAllLiveJobs}
                />
              </>
            )}
          </>
        ) : (
          <>
            <div className="relative">
              <div
                className="absolute left-0 right-0 m-auto my-auto top-12 "
                style={{ textAlign: "center" }}
              >
                <Loader size="md" />
              </div>
              <Placeholder.Paragraph
                style={{ marginTop: 30 }}
                rows={5}
                active
              />
            </div>
          </>
        )}
        {/* <!-- Cards ended / --> */}
      </div>

      <JobDetailModal id={jobId} open={open} onClose={handleClose} />

      {/* job details modal */}
      <Modal open={false} onClose={handleClose}>
        {modalJobDetails ? (
          <>
            <div className="relative bg-white rounded shadow ">
              {/* <!-- Modal header --> */}
              <div className="inline-flex w-full p-4 px-6 justify-end border-b rounded-t bg-blueBgDark">
                <h3 className="w-full text-xl font-semibold text-white uppercase">
                  {modalJobDetails?.jp_position}
                </h3>
                <button
                  onClick={() => {
                    handleClose();
                  }}
                  type="button"
                  className="w-8 text-gray-400 bg-transparent hover:bg-blue-500 hover:text-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              {/* <!-- Modal body --> */}
              <div className="">
                <div className="job-seeker-details flex flex-col items-start space-y-6 p-6 ">
                  <div className="flex flex-col">
                    <p className="ml-0 pl-0 text-3xl font-medium text-black">
                      {modalJobDetails?.name}
                    </p>
                    <p className="text-[12px] text-champText400">
                      <span>{readableDate(modalJobDetails?.createdAt)}</span>
                    </p>
                  </div>
                </div>

                <div className="p-6 flex flex-col gap-8 border-t-4 border-gray-200 rounded-b">
                  <div className="flex flex-col gap-4">
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-location-dot pr-2"></i>
                      {modalJobDetails?.address}
                    </p>
                  </div>

                  <div className="flex flex-col gap-4">
                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">
                        Employment Type:
                      </span>
                      <span className="text-base text-black">
                        {" "}
                        {modalJobDetails?.job_type}
                      </span>
                    </p>
                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">Shift:</span>
                      <span className="text-base text-champText400">
                        {" "}
                        {modalJobDetails?.job_shift}
                      </span>
                    </p>
                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">Schedule:</span>
                      <p>
                        <span>
                          <span className="font-semibold">Date: </span>
                          <span className="text-[#7D7D7D]">
                            {modalJobDetails &&
                              generateDateHTMLModal(
                                modalJobDetails?.job_date,
                                modalJobDetails?.start_job_date,
                                modalJobDetails?.schedule
                              )}

                            {readableDate(modalJobDetails?.createdAt)}
                          </span>
                        </span>
                        <br />
                        <span>
                          <span className="font-semibold">Time: </span>
                          <span className="text-[#7D7D7D]">
                            {readableTime(modalJobDetails?.schedule[0])}
                            {" - "}
                            {readableTime(modalJobDetails?.schedule[1])}
                          </span>
                        </span>
                      </p>
                    </p>
                    <div className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">
                        Job description:
                      </span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: modalJobDetails?.job_description,
                        }}
                      />
                    </div>

                    <div className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">
                        Job description:
                        Job description:
                      </span>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="relative">
              <div
                className="absolute left-0 right-0 m-auto my-auto top-12 "
                style={{ textAlign: "center" }}
              >
                <Loader size="md" />
              </div>
              <Placeholder.Paragraph
                style={{ marginTop: 30 }}
                rows={5}
                active
              />
            </div>
          </>
        )}
      </Modal>

      <Modal open={scheduleTimeModal} onClose={handlescheduleTimeModalClose}>
        {/* <!-- Modal content --> */}

        <div className="relative bg-white rounded mt-60">
          {/* <!-- Modal header --> */}
          <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Schedule Time
            </h3>
            <button
              onClick={handlescheduleTimeModalClose}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* <!-- Modal body --> */}
          <div className="pt-2">
            <div className="form-group pl-6 pb-4 flex flex-col gap-4">
              {scheduleTimeModalContent &&
                generateDateHTMLModal(
                  scheduleTimeModalContent?.job_date,
                  scheduleTimeModalContent?.start_job_date,
                  scheduleTimeModalContent?.schedule
                )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LiveJob;
