import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import NavigationBar from './adminPanel/Components/NavigationBar';
import ClientList from './adminPanel/Pages/Clients/ListClients';
import CreateClient from './adminPanel/Pages/Clients/CreateClient';
import AdminClientDetails from './adminPanel/Pages/Clients/ClientDetails';
import CreateInvoice from './adminPanel/Pages/Clients/Invoices/CreateInvoice';
import AdminInvoiceDetails from './adminPanel/Pages/Clients/Invoices/InvoiceDetails';
import AdminListInvoices from './adminPanel/Pages/Clients/Invoices/ListInvoices';
import StaffList from './adminPanel/Pages/StaffList';
import JobList from './adminPanel/Pages/Jobs/JobList';


import Login from './adminPanel/Pages/Authentication/Login';
import AdminForgotPassword from './adminPanel/Pages/Authentication/PasswordForgot';
import AdminPasswordResetForm from './adminPanel/Pages/Authentication/PasswordResetForm';
import AdminPasswordResetSuccessMessage from './adminPanel/Pages/Authentication/PasswordResetSuccessMessage';


import JobSeeker from './adminPanel/Pages/JobSeeker/JobSeeker';
import CreateJob from './adminPanel/Pages/Jobs/CreateJob';

import TimeSheets from './adminPanel/Pages/TimeSheets/index';
import Scheduling from './adminPanel/Pages/Scheduling/';
import SchedulingForm from './adminPanel/Pages/Scheduling/SchedulingForm';
import HomePageLogin from './Pages';



import CandidateSignupFormType from './candidatPanel/Pages/SignupForm';
import CandidateForgotPassword from './candidatPanel/Pages/Authentication/PasswordForgot';
import CandidatePasswordResetForm from './candidatPanel/Pages/Authentication/PasswordResetForm';
import CandidateResumeForm from './candidatPanel/Pages/SignupForm/ResumeForm';
import TimeSheetsCandidate from './candidatPanel/Pages/TimeSheets';
import CandidateNavigationBar from './candidatPanel/Components/CandidateNavigationBar';
import Unauthorized from './Components/401';
import NotFound from './Components/404';
import Archive from './adminPanel/Pages/DashBoard/Archive';
import CandidateJobPage from './candidatPanel/Pages/Jobs';
import CandidateProfile from './candidatPanel/Pages/Profile';
import CandidateJobDetails from './candidatPanel/Pages/Jobs/JobDetails';
import CandidateJobApplyPage from './candidatPanel/Pages/Jobs/jobApply';
import AdminJobApplication from './adminPanel/Pages/Jobs/jobApplications';
import ClientDetailForm from './clientPanel/Pages/SignupForm/ClientDetailForm';
import ClientSignupFormType from './clientPanel/Pages/SignupForm';
import ClientNavigationBar from './clientPanel/Components/ClientNavigationBar';
import ClientJobList from './clientPanel/Pages/JobList';
import AdminUpdateClient from './adminPanel/Pages/Clients/UpdateClient';
import UpdateJob from './adminPanel/Pages/Jobs/UpdateJob';
import ClientCreateJob from './clientPanel/Pages/Jobs/ClientCreateJob';
import ClientUpdateJob from './clientPanel/Pages/Jobs/ClientUpdateJob';
import ClientSelectedCandidate from './clientPanel/Pages/JobList/SelectedCandidate';
import ClientPasswordResetForm from './clientPanel/Pages/Authentication/PasswordResetForm';
import ClientForgotPassword from './clientPanel/Pages/Authentication/PasswordForgot';
import ClientProfile from './clientPanel/Pages/Profile'
import EditJobSeeker from './adminPanel/Pages/JobSeeker/editJobSeeker';
import UpdateDetails from './candidatPanel/Pages/Profile/UpdateDetails';
import RegisterSuccess from './Pages/RegisterSuccess';
import ClientUpdateClient from './clientPanel/Pages/Profile/UpdateClient';
import AdminProfile from './adminPanel/Pages/DashBoard/Profile';
import CreateJobThankYouPage from './clientPanel/Pages/Jobs/CreateJobThankYouPage';
import ClientJobApplication from './clientPanel/Pages/jobApplications';
import AcceptRejectJob from './candidatPanel/Pages/Jobs/AcceptRejectJob';
import DeleteCookies from './Pages/deleteCookies';
import ClientArchiveList from './clientPanel/Pages/DashBoard/Archive';
import ClientArchiveJobs from './clientPanel/Pages/DashBoard/Archive/jobs';
import ShortListed from './clientPanel/Pages/jobApplications/ShortListed';



function App() {
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const isLoggedIn = getCookie("token");
  // const isLoggedIn = true;

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/admin' element={<><Login /></>} />
          <Route path='/clean-cookies' element={<><DeleteCookies /></>} />
          <Route path='/admin/forgot-password' element={<><AdminForgotPassword /></>} />
          <Route path='/admin/password/reset/:token' element={<><AdminPasswordResetForm /></>} />
          <Route path='/admin/password/reset/success' element={<><AdminPasswordResetSuccessMessage /></>} />

          <Route path='/admin/list-client' element={<>{isLoggedIn ? (<><NavigationBar /> <ClientList /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/list-invoices' element={<>{isLoggedIn ? (<><NavigationBar /> <AdminListInvoices /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/create-invoice' element={<>{isLoggedIn ? (<><NavigationBar /> <CreateInvoice /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/invoice-details' element={<>{isLoggedIn ? (<><NavigationBar /> <AdminInvoiceDetails /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/create-client' element={<>{isLoggedIn ? (<><NavigationBar /> <CreateClient /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/client-details/:clientId' element={<>{isLoggedIn ? (<><NavigationBar /> <AdminClientDetails /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/update-client/:clientId' element={<>{isLoggedIn ? (<><NavigationBar /> <AdminUpdateClient /></>) : (<Unauthorized />)}</>} />

          <Route path='/admin/archive' element={<>{isLoggedIn ? (<><NavigationBar /> <Archive /></>) : (<Unauthorized />)}</>} />


          <Route path='/admin/list-staff' element={<>{isLoggedIn ? (<><NavigationBar /> <StaffList /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/list-jobs' element={<>{isLoggedIn ? (<><NavigationBar /> <JobList /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/job-seeker' element={<>{isLoggedIn ? (<><NavigationBar /> <JobSeeker /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/job-seeker/edit/:jobSeekerId' element={<>{isLoggedIn ? (<><NavigationBar /> <EditJobSeeker /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/job-applications/:jobId' element={<>{isLoggedIn ? (<><NavigationBar /> <AdminJobApplication /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/create-job' element={<>{isLoggedIn ? (<><NavigationBar /> <CreateJob /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/update-job/:jobId' element={<>{isLoggedIn ? (<><NavigationBar /> <UpdateJob /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/scheduling' element={<>{isLoggedIn ? (<><NavigationBar /> <Scheduling /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/scheduling-form' element={<>{isLoggedIn ? (<><NavigationBar /> <SchedulingForm /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/time-sheets' element={<>{isLoggedIn ? (<><NavigationBar /> <TimeSheets /></>) : (<Unauthorized />)}</>} />
          <Route path='/admin/profile' element={<>{isLoggedIn ? (<><NavigationBar /> <AdminProfile /></>) : (<Unauthorized />)}</>} />


          {/* <Route path='/admin/about' element={<>{isLoggedIn ? (<><NavigationBar /> <AdminAbout /></>) : (<Unauthorized />)}</>} /> */}





          {/* Homepage */}
          <Route path='/' element={<><HomePageLogin /></>} />


          {/* CandidatePanel */}
          <Route path='/candidate/register' element={<><CandidateSignupFormType /></>} />
          <Route path='/candidate/forgot-password' element={<><CandidateForgotPassword /></>} />
          <Route path='/candidate/password-reset/:token' element={<><CandidatePasswordResetForm /></>} />

          <Route path='/candidate/register/resume-upload/:jobSeekerId/:name' element={<><CandidateResumeForm /></>} />
          <Route path='/candidate/time-sheets' element={<>{isLoggedIn ? (<><CandidateNavigationBar /><TimeSheetsCandidate /></>) : (<Unauthorized />)}</>} />
          <Route path='/candidate/jobs' element={<>{isLoggedIn ? (<><CandidateNavigationBar /><CandidateJobPage /></>) : (<Unauthorized />)}</>} />
          <Route path='/candidate/jobs/:id' element={<>{isLoggedIn ? (<><CandidateNavigationBar /><CandidateJobDetails /></>) : (<Unauthorized />)}</>} />
          {/* <Route path='/candidate/jobs-apply/:id' element={<>{isLoggedIn ?(<><CandidateNavigationBar /><CandidateJobApplyPage /></>):(<Unauthorized />)}</>} /> */}
          <Route path='/candidate/profile' element={<>{isLoggedIn ? (<><CandidateNavigationBar /><CandidateProfile /></>) : (<Unauthorized />)}</>} />
          <Route path='/candidate/profile/update/:jobSeekerId' element={<>{isLoggedIn ? (<><CandidateNavigationBar /><UpdateDetails /></>) : (<Unauthorized />)}</>} />

          <Route path='/candidate/:acceptReject/:jobId/:jobSeekerId' element={<><AcceptRejectJob /></>} />




          {/* ClientPanel */}
          <Route path='/client/register' element={<><ClientSignupFormType /></>} />

          <Route path='/client/forgot-password' element={<><ClientForgotPassword /></>} />
          <Route path='/client/password-reset/:token' element={<><ClientPasswordResetForm /></>} />
          

          <Route path='/client/time-sheets' element={<>{isLoggedIn ? (<><ClientNavigationBar /><TimeSheetsCandidate /></>) : (<Unauthorized />)}</>} />
          <Route path='/client/jobs' element={<>{isLoggedIn ? (<><ClientNavigationBar /><ClientJobList /></>) : (<Unauthorized />)}</>} />
          <Route path='/client/jobs' element={<>{isLoggedIn ? (<><ClientNavigationBar /><ClientJobList /></>) : (<Unauthorized />)}</>} />
          <Route path='/client/jobs/:jobSeekerId/selected' element={<>{isLoggedIn ? (<><ClientNavigationBar /><ClientSelectedCandidate /></>) : (<Unauthorized />)}</>} />
          {/* <Route path='/client/job-applications/:jobId' element={<>{isLoggedIn ? (<><ClientNavigationBar /> <ClientJobApplication /></>) : (<Unauthorized />)}</>} /> */}
          <Route path='/client/job-applications/:jobId' element={<>{isLoggedIn ? (<><ClientNavigationBar /> <ShortListed /></>) : (<Unauthorized />)}</>} />
          <Route path='/client/job-applications/:jobId/short-listed' element={<>{isLoggedIn ? (<><ClientNavigationBar /> <ClientJobApplication /></>) : (<Unauthorized />)}</>} />
          {/* <Route path='/client/job-applications/:jobId/short-listed' element={<>{isLoggedIn ? (<><ClientNavigationBar /> <ShortListed /></>) : (<Unauthorized />)}</>} /> */}
          <Route path='/client/create-job' element={<>{isLoggedIn ? (<><ClientNavigationBar /><ClientCreateJob /></>) : (<Unauthorized />)}</>} />
          <Route path='/client/create-job/thank-you' element={<>{isLoggedIn ? (<><ClientNavigationBar /><CreateJobThankYouPage /></>) : (<Unauthorized />)}</>} />
          <Route path='/client/update-job/:jobId' element={<>{isLoggedIn ? (<><ClientNavigationBar /><ClientUpdateJob /></>) : (<Unauthorized />)}</>} />
          <Route path='/client/profile' element={<>{isLoggedIn ? (<><ClientNavigationBar /><ClientProfile /></>) : (<Unauthorized />)}</>} />
          <Route path='/client/archive' element={<>{isLoggedIn ? (<><ClientNavigationBar /><ClientArchiveJobs /></>) : (<Unauthorized />)}</>} />
          <Route path='/client/profile/update/:clientId' element={<>{isLoggedIn ? (<><ClientNavigationBar /><ClientUpdateClient /></>) : (<Unauthorized />)}</>} />

          {/* <Route path='/client/about' element={<>{isLoggedIn ? (<><ClientNavigationBar /> <AdminAbout /></>) : (<Unauthorized />)}</>} /> */}





          {/* common */}
          <Route path='/register/success/:name' element={<><RegisterSuccess /></>} />

          <Route path='*' element={<><NotFound /></>} />



        </Routes>
      </BrowserRouter>
    </>
  );
}



export default App;
