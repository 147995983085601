import React, { forwardRef, useEffect, useRef, useState } from "react";
import { candaCities, states } from '../../../../data/CandaData';

import {
  Form,
  Schema,
  SelectPicker,
  TagPicker,
  RadioGroup,
  Radio,
  Message,
  useToaster,
  AutoComplete,
  MaskedInput,
} from "rsuite";
import LocationIcon from '@rsuite/icons/Location';
import { usePlacesAutocomplete } from "../../../../Components/googleMap/useGoogleMap";
import { ChampHealthApiClient } from "../../../../config/apiClients";


const { StringType, ArrayType } = Schema.Types;

const model = Schema.Model({
  residence_name: StringType()
    .pattern(/^[a-zA-Z0-9\s.]*$/, "Please enter legal characters")
    .isRequired("This field is required."),
  position: StringType()
    .pattern(/^[a-zA-Z0-9\s.]*$/, "Please enter legal characters")
    .isRequired("This field is required."),
  street_address: StringType()
    .pattern(/^[\/a-zA-Z0-9\s.!@#&*',\-\u0080-\u052F]*$/, "Please enter legal characters")
    .isRequired("This field is required."),
  province: StringType().isRequired("This field is required."),
  city: StringType().isRequired("This field is required."),
  postalCode: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Postal Code")
    .maxLength(7, "Cannot be greater than 6 characters")
    .minLength(7, "Can't be less than 6 characters")
    .isRequired("This field is required."),
  main_phone: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Phone Number")
    .isRequired("This field is required."),
  person_name: StringType()
    .pattern(/^[a-zA-Z0-9\s.]*$/, "Please enter only characters")
    .isRequired("This field is required."),
  payable_email: StringType().isEmail("Please enter a valid email address."),
  cell_phone: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Phone Number"),
  mode_of_communication: ArrayType().isRequired("This field is required."),
  email_address: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),

  main_street_address: StringType()
    .pattern(/^[\/a-zA-Z0-9\s.!@#&*',\-\u0080-\u052F]*$/, "Please enter legal characters")
    .isRequired("This field is required."),
  main_province: StringType().isRequired("This field is required."),
  main_city: StringType().isRequired("This field is required."),
  main_postal_code: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Postal Code")
    .maxLength(7, "Cannot be greater than 6 characters")
    .minLength(7, "Can't be less than 6 characters")
    .isRequired("This field is required."),


});

const billingAddressModel = Schema.Model({
  billing_phone: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Phone Number")
    .isRequired("This field is required."),
  billing_email_address: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
  billing_street_address: StringType()
    .pattern(/^[\/a-zA-Z0-9\s.!@#&*',\-\u0080-\u052F]*$/, "Please enter legal characters")
    .isRequired("This field is required."),
  billing_province: StringType().isRequired("This field is required."),
  billing_city: StringType().isRequired("This field is required."),
  billing_postalCode: StringType().addRule((value) => {
    if (value.includes('_')) { return false; } return true;
  }, "Please Enter Complete Postal Code")
    .maxLength(7, "Cannot be greater than 6 characters")
    .minLength(7, "Can't be less than 6 characters")
    .isRequired("This field is required."),

});

const TextField = forwardRef((props, ref) => {
  const { placeholder, name, label, accepter, ...rest } = props;
  return (
    <Form.Group
      className="admin_create_client relative mb-4"
      controlId={`${name}-4`}
      ref={ref}
    >
      <Form.ControlLabel className="text-sm text-[#A1A1A1] font-semibold duration-300">
        {label}
      </Form.ControlLabel>
      <Form.Control
        className={`pt-0 pb-2 px-0 w-full text-lg font-semibold text-[#545454] bg-transparent border-0 border-b-2 border-champBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
        name={name}
        accepter={accepter}
        placeholder={placeholder}
        {...rest}
      />
    </Form.Group>
  );
});




const RetirementResidence = () => {

  const toaster = useToaster();
  const data = [
    { label: "Email", value: "Email" },
    { label: "Cell Phone", value: "Cell Phone" },
    { label: "Text messages", value: "Text messages" },
    { label: "Main Phone", value: "Main Phone" },
  ];

  const stateData = states.map((oneState) => ({ label: oneState.name, value: oneState.name }));
  const citiesData = (state_code) => (candaCities(state_code).map((city) => ({ label: city.name, value: city.name })))

  // auto suggestion api
  const [suggestions, fetchSuggestions] = usePlacesAutocomplete()


  //// detail form
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    payable_email: '',
    cell_phone: '',
    billing_address_same_as: true
  });



  //// billing form
  const billingFormRef = useRef();
  const [billingFormError, setBillingFormError] = useState({});
  const [billingFormValue, setBillingFormValue] = useState({});


  const createNewClient = async () => {
    try {
      const response = await ChampHealthApiClient.post(`/admin/clients/add`, {
        ...formValue,
        client_type: 1,
        residence_name: formValue.residence_name,
        relation: formValue.position,
        street_address: formValue.street_address,
        province: formValue.province,
        city: formValue.city,
        postal_code: (formValue.postalCode).toUpperCase(),
        main_phone: formValue.main_phone,
        name: formValue.person_name,
        payable_email: formValue.payable_email,
        cell_phone: formValue.cell_phone,
        communication_mode: formValue.mode_of_communication,
        email_address: formValue.email_address,
        billing_phone: billingFormValue.billing_phone,
        billing_email_address: billingFormValue.billing_email_address,
        billing_street_address: billingFormValue.billing_street_address,
        billing_province: billingFormValue.billing_province,
        billing_city: billingFormValue.billing_city,
        billing_postal_code: (billingFormValue.billing_postalCode).toUpperCase(),
        status: 4,
        reason: '',
      });


      if (response?.status === 200) {
        setFormValue({
          residence_name: "",
          position: "",
          street_address: "",
          province: "",
          city: "",
          main_phone: "",
          postalCode: "",
          person_name: "",
          position: "",
          payable_email: '',
          email_address: '',
          cell_phone: '',
          mode_of_communication: [],
          billing_address_same_as: 'hide'
        })
        setBillingFormValue({
          billing_email_address: "",
          billing_phone: "",
          billing_street_address: "",
          billing_province: "",
          billing_postalCode: "",
          billing_city: "",

        })
        setBillingFormError({})
        setFormError({})
        setSubmit(false)
        setDisableSubmit(false)

        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: 'topEnd', duration: 3000 })
      }


    } catch (error) {
      setSubmit(false)
      setDisableSubmit(false)
      const message = (
        <Message showIcon type="error"  closable>
          {error.response.data.message}
        </Message>
      );
      toaster.push(message, { placement: 'topEnd', duration: 3000 })


      console.log("RetirementResidence_create_client_page_error...", error);
    }

  }


  const [disableSubmit, setDisableSubmit] = useState(false)

  const [submit, setSubmit] = useState(false);

  const handleSubmit = () => {
    setSubmit(false)
    if (formValue.billing_address_same_as === true) {
      setBillingFormValue({
        billing_phone: formValue.main_phone,
        billing_email_address: formValue.email_address,
        billing_street_address: formValue.street_address,
        billing_province: formValue.province,
        billing_city: formValue.city,
        billing_postalCode: formValue.postalCode,
      });
    } else {
      if (!billingFormRef.current.check()) {
        return;
      }
    }

    if (!formRef.current.check()) {
      return;
    }


    setDisableSubmit(true)
    setSubmit(true)
  };

  if (submit) {
    createNewClient();
  }



  return (
    <div className="form-container flex flex-col space-y-4 md:py-4">
      <div className="form-heading_section  mb-2">
        <h1 className="text-[25px] font-[500] mb-4">Retirement Residence</h1>
        <hr className="border rounded bg-champDivideLine" />
      </div>

      <Form
        fluid
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        className="relative flex flex-col gap-2"
      >
        <div className="grid md:grid-cols-2 md:gap-x-16 gap-y-4">
          <TextField
            name="residence_name"
            label="Retirement Residence Name : *"
          />
          <TextField name="position" label="Position  : *" />
          <TextField
            name="street_address"
            label="Street Address : *"
            accepter={AutoComplete}
            onChange={(e) => fetchSuggestions(e)}
            data={suggestions.length ? (suggestions.map((item) => ({ label: `${item.terms[0].value}, ${item.terms[1].value}`, value: `${item.terms[0].value}, ${item.terms[1].value}` }))) : data}
            renderMenuItem={item => {
              return (
                <div>
                  <LocationIcon /> {item}
                </div>
              );
            }}
          />

          <div className="relative z-0 w-full flex justify-between space-x-8">
            <div className="w-full">
              <TextField
                name="province"
                searchable={false}
                data={stateData}
                accepter={SelectPicker}
                label="Province : *"
              />
            </div>
            <div className="w-full">
              <TextField
                name="city"
                searchable
                disabled={formValue.province ? false : true}
                data={
                  formValue.province ? citiesData(formValue.province) : data
                }
                accepter={SelectPicker}
                label="City : *"
              />
            </div>
          </div>

          <TextField name="postalCode" label="Postal Code : *" placeholder='K1W 0N3 '
            style={{ 'text-transform': 'uppercase' }}
            accepter={MaskedInput}
            mask={[/[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/]} />
          <TextField name="main_phone" label="Main Phone : *" accepter={MaskedInput}

            mask={['+', '1', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
          <TextField name="person_name" label="Your Name : *" />
          {/* <TextField
            name="payable_email"
            label="Account Payable Email Address :"
          /> */}
           <TextField name="email_address" label="Email Address : *" />
          <TextField name="cell_phone" label="Cell Phone :" accepter={MaskedInput}

            mask={['+', '1', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
          <TextField
            name="mode_of_communication"
            searchable={false}
            data={data}
            accepter={TagPicker}
            label="Mode Of Communication ( Preferred ) : *"
          />
         
          <div className="hidden relative z-0 w-full group whitespace-nowrap">
            <TextField
              name="billing_address_same_as"
              label="Billing address *"
              accepter={RadioGroup}
              inline
              className={`pt-0 pb-2 px-0 w-full text-lg font-semibold text-[#545454] bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
            >
              <Radio value={true}>Same as Above</Radio>
              <Radio value={false} className="add_new_address_btn">
                <div className="hover:cursor-pointer border border-black text-black font-normal px-4 py-1 rounded inline-flex items-center space-x-2">
                  <i className="fa-solid fa-plus"></i>
                  <span className="text-base">Add new</span>
                </div>
              </Radio>
            </TextField>
          </div>
        </div>


        <div className="mt-4">
          <h2 className="font-medium text-2xl mb-2">Main Address</h2>{" "}
        </div>
        <div
          className="relative flex flex-col space-y-24"
        >
          <div className="grid md:grid-cols-2 md:gap-x-16 gap-y-4">
            <TextField name="payable_email" label="Payable Account Email Address : " />
            <TextField
              name="main_street_address"
              label="Street Address : *"
            />
            <TextField
              name="main_province"
              searchable={false}
              data={stateData}
              accepter={SelectPicker}
              label="Province : *"
            />
            <TextField
              name="main_city"
              searchable
              disabled={formValue.main_province ? false : true}
              data={
                formValue.main_province
                  ? citiesData(formValue.main_province)
                  : []
              }
              accepter={SelectPicker}
              label="City : *"
            />
            <TextField name="main_postal_code" label="Postal Code : *" placeholder='K1W 0N3 '
              accepter={MaskedInput}
              mask={[/[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/]} />
          </div>
        </div>


      </Form>

      <div
        className={`${formValue.billing_address_same_as ? (
          " hidden"
        ) : (
          <>{formValue.billing_address_same_as ? " " : " hidden"}</>
        )
          }`}
      >
        <div>
          <h2 className="font-medium text-2xl mb-8">Billing Address</h2>{" "}
        </div>
        <Form
          fluid
          ref={billingFormRef}
          onChange={setBillingFormValue}
          onCheck={setBillingFormError}
          formValue={billingFormValue}
          model={billingAddressModel}
          className="relative flex flex-col space-y-24"
        >
          <div className="grid md:grid-cols-2 md:gap-x-16 gap-y-4">
            <TextField name="billing_phone" label="Phone : *" style={{ 'text-transform': 'uppercase' }}
              accepter={MaskedInput}
              mask={[/[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/]} />
            <TextField name="billing_email_address" label="Email Address : *" />
            <TextField
              name="billing_street_address"
              label="Street Address : *"
            />
            <TextField
              name="billing_province"
              searchable={false}
              data={stateData}
              accepter={SelectPicker}
              label="Province : *"
            />
            <TextField
              name="billing_city"
              searchable
              disabled={billingFormValue.billing_province ? false : true}
              data={
                billingFormValue.billing_province
                  ? citiesData(billingFormValue.billing_province)
                  : data
              }
              accepter={SelectPicker}
              label="City : *"
            />
            <TextField name="billing_postalCode" label="Postal Code : *" placeholder='K1W 0N3 '
              accepter={MaskedInput}
              mask={[/[A-Z]/, /[A-Z0-9]/, /[A-Z0-9]/, ' ', /[A-Z0-9]/, /[A-Z0-9]/, /[A-Z0-9]/]} />
          </div>
        </Form>
      </div>

      <button
        type="submit"
        className="text-white bg-blueBtnDark font-medium rounded mx-auto text-xl px-12 py-3 mt-8 text-center"
        onClick={handleSubmit}
        disabled={disableSubmit ? true : false} >Create client
      </button>
    </div>
  );
};

export default RetirementResidence;
