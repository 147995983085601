import React, { forwardRef } from "react";
import { Form, InputGroup, } from "rsuite";
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

const TextField = forwardRef((props, ref) => {
    const { placeholder, name, label, type = 'text', accepter, ...rest } = props;
    const [visible, setVisible] = React.useState(false);
    const handleChange = () => {
        setVisible(!visible);
    };

    return (
        <Form.Group
            className="relative candidate_register_input_group w-full"
            controlId={`${name}-4`}
            ref={ref}
        >
            <div className="text-base text-black font-medium">{label}</div>
            <div className="relative flex items-center w-full">


                {type.toLowerCase() === 'password' ? <InputGroup>
                    <Form.Control
                        className={`register_input_field text-base w-full`}
                        name={name}
                        accepter={accepter}
                        {...rest}
                        type={visible ? 'text' : 'password'}
                        placeholder={placeholder}
                    />
                    <InputGroup.Button onClick={handleChange}>
                        {visible ? <EyeIcon /> : <EyeSlashIcon />}
                    </InputGroup.Button>
                </InputGroup> : <Form.Control
                    className={`register_input_field text-base w-full`}
                    name={name}
                    accepter={accepter}
                    {...rest}
                    type={type}
                    placeholder={placeholder}
                />
                }






            </div>
        </Form.Group>
    );
});


export default TextField;