export const StringToArray = (str) => {
    if (str) {
        let string = str?.split(",");
        let array = [];
        for (let index = 0; index < string.length; index++) {
          if (string[index]) {
            array.push(Number(string[index]));
          }
        }
      
        if (array.length) {
          return array;
        } else {
          return [];
        }
    } else {
        return [];
    }
 
};
