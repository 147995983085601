import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChampHealthApiClient } from "../../../config/apiClients";

function ClientSelectedCandidate() {
  const navigate = useNavigate();
  const { jobSeekerId } = useParams()


  const [jobSeekerDetails, setJobSeekerDetails] = useState(false);


  const getJobSeeker = async () => {
    try {
      const response = await ChampHealthApiClient.get('/common/jobseeker-details/' + jobSeekerId)

      if (response?.status === 200) {
        setJobSeekerDetails(response?.data?.data[0])
      } else {

      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getJobSeeker()
  }, [])
  
  return (
    <>
      <main>
        <section className="py-8 bg-[#F1F4F8]">
          <div className="container mx-auto md:flex md:justify-between md:gap-2 lg:flex lg:justify-between w-full">
            {/* <!-- Client List Search bar --> */}
            <div
              onClick={() => navigate("/client/jobs?page=close")}
              className="hover:cursor-pointer inline-flex items-center gap-2 font-semibold text-black "
            >
              <i className="fa-solid fa-arrow-left-long text-2xl"></i>
              <h1 className="text-[30px] text-black whitespace-nowrap">
                Closed Job
              </h1>
            </div>
          </div>
        </section>

        <section className="py-8 bg-white">
          <div className="container">
            <div className="relative bg-white rounded ">
              {/* <!-- Modal body --> */}
              <div className="">
                <div className="job-seeker-details flex flex-col items-start space-y-4 p-6 ">
                  <div className="flex flex-col">
                    <div className="inline-flex items-center space-x-2 py-4 text-black">
                      <div className="bg-champAvatarBg rounded-full text-white h-12 w-12 text-xl font-medium flex justify-center items-center  text-center uppercase">
                        <p>
                          {/* {jobSeekerModalDetails?.company_name.charAt(0)} */}
                          D
                        </p>
                      </div>
                      <p className="text-xl font-medium uppercase">
                        {/* {jobSeekerModalDetails?.company_name} */}
                        dummy Text
                      </p>
                    </div>

                    {/* <p className="ml-0 pl-0 text-3xl font-medium text-black">
                      {jobSeekerModalDetails?.company_name}
                    </p> */}
                    <p className="text-[13px] text-champText400">
                      <span>
                        {/* {readableDate(jobSeekerModalDetails?.createdAt)} */}
                        Aug 23, 2022
                      </span>
                    </p>
                    <div className="space-x-3 py-4">
                      <button className="w-103 h-30 rounded-full bg-champOnBtn text-champOnText text-center font-medium text-base">
                        On
                      </button>
                      <button className="w-103 h-30 rounded-full bg-champPartTimeBtn text-center text-champPartTimeText text-base ">
                        Part Time
                      </button>
                    </div>
                  </div>
                </div>

                <div className="px-6 pb-6 flex flex-col gap-8 rounded-b">
                  <div className="flex flex-col gap-4">
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-location-dot pr-2"></i>
                      84 Nwogu Street 46988 AbisoyeVille
                    </p>
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-envelope pr-2"></i>
                      onomepeter123@gmail.com
                    </p>
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-phone pr-2"></i>
                      +234 814 228 9186
                    </p>
                  </div>

                  <div className="flex flex-col gap-4">

                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">
                        Availability:
                      </span>
                      <span className="text-base text-champText400">
                        {" "}
                        Morning
                      </span>
                    </p>
                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">
                        Transportation Mode:
                      </span>
                      <span>Public Transportation</span>
                    </p>
                    <div className="inline-flex justify-between gap-4 border-t-4 pt-4">
                      <div className="inline-flex gap-4">
                        <button className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2">
                          <span>
                            <img src="/assests/images/jobseeker-resume-icon.png" />
                          </span>
                          <span>Resume</span>
                        </button>
                        <button className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2">
                          <span>
                            <svg
                              width="18"
                              height="22"
                              viewBox="0 0 18 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.651 9.44817V18.8704C16.651 19.4863 16.4057 20.077 15.9689 20.5125C15.5321 20.948 14.9398 21.1926 14.3221 21.1926H3.4538C2.83613 21.1926 2.24376 20.948 1.807 20.5125C1.37025 20.077 1.12488 19.4863 1.12488 18.8704V3.38845C1.12488 2.77254 1.37025 2.18186 1.807 1.74634C2.24376 1.31083 2.83613 1.06616 3.4538 1.06616H8.24507C8.65671 1.06622 9.05147 1.22928 9.34257 1.51949L16.1964 8.35379C16.4874 8.64406 16.651 9.0377 16.651 9.44817Z"
                                stroke="black"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.82848 1.64111V7.39154C8.82848 7.79823 8.99561 8.18827 9.2931 8.47585C9.5906 8.76342 9.99408 8.92498 10.4148 8.92498H16.3635M4.86267 12.7586H12.7943M4.86267 16.5922H12.7943"
                                stroke="black"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          <span>Documents</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ClientSelectedCandidate;
