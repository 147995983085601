import React from 'react'

const Pagination = () => {
  return (
    <div className="flex gap-[5px] justify-end my-12">
    <button className="w-103 h-34 text-center text-lg font-medium text-gray-300 border border-champBorderPagination rounded">
        Previous
      </button>
      <button className="bg-blueBtnDark rounded w-34 h-34 text-center text-white text-lg font-medium border rounded border-blueBtnDark">
        1 
      </button>
      <button className="rounded w-34 h-34 text-center border border-champBorderPagination text-lg font-medium rounded">
        2
      </button>
      <button className="rounded w-34 h-34 text-center border border-champBorderPagination text-lg font-medium rounded">
        3
      </button>
      <button className="rounded w-34 h-34 text-center border border-champBorderPagination text-lg font-medium rounded">
        4
      </button>
      <button className="rounded w-34 h-34 text-center border border-champBorderPagination text-lg font-medium rounded">
        5
      </button>
      <button className="w-77 h-34 text-center text-[18px] font-medium border border-champBorderPagination rounded">
        Next
      </button>
    </div>
  )
}

export default Pagination