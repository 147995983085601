import React, { useEffect, useRef, useState } from "react";
import { Form, Loader, Message, Placeholder, Schema, toaster } from "rsuite";
import { ChampHealthApiClient } from "../../../config/apiClients";
import TextField from "../../../Components/TextField";

function CandidateAccountDetails({ clientDetails }) {

  const { StringType } = Schema.Types;
  const passwordModal = Schema.Model({
    password: StringType()
      .pattern(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,20}$/,
        "please enter 8 characters , smallcase, uppercase & symbols(!@#$%^&*)"
      )
      .isRequired("This field is required."),
    // oldpassword: StringType().isRequired("This field is required."),
    verifyPassword: StringType()
      .addRule((value, data) => {
        if (value !== data.password) {
          return false;
        }

        return true;
      }, "The two passwords do not match")
      .isRequired("This field is required."),
  });
  const [isChangePassword, setIsChangePassword] = useState(false)
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    password: "",
    verifyPassword: "",
  });

  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);

  const changePassword = async () => {
    try {
      const response = await ChampHealthApiClient.post(`/clients/change-password`, {
        password: formValue.verifyPassword,
        c_password: formValue.password,
      });

      if (response.status === 200) {
        setIsChangePassword(!isChangePassword)
        const message = (
          <Message showIcon type="success" header="success" closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 4000 });
      } else {

        const message = (
          <Message showIcon type="error"  closable>
            {response?.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 4000 });

      }
      setIsPasswordUpdating(!isPasswordUpdating)

    } catch (error) {
      setIsPasswordUpdating(!isPasswordUpdating)
      const message = (
        <Message showIcon type="error"  closable>
          {`${error?.response?.data.message ? error?.response?.data.message : error.message}`}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 4000 });

    }
  };

  const handleSubmit = () => {
    if (formRef.current.check()) {
      setIsPasswordUpdating(true);
      changePassword()
      return;
    }
  };




  return (
    <>
      {clientDetails ? (
        <>
          <div className=" flex flex-col space-y-10">
            <div className="hidden flex flex-col space-y-2">
              <div className="inline-flex items-center space-x-2 border-[2px]  p-6 py-6 text-black">
                <div className="bg-champAvatarBg rounded-full text-white h-10 w-10 text-xl font-medium flex justify-center items-center  text-center uppercase">
                  {" "}
                  <p>{clientDetails.residence_name.charAt(0)}</p>
                </div>
                <p className="text-xl font-medium uppercase">
                  {clientDetails.residence_name}
                </p>
              </div>
            </div>

            <div className="flex space-x-4 text-lg">
              <div className="flex space-x-2">
                <span className=" font-normal text-champText400">
                  Customer name:
                </span>
                <span className="text-black font-medium">
                  {clientDetails.name}
                </span>
              </div>
              <div className="flex space-x-2">
                <span className=" font-normal text-champText400">
                  Position:
                </span>
                <span className="text-black font-medium">
                  {clientDetails.relation}
                </span>
              </div>
            </div>

            <div className="flex flex-wrap lg:grid lg:grid-cols-2 border-[1px] border-champBorder text-black">
              <div className="flex flex-col lg:items-center h-full w-full border-[1px] border-champBorder p-4">
                <div>
                  <p className="text-start  text-champText400 font-normal text-base">
                    Main phone :{" "}
                  </p>
                  <p className="text-start text-black font-medium text-lg">
                    {clientDetails.main_phone}
                  </p>
                </div>
              </div>
              <div className="flex flex-col lg:items-center h-full w-full border-[1px] border-champBorder p-4">
                <div>
                  <p className="text-start  text-champText400 font-normal text-base">
                    Cell phone :{" "}
                  </p>
                  <p className="text-start text-black font-medium text-lg">
                    {clientDetails?.cell_phone}
                  </p>
                </div>
              </div>
              <div className="flex flex-col lg:items-center h-full w-full border-[1px] border-champBorder p-4">
                <div>
                  <p className="text-start  text-champText400 font-normal text-base">
                    Email :{" "}
                  </p>
                  <p className="text-start text-black font-medium text-lg">
                    {clientDetails.email_address}
                  </p>
                </div>
              </div>
              <div className="flex flex-col lg:items-center h-full w-full border-[1px] border-champBorder p-4">
                <div>
                  <p className="text-start  text-champText400 font-normal text-base">
                    Account Payable Email :{" "}
                  </p>
                  <p className="text-start text-black font-medium text-lg">
                    {clientDetails.payable_email}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex space-x-4 text-base">
              <div className="flex space-x-2">
                <span className=" font-normal text-champText400">
                  Mode Of Communication:
                </span>
                <span className="text-black font-medium">
                  {clientDetails.communication_mode.map((item, i) => (
                    <>
                      {clientDetails.communication_mode.length - 1 === i ? (
                        <span>{item}</span>
                      ) : (
                        <>
                          <span>{item}</span> ,
                        </>
                      )}
                    </>
                  ))}
                </span>
              </div>
            </div>

            <div className="flex space-x-4 text-base">
              <div className="flex space-x-2">
                <span className=" font-normal text-champText400">
                  <i className="fa-solid fa-location-dot pr-2"></i>
                </span>
                <span className="text-black font-medium">
                  {`${clientDetails.street_address}, ${clientDetails.postal_code}, ${clientDetails.city}, ${clientDetails.province} `}
                </span>
              </div>
            </div>


            {clientDetails?.main_city ? 
          <div className="flex flex-col text-base">
            <h2>Main Address :</h2>
            <div className="flex space-x-2">
              <span className=" font-normal text-champText400">
                <i className="fa-solid fa-location-dot pr-2"></i>
              </span>
              <span className="text-black font-medium">
                {`${clientDetails?.main_street_address}    ${clientDetails?.main_city}, ${clientDetails?.main_province} , ${clientDetails?.main_postal_code} `}
              </span>
            </div>
          </div>:''}



            <hr className="my-4 border-t border-gray-200 " />
            <div id="changePassword" className="flex items-start gap-6 w-full">
              <div className="w-[230px] whitespace-nowrap">
                <h3 className="text-xl font-medium text-[#7D7D7D]">
                  Change password:{" "}
                </h3>
              </div>
              <div className="w-full">
                {isChangePassword ? <>
                  {isPasswordUpdating ? <><div className="flex justify-between items-center mx-2 border-blueBtnDark w-full">
                    <Loader speed="fast" content="Processing...." />
                  </div></> : <div className="flex flex-col  space-y-8 justify-center w-full items-center">
                    <div className="w-full">
                      <Form
                        fluid
                        ref={formRef}
                        onChange={setFormValue}
                        onCheck={setFormError}
                        formValue={formValue}
                        model={passwordModal}
                        className="w-full flex flex-col gap-4"
                      >
                        {/* <TextField
                          name="oldpassword"
                          type="password"
                          placeholder="Old Password"
                          autoComplete="off"
                        /> */}
                        <TextField
                          name="password"
                          type="password"
                          autoComplete="off"
                        />
                        <TextField
                          name="verifyPassword"
                          type="password"
                        />
                      </Form>
                    </div>
                    <div className="flex gap-4">
                      <button
                        className="px-4 text-center m-auto border-2 py-2 rounded-[5px] text-lg font-semibold  text-black border-blueBtnDark"
                        onClick={() => setIsChangePassword(!isChangePassword)}
                      >
                        {" "}
                        Cancel
                      </button>
                      <button
                        className="px-4 text-center m-auto border-2 py-2 rounded-[5px] text-lg font-normal bg-blueBtnDark text-white border-blueBtnDark"
                        onClick={handleSubmit}
                      >
                        {" "}
                        Update
                      </button>
                    </div>

                  </div>}

                </> : <>

                  <div className="flex justify-between flex-wrap">
                    <h3 className="text-xl font-medium ">*********</h3>
                    <a
                      onClick={() => setIsChangePassword(!isChangePassword)}
                      href="#changePassword"
                      className="text-[#0066FF] text-lg font-semibold decoration-solid	!underline"
                    >
                      Edit
                    </a>
                  </div>
                </>}
                <hr className="w-full mt-4 h-[1px] rounded bg-[#ECF0F5]" />
              </div>
            </div>


            <hr className="my-4 border-t-2 border-gray-600 " />

            <div className="hidden">

              <div className="flex space-x-4 text-xl">
                <div className="flex space-x-2">
                  <span className="text-black font-medium">Billing address</span>
                </div>
              </div>

              <div className="flex space-x-4 text-base">
                <div className="flex space-x-2">
                  <span className=" font-normal text-champText400">Phone:</span>
                  <span className="text-black font-medium">
                    {clientDetails.billing_phone}
                  </span>
                </div>
                <div className="flex space-x-2">
                  <span className=" font-normal text-champText400">Email:</span>
                  <span className="text-black font-medium">
                    {clientDetails.billing_email_address}
                  </span>
                </div>
              </div>

              <div className="flex space-x-4 text-base">
                <div className="flex space-x-2">
                  <span className=" font-normal text-champText400">
                    <i className="fa-solid fa-location-dot pr-2"></i>
                  </span>
                  <span className="text-black font-medium">
                    {`${clientDetails.billing_street_address}, ${clientDetails.billing_postal_code}, ${clientDetails.billing_city}, ${clientDetails.billing_province} `}
                  </span>
                </div>
              </div>



            </div>
          </div>
        </>
      ) : (
        <>
          <div className="relative">
            <div
              className="absolute left-0 right-0 m-auto my-auto top-12 "
              style={{ textAlign: "center" }}
            >
              <Loader size="md" />
            </div>
            <Placeholder.Paragraph style={{ marginTop: 30 }} rows={5} active />
          </div>
        </>
      )}
    </>
  );
}

export default CandidateAccountDetails;
