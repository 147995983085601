import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PrivateHome from "./PrivateHome";
import RetirementResidence from "./RetirementResidence";

const CreateClient = () => {
  const navigate = useNavigate();
  const [formType, setFormType] = useState(false);

  return (
    <main>
      <section className="py-8 bg-[#F1F4F8]">
        <div className="container mx-auto md:flex md:justify-between md:gap-2 lg:flex lg:justify-between w-full ">
          {/* <!-- Client List Search bar --> */}
          <div
            className="inline-flex items-center gap-2 font-[600] hover:cursor-pointer text-black"
            onClick={() => navigate("/admin/list-client")}
          >
            <i className="fa-solid fa-arrow-left-long text-2xl"></i>
            <h1 className="text-[30px] whitespace-nowrap">
              Create Client
            </h1>
          </div>
        </div>
      </section>

      {/* <!-- Create Client Form --> */}
      <section className="py-8 bg-white">
        <div className="container">
          {/* <!-- type of form selection --> */}
          <div className="flex flex-col space-y-2 text-sm">
            <p>Client Type:</p>
            <div>
              <div className="flex">
                <div className="mb-3 xl:w-96">
                  
                  <select
                    className="form-select w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border  border border-champBorder rounded"
                    onChange={(event) => setFormType(event.target.value)}
                  >
                    <option>Select Client Type</option>
                    <option value="Retirement Residence">Retirement Residence</option>
                    <option value="Private Home">Private Home</option>
                  </select>
                </div>
              </div>
            </div>
            {formType ? <></>:<p>You have to first select the client type.</p>}
            
          </div>

          {/* <!-- type of form component --> */}

          {formType === "Retirement Residence" ? <RetirementResidence />:<>
          {formType === "Private Home" ? <PrivateHome />:<>
          {formType === "Private Home" ? <PrivateHome />:<>
          {formType === "Private Home" ? <PrivateHome />:<>
          {formType === "Private Home" ? <PrivateHome />:<></>}</>}</>}</>}</>}


        </div>
      </section>
    </main>
  );
};

export default CreateClient;
