import { useEffect, useState } from "react";
import { DateRangePicker, Message, useToaster } from "rsuite";
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";

import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import { ChampHealthApiClient } from "../../../config/apiClients";

const {
  allowedMaxDays,
  allowedDays,
  allowedRange,
  beforeToday,
  afterToday,
  combine,
} = DateRangePicker;

const Scheduling = () => {
  const toaster = useToaster();


  const datePickerStyle = { width: 260, display: "block", marginBottom: 10 };

  const predefinedRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Last 7 days",
      value: [subDays(new Date(), 6), new Date()],
      placement: "left",
    },
    {
      label: "Last 2 week",
      value: [subDays(new Date(), 14), new Date()],
      placement: "left",
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
      placement: "left",
    },
    {
      label: "This month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
    {
      label: "Last week",
      closeOverlay: false,
      value: (value) => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
        ];
      },
      appearance: "default",
    },
    {
      label: "Next week",
      closeOverlay: false,
      value: (value) => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
        ];
      },
      appearance: "default",
    },
  ];

  const createCard = (data) => {

 
    return (
      <div className="grid grid-cols-3 gap-2 items-center text-lg font-[500]">
        <p className="flex flex-col items-start py-2">
          <p className="inline-flex uppercase">
            <span className="rounded-full text-[15px] uppercase border-2 text-center px-2 font-[400] mr-2">
              {/* {data.selected_candidate.charAt(0)} */}
              S
            </span>
            {/* {data.selected_candidate} */}
            Sachin #{data}
          </p>

          <span className="ml-8 text-[15px] text-center px-2 font-[400] mr-2">
            {/* {data.jp_position} */}
            HouseKeeping
          </span>
        </p>
        <p className="">Sachin client, America Baie Ste. Anne, New Brunswick , N14 523</p>
        {/* <p className="">{data.client}, {data.address}</p> */}
        <p className="text-md border-solid  px-6 py-1 mx-auto">
          6:30 - 17:30, {data.job_shift}, Friday Mar 11
        </p>
      </div>
    );
  };

  const [data, setData] = useState([]);

      const getScheduling = async() => {
        try {
          const response =  await ChampHealthApiClient.get(`/admin/clients/scheduling`);
          if(response.status === 200){
            setData(response?.data?.data)
          }
          else{
            const message = (
              <Message showIcon type="error"  closable>
                {response?.data.message}
              </Message>
            );
            toaster.push(message, { placement: "topEnd", duration: 3000 });
          }

          
        } catch (error) {
          const message = (
            <Message showIcon type="error"  closable>
              {error?.response?.message}
            </Message>
          );
          toaster.push(message, { placement: "topEnd", duration: 3000 });
        }
      }


useEffect(()=>{
  getScheduling()
},[])
  
  return (
    <>
      <main className="main-content">
        {/* <!-- Filter and Searched Options --> */}
        <section className="py-10 bg-[#F1F4F8]">
          <div className="container md:flex md:justify-between md:gap-2 lg:flex  lg:justify-between w-full md:items-center">
            {/* <!-- Client List Search bar --> */}
            <div className="w-full md:w-1/2 lg:flex lg:items-center lg:justify-between lg:w-1/2 lg:space-x-4 xl:w-2/3 xl:justify-between my-2 ml-[4px]">
              <h1 className="font-[500] text-black lg:text-[30px] text-lg whitespace-nowrap">
                Scheduling
              </h1>
              <div className="flex justify-center items-center h-[45px] w-full lg:w-2/3">
                <DateRangePicker
                  ranges={predefinedRanges}
                  format="MM-dd-yyyy"
                  showOneCalendar
                  style={{width:'100%', display: "block", padding: "0 3px" }}
                />
              </div>
            </div>

            {/* <!-- Filters --> */}
            <div className="w-full md:w-1/2 lg:flex md:items-center lg:gap-2 lg:w-2/3 md:justify-end">
              <h1 className="lg:hidden font-[500] lg:text-[30px] text-lg whitespace-nowrap">
                filter
              </h1>
              <div className="flex flex-col md:flex-row gap-2 lg:flex-row w-full md:justify-end">
                <select
                  id="client"
                  className="h-[45px] md:w-1/3 bg-[#ffffff] px-2 border text-[#94A1CE] font-[400] text-sm rounded"
                >
                  <option defaultValue>company name</option>
                  <option value="company-1">company 1</option>
                  <option value="company-2">company 2</option>
                  <option value="company-3">company 3</option>
                  <option value="company-4">company 4</option>
                </select>

                <select
                  id="jobTitle"
                  className="h-[45px] md:w-1/3 px-4 bg-[#ffffff] border text-[#94A1CE]  font-[400] text-[15px] rounded"
                >
                  <option defaultValue>Job title</option>
                  <option value="Active">Active</option>
                  <option value="Deactive">Deactive</option>
                </select>
                <button className="h-[45px] px-8 bg-[#0066FF] border text-[#FFFFFF] font-[400] text-[15px] rounded">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* staff list and pagination section  */}
        <section className="pt-6">
          <div className="container flex flex-col">
            {/* <!-- Cards Started--> */}
            <div className="grid gap-2 my-4 grid-cols-1 divide-y-[1px] divide-[#C8D3E1] space-y-6 overflow-auto">
              <div className="grid grid-cols-3  gap-2 items-center text-lg font-[600] text-[#123168] ">
                <p className="">Name</p>
                <p className="">Client Details</p>
                <p className="mx-auto">Schedule</p>
              </div>

              {[1,2,3,4,5,6].map((member) => createCard(member))}
              {/* {data && data.map((member) => createCard(member))} */}
            </div>
            {/* <!-- Cards ended / --> */}

            {/* <!-- Pagination Started / --> */}

            <div className="flex gap-[5px] justify-end my-12">
              <button className="font-[500] text-[18px]text-center border-[1px] w-[103px] h-[34px] border-solid border-[#D9D9D9] rounded-[4px">
                Previous
              </button>
              <button className="bg-[#D9D9D9] rounded-[4px] w-[34px] h-[34px] text-center text-[18px] font-[500] border-[1px] border-solid border-[#D9D9D9] rounded-[4px]">
                1
              </button>
              <button className="rounded-[4px] w-[34px] h-[34px] text-center text-[18px] font-[500] border-[1px] border-solid border-[#D9D9D9] rounded-[4px]">
                2
              </button>
              <button className="rounded-[4px] w-[34px] h-[34px] text-center text-[18px] font-[500] border-[1px] border-solid border-[#D9D9D9] rounded-[4px]">
                3
              </button>
              <button className="rounded-[4px] w-[34px] h-[34px] text-center text-[18px] font-[500] border-[1px] border-solid border-[#D9D9D9] rounded-[4px]">
                4
              </button>
              <button className="rounded-[4px] w-[34px] h-[34px] text-center text-[18px] font-[500] border-[1px] border-solid border-[#D9D9D9] rounded-[4px]">
                5
              </button>
              <button className="rounded-[4px] w-[77px] h-[34px] text-center text-[18px] font-[500] border-[1px] border-solid border-[#D9D9D9] rounded-[4px]">
                Next
              </button>
            </div>

            {/* <!-- Pagination ended / --> */}
          </div>
        </section>
      </main>
    </>
  );
};

export default Scheduling;
