
import React, { useEffect } from 'react'

const DeleteCookies = () => {
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        // d.setTime(d.getTime() + (60*1000));
        d.setTime(d.getTime() + (0 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }




    useEffect(() => {
        setCookie("token", "", 0);
        setCookie("user", "", 0);
    }, [])

    return (
        <div className='flex flex-col items-center h-screen justify-center'>
            <p>Cookies deleted</p>
            <a className='text-blue-600 text-2xl' href='/'>Go to Home</a>
        </div>
    )
}

export default DeleteCookies