import React, { useEffect, useState } from "react";
import { DatePicker, SelectPicker } from 'rsuite';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { useNavigate } from "react-router-dom";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}




function AdminInvoiceDetails() {
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
  );
  const updateData = () => {
    if (items.length === 0) {
      setItems(data);
    }
  };

  const renderMenu = menu => {
    if (items.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <SpinnerIcon spin /> Loading...
        </p>
      );
    }
    return menu;
  };


  //// date 
   const [selectedDate, setSelecteDate] = useState(Date.now())
   const [dueDate, setDueDate] = useState('');
   const [dueDays, setDueDays] = useState(0);


   const updateDueDate = () => {
     let result = new Date(selectedDate);
     result.setDate(result.getDate() + dueDays);
     let month = result.getMonth();
     month +=1;
     if (month <= 9) {
       month = `0${month}`;
     }
     let day = result.getDate();
     if (day <= 9) {
      day = `0${day}`;
     }
     let date = `${result.getFullYear()}-${month}-${day}`;
     setDueDate(date);
   };


   useEffect(()=>{
    if(dueDays){
      updateDueDate();
    }
   },[dueDays, selectedDate]);



  return (
    <main>
      {/* <!------ Top section  ------> */}
      <section className="py-8 bg-[#F1F4F8]">
        <div className="container mx-auto md:flex md:justify-between md:gap-2 lg:flex lg:justify-between w-full">
          <div onClick={()=>navigate('/admin/list-invoices')} className="hover:cursor-pointer inline-flex items-center gap-2 font-semibold text-black ">
            <i className="fa-solid fa-arrow-left-long mt-1 text-2xl"></i>
            <h1 className="text-2xl whitespace-nowrap">Invoice detail</h1>
          </div>
        </div>
      </section>

      {/* <!------ Main Section  ------> */}
      <section className="py-8 bg-white">
        <div className="container flex flex-col space-y-4">
          <div className="flex flex-col space-y-2 text-sm">
            <p className="text-lg font-medium text-blueDarkText">Customer name: <span className="text-black font-normal">Umbrella Corporation</span></p>
          </div>

          <div className="flex flex-col space-y-2 text-sm">
            <p className="text-lg font-medium text-blueDarkText">Invoice No: 
            <span className="text-black font-normal">012545</span></p>
            
          </div>

          <div className="flex flex-col space-y-2 text-sm">
            <p className="text-lg font-medium  text-blueDarkText">
              Invoice date:<span className="text-black font-normal">17/11/2022</span></p>
            <div>
            </div>
          </div>
          
          <div className="flex flex-col space-y-2 text-sm">
            <p className="text-lg font-medium  text-blueDarkText">
              Due date:<span className="text-black font-normal">25/11/2022</span></p>
            <div>
            </div>
          </div>

          <div className="flex flex-col space-y-2 text-sm">
            <div className="flex items-start space-x-16 mt-8">
              <div className="client-details flex flex-col space-y-6">
                <div className="inline-flex space-x-4">
                  <p className="text-xl font-medium  text-black">
                    Client details
                  </p>
                </div>

                <div className="flex flex-col space-y-2 text-[#172A43] text-base">
                  <p>Stillwater Creek Retirement Residence</p>
                  <p>2018 Robertson Road, Nepean, Ontario</p>
                  <p>K2H 1C6</p>
                  <p>613-828-7575</p>
                  <p>613-726-5294</p>
                  <p>Contact Name: Sarah</p>
                </div>
              </div>

              <div className="Billing-details flex flex-col space-y-6">
                <div className="inline-flex space-x-4">
                  <p className="text-xl font-medium  text-black">
                    Billing To
                  </p>
                </div>

                <div className="flex flex-col space-y-2  text-[#172A43] text-base">
                  <p>Chartwell Inc.</p>
                  <p>7070 Derrycrest Dr.</p>
                  <p>L5W 0G5</p>
                  <p>Attn: AP Department</p>
                  <p>ap@chartwell.com</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-6 text-sm mt-16">
            <div className="overflow-x-auto">
              <table className="border-2 border-champBorder border-spacing-2 table-auto">
                <thead>
                  <tr className="text-blueDarkText font-medium text-base">
                    <th className="border-2 border-champBorder p-2">
                      Date of service
                    </th>
                    <th className="border-2 border-champBorder p-2">
                      Shift Hours
                    </th>
                    <th className="border-2 border-champBorder p-2">
                      Position
                    </th>
                    <th className="border-2 border-champBorder p-2">
                      Call In / h
                    </th>
                    <th className="border-2 border-champBorder p-2">
                      Time In / h
                    </th>
                    <th className="border-2 border-champBorder p-2">
                      Time Out / h
                    </th>
                    <th className="border-2 border-champBorder p-2">Rate $</th>
                    <th className="border-2 border-champBorder p-2">Total $</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center text-base">
                    <td className="border-2 border-champBorder p-2">
                      28-Jul-20
                    </td>
                    <td className="border-2 border-champBorder p-2">4</td>
                    <td className="border-2 border-champBorder p-2">RPN</td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2">3:00 pm</td>
                    <td className="border-2 border-champBorder p-2">
                      11:30 am
                    </td>
                    <td className="border-2 border-champBorder p-2">49.95</td>
                    <td className="border-2 border-champBorder p-2">199.80</td>
                  </tr>
                  <tr className="text-center text-base">
                    <td className="border-2 border-champBorder p-2">
                      28-Jul-20
                    </td>
                    <td className="border-2 border-champBorder p-2">4</td>
                    <td className="border-2 border-champBorder p-2">RPN</td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2">3:00 pm</td>
                    <td className="border-2 border-champBorder p-2">
                      11:30 am
                    </td>
                    <td className="border-2 border-champBorder p-2">49.95</td>
                    <td className="border-2 border-champBorder p-2">199.80</td>
                  </tr>
                  <tr className="text-center text-base">
                    <td className="border-2 border-champBorder p-2">
                      28-Jul-20
                    </td>
                    <td className="border-2 border-champBorder p-2">4</td>
                    <td className="border-2 border-champBorder p-2">RPN</td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2">3:00 pm</td>
                    <td className="border-2 border-champBorder p-2">
                      11:30 am
                    </td>
                    <td className="border-2 border-champBorder p-2">49.95</td>
                    <td className="border-2 border-champBorder p-2">199.80</td>
                  </tr>
                  <tr className="text-center text-base">
                    <td className="border-2 border-champBorder p-2">
                      28-Jul-20
                    </td>
                    <td className="border-2 border-champBorder p-2">4</td>
                    <td className="border-2 border-champBorder p-2">RPN</td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2">3:00 pm</td>
                    <td className="border-2 border-champBorder p-2">
                      11:30 am
                    </td>
                    <td className="border-2 border-champBorder p-2">49.95</td>
                    <td className="border-2 border-champBorder p-2">199.80</td>
                  </tr>
                  <tr className="text-center text-base">
                    <td className="border-2 border-champBorder p-2">
                      28-Jul-20
                    </td>
                    <td className="border-2 border-champBorder p-2">4</td>
                    <td className="border-2 border-champBorder p-2">RPN</td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2">3:00 pm</td>
                    <td className="border-2 border-champBorder p-2">
                      11:30 am
                    </td>
                    <td className="border-2 border-champBorder p-2">49.95</td>
                    <td className="border-2 border-champBorder p-2">199.80</td>
                  </tr>
                  <tr className="text-center text-base">
                    <td className="border-2 border-champBorder p-2">
                      28-Jul-20
                    </td>
                    <td className="border-2 border-champBorder p-2">4</td>
                    <td className="border-2 border-champBorder p-2">RPN</td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2">3:00 pm</td>
                    <td className="border-2 border-champBorder p-2">
                      11:30 am
                    </td>
                    <td className="border-2 border-champBorder p-2">49.95</td>
                    <td className="border-2 border-champBorder p-2">199.80</td>
                  </tr>
                  <tr className="text-center text-base">
                    <td className="border-2 border-champBorder p-2">
                      28-Jul-20
                    </td>
                    <td className="border-2 border-champBorder p-2">4</td>
                    <td className="border-2 border-champBorder p-2">RPN</td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2">3:00 pm</td>
                    <td className="border-2 border-champBorder p-2">
                      11:30 am
                    </td>
                    <td className="border-2 border-champBorder p-2">49.95</td>
                    <td className="border-2 border-champBorder p-2">199.80</td>
                  </tr>

                  <tr className="text-center text-base">
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                  </tr>

                  <tr className="text-center text-base">
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2 bg-[#F3F7FD]">
                      19.5
                    </td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                    <td className="border-2 border-champBorder p-2"></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex flex-col">
              <div className="inline-flex items-center space-x-2 justify-end ">
                <p className="text-lg font-medium  text-blueDarkText">Sub-Total:</p>
                <span className="text-black text-base">974.03 $</span>
              </div>
              <div className="inline-flex items-center space-x-2 justify-end ">
                <div className="text-lg font-medium  text-blueDarkText">
                 <span>Tax Rate:</span>
                </div>
                <div className="space-x-2 text-base font-normal  text-black">
                  <span>13%</span>
                  <span>126.00 $</span>
                </div>
              </div>
              <div className="inline-flex items-center space-x-2 justify-end ">
                <p className="text-lg font-medium  text-blueDarkText">Total:</p>
                <span className="text-black text-base">1100.03 $</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-3">
            <p className="text-xl font-medium  text-blueDarkText">
              Customer note:
            </p>
            <div className="flex">
             <p className="text-base text-black font-normal">Thank you for your business.</p>
            </div>
          </div>

          <div className="flex flex-col  my-4 space-y-2 text-sm">
            <p className="text-xl font-medium  text-blueDarkText">Email to:</p>
            <div className="flex justify-between flex-wrap">
              <div className="inline-flex items-center space-x-4">
                <div className="inline-flex items-center space-x-2">
                <span className="text-black text-[18px]">From:</span>
                <span className="text-[#696969] text-base">
                 champhealth@gmail.com
                </span>
                </div>

                <div className="inline-flex items-center space-x-2">
                   <span className="text-black text-[18px]">To:</span>
                <span className="text-[#696969] text-base"> sarahInfo@gmail.com </span>
                </div>
              </div>

              <div className="pdf-download-btn">
                <button className="inline-flex items-center py-2 px-6 space-x-2 border border-champBorderDark">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M8 11.575C7.86667 11.575 7.74167 11.554 7.625 11.512C7.50833 11.4707 7.4 11.4 7.3 11.3L3.7 7.7C3.51667 7.51667 3.425 7.28333 3.425 7C3.425 6.71667 3.51667 6.48333 3.7 6.3C3.88333 6.11667 4.12067 6.02067 4.412 6.012C4.704 6.004 4.94167 6.09167 5.125 6.275L7 8.15V1C7 0.716667 7.096 0.479 7.288 0.287C7.47933 0.0956668 7.71667 0 8 0C8.28333 0 8.521 0.0956668 8.713 0.287C8.90433 0.479 9 0.716667 9 1V8.15L10.875 6.275C11.0583 6.09167 11.296 6.004 11.588 6.012C11.8793 6.02067 12.1167 6.11667 12.3 6.3C12.4833 6.48333 12.575 6.71667 12.575 7C12.575 7.28333 12.4833 7.51667 12.3 7.7L8.7 11.3C8.6 11.4 8.49167 11.4707 8.375 11.512C8.25833 11.554 8.13333 11.575 8 11.575ZM2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V12C0 11.7167 0.0956668 11.479 0.287 11.287C0.479 11.0957 0.716667 11 1 11C1.28333 11 1.521 11.0957 1.713 11.287C1.90433 11.479 2 11.7167 2 12V14H14V12C14 11.7167 14.096 11.479 14.288 11.287C14.4793 11.0957 14.7167 11 15 11C15.2833 11 15.5207 11.0957 15.712 11.287C15.904 11.479 16 11.7167 16 12V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2Z"
                      fill="black"
                    />
                  </svg>
                  <span>Download pdf </span>
                </button>
              </div>
            </div>
          </div>

          <div className="flex space-x-4 my-20 font-medium">
          <button className="inline-flex items-center py-2 px-4 space-x-2 border border-champBorder">
                  <input
                    id="paymentReceived"
                    name="paymentReceived"
                    type="checkbox"
                    className="border border-champBorders"
                  />
                  <label htmlFor="paymentReceived">Payment Recevied</label>
                </button>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AdminInvoiceDetails;


