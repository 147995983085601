export const readableTime = (selectedDate) => {
  if (!selectedDate) {
    return;
  }

  // Create a Date object from the input date string
  const inputDate = new Date(selectedDate);

  // Define the target time zone (e.g., Canada Eastern Time - "America/Toronto")
  // const targetTimeZone = "America/Toronto";
  // const targetTimeZone = "Asia/Kolkata";

  // Convert the input date to the target time zone
  const dateInTargetTimeZone = inputDate.toLocaleString("en-US", {
    // timeZone: targetTimeZone,
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Include AM/PM indicator
  });

  return dateInTargetTimeZone;
};


