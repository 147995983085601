export const FormatedDate = (dateString) => {
    if (!dateString) {return ''}
    const date = new Date(dateString);

    // Extract the date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    // Assemble the converted date string
    const convertedDate = `${year}-${month}-${day}`;

    return convertedDate;
}