import React from 'react'
import { useParams } from 'react-router-dom'

const RegisterSuccess = () => {
    const { name } = useParams();

    return (


        <div className=" h-screen relative pb-12 overflow-y-hidden">
            <img className="-z-10 absolute w-full bottom-0" src="/assests/images/candidate-bg.png" />
            <header className="container py-4">
               <a href='/' > <img src="/logo.png" alt="health champ logo" /></a>
            </header>
            <main className="h-full container">
                <section className="relative h-full w-full">
                    <div className="h-full w-full flex justify-center items-center">
                        <div className="flex justify-center items-center flex-wrap h-full -mt-16 gap-6 text-gray-800 lg:w-1/3">
                            <div className="w-full">
                                <div className="flex flex-col justify-center items-center space-y-6 mb-6">
                                    <h1 className="font-semibold text-3xl">Thank You !</h1>
                                    <h3 className="font-normal leading-7 text-lg text-center">

                                       <p className='font-medium'> Welcome {name.charAt(0).toUpperCase() + name.slice(1)} to ChampHealth. </p>
                                        <br />
                                        Your account has been successfully created. Our team will activate it pretty soon.
                                        <br />
                                        <br />
                                        Thanks for your patience.

                                    </h3>
                                </div>



                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>

    )
}

export default RegisterSuccess