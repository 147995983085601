import React, { useEffect, useRef, useState } from "react";
import { Form, Loader, MaskedInput, Message, Nav, Placeholder, Schema, toaster } from "rsuite";
import { useNavigate } from "react-router-dom";
import { ChampHealthApiClient } from "../../../../config/apiClients";
import TextFieldBottomBorder from "../../../../Components/TextFieldBottomBorder";
import { USER_DETAIL, setCookie } from "../../../../config/headerToken";

const { StringType } = Schema.Types;

const model = Schema.Model({
    username: StringType().isRequired("This field is required."),
    email: StringType()
        .isEmail("Please enter a valid email address.")
        .isRequired("This field is required."),
    phone: StringType().addRule((value) => {
        if (value.includes('_')) { return false; } return true;
    }, "Please Enter Complete Phone Number")
        .isRequired("This field is required."),
});



function AdminProfile() {
    const navigate = useNavigate()

    const [isDetailUpdating, setIsDetailUpdating] = useState(false);
    const [readOnly, setReadOnly] = useState(true)
    const [profileDetails, setProfileDetails] = useState(false);

    const getProfileDetails = async () => {
        try {
            const response = await ChampHealthApiClient.get("/admin/profile");

            if (response?.status === 200) {
                setProfileDetails({
                    username: response?.data?.data[0].username,
                    email: response?.data?.data[0].email_id,
                    phone: response?.data?.data[0].phone,
                });
                setFormValueDetail({
                    username: response?.data?.data[0].username,
                    email: response?.data?.data[0].email_id,
                    phone: response?.data?.data[0].phone,
                })
            } else {
                const message = (
                    <Message showIcon type="error"  closable>
                        {response?.data?.message}
                    </Message>
                );
                toaster.push(message, { placement: "topEnd", duration: 3000 });
            }
        } catch (error) {
            const message = (
                <Message showIcon type="error"  closable>
                    {error.message}
                </Message>
            );
            toaster.push(message, { placement: "topEnd", duration: 3000 });
        }
    };

    useEffect(() => {
        getProfileDetails();
    }, []);



    const getUserDetail = async () => {
        try {
            const response = await ChampHealthApiClient.get(`/admin/admin-name`);
            if (response?.status === 200) {
                let data = response?.data;
                setCookie("token", USER_DETAIL?.token, 3);
                setCookie("user", JSON.stringify({
                    ...USER_DETAIL,
                    ...data
                }), 3)
                
                window.location.reload();
                
            } else {
                const message = (
                    <Message showIcon type="error"  closable>
                        {response?.response?.message}
                    </Message>
                );
                toaster.push(message, { placement: "topEnd", duration: 3000 });
            }
        } catch (error) {
            console.log("Create_job_getSingleLiveJobDetails_Error...", error);
        }
      
      }

      


    //// detail form
    const formRefDetail = useRef();
    const [formErrorDetail, setFormErrorDetail] = useState({});
    const [formValueDetail, setFormValueDetail] = useState({});

    const updateProfileDetails = async () => {
        try {
            const response = await ChampHealthApiClient.put(`/admin/update`, {
                username: formValueDetail?.username,
                email_id: formValueDetail?.email,
                phone: formValueDetail?.phone
            });

            if (response.status === 200) {
                getProfileDetails();
                getUserDetail();
                setReadOnly(true)
                const message = (
                    <Message showIcon type="success" header="success" closable>
                        {response?.data.message}
                    </Message>
                );
                toaster.push(message, { placement: "topEnd", duration: 4000 });
            } else {

                const message = (
                    <Message showIcon type="error"  closable>
                        {response?.data.message}
                    </Message>
                );
                toaster.push(message, { placement: "topEnd", duration: 4000 });

            }

            setIsDetailUpdating(false)

        } catch (error) {
            setIsDetailUpdating(!isDetailUpdating)
            const message = (
                <Message showIcon type="error"  closable>
                    {`${error?.response?.data.message ? error?.response?.data.message : error.message}`}
                </Message>
            );
            toaster.push(message, { placement: "topEnd", duration: 4000 });

        }

    }

    const handleDetailUpdate = () => {
        if (formRefDetail.current.check()) {
            setIsDetailUpdating(!isDetailUpdating);
            updateProfileDetails()
            return;
        }
    };








    const passwordModal = Schema.Model({
        password: StringType()
            .pattern(
                /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,20}$/,
                "please enter 8 characters , smallcase, uppercase & symbols(!@#$%^&*)"
            )
            .isRequired("This field is required."),
        // oldpassword: StringType().isRequired("This field is required."),
        verifyPassword: StringType()
            .addRule((value, data) => {
                if (value !== data.password) {
                    return false;
                }

                return true;
            }, "The two passwords do not match")
            .isRequired("This field is required."),
    });
    const [isChangePassword, setIsChangePassword] = useState(false)
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({
        password: "",
        verifyPassword: "",
    });

    const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);

    const changePassword = async () => {
        try {
            const response = await ChampHealthApiClient.post(`/jobseekers/change-password/`, {
                password: formValue.verifyPassword,
                c_password: formValue.password,
            });

            if (response.status === 200) {
                setIsChangePassword(!isChangePassword)
                const message = (
                    <Message showIcon type="success" header="success" closable>
                        {response?.data.message}
                    </Message>
                );
                toaster.push(message, { placement: "topEnd", duration: 4000 });
            } else {

                const message = (
                    <Message showIcon type="error"  closable>
                        {response?.data.message}
                    </Message>
                );
                toaster.push(message, { placement: "topEnd", duration: 4000 });

            }
            setIsPasswordUpdating(!isPasswordUpdating)

        } catch (error) {
            setIsPasswordUpdating(!isPasswordUpdating)
            const message = (
                <Message showIcon type="error"  closable>
                    {`${error?.response?.data.message ? error?.response?.data.message : error.message}`}
                </Message>
            );
            toaster.push(message, { placement: "topEnd", duration: 4000 });

        }
    };

    const handleSubmit = () => {
        if (formRef.current.check()) {
            setIsPasswordUpdating(true);
            changePassword()
            return;
        }
    };


    return (
        <>
            <div className=" relative">
                <section className="py-6 bg-[#F1F4F8]">
                    <div className="container divide-y divide-y-4 space-y-6">
                        <div className="flex justify-between md:flex items-center lg:items-center md:space-x-2 md:justify-between w-full lg:py-6">
                            {/* <!-- job List Search bar --> */}
                            <div className="md:w-1/2 w-full lg:flex lg:items-center lg:justify-between lg:w-1/2 lg:space-x-4 xl:w-2/3 xl:justify-between my-2 ml-[4px]">
                                <h1 className="font-semibold text-black min-w-[150px] text-3xl whitespace-nowrap my-2 md:my-0">Profile</h1>
                            </div>
                        </div>
                    </div>
                </section>


                <main className="container w-full h-full relative flex flex-col justify-center content-center">
                    <div className="-mt-[14px]">
                        <div className="flex items-center  justify-between text-black">
                            <div className="inline-flex items-center space-x-3 text-black">
                                <div className="bg-champAvatarBg rounded-full text-white h-20 w-20 text-2xl font-medium flex justify-center items-center  text-center uppercase">
                                    {" "}
                                    <p>{profileDetails?.username?.charAt(0).toUpperCase()}</p>
                                </div>
                                <p className="text-3xl font-medium uppercase">{profileDetails ? profileDetails?.username : <Loader size="sm" speed="fast" content="Loading" />}</p>
                            </div>

                            {readOnly ? <button onClick={() => {
                                setReadOnly(!readOnly)
                                setFormValueDetail({ ...profileDetails })
                            }} className="text-end border border-black rounded px-4 py-1 text-lg text-black font-medium bg-white">Edit Details</button> :


                                <div className="flex gap-4">
                                    <button
                                        className="px-4 text-center border border-black rounded px-4 py-1 text-lg text-black font-medium bg-white"
                                        onClick={() => setReadOnly(!readOnly)}
                                    >
                                        {" "}
                                        Cancel
                                    </button>
                                    <button
                                        className="px-4 text-center m-auto border-2 py-1 rounded-[5px] text-lg font-normal bg-blueBtnDark text-white border-blueBtnDark"
                                        onClick={handleDetailUpdate}
                                    >
                                        {" "}
                                        Save Changes
                                    </button>
                                </div>}
                        </div>

                    </div>

                    <section className="py-8">
                        {isDetailUpdating ? <>

                            <div className="flex justify-between text-lg items-center mx-2 border-blueBtnDark w-full">
                                <Loader speed="fast" content="Processing...." />
                            </div>
                        </> : <>
                            {readOnly ? <>
                                <div className="flex text-lg lg:items-center lg:flex-row justify-between flex-col gap-4">
                                    <div className="flex text-xl items-center gap-2">
                                        <span className="text-[#7D7D7D]">Username: </span>
                                        <span className="text-black">{profileDetails?.username}</span>
                                    </div>
                                    <div className="flex text-xl items-center gap-2">
                                        <span className="text-[#7D7D7D]">Email: </span>
                                        <span className="text-black">{profileDetails?.email}</span>
                                    </div>
                                    <div className="flex text-xl items-center gap-2">
                                        <span className="text-[#7D7D7D]">Phone: </span>
                                        <span className="text-black">{profileDetails?.phone}</span>
                                    </div>
                                </div>
                                <hr className="w-full my-6 h-[1px] rounded bg-[#ECF0F5]" />
                            </> :
                                <>
                                    <Form
                                        fluid
                                        ref={formRefDetail}
                                        onChange={setFormValueDetail}
                                        onCheck={setFormErrorDetail}
                                        formValue={formValueDetail}
                                        model={model}
                                        className="flex flex-wrap lg:flex-nowrap justify-between gap-6 w-full"
                                    >

                                        <TextFieldBottomBorder Placeholder="admin" label="Username : *" name="username" />
                                        <TextFieldBottomBorder name="email"
                                            type="email"
                                            label="Email address : *"
                                            placeholder="patrickswanson@gmail.com " />
                                        <TextFieldBottomBorder label="Phone number : *"
                                            placeholder="+1(676)576-5794"
                                            accepter={MaskedInput}
                                            mask={['+', '1', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} name="phone" />

                                    </Form>
                                </>}
                        </>}

                    </section>

                    <hr className="w-full hidden h-[1px] rounded bg-[#ECF0F5]" />

                    <section className="py-8">

                        <div id="changePassword" className="flex items-start gap-6 w-full">
                            <div className="w-full">
                                {isChangePassword ? <>
                                    <div>
                                        <h2 className="text-xl text-black font-semibold">Change password</h2>
                                        <p className="text-gray-400">Please enter your current password to change your old password</p>
                                        <hr className="w-full my-4 h-[1px] rounded bg-[#ECF0F5]" />
                                    </div>

                                    {isPasswordUpdating ? <><div className="flex justify-between items-center mx-2 border-blueBtnDark w-full">
                                        <Loader speed="fast" content="Processing...." />
                                    </div></> : <div className="flex flex-col  space-y-8 justify-center w-full items-center">
                                        <div className="w-full">
                                            <Form
                                                fluid
                                                ref={formRef}
                                                onChange={setFormValue}
                                                onCheck={setFormError}
                                                formValue={formValue}
                                                model={passwordModal}
                                                className="w-full flex flex-col gap-4"
                                            >
                                                {/* <div className="hidden flex items-center gap-6 border-b-2">
                                                    <h1 className="whitespace-nowrap text-xl font-medium w-1/2 lg:w-[20%]">Current password</h1>
                                                    <TextFieldBottomBorder
                                                        name="oldpassword"
                                                        type="password"
                                                        placeholder="Current Password"
                                                        autoComplete="off"
                                                    />
                                                </div> */}
                                                <div className="flex items-center justify-evenly gap-6 border-b-2">
                                                    <h1 className="whitespace-nowrap text-xl font-medium w-1/2 lg:w-[20%]">New password</h1>
                                                    <TextFieldBottomBorder
                                                        name="password"
                                                        type="password"
                                                        placeholder="New Password"
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="flex items-center justify-between gap-6 border-b-2">
                                                    <h1 className="whitespace-nowrap text-xl font-medium w-1/2 lg:w-[20%]">Confirm password</h1>
                                                    <TextFieldBottomBorder
                                                        name="verifyPassword"
                                                        type="password"
                                                        placeholder="Confirm New Password"
                                                    />
                                                </div>



                                            </Form>
                                        </div>
                                        <div className="flex gap-4">
                                            <button
                                                className="px-4 text-center m-auto border-2 py-2 rounded-[5px] text-lg font-semibold  text-black border-blueBtnDark"
                                                onClick={() => setIsChangePassword(!isChangePassword)}
                                            >
                                                {" "}
                                                Cancel
                                            </button>
                                            <button
                                                className="px-4 text-center m-auto border-2 py-2 rounded-[5px] text-lg font-normal bg-blueBtnDark text-white border-blueBtnDark"
                                                onClick={handleSubmit}
                                            >
                                                {" "}
                                                Update
                                            </button>
                                        </div>

                                    </div>}

                                </> : <>

                                    <div className="flex justify-between flex-wrap">



                                        <a onClick={() => setIsChangePassword(!isChangePassword)}
                                            href="#changePassword"
                                            className="text-end border border-black rounded px-4 py-1 text-lg text-black font-medium bg-white" >
                                            Change Password                    </a>
                                    </div>
                                </>}
                            </div>
                        </div>


                    </section>

                </main>
            </div>
        </>
    );
}

export default AdminProfile;
