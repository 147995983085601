import { useEffect, useState } from "react";
import SavedJobs from "./SavedJob";
import AppliedJobs from "./AppliedJob";
import LiveJob from "./LiveJob";
import { useSearchParams } from "react-router-dom";

const CandidateJobPage = () => {
  const [selectedFilterBtn, setSelectedFilterBtn] = useState('Live Jobs');


  const data = [1,2,3,4,5];

  const [searchParams, setSearchParams] = useSearchParams();


useEffect(()=>{
  if (searchParams.get('page') === 'applied') {
    setSelectedFilterBtn("Applied Jobs");
  } else if (searchParams.get('page') === 'saved') {
    setSelectedFilterBtn("Saved Jobs");
  }
  setSearchParams('')
},[])

  return (
    <>
      <main className="main-content ">
        {/* <!------ Top section  ------> */}
        <section className="pt-10 pb-5 bg-[#F1F4F8]">
          <div className="container">
            {/* <!------ selection buttons ----> */}
            <div className="grid grid-cols-2 md:flex md:justify-center gap-4 lg:flex w-full md:items-center font-semibold text-sm md:text-md">
              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Live Jobs"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Live Jobs");
                }}
              >
                Live Jobs
              </button>

              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Applied Jobs"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Applied Jobs");
                }}
              >
                Applied Jobs
              </button>

              <button
                className={
                  "border-solid rounded-full px-8 lg:px-20 py-3 border-[#C8D3E1] " +
                  (selectedFilterBtn === "Saved Jobs"
                    ? "bg-[#123168] text-white border-0"
                    : "bg-white  border-2")
                }
                onClick={() => {
                  setSelectedFilterBtn("Saved Jobs");
                }}
              >
                Saved Jobs
              </button>
            </div>
          </div>
        </section>

        {/* <!------ Main Section  ------> */}
        <section className="pt-0">
               {selectedFilterBtn === "Saved Jobs" ? <SavedJobs />: <>
               {selectedFilterBtn === "Live Jobs" ? <LiveJob  />: <>
               {selectedFilterBtn === "Applied Jobs" ? <AppliedJobs />: <>
               </> }
               </> }
               </> }
        </section>
      </main>

      
    </>
  );
};

export default CandidateJobPage;
