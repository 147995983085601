import axios from "axios";
import React, { forwardRef, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_For_File_Upload } from "../../../config/apiKeys";
import { Loader, Message, useToaster } from "rsuite";

const CandidateResumeForm = () => {
  const { jobSeekerId, name } = useParams();
  const navigate = useNavigate();
  const toaster = useToaster();

  const [isResumeUploading, setIsResumeUploading] = useState(false);
  const [isVaccineUploading, setIsVaccineUploading] = useState(false);
  const [isDiplomaUploading, setIsDiplomaUploading] = useState(false);
  const [isOtherUploading, setIsOtherUploading] = useState(false);

  const [isResumeUploaded, setIsResumeUploaded] = useState(false);
  const [isVaccineUploaded, setIsVaccineUploaded] = useState(false);
  const [isDiplomaUploaded, setIsDiplomaUploaded] = useState(false);
  const [isOtherUploaded, setIsOtherUploaded] = useState(false);


  const [documents, setDocuments] = useState({
    resume: false,
    vaccine: false,
    diploma: false,
    other: false,
  })

  const UploadFile = async (fileInput, id) => {
    let formdata = new FormData();
    formdata.append("docs", fileInput.files[0]);
    formdata.append("doc_type", id);
    formdata.append("jobseeker_id", jobSeekerId);



    let requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${API_BASE_For_File_Upload}/jobseekers/upload-docs`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response?.status) {
          const message = (
            <Message showIcon type="success" header="success" closable>
              {response?.message}
            </Message>
          );
          toaster.push(message, { placement: "topEnd", duration: 3000 });
        }

      //  setIsResumeUploading(false)
      // setIsVaccineUploading(false)
      //   setIsDiplomaUploading(false)
      //  setIsOtherUploading(false)

        setIsResumeUploaded(true)
        setIsVaccineUploaded(true)
        setIsDiplomaUploaded(true)
        setIsOtherUploaded(true)

      })
      .catch((error) => {
        const message = (
          <Message showIcon type="error"  closable>
            {error?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        setIsResumeUploading(false);
        setIsVaccineUploading(false);
        setIsDiplomaUploading(false);

      });
  };

  const handleSubmit = () => {
    navigate('/');
    const message = (
      <Message showIcon type="info" closable>
        {"Login to access your account"}
      </Message>
    );
    toaster.push(message, { placement: "topEnd", duration: 5000 });
  };



  return (
    <>
      <div className="min-h-screen w-full relative">
        <img
          className="-z-10 absolute w-full bottom-0"
          src="/assests/images/candidate-bg.png"
        />

        <div className="container relative pb-12">
          <header className="py-4">
            <img onClick={() => navigate('/')} className="hover:cursor-pointer" src="/logo.png" alt="health champ logo" />
          </header>
          <main className="w-full h-full relative flex justify-center content-center">
            <div className="flex flex-col space-y-8 w-2/3 text-black m-auto mt-16 justify-center content-center items-center">
              <h3 className="font-semibold text-3xl text-center">
                Lets create your resume
              </h3>

              <div className="flex flex-col space-y-8 justify-center w-full items-center">
                <div className="w-full text-center flex flex-col space-y-8 justify-center">
                  <label
                    htmlFor="myFile"
                    className="hover:cursor-pointer w-full text-center m-auto border py-2 rounded-[5px] text-lg font-normal text-blueBtnDark border-blueBtnDark"
                  >
                    <input
                      className="hidden"
                      type="file"
                      name="myFile"
                      id="myFile"
                      onChange={(e) => {
                        setDocuments((prevState) => ({ ...prevState, resume: e.target }))
                        UploadFile(e.target,1)
                        setIsResumeUploading(true);
                      }}
                    />
                    {isResumeUploading ? (
                      <>
                        <div className="flex justify-between items-center mx-2">
                          <p className="overflow-auto w-2/3">
                            {documents?.resume?.files[0]?.name}</p>
                          <p className="inline-flex items-center gap-2">
                            <button onClick={(e) => {
                              e.preventDefault();
                              setDocuments((prevState) => ({ ...prevState, resume: false }))
                              setIsResumeUploading(false)
                            }} className="inline-flex items-start"><i className="text-red-600 fa fa-window-close"></i>
                            </button>
                            <button onClick={(e) => {
                              UploadFile(documents?.resume, 1);
                              e.preventDefault();
                            }} className="hidden inline-flex items-start"><i className="text-green-600 fa fa-upload"></i>
                            </button></p>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <i className="fa-solid fa-file-arrow-up mr-2"></i>{" "}
                        Upload Resume
                      </>
                    )}
                  </label>
                  <label
                    htmlFor="vaccineFile"
                    className="hover:cursor-pointer w-full text-center m-auto border py-2 rounded-[5px] text-lg font-normal text-blueBtnDark border-blueBtnDark"
                  >
                    <input
                      className="hidden"
                      type="file"
                      name="vaccineFile"
                      id="vaccineFile"
                      onChange={(e) => {
                        setDocuments((prevState) => ({ ...prevState, vaccine: e.target }))
                        UploadFile(e.target,2)
                        setIsVaccineUploading(true);
                      }}
                    />
                    {isVaccineUploading ? (
                      <div className="flex justify-between items-center mx-2">
                        <p className="overflow-auto w-2/3">
                          {documents?.vaccine?.files[0]?.name}</p>
                        <p className="inline-flex items-center gap-2">
                          <button onClick={(e) => {
                            e.preventDefault();
                            setDocuments((prevState) => ({ ...prevState, vaccine: false }))
                            setIsVaccineUploading(false)
                          }} className="inline-flex items-start"><i className="text-red-600 fa fa-window-close"></i>
                          </button>
                          <button onClick={(e) => {
                            UploadFile(documents?.vaccine, 1);
                            e.preventDefault();
                          }} className="hidden inline-flex items-start"><i className="text-green-600 fa fa-upload"></i>
                          </button></p>
                      </div>
                    ) : (
                      <>
                        <i className="fa-regular fa-file-lines mr-2"></i>{" "}
                        Vaccine Certifications
                      </>
                    )}
                  </label>

                  <label
                    htmlFor="diplomaFile"
                    className="hover:cursor-pointer w-full text-center m-auto border py-2 rounded-[5px] text-lg font-normal text-blueBtnDark border-blueBtnDark"
                  >
                    <input
                      className="hidden"
                      type="file"
                      name="diplomaFile"
                      id="diplomaFile"
                      onChange={(e) => {
                        setDocuments((prevState) => ({ ...prevState, diploma: e.target }))
                        UploadFile(e.target,3)
                        setIsDiplomaUploading(true);
                      }}
                    />
                    {isDiplomaUploading ? (
                      <div className="flex justify-between items-center mx-2">
                        <p className="overflow-auto w-2/3">
                          {documents?.diploma?.files[0]?.name}</p>
                        <p className="inline-flex items-center gap-2">
                          <button onClick={(e) => {
                            e.preventDefault();
                            setDocuments((prevState) => ({ ...prevState, diploma: false }))
                            setIsDiplomaUploading(false)
                          }} className="inline-flex items-start"><i className="text-red-600 fa fa-window-close"></i>
                          </button>
                          <button onClick={(e) => {
                            UploadFile(documents?.diploma, 1);
                            e.preventDefault();
                          }} className="hidden inline-flex items-start"><i className="text-green-600 fa fa-upload"></i>
                          </button></p>
                      </div>
                    ) : (
                      <>
                        <i className="fa-regular fa-file-lines mr-2"></i>{" "}
                        Diploma
                      </>
                    )}
                  </label>

                  <label
                    htmlFor="otherFile"
                    className="hover:cursor-pointer w-full text-center m-auto border py-2 rounded-[5px] text-lg font-normal text-blueBtnDark border-blueBtnDark"
                  >
                    <input
                      className="hidden"
                      type="file"
                      name="otehrFile"
                      id="otherFile"
                      onChange={(e) => {
                        setDocuments((prevState) => ({ ...prevState, other: e.target }))
                        UploadFile(e.target,4)
                        setIsOtherUploading(true);
                      }}
                    />
                    {isOtherUploading ? (
                      <div className="flex justify-between items-center mx-2">
                        <p className="overflow-auto w-2/3">
                          {documents?.other?.files[0]?.name}</p>
                        <p className="inline-flex items-center gap-2">
                          <button onClick={(e) => {
                            e.preventDefault();
                            setDocuments((prevState) => ({ ...prevState, other: false }))
                            setIsOtherUploading(false)
                          }} className="inline-flex items-start"><i className="text-red-600 fa fa-window-close"></i>
                          </button>
                          <button onClick={(e) => {
                            UploadFile(documents?.other, 4);
                            e.preventDefault();
                          }} className="hidden inline-flex items-start"><i className="text-green-600 fa fa-upload"></i>
                          </button></p>
                      </div>
                    ) : (
                      <>
                        <i className="fa-regular fa-file-lines mr-2"></i>{" "}
                        Other Documents
                      </>
                    )}
                  </label>
                  <a onClick={() => navigate('/register/success/' + name)} className="text-black font-medium hover:cursor-pointer">
                    Will do it later, Skip for now
                  </a>
                </div>
                <button
                  className={`w-full text-center m-auto border-2 py-2 rounded-[5px] text-lg font-normal border-blueBtnDark ${isResumeUploaded || isVaccineUploaded || isDiplomaUploaded || isOtherUploaded ? 'bg-blueBtnDark text-white ' : ' text-blueBtnDark'}`}
                  onClick={() => navigate('/register/success/' + name)}
                  disabled={isResumeUploaded || isVaccineUploaded || isDiplomaUploaded || isOtherUploaded ? false : true}
                >
                  {" "}
                  Next
                </button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default CandidateResumeForm;
