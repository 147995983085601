import React, { forwardRef, useState } from "react";
import { Form, Toggle, } from "rsuite";

const TextFieldBottomBorder = forwardRef((props, ref) => {
  const { placeholder, name, label, accepter, onToggle,checked,  ...rest } = props;


  return (
    <Form.Group
      className="admin_create_client relative w-full"
      controlId={`${name}-4`}
      ref={ref}
    >
      <Form.ControlLabel className="text-sm text-[#A1A1A1] font-semibold duration-300 !flex justify-between w-full">
        <span> {label}</span>
        {name === 'date' ? <span >
          <Toggle  checked={checked} onChange={(res) => {
            onToggle(res)
          }} size="md" checkedChildren="More Than 1" unCheckedChildren="One Day" />

        </span> : ''}

      </Form.ControlLabel>

      <Form.Control
        className={`pt-0 pb-2 px-0 w-full text-lg font-semibold text-[#545454] bg-transparent border-0 border-b-2 border-champBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
        name={name}
        accepter={accepter}
        placeholder={placeholder}
        {...rest}
      />
    </Form.Group>
  );
});


export default TextFieldBottomBorder;

