import axios from "axios";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import CandidateDetailForm from './CandidateDetailForm';
import { Form, SelectPicker,
  Message,
  useToaster, 
  Schema,
  TagPicker,
} from 'rsuite';
import { API_BASE_URL } from "../../../config/apiKeys";
import { ChampHealthApiClient } from "../../../config/apiClients";

const { StringType, ArrayType, NumberType } = Schema.Types;

const model = Schema.Model({
  company_name: NumberType().isRequired("This field is required."),
  street_address: StringType()
    .pattern(
      /^[\/a-zA-Z0-9\s.!(),@#&*',\-\u0080-\u052F]*$/,
      "Please enter legal characters"
    )
    .isRequired("This field is required."),
  postion: ArrayType().isRequired("This field is required."),
  schedule: ArrayType().isRequired("This field is required."),
  job_type: NumberType().isRequired("This field is required."),
  payment_schedule: NumberType().isRequired("This field is required."),
  job_shift: NumberType().isRequired("This field is required."),
  candidates_required: StringType()
    .pattern(/^[1-9][0-9]*$/, "Please enter only Positive Numbers")
    .isRequired("This field is required."),
});

const TextField = forwardRef((props, ref) => {
  const { placeholder, name, label, accepter, ...rest } = props;
  return (
    <Form.Group
      className="candidate_form relative mb-4"
      controlId={`${name}-4`}
      ref={ref}
    >
      <Form.ControlLabel className="text-sm text-[#A1A1A1] font-semibold duration-300">
        {label}
      </Form.ControlLabel>
      <Form.Control
        className={`pt-0 pb-2 px-0 w-full text-lg font-semibold text-[#545454] bg-transparent border-0 border-b-2 border-champBorder appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
        name={name}
        accepter={accepter}
        placeholder={placeholder}
        {...rest}
      />
    </Form.Group>
  );
});




const CandidateSignupFormType = () => {
    const navigate = useNavigate();
    const [formType, setFormType] = useState(false);
    const [callForm, setCallForm] = useState(false);



  //// detail form
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({});


    const handleClick = () => {
      setCallForm(true)
    }

    const [jobPostions, setJobPostions] = useState(false);
     // fetch AllJobPostion
  const getAllJobPostion = async () => {
    try {
      const response = await ChampHealthApiClient.get(`/common/job-positions`);
      if (response?.status === 200) {
        setJobPostions(
          response?.data?.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.log("candidate_register_getAllJobPostion_response_Error...", error);
    }
  };


  useEffect(() => {
    getAllJobPostion();
  }, []);

  return (
    <>
    <div className="min-h-screen w-full relative">
      <img className="-z-10 absolute w-full bottom-0" src="/assests/images/candidate-bg.png" />


      {callForm ? (<>{
        formType ? <CandidateDetailForm position={formType} />:<></>}
      </>): (<div className="container relative">
        <header className="py-4">
          <img onClick={() => navigate('/')} className="hover:cursor-pointer" src="/logo.png" alt="health champ logo" />
        </header>
        <main className="w-full h-full relative flex justify-center content-center">

          <div className="flex flex-col space-y-8  lg:w-1/2 text-black m-auto mt-16 justify-center content-center items-center">
            <h3 className="font-semibold text-3xl text-center">What Position you are looking for?</h3>
            
            <div className="w-9/12 flex flex-col space-y-8">
            <Form
              fluid
              ref={formRef}
              onChange={setFormValue}
              onCheck={setFormError}
              formValue={formValue}
              model={model}
              className="relative flex flex-col space-y-4"
            >
              <div className="grid md:grid-cols-1 md:gap-x-16 ">
                <TextField
                  name="postion"
                  searchable={false}
                  data={jobPostions ? jobPostions : []}
                  accepter={TagPicker}
                  placeholder="Position"
                  onChange={(event)=>{
                    setFormType(event)
                  }}
                  className="border-2 border-blueBtnDark py-2 w-full px-4 py-2 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border border-champBorder rounded"
                />

                </div>

                <button disabled={formType ? false:true} className={`border-2 py-2 rounded-[5px] text-lg font-normal  ${formType ? " bg-blueBtnDark text-white border-blueBtnDark ":" border-champInputBorder"}`} onClick={handleClick}>Next</button>
            </Form>

        </div>
          </div>

        </main>
        <button onClick={()=>navigate('/')} className="text-white border-blueBtnDark px-3 py-1 bg-blueBtnDark text-base font-normal rounded-[5px] mt-20 lg:mt-36  hover:text-white"><i className="fa-solid fa-arrow-left pr-2"></i> Back</button>
      </div>)}
     



    </div>
    </>
  );
}

export default CandidateSignupFormType