import React from "react";
import ResidentialForm from "./ResidentialForm";
import PrivateHomeForm from "./PrivateHomeForm";


const ClientDetailForm = ({ clientType }) => {
  return clientType === 1 ? <ResidentialForm clientType={clientType} />:<PrivateHomeForm clientType={clientType}/>
};

export default ClientDetailForm;
