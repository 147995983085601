import { Schema } from "rsuite";

const { StringType, NumberType, MixedType, ArrayType } = Schema.Types;

export const jobSeekerProfileModal = Schema.Model({
  firstName: StringType().isRequired("This field is required."),
  lastName: StringType().isRequired("This field is required."),
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
  phone: StringType()
    .addRule((value) => {
      if (value.includes("_")) {
        return false;
      }
      return true;
    }, "Please Enter Complete Phone Number")
    .isRequired("This field is required."),
  address: StringType().isRequired("This field is required."),
  // availability: NumberType().isRequired("This field is required."),
  jobtype: NumberType().isRequired("This field is required."),
  language: StringType().isRequired("This field is required."),
  province: StringType().isRequired("This field is required."),
  city: StringType().isRequired("This field is required."),
  postalCode: StringType()
    .addRule((value) => {
      if (value.includes("_")) {
        return false;
      }
      return true;
    }, "Please Enter Complete Postal Code")
    .maxLength(7, "Cannot be greater than 6 characters")
    .minLength(7, "Can't be less than 6 characters")
    .isRequired("This field is required."),

  mon: MixedType().when((schema) => {
    let { mon, tue, wed, thu, fri, sat, sun } = schema;
    return !(mon?.value?.length ||
      tue?.value?.length ||
      wed?.value?.length ||
      thu?.value?.length ||
      fri?.value?.length ||
      sat?.value?.length ||
      sun?.value?.length)
      ? ArrayType().isRequired("This field is required")
      : ArrayType();
  }),
  tue: MixedType().when((schema) => {
    let { mon, tue, wed, thu, fri, sat, sun } = schema;

    return !(mon?.value?.length ||
      tue?.value?.length ||
      wed?.value?.length ||
      thu?.value?.length ||
      fri?.value?.length ||
      sat?.value?.length ||
      sun?.value?.length)
      ? ArrayType().isRequired("This field is required")
      : ArrayType();
  }),
  wed: MixedType().when((schema) => {
    let { mon, tue, wed, thu, fri, sat, sun } = schema;
    return !(mon?.value?.length ||
      tue?.value?.length ||
      wed?.value?.length ||
      thu?.value?.length ||
      fri?.value?.length ||
      sat?.value?.length ||
      sun?.value?.length)
      ? ArrayType().isRequired("This field is required")
      : ArrayType();
  }),
  thu: MixedType().when((schema) => {
    let { mon, tue, wed, thu, fri, sat, sun } = schema;
    return !(mon?.value?.length ||
      tue?.value?.length ||
      wed?.value?.length ||
      thu?.value?.length ||
      fri?.value?.length ||
      sat?.value?.length ||
      sun?.value?.length)
      ? ArrayType().isRequired("This field is required")
      : ArrayType();
  }),
  fri: MixedType().when((schema) => {
    let { mon, tue, wed, thu, fri, sat, sun } = schema;
    return !(mon?.value?.length ||
      tue?.value?.length ||
      wed?.value?.length ||
      thu?.value?.length ||
      fri?.value?.length ||
      sat?.value?.length ||
      sun?.value?.length)
      ? ArrayType().isRequired("This field is required")
      : ArrayType();
  }),
  sat: MixedType().when((schema) => {
    let { mon, tue, wed, thu, fri, sat, sun } = schema;
    return !(mon?.value?.length ||
      tue?.value?.length ||
      wed?.value?.length ||
      thu?.value?.length ||
      fri?.value?.length ||
      sat?.value?.length ||
      sun?.value?.length)
      ? ArrayType().isRequired("This field is required")
      : ArrayType();
  }),
  sun: MixedType().when((schema) => {
    let { mon, tue, wed, thu, fri, sat, sun } = schema;
    return !(mon?.value?.length ||
      tue?.value?.length ||
      wed?.value?.length ||
      thu?.value?.length ||
      fri?.value?.length ||
      sat?.value?.length ||
      sun?.value?.length)
      ? ArrayType().isRequired("This field is required")
      : ArrayType();
  }),
});

export const daysArray = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 7, label: "Sunday" },
];
