import React, { forwardRef, useState } from 'react'



const CustomPagination = forwardRef((props, ref) => {
    const { count = 0, clickFuntion} = props;

    const [currentPage, setCurrentPage] = useState(1);

    const itemPerPage = 6;
    if (count < itemPerPage) return;

    const emptyArray = new Array((Math.ceil(count / itemPerPage)));
    for (let i = 0; i < emptyArray.length; i++) {
        emptyArray[i] = i + 1;
    }



    const handleClick = (btn) => {
        if (btn === 'prev' || btn === 'next') {
            btn === 'prev' ? (<>
                {setCurrentPage(currentPage - 1)}
                {clickFuntion(((itemPerPage * (currentPage - 1)) - itemPerPage), itemPerPage )}
            </>) : (<>
                {setCurrentPage(currentPage + 1)}
                {clickFuntion(((itemPerPage * (currentPage + 1)) - itemPerPage), itemPerPage )}
            </>)
        } else {
            setCurrentPage(btn);
            clickFuntion(((itemPerPage * btn) - itemPerPage), itemPerPage)
        }

    }


    return (
        <>
            {count > itemPerPage ? <div className="flex gap-[5px] justify-end my-12">
                {currentPage > 1 ? <button onClick={() => handleClick('prev')} className="w-103 h-34 text-center text-lg font-medium  border border-champBorderPagination rounded">
                    Previous
                </button> : <button disabled={true} className="w-103 h-34 text-center text-lg font-medium text-gray-300 border border-champBorderPagination rounded">
                    Previous
                </button>}

                {emptyArray.map((i) => (
                    <>
                        {
                            currentPage === i ? <button className="bg-blueBtnDark rounded w-34 h-34 text-center text-white text-lg font-medium border rounded border-blueBtnDark">
                                {i}
                            </button> : <button onClick={() => handleClick(i)} className="rounded w-34 h-34 text-center border border-champBorderPagination text-lg font-medium rounded">
                                {i}
                            </button>

                        }

                    </>
                ))}

                {currentPage < (Math.ceil(count / itemPerPage)) ? <button onClick={() => handleClick('next')} className="w-77 h-34 text-center text-[18px] font-medium border border-champBorderPagination rounded">
                    Next
                </button> : <button disabled={true} className="w-77 h-34 text-gray-300 text-center text-[18px] font-medium border border-champBorderPagination rounded">
                    Next
                </button>}

            </div> : ''}


        </>
    )
});

export default CustomPagination