import React, { useState } from "react";
import { Loader, Message, Modal, Placeholder, useToaster } from "rsuite";
import { readableDate } from "../readableDate";
import { ChampHealthApiClient } from "../../config/apiClients";
import { useNavigate, useParams } from "react-router-dom";
import { ExtractDaysOfWeek, getTimeOfDaysAsString } from "../AvailbilityValues";
import { StringToArray } from "../stringToArray";
import { readableTime } from "../readableTime";

const JobApplicantCard = ({ jobSeeker, isAdmin = true, jobTitle }) => {
  const toaster = useToaster();
  const { jobId } = useParams();
  const navigate = useNavigate();
  const baseUrl = isAdmin ? "/admin" : "/client";
  const apiBaseUrl = isAdmin ? "/admin" : "/clients";

  // job seeker modal details
  const [jobSeekerModal, setJobSeekerModal] = useState(false);
  const [jobSeekerModalDetails, setJobSeekerModalDetails] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = (job) => {
    setJobSeekerModal(job);
    setOpenModal(true);
    getSingleJobSeekerDetails(job.jobseeker_id);
  };
  const handleClose = () => {
    setJobSeekerModal(false);
    setOpenModal(false);
    setJobSeekerModalDetails(false);
  };

  const selectJobSeeker = async (id) => {
    try {
      const response = await ChampHealthApiClient.post(
        apiBaseUrl + `/select-jobseekers/`,
        {
          job_id: jobId,
          jobseeker_id: id,
        }
      );

      if (response?.status === 200) {
        const message = (
          <Message showIcon type="success" header="Success" closable>
            {response.data.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
        navigate(
          isAdmin
            ? baseUrl + "/list-jobs?page=live"
            : baseUrl + "/jobs?page=live"
        );
      }
    } catch (error) {
      const message = (
        <Message showIcon type="error" closable>
          {error.response.data.message}
        </Message>
      );
      toaster.push(message, { placement: "topEnd", duration: 3000 });

      console.log("list_client_error_page...", error);
    }
  };

  /// get function for jobs
  const getSingleJobSeekerDetails = async (id) => {
    try {
      const response = await ChampHealthApiClient.get(
        `/common/jobseeker-details/${id}`
      );

      if (response?.status === 200) {
        setJobSeekerModalDetails(response?.data?.data[0]);
      } else {
        const message = (
          <Message showIcon type="error" closable>
            {response?.response?.message}
          </Message>
        );
        toaster.push(message, { placement: "topEnd", duration: 3000 });
      }
    } catch (error) {
      console.log("Create_job_getSingleLiveJobDetails_Error...", error);
    }
  };

  return (
    <>
      <div
        key={jobSeeker.id}
        className="card rounded bg-white border-champBorder border hover:shadow-lg shadow-clientCard p-6 relative"
      >
        <div className="h-full flex flex-col justify-between space-y-2 relative">
          <div className="flex flex-col justify-between space-y-2 relative">

            <div className="inline-flex justify-between items-start">
              <p
                onClick={() => handleOpen(jobSeeker)}
                className="font-[600] text-[25px]  hover:cursor-pointer"
              >
                {jobSeeker.name}
              </p>
            </div>

            <div
              className="flex flex-col justify-between space-y-2 hover:cursor-pointer"
              onClick={() => handleOpen(jobSeeker)}
            >
              <p className="font-medium text-base">{jobTitle || jobSeeker.job_position}</p>
              <div className=" inline-flex items-center text-center gap-2 text-sm font-medium">
                <span className="p-2 flex items-center h-30 rounded-full bg-champPartTimeBtn text-center text-champPartTimeText">
                  {jobSeeker.availability}
                </span>
                {jobSeeker.job_shifts && (
                  <span className="p-2 flex items-center h-30 rounded-full bg-champOnBtn text-champOnText text-center">
                    {jobSeeker.job_shifts}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <p className="font-normal text-base">
                  <i className="fa-solid fa-location-dot pr-2"></i>
                  {jobSeeker?.address}
                </p>
                <p className="font-normal text-base">
                  <i className="fa-solid fa-envelope pr-2"></i>
                  {jobSeeker?.email_id}
                </p>
                <p className="font-normal text-base">
                  <i className="fa-solid fa-phone pr-2"></i>
                  {jobSeeker?.phone}
                </p>
                <p className="font-normal text-base flex">
                  <span className="font-semibold">Applied for: </span>
                  <p className="flex flex-col ml-2">
                    {jobSeeker?.applied_dates?.split(",").map((item) => {
                      return <span>{readableDate(item)}</span>;
                    })}
                  </p>
                </p>
                <p className="font-normal text-base flex">
                  <span className="font-semibold">Time : </span>
                  <p className="flex flex-col ml-2">
                    {jobSeeker?.schedule ? (
                      <>
                        {"  "}
                        {readableTime(JSON.parse(jobSeeker?.schedule)[0]) +
                          " - " +
                          readableTime(JSON.parse(jobSeeker?.schedule)[1])}
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </p>
                <p className="font-normal text-base flex">
                  <span className="font-semibold">Note : </span>
                  <div className="flex flex-col ml-2">
                    {jobSeeker?.notes ? <div
                      dangerouslySetInnerHTML={{
                        __html: jobSeeker?.notes,
                      }}
                    /> : <span className="text-gray-300">Not Mentioned</span>}


                  </div>
                </p>
              </div>
            </div>
          </div>

          <div className=" border-t  pt-2 inline-flex justify-between gap-4">
            <div className="inline-flex gap-4">
              {jobSeeker?.resume ? (
                <a
                  href={jobSeeker?.resume}
                  className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                >
                  <span>
                    <img src="/assests/images/jobseeker-resume-icon.png" />
                  </span>
                  <span>Resume</span>
                </a>
              ) : (
                ""
              )}
              {jobSeeker?.vaccine ? (
                <a
                  href={jobSeeker?.vaccine}
                  className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                >
                  <span>
                    <svg
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.651 9.44817V18.8704C16.651 19.4863 16.4057 20.077 15.9689 20.5125C15.5321 20.948 14.9398 21.1926 14.3221 21.1926H3.4538C2.83613 21.1926 2.24376 20.948 1.807 20.5125C1.37025 20.077 1.12488 19.4863 1.12488 18.8704V3.38845C1.12488 2.77254 1.37025 2.18186 1.807 1.74634C2.24376 1.31083 2.83613 1.06616 3.4538 1.06616H8.24507C8.65671 1.06622 9.05147 1.22928 9.34257 1.51949L16.1964 8.35379C16.4874 8.64406 16.651 9.0377 16.651 9.44817Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.82848 1.64111V7.39154C8.82848 7.79823 8.99561 8.18827 9.2931 8.47585C9.5906 8.76342 9.99408 8.92498 10.4148 8.92498H16.3635M4.86267 12.7586H12.7943M4.86267 16.5922H12.7943"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span>Vaccine</span>
                </a>
              ) : (
                ""
              )}
              {jobSeeker?.diploma ? (
                <a
                  href={jobSeekerModalDetails?.diploma}
                  className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                >
                  <span>
                    <img src="/assests/images/jobseeker-resume-icon.png" />
                  </span>
                  <span>Diploma</span>
                </a>
              ) : (
                ""
              )}
              {jobSeeker?.other_doc ? (
                <a
                  href={jobSeekerModalDetails?.other_doc}
                  className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                >
                  <span>
                    <svg
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.651 9.44817V18.8704C16.651 19.4863 16.4057 20.077 15.9689 20.5125C15.5321 20.948 14.9398 21.1926 14.3221 21.1926H3.4538C2.83613 21.1926 2.24376 20.948 1.807 20.5125C1.37025 20.077 1.12488 19.4863 1.12488 18.8704V3.38845C1.12488 2.77254 1.37025 2.18186 1.807 1.74634C2.24376 1.31083 2.83613 1.06616 3.4538 1.06616H8.24507C8.65671 1.06622 9.05147 1.22928 9.34257 1.51949L16.1964 8.35379C16.4874 8.64406 16.651 9.0377 16.651 9.44817Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.82848 1.64111V7.39154C8.82848 7.79823 8.99561 8.18827 9.2931 8.47585C9.5906 8.76342 9.99408 8.92498 10.4148 8.92498H16.3635M4.86267 12.7586H12.7943M4.86267 16.5922H12.7943"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span>Other Document</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div>
              {jobSeeker?.status === 13 ? (
                <button className="hover:cursor-default	 px-3 py-1 rounded-[2px] bg-green-600 text-center text-white text-base">
                  Offer Accepted
                </button>
              ) : (
                ""
              )}
              {jobSeeker?.status === 14 ? (
                <button className="hover:cursor-default	 px-3 py-1 rounded-[2px] bg-red-600 text-center text-white text-base">
                  Offer Denied
                </button>
              ) : (
                ""
              )}
              {jobSeeker?.status === 12 ? (
                <button className="hover:cursor-default px-3 py-1 rounded bg-orange-500 text-center text-white text-base">
                  Shortlisted
                </button>
              ) : (
                ""
              )}
              {jobSeeker?.status === 16 ? (
                <button className="hover:cursor-default px-3 py-1 rounded bg-orange-500 text-center text-white text-base">
                  Pending at JobSeeker
                </button>
              ) : (
                ""
              )}
              {![12, 13, 14, 16].includes(jobSeeker?.status) ||
                (![13, 14, 16].includes(jobSeeker?.status) && !isAdmin) ? (
                <button
                  onClick={() => {
                    selectJobSeeker(jobSeeker?.jobseeker_id);
                  }}
                  className="hover:cursor-pointer px-3 py-1 rounded bg-blueBtnDark text-center text-white text-base"
                >
                  Select
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal open={openModal} onClose={handleClose}>
        {jobSeekerModalDetails ? (
          <>
            <div className="relative bg-white rounded shadow ">
              {/* <!-- Modal header --> */}
              <div className="inline-flex w-full p-4 px-6 justify-end border-b rounded-t bg-blueBgDark">
                <h3 className="w-full text-xl font-semibold text-white uppercase">
                  JobSeeker Detail
                </h3>
                <button
                  onClick={() => {
                    handleClose();
                  }}
                  type="button"
                  className="w-8 text-gray-400 bg-transparent hover:bg-blue-500 hover:text-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              {/* <!-- Modal body --> */}
              <div className="">
                <div className="job-seeker-details flex flex-col items-start space-y-4 p-6 ">
                  <div className="flex flex-col">
                    <p className="font-normal text-base text-[#FF0F00]">
                      {jobSeekerModalDetails?.reason}{" "}
                    </p>
                    <div className="inline-flex items-center space-x-2 py-4 text-black">
                      <div className="bg-champAvatarBg rounded-full text-white h-10 w-10 text-xl font-medium flex justify-center items-center  text-center uppercase">
                        <p>{jobSeekerModalDetails?.name.charAt(0)}</p>
                      </div>
                      <p className="text-xl font-medium uppercase">
                        {jobSeekerModalDetails?.name}
                      </p>
                    </div>

                    {/* <p className="ml-0 pl-0 text-3xl font-medium text-black">
                      {jobSeekerModalDetails?.company_name}
                    </p> */}
                    <p className="text-[13px] text-champText400">
                      <span>
                        {readableDate(jobSeekerModalDetails?.createdAt)}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="px-6 pb-6 flex flex-col gap-8 rounded-b">
                  <div className="flex flex-col gap-4">
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-location-dot pr-2"></i>
                      {`${jobSeekerModalDetails?.address}, ${jobSeekerModalDetails?.city
                        }, ${jobSeekerModalDetails?.province
                        }, ${jobSeekerModalDetails?.postal_code.toUpperCase()}`}
                    </p>
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-envelope pr-2"></i>
                      {jobSeekerModalDetails?.email_id}
                    </p>
                    <p className="font-normal text-base">
                      <i className="fa-solid fa-phone pr-2"></i>
                      {jobSeekerModalDetails?.phone}
                    </p>
                  </div>

                  <div className="flex flex-col gap-4">
                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">Positions:</span>
                      <span className="text-base">
                        {" "}
                        {jobSeekerModalDetails?.job_position}
                      </span>
                    </p>

                    {/* <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">
                        Applied jobs:
                      </span>
                      <span className="text-base">
                        {" "}
                        {jobSeekerModalDetails?.applied_jobs || 0}
                      </span>
                    </p> */}
                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">
                        Availability:
                      </span>
                      <span className="text-base text-champText400">
                        {" "}
                        <div className="text-base font-medium text-black ml-2">
                          {Object.entries(
                            ExtractDaysOfWeek(jobSeekerModalDetails)
                          ).map(([key, value]) => (
                            <p>
                              <span>{key}</span> :{" "}
                              <span className="text-base font-normal">
                                {value
                                  ? getTimeOfDaysAsString(value)
                                  : "Not Available"}
                              </span>
                            </p>
                          ))}
                        </div>
                      </span>
                    </p>

                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">Job Type:</span>
                      <span className="text-base text-champText400">
                        {" "}
                        {jobSeekerModalDetails?.job_type}
                      </span>
                    </p>
                    <p className="font-normal text-base flex flex-col">
                      <span className="text-2xl font-medium">Language:</span>
                      <span className="text-base text-champText400">
                        {" "}
                        {jobSeekerModalDetails?.language}
                      </span>
                    </p>

                    <div className="inline-flex justify-between gap-4 border-t-4 pt-4">
                      <div className="inline-flex gap-4">
                        {jobSeekerModalDetails?.resume ? (
                          <a
                            href={jobSeekerModalDetails?.resume}
                            className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                          >
                            <span>
                              <img src="/assests/images/jobseeker-resume-icon.png" />
                            </span>
                            <span>Resume</span>
                          </a>
                        ) : (
                          ""
                        )}
                        {jobSeekerModalDetails?.vaccine ? (
                          <a
                            href={jobSeekerModalDetails?.vaccine}
                            className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                          >
                            <span>
                              <svg
                                width="18"
                                height="22"
                                viewBox="0 0 18 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.651 9.44817V18.8704C16.651 19.4863 16.4057 20.077 15.9689 20.5125C15.5321 20.948 14.9398 21.1926 14.3221 21.1926H3.4538C2.83613 21.1926 2.24376 20.948 1.807 20.5125C1.37025 20.077 1.12488 19.4863 1.12488 18.8704V3.38845C1.12488 2.77254 1.37025 2.18186 1.807 1.74634C2.24376 1.31083 2.83613 1.06616 3.4538 1.06616H8.24507C8.65671 1.06622 9.05147 1.22928 9.34257 1.51949L16.1964 8.35379C16.4874 8.64406 16.651 9.0377 16.651 9.44817Z"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.82848 1.64111V7.39154C8.82848 7.79823 8.99561 8.18827 9.2931 8.47585C9.5906 8.76342 9.99408 8.92498 10.4148 8.92498H16.3635M4.86267 12.7586H12.7943M4.86267 16.5922H12.7943"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                            <span>Vaccine</span>
                          </a>
                        ) : (
                          ""
                        )}
                        {jobSeekerModalDetails?.diploma ? (
                          <a
                            href={jobSeekerModalDetails?.diploma}
                            className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                          >
                            <span>
                              <img src="/assests/images/jobseeker-resume-icon.png" />
                            </span>
                            <span>Diploma</span>
                          </a>
                        ) : (
                          ""
                        )}
                        {jobSeekerModalDetails?.other_doc ? (
                          <a
                            href={jobSeekerModalDetails?.other_doc}
                            className="border border-black rounded-lg bg-white text-black text-base font-medium px-2 py-1 inline-flex gap-2"
                          >
                            <span>
                              <svg
                                width="18"
                                height="22"
                                viewBox="0 0 18 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.651 9.44817V18.8704C16.651 19.4863 16.4057 20.077 15.9689 20.5125C15.5321 20.948 14.9398 21.1926 14.3221 21.1926H3.4538C2.83613 21.1926 2.24376 20.948 1.807 20.5125C1.37025 20.077 1.12488 19.4863 1.12488 18.8704V3.38845C1.12488 2.77254 1.37025 2.18186 1.807 1.74634C2.24376 1.31083 2.83613 1.06616 3.4538 1.06616H8.24507C8.65671 1.06622 9.05147 1.22928 9.34257 1.51949L16.1964 8.35379C16.4874 8.64406 16.651 9.0377 16.651 9.44817Z"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.82848 1.64111V7.39154C8.82848 7.79823 8.99561 8.18827 9.2931 8.47585C9.5906 8.76342 9.99408 8.92498 10.4148 8.92498H16.3635M4.86267 12.7586H12.7943M4.86267 16.5922H12.7943"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                            <span>Other Document</span>
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="relative">
              <div
                className="absolute left-0 right-0 m-auto my-auto top-12 "
                style={{ textAlign: "center" }}
              >
                <Loader size="md" />
              </div>
              <Placeholder.Paragraph
                style={{ marginTop: 30 }}
                rows={5}
                active
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default JobApplicantCard;
