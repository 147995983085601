import { Input, InputGroup, Grid, Row, Col } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { forwardRef } from 'react';



 const CustomInputWithButton = forwardRef((props, ref) => {
    const { placeholder, name, ...rest } = props;


    return <InputGroup  inside className='inline-flex items-center border-2 border-[#c9ddf8] hover:border-2'>
        <InputGroup.Button style={{height:'100%'}}>
            <SearchIcon />
        </InputGroup.Button>
        <Input {...rest} name={name} placeholder={placeholder} />
    </InputGroup>
});


export default CustomInputWithButton