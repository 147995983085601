import React, { useEffect, useState } from "react";
import { Loader, Modal, Placeholder, Message, toaster } from "rsuite";

import { readableDate } from "../readableDate";
import { useNavigate } from "react-router-dom";
import { ChampHealthApiClient } from "../../config/apiClients";
import { readableTime } from "../readableTime";
import { ObjectDate } from "../objectDate";

function JobDetailModal({ id = 0, open = false, onClose }) {
    const navigate = useNavigate();

    const [modalJobDetails, setModalJobDetails] = useState(false);
    const handleClose = () => {
        onClose();
        setModalJobDetails(false);
    };

    const getSingleLiveJobDetails = async (id) => {
        try {
            const response = await ChampHealthApiClient.get(
                `/jobseekers/details/${id}`
            );
            if (response?.status === 200) {
                setModalJobDetails(response?.data?.data[0]);
            }
        } catch (error) {
            console.log("Create_job_getSingleLiveJobDetails_Error...", error);
        }
    };

    useEffect(() => {
        if (!id) {
            return;
        } else {
            getSingleLiveJobDetails(id);
        }
    }, [id]);

    function generateDateHTMLModal(jobDate, startJobDate, scheduleJSON) {
        const endDate = new Date(jobDate);
        const schedule = scheduleJSON; // Parse the JSON schedule here
        if (startJobDate) {
            const startDate = startJobDate;
            const dateHTMLs = [];

            for (
                let currentDate = new Date(startDate);
                currentDate <= endDate;
                currentDate.setDate(currentDate.getDate() + 1)
            ) {
                const formattedDate = readableDate(currentDate);
                const startTime = readableTime(schedule[0]);
                const endTime = readableTime(schedule[1]);

                const html = (
                    <p key={formattedDate}>
                        <span>
                            <span className="font-semibold">Date: </span>
                            <span className="text-[#7D7D7D]">{formattedDate}</span>
                        </span>
                        <br />
                        <span>
                            <span className="font-semibold">Time: </span>
                            <span className="text-[#7D7D7D]">
                                {startTime} - {endTime}
                            </span>
                        </span>
                    </p>
                );

                dateHTMLs.push(html);
            }
            return dateHTMLs;
        } else {
            const formattedDate = readableDate(endDate);
            const startTime = readableTime(schedule[0]);
            const endTime = readableTime(schedule[1]);

            const html = (
                <p>
                    <span>
                        <span className="font-semibold">Date: </span>
                        <span className="text-[#7D7D7D]">{formattedDate}</span>
                    </span>
                    <br />
                    <span>
                        <span className="font-semibold">Time: </span>
                        <span className="text-[#7D7D7D]">
                            {startTime} - {endTime}
                        </span>
                    </span>
                </p>
            );

            return html;
        }
    }

    return (
        <>
            {/* job details modal */}
            <Modal open={open} onClose={handleClose}>
                {modalJobDetails ? (
                    <>
                        <div className="relative bg-white rounded shadow ">
                            {/* <!-- Modal header --> */}
                            <div className="inline-flex w-full p-4 px-6 justify-end border-b rounded-t bg-blueBgDark">
                                <h3 className="w-full text-xl font-semibold text-white uppercase">
                                    {modalJobDetails?.jp_position}
                                </h3>
                                <button
                                    onClick={() => {
                                        handleClose();
                                    }}
                                    type="button"
                                    className="w-8 text-gray-400 bg-transparent hover:bg-blue-500 hover:text-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                                    data-modal-hide="defaultModal"
                                >
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>

                            {/* <!-- Modal body --> */}
                            <div className="">
                                <div className="job-seeker-details flex flex-col items-start space-y-6 p-6 ">
                                    <div className="flex flex-col">
                                        <p className="ml-0 pl-0 text-3xl font-medium text-black">
                                            {modalJobDetails?.name}
                                        </p>
                                        <p className="text-[12px] text-champText400">
                                            <span>{readableDate(modalJobDetails?.createdAt)}</span>
                                        </p>
                                    </div>
                                </div>

                                <div className="p-6 flex flex-col gap-8 border-t-4 border-gray-200 rounded-b">
                                    <div className="flex flex-col gap-4">
                                        <p className="font-normal text-base">
                                            <i className="fa-solid fa-location-dot pr-2"></i>
                                            {modalJobDetails?.address}
                                        </p>
                                    </div>

                                    <div className="flex flex-col gap-4">
                                        <p className="font-normal text-base flex flex-col">
                                            <span className="text-2xl font-medium">
                                                Employment Type:
                                            </span>
                                            <span className="text-base text-black">
                                                {" "}
                                                {modalJobDetails?.job_type}
                                            </span>
                                        </p>
                                        <p className="font-normal text-base flex flex-col">
                                            <span className="text-2xl font-medium">Shift:</span>
                                            <span className="text-base text-champText400">
                                                {" "}
                                                {modalJobDetails?.job_shift}
                                            </span>
                                        </p>
                                        <p className="font-normal text-base flex flex-col">
                                            <span className="text-2xl font-medium">Schedule:</span>
                                            <p>
                                                {modalJobDetails &&
                                                    generateDateHTMLModal(
                                                        modalJobDetails?.job_date,
                                                        modalJobDetails?.start_job_date,
                                                        modalJobDetails?.schedule
                                                    )}
                                            </p>
                                        </p>
                                        <div className="font-normal text-base flex flex-col">
                                            <span className="text-2xl font-medium">
                                                Job description:
                                            </span>
                                            {modalJobDetails?.job_description ? (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: modalJobDetails?.job_description,
                                                    }}
                                                />
                                            ) : (
                                                <span className="text-gray-300"> Not Mentioned</span>
                                            )}
                                        </div>

                                        {modalJobDetails?.applied_dates ? (
                                            <div className="font-normal text-base flex flex-col">
                                                <span className="text-2xl font-medium mb-2">
                                                    Application Information:
                                                </span>

                                                <p className="font-normal text-base flex">
                                                    <span className="font-semibold">Applied for: </span>
                                                    <p className="flex flex-col ml-2">
                                                        {modalJobDetails?.applied_dates
                                                            ?.split(",")
                                                            .map((item) => {
                                                                return <span>{readableDate(item)}</span>;
                                                            })}
                                                    </p>
                                                </p>

                                                <p className="font-normal text-base flex">
                                                    <span className="font-semibold">Time : </span>
                                                    <p className="flex flex-col ml-2">
                                                        {modalJobDetails?.schedule ? (
                                                            <>
                                                                {"  "}
                                                                {readableTime((modalJobDetails?.schedule)[0]) +
                                                                    " - " +
                                                                    readableTime((modalJobDetails?.schedule)[1])}
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </p>
                                                </p>

                                                <div className="font-normal text-base flex">
                                                    <span className="font-semibold mr-2">
                                                        Note : {"  "}
                                                    </span>
                                                    {modalJobDetails?.notes ? (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: modalJobDetails?.notes,
                                                            }}
                                                        />
                                                    ) : (
                                                        <span className="text-gray-300">
                                                            {" "}
                                                            Not Mentioned
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="relative">
                            <div
                                className="absolute left-0 right-0 m-auto my-auto top-12 "
                                style={{ textAlign: "center" }}
                            >
                                <Loader size="md" />
                            </div>
                            <Placeholder.Paragraph
                                style={{ marginTop: 30 }}
                                rows={5}
                                active
                            />
                        </div>
                    </>
                )}
            </Modal>
        </>
    );
}

export default JobDetailModal;
