export const ObjectDate = (selectedDate) => {
  if (!selectedDate) {
    return;
  }

  // Create a Date object from the input date string
  const inputDate = new Date(selectedDate);

  // Define the target time zone (e.g., Canada Eastern Time - "America/Toronto")
  // const targetTimeZone = "America/Toronto";

  // // Convert the input date to the target time zone
  // const dateInTargetTimeZone = new Date(inputDate.toLocaleString("en-US", {
  //   // timeZone: targetTimeZone
  // }));

  return inputDate;
};
